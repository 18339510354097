<template>
  <div>
    <ShippingCompanyHead
        :navTabs="navTabs"
        @changeTab="(data) => $emit('changeTab', data)"
        @reload="$emit('reload')"
    />

    <div class="table-filter-wrap">

      <ShippingCompanyTable
          v-if="navTabs.shippingCompany.active"
          :countFilterParams="countFilterParams"
          @showMore="$emit('showMore')"
          @reload="$emit('reload')"
      />

      <ShippingCompanyForOrderTable
          v-if="navTabs.shippingCompanyForOrder.active"
          :countFilterParams="countFilterParams"
          @showMore="$emit('showMore')"
          @reload="$emit('reload')"
      />
      <ShippingMethodsForOrderTable
          v-if="navTabs.shippingMethodsForOrder.active"
          :countFilterParams="countFilterParams"
          @showMore="$emit('showMore')"
          @reload="$emit('reload')"
      />
    </div>
  </div>
</template>

<script>


import ShippingCompanyHead from "./ShippingCompanyHead/ShippingCompanyHead";
import ShippingCompanyTable from "./ShippingCompanyTableSection/ShippingCompanyTable/ShippingCompanyTable";
import ShippingCompanyForOrderTable from "./ShippingCompanyTableSection/ShippingCompanyForOrderTable/ShippingCompanyForOrderTable";
import ShippingMethodsForOrderTable
  from "@/components/modules/ShippingCompanyModule/components/ShippingCompanyTable/ShippingCompanyTableAdmin/ShippingCompanyTableSection/ShippingMethodsForOrderTable/ShippingMethodsForOrderTable";

export default {
  name: "ShippingCompanyAdmin",

  components: {
    ShippingMethodsForOrderTable,
    ShippingCompanyHead,
    ShippingCompanyTable,
    ShippingCompanyForOrderTable,
  },

  props: {
    navTabs: Object,
    countFilterParams: Number,
    filterGetParams: Object,
  },

  data() {
    return {
      showFilter: false,
      filterId: '',
      filterName: '',
      forPage: 10,
      page: 1,
      activeTab: 1,
    }
  },

  methods: {
    closeFilter() {
      this.showFilter = false
    },

    toggleFilter() {
      this.showFilter = !this.showFilter
    },

    changeShopType(index) {
      this.$emit('changeShopType', index)
    },


    changeFilter(data) {
      this.$emit('changeFilter', data)
    },

    changeTab(id) {
      this.activeTab = id
    },
  }
}
</script>

<style scoped>

</style>

<template>
  <div class="detail-page">
    <div class="detail-page__left">
      <CardLeftBlock
          :name="$t('disposal_id.localization_value.value')"
          :value="'#'+DO.data.Order.getId()"
          :backgroundImage="'product-admin'"
      >
        <template slot="body">
          <div class="card-detail-left__date mt-1">
            {{ new Date() | moment("DD MMM, YYYY") }}
          </div>
        </template>
      </CardLeftBlock>
    </div>

    <div class="detail-page__right">
      <CardRightBlock>
        <template slot="header">
          <div class="order-create__head">
            <div class="order-create__head-line">
              <div class="order-create__header-col"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['disposal_BackToDisposalOrders',])"></div>
                <router-link :to="$store.getters.GET_PATHS.warehouseDisposalOrders" class="order-create__head-back">
                  <LinkBack
                      :value="$t('disposal_BackToDisposalOrders.localization_value.value')"
                  />
                </router-link>
              </div>
            </div>
            <div class="order-create__head-title"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['disposal_DisposalOrders',])"></div>
              {{ $t('disposal_DisposalOrders.localization_value.value') }}
            </div>
            <div class="order-create__head-txt mb-0"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['disposal_DisposalOrdersPlease',])"></div>
              {{ $t('disposal_DisposalOrdersPlease.localization_value.value') }}
            </div>
          </div>
        </template>

        <template slot="body">
          <div class="order-create__section">
            <div class="custom-row" v-if="$store.getters.getIsAdminRights === 'admin'">
              <div class="custom-col custom-col--33 custom-col--md-100">
                <UserSelect
                    :serverError="false"
                    :validationUser="DO.data.User.validation.user"
                    :validationTxtUser="DO.data.User.validationTxt.user"
                    :validationTranslateUser="DO.data.User.validationTranslate.user"
                    :userSelected="DO.data.User.user"
                    :typeSelect="'users'"
                    :userEmail="true"
                    :disabled="true"
                    @changeUser="changeUser"
                />
              </div>
            </div>

            <div class="order-create__row custom-row">
              <Products
                  :DO="DO"
                  :products="DO.data.productArray"
              />
            </div>
            <Price
                v-if="isAdmin"
                :DO="DO"
            />
            <Comment
                :DO="DO"
            />
          </div>

        </template>
        <template slot="footer">
          <div class="order-create__footer">

            <div class="order-create__footer-link">
              <router-link :to="$store.getters.GET_PATHS.warehouseDisposalOrders"
                           class="site-link site-link--alt"
              >
                {{ $t('common_cancel.localization_value.value') }}
              </router-link>
            </div>

            <div class="order-create__footer-btn ml-auto"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit"
                   @click="editTranslate(['common_SaveInDrafts', 'common_drafts', 'common_savingInDrafts', 'common_update', 'common_complete'])"></div>

              <MainButton
                  v-if="DO.data.Order.getStatus() === 'draft' && !isAdmin"
                  class="order-create__footer-btn-i secondary"
                  :value="$t('common_SaveInDrafts.localization_value.value')"
                  :tooltip="true"
                  @click.native="$emit('saveInDraft', { edit: true, draft: true })"
              >
                <template slot="tooltip">
                  <p>
                    <b>{{ $t('common_drafts.localization_value.value') }}</b>
                  </p>
                  <p>
                    {{ $t('common_savingInDrafts.localization_value.value') }}
                  </p>
                </template>
              </MainButton>

<!--              <MainButton-->
<!--                  v-if="DO.data.Order.getStatus() !== 'draft' && isAdmin"-->
<!--                  class="order-create__footer-btn-i secondary"-->
<!--                  :value="$t('common_save.localization_value.value')"-->
<!--                  @click.native="$emit('prepareSave', { edit: true, draft: false })"-->
<!--              >-->
<!--              </MainButton>-->

              <MainButton
                  v-if="DO.data.Order.getStatus() !== 'completed' || isAdmin"
                  class="order-create__footer-btn-i"
                  :value="$t('common_update.localization_value.value')"
                  @click.native="$emit('prepareSave', { edit: true, draft: false })"
              />

              <MainButton
                  v-if="isAdmin && DO.data.Order.getStatus() !== 'completed'"
                  class="order-create__footer-btn-i"
                  :value="$t('common_complete.localization_value.value')"
                  @click.native="$emit('prepareSave', { edit: true, draft: false, complete: true })"
              />
            </div>

          </div>
        </template>
      </CardRightBlock>

    </div>
  </div>
</template>

<script>
import CardLeftBlock from "../../../../../../components/coreComponents/CardLeftBlock/CardLeftBlock.vue";
import MainButton from "../../../../../../components/UI/buttons/MainButton/MainButton";
import CardRightBlock from "../../../../../coreComponents/CardRightBlock/CardRightBlock.vue";
import LinkBack from "../../../../../UI/buttons/LinkBack/LinkBack.vue";
import Products from "../../chunks/Products/Products";
import Comment from "../../chunks/Comment/Comment";
import UserSelect from "@/components/coreComponents/UserSelect/UserSelect";
import {Product} from "@/components/globalModels/Product";
import Price from "@/components/modules/DisposalOrdersModule/components/chunks/Price/Price";
// import SelectProductBlock from "../../../../../coreComponents/SelectProductBlock/SelectProductBlock";

export default {
  name: "DisposalOrdersEditBlock",

  components: {
    UserSelect,
    Comment,
    Products,
    // SelectProductBlock,
    CardLeftBlock,
    MainButton,
    CardRightBlock,
    LinkBack,
    Price,
  },

  props: {
    DO: {
      type: Object,
      required: () => {
        return true;
      },
    },
  },

  data() {
    return {}
  },

  methods: {

    changeUser(user) {
      this.DO.data.productArray = [new Product()]
      this.DO.data.User.setUser(user);
    },

    changeValueSum(data) {
      this.valueSum = data
    },

    changeImg(files) {
      this.files = files
    },

    changeRadio(val) {
      this.radioVal = val
    },

    changeValueQuantity() {
      console.log('changeValueQuantity')
    },
  }
}
</script>

<style lang="scss">

.hide-upc {
  opacity: 0.5;
  pointer-events: none;
}

.product-progress-steps {
  max-width: 625px;
}

</style>

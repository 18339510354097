var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"detail-page reverse"},[_c('div',{staticClass:"detail-page__left"},[_c('CardLeftBlock',{staticClass:"clear-992",attrs:{"backgroundImage":'product-admin'}},[_c('template',{slot:"body"},[_c('div',{staticClass:"user-info-admin__large"},[(_vm.APCD.data.createdAt)?[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.APCD.data.createdAt,"DD MMM, YYYY"))+" ")]:[_vm._v(" "+_vm._s(_vm._f("moment")(new Date(),"DD MMM, YYYY"))+" ")]],2)])],2)],1),_c('div',{staticClass:"detail-page__right"},[_c('CardRightBlock',[_c('template',{slot:"header"},[_c('div',{staticClass:"order-create__head"},[_c('div',{staticClass:"order-create__head-line"},[_c('div',{staticClass:"order-create__header-col",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                  'apcDelcon_backTo',
                  ])}}}),_c('router-link',{staticClass:"detail-page__head-back",attrs:{"to":_vm.$store.getters.GET_PATHS.reportsAPCDelcon}},[_c('LinkBack',{attrs:{"value":_vm.$t('apcDelcon_backTo.localization_value.value')}})],1)],1)]),_c('div',{staticClass:"order-create__head-title",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                  'apcDelcon_apcDelconConso',
                  ])}}}),_vm._v(" "+_vm._s(_vm.$t('apcDelcon_apcDelconConso.localization_value.value'))+" ")]),_c('div',{staticClass:"order-create__head-txt mb-2"})])]),_c('template',{slot:"body"},[_c('div',{staticClass:"order-create__section"},[_c('ScannerBlock',{attrs:{"APCD":_vm.APCD,"ordersFBM":_vm.ordersFBM}}),_c('OrdersFBMBlock',{attrs:{"APCD":_vm.APCD,"ordersFBM":_vm.ordersFBM}}),_c('FieldsBlock',{attrs:{"APCD":_vm.APCD,"shippingCompanies":_vm.shippingCompanies}}),_c('div',{staticClass:"order-create__row custom-row"},[_c('div',{staticClass:"custom-col custom-col--md-100",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                  'apcDelcon_comment',
                  ])}}}),_c('TextareaDefault',{attrs:{"label":_vm.$t('apcDelcon_comment.localization_value.value')},model:{value:(_vm.APCD.data.comment),callback:function ($$v) {_vm.$set(_vm.APCD.data, "comment", $$v)},expression:"APCD.data.comment"}})],1)])],1)]),_c('template',{slot:"footer"},[_c('div',{staticClass:"order-create__footer"},[_c('div',{staticClass:"order-create__footer-link",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                  'common_cancel',
                  ])}}}),_c('router-link',{staticClass:"site-link site-link--alt",attrs:{"to":_vm.$store.getters.GET_PATHS.reportsAPCDelcon}},[_vm._v(" "+_vm._s(_vm.$t('common_cancel.localization_value.value'))+" ")])],1),_c('div',{staticClass:"order-create__footer-btn ml-auto",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                  'common_save',
                  ])}}}),_c('MainButton',{staticClass:"order-create__footer-btn-i",attrs:{"value":_vm.$t('common_save.localization_value.value')},nativeOn:{"click":function($event){return _vm.$emit('saveAPCDelcon', true)}}})],1)])])],2)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
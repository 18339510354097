<template>
  <div>
    <ConsolidationUnionHead
        :showFilter="showFilter"
        :navTabs="navTabs"
        :countFilterParams="countFilterParams"
        :shippingCompanies="shippingCompanies"
        @toggleFilter="toggleFilter"
        @filter="$emit('filter')"
        @changeTab="(data) => $emit('changeTab', data)"
        @reload="$emit('reload')"
        @selectAll="selectAll"
        @setTrackForSelectedUnions="(selectedOption, trackNumber) => $emit('setTrackForSelectedUnions', selectedOption, trackNumber)"
        @acceptInMinnesota="$emit('acceptInMinnesota')"
    />

    <div class="table-filter-wrap">

      <ConsolidationUnionFilter
          :showFilter="showFilter"
          :navTabs="navTabs"
          :filterGetParams="filterGetParams"
          :warehousesData="warehouses"
          :shippingCompanies="shippingCompanies"
          @close="closeFilter"
          @changeFilter="data => $emit('changeFilter', data)"
          @resetFilter="$emit('resetFilter')"
      />

      <ConsolidationUnionTable
          :filterGetParams="filterGetParams"
          :countFilterParams="countFilterParams"
          :selectedNow="selectedNow"
          :navTabs="navTabs"
          :consoUsers="consoUsers"
          :consoTags="consoTags"
          :adminUsers="adminUsers"
          @showMore="$emit('showMore')"
          @resetFilter="$emit('resetFilter')"
          @reload="$emit('reload')"
          @removeItem="(id) => { $emit('removeItem', id) }"
          @acceptInMinnesota="(id) => { $emit('acceptInMinnesota', id) }"
          @getConsolidationUnionProform="(id) => { $emit('getConsolidationUnionProform', id) }"
          @getConsolidationUnionProformByState="(id, state) => { $emit('getConsolidationUnionProformByState', id, state) }"
          @downloadFiles="type => $emit('downloadFiles', type)"
      />
    </div>
  </div>
</template>

<script>


  import ConsolidationUnionHead from "./ConsolidationUnionHead/ConsolidationUnionHead";
  import ConsolidationUnionFilter from "./ConsolidationUnionFilter/ConsolidationUnionFilter";
  import ConsolidationUnionTable from "./ConsolidationUnionTable/ConsolidationUnionTable";
  import {mixinDetictingMobile} from "@/mixins/mobileFunctions";

  export default {
    name: "ConsolidationUnionTableUser",

    components: {
      ConsolidationUnionHead,
      ConsolidationUnionFilter,
      ConsolidationUnionTable,
    },

    mixins: [mixinDetictingMobile],

    props: {
      typeShops: String,
      countFilterParams: Number,
      filterGetParams: Object,
      navTabs: Object,
      consoUsers: Array,
      consoTags: Array,
      adminUsers: Array,
      shippingCompanies: Array,
      warehouses: Array,
    },

    data(){
      return{
        showFilter: false,
        selectedNow: false,
      }
    },

    computed: {
      getActiveTab() {
        return this._.find(this.navTabs, {active: true})
      }
    },

    watch: {
      filterGetParams(newVal) {
        this.filterGetParams = newVal
      },
      countFilterParams(newVal) {
        this.countFilterParams = newVal
      },

      getActiveTab(newVal) {
        if(this.isMobileFunc()) {
          if(newVal.name === 'sent') {
            this.showFilter = true
          } else {
            this.showFilter = false
          }
        }
      },
    },

    methods: {
      closeFilter() {
        this.showFilter = false
      },

      toggleFilter() {
        this.showFilter = !this.showFilter
      },

      selectAll(val) {
        this.selectedNow = val
      },
    }
  }
</script>

<style scoped>

</style>

<template>
  <modal
          @close="$emit('close')"
          class="custom-popup-modal custom-popup shipping-company-popup"
  >
    <template slot="header">
      <template v-if="openType === 'edit'">
        <div
                v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['common_EditShippingCompany'])"></div>
          {{$t('common_EditShippingCompany.localization_value.value')}}
        </div>
      </template>
      <template v-if="openType === 'create'">
        <div
                v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['common_NewShippingCompany'])"></div>
          {{$t('common_NewShippingCompany.localization_value.value')}}
        </div>
      </template>
    </template>
    <template slot="body">
      <div class="custom-popup__content">
        <div class="shipping-company-popup__body">
          <div class="shipping-company-popup__content">
            <div class="custom-row">
              <div class="custom-col custom-col--50 mb-3"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_ShippingCompanyTitle'])"></div>
                <DefaultInput
                        :label="$t('common_ShippingCompanyTitle.localization_value.value')"
                        v-bind:class="{'ui-no-valid': SC.validation.name}"
                        :error="SC.validation.name"
                        :errorTxt="$t(`${SC.validationTranslate.name}.localization_value.value`)"
                        v-model="SC.data.name"
                />
              </div>
              <div class="custom-col custom-col--50 mb-3"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_ShippingCompanyCode'])"></div>
                <DefaultInput
                        v-bind:class="{'ui-no-valid': SC.validation.code}"
                        :error="SC.validation.code"
                        :errorTxt="$t(`${SC.validationTranslate.code}.localization_value.value`)"
                        :label="$t('common_ShippingCompanyCode.localization_value.value')"
                        v-model="SC.data.code"
                />
              </div>
              <!--<div class="custom-col mb-3"-->
                   <!--v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
                <!--<div class="admin-edit" @click="editTranslate(['common_ShippingCompanyDescription'])"></div>-->
                <!--<TextareaDefault-->
                        <!--:label="$t('common_ShippingCompanyDescription.localization_value.value')"-->
                        <!--:type="'text'"-->
                        <!--v-model="SC.data.description"-->
                <!--/>-->
              <!--</div>-->
              <div class="custom-col mb-3"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_ShippingCompanyEnable'])"></div>
                <DefaultCheckbox
                        :label="$t('common_ShippingCompanyEnable.localization_value.value')"
                        :value="SC.data.active"
                        @input="SC.data.active = !SC.data.active"
                />
              </div>
              <div class="custom-col mb-3"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_ShippingCompanyEnableListing'])"></div>
                <DefaultCheckbox
                        :label="$t('common_ShippingCompanyEnableListing.localization_value.value')"
                        :value="SC.data.showInListing"
                        @input="SC.data.showInListing = !SC.data.showInListing"
                />
              </div>
            </div>
          </div>
          <div class="shipping-company-popup__file">
            <FileBox
                    @change="changeFile"
            />

          </div>
        </div>

        <!--<div>-->
        <!--<DropZone class="drop-zone-bg"-->
        <!--:parentFiles="SC.data.Files.data.files"-->
        <!--:multiple="true"-->
                  <!--:accept="'image/svg+xml'"-->
        <!--:maxCount="SC.data.Files.data.maxCountFiles"-->
        <!--:maxSize="SC.data.Files.data.maxSizeFiles"-->
        <!--@changeImg="changeImg"-->
        <!--/>-->
        <!--</div>-->


      </div>
    </template>
    <template slot="footer">
      <div class="custom-popup__btn align-items-center d-flex flex-wrap"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_cancel', 'common_create'])"></div>
        <MainButton
                class="custom-popup__btn-i secondary"
                :value="$t('common_cancel.localization_value.value')"
                @click.native="$emit('close')"
        />
        <MainButton
                v-if="openType === 'create'"
                class="custom-popup__btn-i "
                :value="$t('common_create.localization_value.value')"
                @click.native="save(false)"
        />
        <MainButton
                v-else
                class="custom-popup__btn-i "
                :value="$t('common_edit.localization_value.value')"
                @click.native="save(true)"
        />
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from "../../../../commonModals/Modal";
  import MainButton from "../../../../UI/buttons/MainButton/MainButton";
  import DefaultInput from "../../../../UI/inputs/DefaultInput/DefaultInput";
  import DefaultCheckbox from "../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import {ShippingCompany} from "../../models/ShippingCompany";
  // import TextareaDefault from "../../../../UI/textarea/TextareaDefault/TextareaDefault";
  import FileBox from "../../../../coreComponents/FileBox/FileBox";
  // import DropZone from "../../../../coreComponents/DropZone/DropZone";
  // import FileFloppyIco from "../../../../../../public/img/UI-group/file-floppy-icon.svg?inline";


  export default {
    name: "ShippingCompanyPopup",
    components: {
      // DropZone,
      // TextareaDefault,
      Modal,
      MainButton,
      DefaultInput,
      DefaultCheckbox,
      // FileFloppyIco,
      FileBox,
    },

    props: {
      openType: String,
      deliverySystemId: Number,
    },

    data() {
      return {
        input01: '',
        input02: '',

        files: {},

        SC: new ShippingCompany()
      }
    },

    mounted() {
      if (this.openType === 'edit') {
        this.$store.dispatch('getShippingCompany', this.deliverySystemId).then(response => {
          this.SC.setItem(this.getRespData(response))
        }).catch(error => this.createErrorLog(error))
      }

    },

    methods: {
      changeFile(data) {
        this.SC.data.Files.setFiles([data])
      },

      changeImg(files, maxSizeError) {
        this.SC.data.Files.setFiles(files)
        this.SC.data.Files.setFilesMaxSizeError(maxSizeError)
      },

      save(edit = false) {
        if (!this.SC.firstValidation()) return

        let data = this.SC.prepareSave()
        let saveType = 'createShippingCompany'

        if (edit) {
          data = {
            id: this.deliverySystemId,
            data: data
          }
          saveType = 'updateShippingCompany'
        }

        this.$store.dispatch(saveType, data).then(response => {
          if (!this._.has(response, 'data')) return this.openNotify('error', 'common_notificationUndefinedError')

          switch (this.getResponseStatus(response)) {
            /**
             * Success
             */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {

              if (edit) {
                this.openNotify('success', 'common_notificationRecordChanged')
              } else {
                this.openNotify('success', 'common_notificationRecordCreated')
              }
              this.$emit('reload')
              this.$emit('close')
              break
            }
            /**
             * Validation Error
             */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
            /**
             * Undefined Error
             */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }
        })


      },

    }
  }
</script>

<style scoped lang="scss">

  @import "../../../../../scss/colors";
  @import "../../../../../scss/mixins/mixins";

  .shipping-company-popup {

    &__body {
      display: flex;

      @include for-680 {
        flex-direction: column;
      }
    }

    &__content {
      width: 100%;

      @include from-680 {
        padding-right: 20px;
      }
    }

    &__file {
      max-width: 105px;
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-bottom: 15px;

      @include for-680 {
        justify-content: center;
        align-items: center;
        max-width: 100%;
        margin-bottom: 35px;
      }

      input[type="file"] {
        display: none;
      }
    }

    &__file-label {
      display: flex;
      flex-direction: column;
      cursor: pointer;
    }

    &__file-img {
      display: flex;
      flex-direction: column;

      img {
        width: 105px;
        height: 105px;
        object-fit: contain;
      }
    }

    &__file-text {
      margin-top: 10px;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: $brown;
    }

  }

  .custom-popup__btn {

  }

  .custom-popup__btn-i {
    margin-right: 10px;
    min-width: 122px;
    width: fit-content;
    margin-bottom: 10px;

    &.secondary {
      border-width: 2px;
    }
  }
</style>

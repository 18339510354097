var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fragment"},[_c('div',{staticClass:"horiz-block"},[_c('div',{staticClass:"horiz-block__inner"},[(!_vm.navTabs.sent.active)?_c('SetTrackNumberForm',{on:{"setTrackForSelectedUnions":(selectedOption, trackNumber) => _vm.$emit('setTrackForSelectedUnions', selectedOption, trackNumber)}}):_vm._e(),(!_vm.navTabs.sent.active)?_c('div',{staticClass:"horiz-block__btn",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
        'common_AddNew',
        ])}}}),_c('router-link',{attrs:{"to":_vm.$store.getters.GET_PATHS.reportsAPCDelconCreate}},[_c('MainButton',{staticClass:"btn-fit-content ml-2",attrs:{"value":_vm.$t('common_AddNew.localization_value.value'),"ico":true}},[_c('template',{slot:"ico"},[_c('PlusIconSVG',{staticClass:"btn-icon-plus"})],1)],2)],1)],1):_vm._e()],1)]),_c('div',{staticClass:"content-top-line content-top-line--with-bottom-line",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
          'apcDelcon_all',
          'apcDelcon_sent',
          'apcDelcon_inditateTrackNum',
          'apcDelcon_setForSelected',
        ])}}}),(Object.keys(_vm.$store.getters.getUserProfile).length > 0)?_c('div',{staticClass:"content-tabs content-tabs--separator"},[_vm._l((_vm.navTabs),function(item,index){return [(item.permissionVisible)?_c('div',{key:index + _vm.loadUserRoleAndAuthorizedData,staticClass:"content-tabs__item",class:{active: item.active},on:{"click":function($event){return _vm.$emit('changeTab', item.name)}}},[_c('div',{staticClass:"content-tabs__ico"},[(item.ico)?[_c('img',{attrs:{"src":item.ico,"alt":"company-ico","width":item.icoWidth}})]:[_vm._v(" "+_vm._s(item.label)+" ")]],2),_c('span',{staticClass:"content-tabs__item-small"},[_vm._v(" "+_vm._s(item.subLabel)+" ")])]):_vm._e()]})],2):_vm._e(),_c('div',{staticClass:"content-top-line-wrap"},[_c('ToggleFilterButton',{attrs:{"count":_vm.countFilterParams,"showFilter":_vm.showFilter},on:{"toggleFilter":function($event){return _vm.$emit('toggleFilter')}}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }
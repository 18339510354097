<template>
  <FilterBlock :showFilter="showFilter" :title="$t('common_filterBy.localization_value.value')" @close="closeFilter" @changeFilter="changeFilter">
    <template slot="body">

      <template v-if="!isMobileFunc() || (isMobileFunc() && !navTabs.sent.active) || showAllFilters">
        <div class="table-filter__item"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <span class="admin-edit" @click="editTranslate(['consolidationUnion_consolidationUnion'])"></span>
          <DefaultInput
              :label="$t('consolidationUnion_consolidationUnion.localization_value.value')"
              :type="'text'"
              v-model="consolidationUnionId"
          />
        </div>

        <!--           v-if="navTabs.sent.active"-->
        <div class="table-filter__item"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <span class="admin-edit" @click="editTranslate(['consolidations_created'])"></span>
          <DatePickerDefault
              :label="$t('consolidations_created.localization_value.value')"
              v-model="createdDate"
          >
            <template slot="body">
              <date-picker
                  v-model="createdDate"
                  ref="datePicker"
                  valueType="format"
                  range
                  :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                  :format="'MM/DD/YY'"
                  :placeholder="'mm/dd/yy'"
              ></date-picker>
            </template>
          </DatePickerDefault>
        </div>

        <div class="table-filter__item"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <span class="admin-edit" @click="editTranslate(['consolidationUnion_concolidation'])"></span>
          <DefaultInput
              :label="$t('consolidationUnion_concolidation.localization_value.value')"
              :type="'text'"
              v-model="consolidationId"
          />
        </div>

        <div class="table-filter__item"
             v-if="isAdmin"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <span class="admin-edit" @click="editTranslate(['common_userAdmin'])"></span>

          <DefaultSelect
              :options="adminOptions"
              :optionsLabel="'email'"
              :otherValue="'adminItem'"
              :label="$t('common_userAdmin.localization_value.value')"
              @change="(val) => {changeAdminsFilter(val)}"
              :selected="adminItem"
          />
        </div>

        <div class="table-filter__item"
             v-if="isAdmin"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <span class="admin-edit" @click="editTranslate(['consolidationUnion_consoUser'])"></span>

          <DefaultSelect
              :options="adminOptions"
              :optionsLabel="'email'"
              :otherValue="'adminItem'"
              :label="$t('consolidationUnion_consoUser.localization_value.value')"
              @change="(val) => {changeConsoUserFilter(val)}"
              :selected="consoUserId ? {
                id: consoUserId,
                user_personal_contact: {
                  user_full_name: consoUserName
                }
              } : null"
          />
        </div>

        <!--      <div class="table-filter__item"-->
        <!--           v-if="isAdmin"-->
        <!--           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
        <!--        <span class="admin-edit" @click="editTranslate(['common_user'])"></span>-->

        <!--        <SearchSelect-->
        <!--            :options="options"-->
        <!--            :label="$t('common_user.localization_value.value')"-->
        <!--            :functionSearch="functionSearch"-->
        <!--            @change="changeUsersFilter"-->
        <!--            :selected="userName"-->
        <!--            :userEmail="true"-->
        <!--            :typeSelect="'users'"-->
        <!--        />-->
        <!--      </div>-->

        <div class="table-filter__item"
             v-if="isAdmin"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <span class="admin-edit" @click="editTranslate(['common_user'])"></span>

          <DefaultSelect
              :options="shippingCompanies"
              :optionsLabel="'name'"
              :label="$t('consolidationUnion_shippingCompany.localization_value.value')"
              @change="(val) => {setShippingCompany(val)}"
              :selected="shippingCompanyItem"
          />
        </div>

        <div class="table-filter__item"
             v-if="isAdmin"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <span class="admin-edit" @click="editTranslate(['common_user'])"></span>

          <DefaultSelect
              :options="warehousesData"
              :optionsLabel="'name'"
              :otherValue="'currentTranslation'"
              :label="$t('consolidationUnion_warehouse.localization_value.value')"
              @change="(val) => {setWarehouse(val)}"
              :selected="warehouseItem"
          />
        </div>
      </template>



      <div class="table-filter__item">
        <DefaultInput
            :label="$t('consolidationUnion_trackingNumber.localization_value.value')"
            :type="'text'"
            v-model="trackingNumber"
        />
      </div>


      <div
          v-if="isMobileFunc() && navTabs.sent.active && !showAllFilters"
          class="show-all"
          @click="showAllFiltersFunc"
      >
        {{ $t('common_showMore.localization_value.value') }}
      </div>



    </template>
    <template slot="footer">
      <MainButton @click.native="resetFilter" :value="$t('common_resetFilters.localization_value.value')" class="secondary wfc"/>
      <MainButton @click.native="changeFilter" :value="$t('common_applyFilters.localization_value.value')" class="wfc"/>
    </template>
  </FilterBlock>
</template>

<script>
import FilterBlock from "../../../../../../coreComponents/FilterBlock/FilterBlock";
import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
import {filterFunc} from "../../../../../../../mixins/filterFuncMixin/filterFunc";
import {usersFunc} from "../../../../../../../mixins/usersMixins/usersFunc";
// import SearchSelect from "../../../../../../UI/selectiones/SearchSelect/SearchSelect";
import DefaultSelect from "../../../../../../UI/selectiones/DefaultSelect/DefaultSelect";
import DatePicker from "vue2-datepicker";
import DatePickerDefault from "@/components/UI/inputs/DatePickerDefault/DatePickerDefault";
import {mixinDetictingMobile} from "@/mixins/mobileFunctions";

export default {
  name: "ConsolidationFilter",
  components: {
    DefaultSelect,
    // SearchSelect,
    FilterBlock,
    MainButton,
    DefaultInput,
    DatePicker,
    DatePickerDefault,
  },

  props: {
    showFilter: Boolean,
    countFilterParams: Number,
    filterGetParams: Object,
    navTabs: Object,
    warehousesData: Array,
    shippingCompanies: Array,
  },

  mixins: [filterFunc, usersFunc, mixinDetictingMobile],

  data(){
    return {
      createdDate: this.filterGetParams.createdDate ? this.generateFilterDate(this.filterGetParams.createdDate, 'MM/DD/YY') : '',
      consolidationId: this.filterGetParams.consolidationId ? this.filterGetParams.consolidationId : '',
      consolidationUnionId: this.filterGetParams.consolidationUnionId ? this.filterGetParams.consolidationUnionId : '',

      shippingCompanyId: this.filterGetParams.shippingCompanyId ? this.filterGetParams.shippingCompanyId : '',
      warehouseId: this.filterGetParams.warehouseId ? this.filterGetParams.warehouseId : '',

      trackingNumber: this.filterGetParams.trackingNumber ? this.filterGetParams.trackingNumber : '',

      userName: this.filterGetParams.userName ? this.filterGetParams.userName : '',
      userId: this.filterGetParams.userId ? this.filterGetParams.userId : '',

      adminName: this.filterGetParams.adminName ? this.filterGetParams.adminName : '',
      adminId: this.filterGetParams.adminId ? this.filterGetParams.adminId : '',

      consoUserId: this.filterGetParams.consoUserId ? this.filterGetParams.consoUserId : '',
      consoUserName: this.filterGetParams.consoUserName ? this.filterGetParams.consoUserName : '',

      options: [],
      adminOptions: [],
      adminItem: null,
      consoUserItem: null,
      warehouseItem: null,
      shippingCompanyItem: null,

      filterCounts: [
        'createdDate',
        'consolidationId',
        'consolidationUnionId',
        'shippingCompanyId',
        'warehouseId',
        'trackingNumber',
        'userName',
        'userId',
        'adminName',
        'adminId',
        'consoUserId',
        'consoUserName',
      ],

      showAllFilters: false,
    }
  },

  watch: {
    filterGetParams(newVal) {
      this.createdDate = newVal.createdDate ? this.generateFilterDate(newVal.createdDate, 'MM/DD/YY') : ''
      this.consolidationId = newVal.consolidationId ? newVal.consolidationId : ''
      this.consolidationUnionId = newVal.consolidationUnionId ? newVal.consolidationUnionId : ''

      this.shippingCompanyId = newVal.shippingCompanyId ? newVal.shippingCompanyId : ''
      this.warehouseId = newVal.warehouseId ? newVal.warehouseId : ''

      this.userId = newVal.userId ? newVal.userId : ''
      this.userName = newVal.userName ? newVal.userName : ''

      this.adminId = newVal.adminId ? newVal.adminId : ''
      this.adminName = newVal.adminName ? newVal.adminName : ''

      this.consoUserId = newVal.consoUserId ? newVal.consoUserId : ''
      this.consoUserName = newVal.consoUserName ? newVal.consoUserName : ''

      this.trackingNumber = newVal.trackingNumber ? newVal.trackingNumber : ''
    },

  },

  mounted() {

    this.$store.dispatch('fetchAdminUsersFromFilter', '?filter[isUSAAdmin]=true').then(response => {
      this.adminOptions = this.getRespData(response)

      // if (this.adminId) {
      //   console.log(1111);
      //   this.adminItem = this.adminOptions.find(item => {
      //     return item.id === this.adminId
      //   })
      // }
    })
  },

  methods: {

    showAllFiltersFunc() {
      this.showAllFilters = true
    },

    closeFilter() {
      this.$emit('close')
    },

    functionSearch(value, loading) {
      loading(true)
      this.getFilteredUsers(value, this, 'options', loading)
    },

    changeUsersFilter(value) {
      this.userId = value.id
      this.userName = value.contacts[0].user_full_name
    },

    changeAdminsFilter(value) {
      this.adminItem = value
      this.adminId = value.id
      this.adminName = value.user_personal_contact.user_full_name
    },

    changeConsoUserFilter(value) {
      this.consoUserItem = value
      this.consoUserId = value.id
      this.consoUserName = value.user_personal_contact.user_full_name
    },

    resetFilter() {
      let data = this.resetFilterParams(this, this.filterCounts)
      this.shippingCompanyItem = null
      this.warehouseItem = null
      this.adminItem = null
      this.consoUserItem = null

      this.$emit('changeFilter', data)
    },

    changeFilter() {
      let data = this.changeFilterParams(this, this.filterCounts)
      data.createdDate = this.changeDateParams(this, 'createdDate')

      this.$emit('changeFilter', data)
    },

    setShippingCompany(val) {
      this.shippingCompanyId = val.id
      this.shippingCompanyItem = val
    },

    setWarehouse(val) {
      this.warehouseId = val.id
      this.warehouseItem = val
    },
  },
}
</script>

<style scoped>

.show-all {
  position: relative;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #cd9e64;
  margin-left: auto;
  padding-right: 15px;
  cursor: pointer;
}
</style>

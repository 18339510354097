import {router} from '../../router/router'
import {API} from '../../axios/axiosMainUrl'
import {ADMIN_ROUTE_STORAGE} from "../../staticData/staticVariables";
import {ErrorLog} from "@/components/globalModels/ErrorLog";

export const auth = {
  state: {
    auth: false,
    token: false,
    loadingForgot: false,
    loadingReset: false,
    loadingLogin: false,
    loadingRegistration: false,
    impersonateButton: false,
    toFactorCodeBtn: false,
    countries: [],
    regions: [],
  },

  mutations: {
    setLoginStart: (state) => {
      state.loadingLogin = true
    },
    setLoginEnd: (state) => {
      state.loadingLogin = false
    },
    setRegistrationStart: (state) => {
      state.loadingRegistration = true
    },
    setRegistrationEnd: (state) => {
      state.loadingRegistration = false
    },
    setToFactorCodeBtnStart: (state) => {
      state.toFactorCodeBtn = true
    },
    setToFactorCodeBtnEnd: (state) => {
      state.toFactorCodeBtn = false
    },
    setForgotStart: (state) => {
      state.loadingForgot = true
    },
    setForgotEnd: (state) => {
      state.loadingForgot = false
    },
    setResetStart: (state) => {
      state.loadingReset = true
    },
    setResetEnd: (state) => {
      state.loadingReset = false
    },

    setImpersonateButton: (state, payload) => {
      state.impersonateButton = payload
    },


    setAuth: (state, payload) => {
      state.auth = payload.auth
      state.token = payload.token
    },
    // setCountry: (state, payload) => {
    //   state.countries = payload
    // },
    setRegions: (state, payload) => {
      state.regions = payload
    },
  },

  getters: {
    getAuth: state => {
      return state.auth
    },

    getToken: state => {
      return state.token
    },

    getLoginLoading: (state) => {
      return state.loadingLogin
    },

    getRegistrationLoading: (state) => {
      return state.loadingRegistration
    },

    getToFactorCodeBtn: (state) => {
      return state.toFactorCodeBtn
    },

    getForgotLoading: (state) => {
      return state.loadingForgot
    },

    getResetLoading: (state) => {
      return state.loadingReset
    },

    getImpersonateButton: (state) => {
      return state.impersonateButton
    },

    // getCountries: state => {
    //   return state.countries
    // },
    getRegions: state => {
      return state.regions
    },
  },

  actions: {

    async getFbLink({commit}) {
      try {
        return await API.get('oauth/login/facebook').then((response) =>{
          return response;
        });
      } catch (e) {
        commit('setLoginEnd');
        return e;
      }
    },

    async impersonate({commit}, data) {
      // commit('setLoginStart');
      try {
        return await API.post('oauth/impersonate', data).then((response) =>{
          console.log(response);
          localStorage.setItem('parentUserId', response.data.impersonateId);
          commit('setAuth', {token: response.data.token, auth: true});
          localStorage.setItem('token', response.data.token);

          /**** SET X-CSRF-TOKEN *****/
          localStorage.setItem('csrf', response.data.csrf);
          API.defaults.headers.common['X-CSRF-TOKEN'] = localStorage.getItem('csrf');

          /**** SET TOKEN *****/
          API.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;

          return response;
        });
      } catch (e) {
        commit('setLoginEnd');
        return e;
      }
    },


    async resetImpersonate({commit}, data) {
      commit('setImpersonateButton', true);
      try {
        return await API.post('oauth/impersonate/reset-impersonate', data).then((response) =>{
          commit('setImpersonateButton', false);
          console.log(response);
          localStorage.removeItem('parentUserId');
          commit('setAuth', {token: response.data.token, auth: true});
          localStorage.setItem('token', response.data.token);

          /**** SET X-CSRF-TOKEN *****/
          localStorage.setItem('csrf', response.data.csrf);
          API.defaults.headers.common['X-CSRF-TOKEN'] = localStorage.getItem('csrf');

          /**** SET TOKEN *****/
          API.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;

          return response;
        });
      } catch (e) {
        commit('setLoginEnd');
        return e;
      }
    },

    async auth({commit}, data) {
      commit('setLoginStart');
      try {
        return await API.post('oauth/login', data, {credentials: 'same-origin'}).then((response) =>{
          commit('setAuth', {token: response.data.token, auth: true});
          localStorage.setItem('token', response.data.token);

          /**** SET X-CSRF-TOKEN *****/
          localStorage.setItem('csrf', response.data.csrf);
          API.defaults.headers.common['X-CSRF-TOKEN'] = localStorage.getItem('csrf');

          /**** SET TOKEN *****/
          API.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;

          // dispatch('fetchCurrentUserRights')

          commit('setLoginEnd');
          return response;
        });
      } catch (e) {
        commit('setLoginEnd');
        return e;
      }
    },

    async authThroughtFb({commit}, data) {
      commit('setLoginStart');
      try {
        return await API.post('oauth/login/facebook', data, {credentials: 'same-origin'}).then((response) =>{
          commit('setAuth', {token: response.data.token, auth: true});
          localStorage.setItem('token', response.data.token);

          /**** SET X-CSRF-TOKEN *****/
          localStorage.setItem('csrf', response.data.csrf);
          API.defaults.headers.common['X-CSRF-TOKEN'] = localStorage.getItem('csrf');

          /**** SET TOKEN *****/
          API.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;

          commit('setLoginEnd');
          return response;
        });
      } catch (e) {
        commit('setLoginEnd');
        return e;
      }
    },

    checkAuth({commit}) {
      if(localStorage.getItem('token') !== null){
        commit('setAuth', {token: localStorage.getItem('token'), auth: true});
        return true;
      }

      commit('setAuth',{auth: false, token: false})

      if((location.pathname + location.search).indexOf('/auth/login') === -1){
        localStorage.setItem('urlRedirectedFromUnauthorizedPage', location.pathname + location.search)
      } else {
        localStorage.removeItem('urlRedirectedFromUnauthorizedPage')
      }

      return false;
    },

    async checkReferral({commit}, data) {
      try {
        return await API.put(`users/referrals/count-visits`, data).then((response) =>{
          console.log(response.data);
          return response.data;
        });
      } catch (e) {
        commit('setRegistrationEnd');
        return e;
      }
    },

    async logOut({commit}) {

      try {
        return await API.post('oauth/logout').then((response) =>{
          commit('setAuth',{auth: false, token: false})
          localStorage.removeItem('csrf');
          localStorage.removeItem('token');
          localStorage.removeItem('parentUserId');

          API.defaults.headers.common['X-CSRF-TOKEN'] = '';
          API.defaults.headers.common['Authorization'] = '';

          /**
           * Loging UNAUTHORIZED_CODE
           */
          let ErrorLogModel = new ErrorLog()
          ErrorLogModel.setItem({
            baseURI: this.$router.app?.$el.baseURI,
            vueComponent: 'UNAUTHORIZED logout',
            user: `Id: ${this.$store.getters.GET_COMMON_AUTHORIZED?.user?.id}, email: ${this.$store.getters.GET_COMMON_AUTHORIZED?.user?.email}`,
            userAgent: navigator.userAgent,
            error: `UNAUTHORIZED logout`,
            typeError: 'UNAUTHORIZED logout',
            routeHistory: this.$store.getters.GET_ROUTER_HISTORY,
          })

          let data = ErrorLogModel.prepareSave()
          this.$store.dispatch('createErrorLogs', data)

          router.push(this.getters.GET_PATHS.auth)
          localStorage.removeItem(ADMIN_ROUTE_STORAGE)
          return response;
        });
      } catch (e) {
        if(e.response.status === this.getters.GET_ERRORS.UNAUTHORIZED_CODE){
          commit('setAuth',{auth: false, token: false})
          localStorage.removeItem('csrf');
          localStorage.removeItem('token');
          localStorage.removeItem('parentUserId');

          API.defaults.headers.common['X-CSRF-TOKEN'] = '';
          API.defaults.headers.common['Authorization'] = '';

          /**
           * Loging UNAUTHORIZED_CODE
           */
          let ErrorLogModel = new ErrorLog()
          ErrorLogModel.setItem({
            baseURI: this.$router.app?.$el.baseURI,
            vueComponent: 'UNAUTHORIZED logout error',
            user: `Id: ${this.$store.getters.GET_COMMON_AUTHORIZED?.user?.id}, email: ${this.$store.getters.GET_COMMON_AUTHORIZED?.user?.email}`,
            userAgent: navigator.userAgent,
            error: `UNAUTHORIZED logout error`,
            typeError: 'UNAUTHORIZED logout error',
            routeHistory: this.$store.getters.GET_ROUTER_HISTORY,
          })
          let data = ErrorLogModel.prepareSave()
          this.$store.dispatch('createErrorLogs', data)

          router.push(this.getters.GET_PATHS.auth)
          localStorage.removeItem(ADMIN_ROUTE_STORAGE)
        }
        return e;
      }


    },

    async registration({commit}, data) {

      commit('setRegistrationStart');
      try {
        return await API.post('/oauth/register', data).then((response) =>{
          // commit('setAuth', {token: response.data.token, auth: true});
          // localStorage.setItem('token', response.data.token);
          console.log(response);

          /**** SET X-CSRF-TOKEN *****/
          // localStorage.setItem('csrf', response.data.csrf);
          // API.defaults.headers.common['X-CSRF-TOKEN'] = localStorage.getItem('csrf');

          commit('setRegistrationEnd');
          return response;
        });
      } catch (e) {
        commit('setRegistrationEnd');
        return e;
      }
    },

    // async getRegistrationData({commit}) {
    //
    //   try {
    //     await API.get('/contact/country/countries-list').then((response) =>{
    //
    //       commit('setCountry', response.data.data.countries);
    //       return response;
    //     });
    //   } catch (e) {
    //     console.log(e);
    //     return e;
    //   }
    // },

    async getRegionsByCountry({commit}, id) {
      try {
        await API.get(`/contact/region/${id}/get-region`).then((response) =>{

          commit('setRegions', response.data.data.regions);
          return response;
        });
      } catch (e) {
        console.log(e);
        return e;
      }
    },

    async getRegionsByCountryExpress({commit}, {id, url = ''}) {
      try {
        await API.get(`/contact/region/${id}/get-region${url}`).then((response) =>{

          commit('setRegions', response.data.data.regions);
          return response;
        });
      } catch (e) {
        console.log(e);
        return e;
      }
    },


    async forgot({commit}, data) {
      commit('setForgotStart');
      try {
        return await API.post('/oauth/password/email', data).then((response) =>{
          commit('setAuthEnd');
          return response;
        });
      } catch (e) {
        commit('setForgotEnd');
        return e;
      }
    },


    async verifyUser({commit}, url) {
      commit('setForgotEnd');
      try {
        return await API.get(`/oauth/email/verify/${url}`).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        return e;
      }
    },

    async checkResetPass({commit}, data) {
      commit('setForgotEnd');
      try {
        return await API.post(`/oauth/password/can-reset`, data).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        return e;
      }
    },

    async resetPass({commit}, data) {
      commit('setResetStart');
      try {
        return await API.post(`/oauth/password/reset`, data).then((response) =>{
          console.log(response);
          commit('setResetEnd');
          return response;
        });
      } catch (e) {
        commit('setResetEnd');
        return e;
      }
    },

    async twoFactorEmail({commit}) {
      try {
        return await API.post(`/oauth/2fa/send-email`).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('setToFactorCodeBtnEnd');
        return e;
      }
    },

    async twoFactorEmailSendCode({commit}, data) {
      commit('setToFactorCodeBtnStart');
      try {
        return await API.post(`/oauth/2fa/store-email-code`, data).then((response) =>{
          console.log(response);
          commit('setToFactorCodeBtnEnd');
          return response;
        });
      } catch (e) {
        commit('setToFactorCodeBtnEnd');
        return e;
      }
    },

    async twoFactorGoogle({commit}) {
      commit('setResetStart');
      try {
        return await API.get(`/oauth/2fa/qr-code`).then((response) =>{
          console.log(response);
          commit('setResetEnd');
          return response;
        });
      } catch (e) {
        commit('setResetEnd');
        return e;
      }
    },

    async twoFactorGoogleEmergency({commit}) {
      commit('setResetStart');
      try {
        return await API.get(`/oauth/2fa/emergency-codes`).then((response) =>{
          console.log(response);
          commit('setResetEnd');
          return response;
        });
      } catch (e) {
        commit('setResetEnd');
        return e;
      }
    },

    async twoFactorGoogleEmergencyByUserId({commit}, data) {
      commit('setResetStart');
      try {
        return await API.get(`/oauth/2fa/admin-emergency-codes?user_id=${data.user_id}`).then((response) =>{
          console.log(response);
          commit('setResetEnd');
          return response;
        });
      } catch (e) {
        commit('setResetEnd');
        return e;
      }
    },

    async twoFactorGoogleSendCode({commit}, data) {
      commit('setToFactorCodeBtnStart');
      try {
        return await API.post(`/oauth/2fa/validate-google-code`, data).then((response) =>{
          console.log(response);
          commit('setToFactorCodeBtnEnd');
          return response;
        });
      } catch (e) {
        commit('setToFactorCodeBtnEnd');
        return e;
      }
    },

    async twoFactorGoogleActivate({commit}, data) {
      commit('setResetStart');
      try {
        return await API.post(`/oauth/2fa/activate-2fa-google`, data).then((response) =>{
          console.log(response);
          commit('setResetEnd');
          return response;
        });
      } catch (e) {
        commit('setResetEnd');
        return e;
      }
    },

    async checkExpired2faAt({commit}) {
      try {
        return await API.post(`/oauth/2fa/can-enter`).then((response) =>{
          return response.data.data;
        });
      } catch (e) {
        commit('setLoadingEnd')
        return e;
      }
    },

    async checkExpiredOauthToken({commit}) {
      try {
        return await API.get(`/oauth/token-expired`).then((response) =>{
          return response.data.data;
        });
      } catch (e) {
        commit('setLoadingEnd')
        return e;
      }
    },

  }
}

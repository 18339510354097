<template>
  <ConsolidationUnionCreateAdmin
      :CU="CU"
      :warehousesData="warehousesData"
      :carriers="carriers"
      :shippingCompanies="shippingCompanies"
      @saveConsolidationUnion="saveConsolidationUnion"
  />
</template>

<script>
  import ConsolidationUnionCreateAdmin from "./ConsolidationUnionCreateAdmin/ConsolidationUnionCreateAdmin";
  import {ConsolidationUnion} from "../../models/ConsolidationUnion";
  import {consolidationUnionMixin} from "../../../../../mixins/consolidationUnionMixins/consolidationUnionMixin";
  import {
    CONSOLIDATION_UNION_SHIPPING_COMPANIES_ARRAY,
    CONSOLIDATION_UNION_WAREHOUSES_ARRAY
  } from "../../../../../staticData/staticVariables";

  export default {
    name: "ConsolidationUnionCreate",

    components: {
      ConsolidationUnionCreateAdmin,
    },

    mixins: [
      consolidationUnionMixin
    ],

    data() {
      return {
        CU: new ConsolidationUnion(),
        warehousesData: [],
        shippingCompanies: [],
        // statesData: [],
        carriers: [],
      }
    },

    created() {
      this.initConsolidationUnionCreate()
    },

    methods: {
      initConsolidationUnionCreate() {
        this.$store.dispatch('getConsolidationUnionCreate').then(response => {
          let respData = this.getRespData(response)
          this.warehousesData = respData.warehouses.filter(item => {
            return CONSOLIDATION_UNION_WAREHOUSES_ARRAY.find(findItem => {
              return findItem.id === item.id
            })
          })
          this.shippingCompanies = respData.delivery_services.filter(item => {
            return CONSOLIDATION_UNION_SHIPPING_COMPANIES_ARRAY.find(findItem => {
              return findItem.id === item.id
            })
          })
          this.carriers = respData?.carriers || []
        })
      },

    }
  }
</script>

<style scoped>

</style>
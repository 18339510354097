import {checkValid} from "../../../globalModels/functions/ModelsValidate";


export function ConsolidationUnion() {

  this.checkValid = checkValid

  this.data = {
    id: '',
    warehouse: '',
    sendState: null,
    carrier: null,
    shippingCompany: '',
    createdAt: null,
    trackingNumber: '',
    comment: '',
    relatedOrders: [],

    status: '',
    sendAt: null,
    historyOfChanged: null,

  }

  this.validation = {
    id: false,
    warehouse: false,
    shippingCompany: false,
    trackingNumber: false,
    comment: false,
    status: false,
    sendAt: false,
  }

  this.validationTranslate = {
    id: '',
    warehouse: '',
    shippingCompany: '',
    trackingNumber: '',
    comment: '',
    status: '',
    sendAt: '',
  }

  this.validationTxt = {
    id: false,
    warehouse: false,
    shippingCompany: false,
    trackingNumber: false,
    comment: false,
    status: false,
    sendAt: false,
  }

  /**
   * Getters
   */
  this.getId = () => {
    return this.data.id
  }

  this.getWarehouse = () => {
    return this.data.warehouse
  }
  this.getSendState = () => {
    return this.data.sendState
  }
  this.getCarrier = () => {
    return this.data.carrier
  }
  this.getShippingCompany = () => {
    return this.data.shippingCompany
  }
  this.getCreatedAt = () => {
    return this.data.createdAt
  }
  this.getSendAt = () => {
    return this.data.sendAt
  }
  this.getTrackingNumber = () => {
    return this.data.trackingNumber
  }
  this.getComment = () => {
    return this.data.comment
  }
  this.getStatus = () => {
    return this.data.status
  }
  this.getRelatedOrders = () => {
    return this.data.relatedOrders
  }

  /**
   * Setters
   */
  this.setId = (val) => {
    this.data.id = val
  }
  this.setWarehouse = (val) => {
    this.data.warehouse = val
  }
  this.setSendState = (val) => {
    this.data.sendState = val
  }
  this.setCarrier = (val) => {
    this.data.carrier = val
  }
  this.setShippingCompany = (val) => {
    this.data.shippingCompany = val
  }
  this.setCreatedAt = (val) => {
    this.data.createdAt = val
  }
  this.setSendAt = (val) => {
    this.data.sendAt = val
  }
  this.setTrackingNumber = (val) => {
    this.data.trackingNumber = val
  }
  this.setComment = (val) => {
    this.data.comment = val
  }
  this.setStatus = (val) => {
    this.data.status = val
  }
  this.setRelatedOrders = (val) => {
    this.data.relatedOrders = val
  }
  this.setHistoryOfChanged = (val) => {
    this.data.historyOfChanged = val
  }
  this.addRelatedOrders = (val) => {
    this.data.relatedOrders.unshift(val)
  }
  this.removeRelatedOrders = (val) => {
    this.data.relatedOrders.splice(val, 1)
  }
}

/**
 * Global Setters
 */
ConsolidationUnion.prototype.setConsolidationUnionItem = function (item, warehouses = false, shippingCompanies = false, carriers = false, that) {
  this.setId(item?.id)
  this.setTrackingNumber(item?.tracking_number)
  this.setComment(item?.comment)
  this.setHistoryOfChanged(item.activityUUID)

  if (warehouses) {
    this.setWarehouse(warehouses.find(whItem => {
      return whItem.id === item.warehouse.id
    }))
  }

  if (shippingCompanies) {
    this.setShippingCompany(shippingCompanies.find(scItem => {
      return scItem.id === item.delivery_service.id
    }))
  }

  // if (states) {
  //   this.setSendState(states.find(stItem => {
  //     return stItem.code === item.send_state
  //   }))
  // }

  if (carriers) {
    this.setCarrier(carriers.find(stItem => {
      return stItem.code === item.carrier_code
    }))
  }

  if (item?.checkOrders.length) {
    this.setRelatedOrders(item.checkOrders.map(ordItem => {
      return {
        checked: ordItem.checked,
        id: ordItem.order_fbm_id,
        type: ordItem.order_type_id,
        tracking_number: ordItem.tracking_number,
        customer_name: ordItem.customer_name
      }
    }))
  }

  this.setStatus(item.status)
  if (this.getStatus()) {
    this.setSendAt(item?.send_at ? that.$moment(item?.send_at).format('YYYY-MM-DD HH:mm') : null)
  }
}

/**
 * Functions
 */


/**
 * Prepare Data
 */

ConsolidationUnion.prototype.prepareSave = function () {


  let preparedData = {
    'delivery_service_id': this.getShippingCompany()?.id,
    'warehouse_id': this.getWarehouse()?.id,
    'tracking_number': this.getTrackingNumber(),
    'comment': this.getComment(),
  }

  if (this.getStatus()) {
    preparedData['send_at'] = this.getSendAt()
  }

  // if (this.getSendState()) {
  //   preparedData['send_state'] = this.getSendState()?.code
  //

  if (this.getCarrier()) {
    preparedData['carrier_code'] = this.getCarrier()?.code
  }

  if (this.getRelatedOrders().length > 0) {
    preparedData['check_orders'] = this.getRelatedOrders().map(item => {
      return {
        'order_fbm_id': item.id,
        'checked': item.checked,
      }
    })
  } else {
    preparedData['check_orders'] = null
  }

  console.log(preparedData);

  return preparedData
}

/**
 * Validations
 */

ConsolidationUnion.prototype.firstValidationAdmin = function () {

  let validationItems = {
    shippingCompany: this.getShippingCompany(),
    warehouse: this.getWarehouse(),
  }

  let validationOptions = {
    shippingCompany: {type: ['empty']},
    warehouse: {type: ['empty']},
  }

  return (this.checkValid(validationItems, validationOptions))
}

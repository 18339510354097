export const consolidationUnionLabelMixin = {

	methods: {

		checkPermission(){
			return true
		},

		changePayOrderPopup(val) {
			this.payOrderPopup = val
		},

		async addFiles(id, edit) {
			if(this.CUL.data.invoice.getFiles().length > 0 || this.CUL.data.toxicSubstanceCert.getFiles().length > 0
				|| this.CUL.data.dropBallCert.getFiles().length > 0 || this.CUL.data.certificateOfOrigin.getFiles().length > 0){
				await this.addConsolidationUnionLabelFiles(id)
			}

			if (edit) {
				this.openNotify('success', 'common_notificationRecordChanged')
			}
			else {
				this.openNotify('success', 'common_notificationRecordCreated')
			}

			this.$router.push(this.$store.getters.GET_PATHS.reportsConsolidation)

		},

		addConsolidationUnionLabelFiles(id) {

			let data = this.CUL.prepareFilesData(id)
			if(data.length === 0) return Promise.resolve()

			data.map(file => {
				return this.$store.dispatch('uploadConsolidationUnionLabelFile', file).then(response => {
					this.responseFilesProcessing(response)
					return response
				})
			})
		},

		getFileFromServer(file, type = null){
			if(!file) return

			let localItem = file

			return this.$store.dispatch('getFileFromServer', file.id)
				.then((response) => {
					console.log(response);
					let fileBase64 = response
					switch (type) {
						case 'image':
							localItem.typeBase64 = `image`
							localItem.base64 = fileBase64[file.id]
							break
						default:
							localItem.base64 = fileBase64[file.id]
							break
					}

					return localItem
				})

		},

		saveConsolidationUnionLabel(edit = false) {

			if (!this.CUL.firstValidationAdmin()) return

			let data = this.CUL.prepareSave()
			let saveType = 'createConsolidationUnionLabel'

			if (edit) {
				data = {
					id: this.CUL.getId(),
					data: data
				}
				saveType = 'updateConsolidationUnionLabel'
			}

			console.log(data);
			console.log(saveType);

			return this.$store.dispatch(saveType, data).then(response => {
				if (!this.getResponseStatus(response)) return this.openNotify('error', 'common_notificationUndefinedError')
				console.log(data);

				switch (this.getResponseStatus(response)) {
					/**
					 * Success
					 */
					case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {

						if (this.CUL.data.invoice.getFiles().length > 0 || this.CUL.data.toxicSubstanceCert.getFiles().length > 0
						|| this.CUL.data.dropBallCert.getFiles().length > 0 || this.CUL.data.certificateOfOrigin.getFiles().length > 0) {
							if (edit) {
								this.addFiles(data.id, edit)
							}
							else {
								this.addFiles(this.getRespData(response)?.['consolidation-union-label']?.id, edit)
							}

							return
						}

						if (edit) {
							this.openNotify('success', 'common_notificationRecordChanged')
						} else {
							this.openNotify('success', 'common_notificationRecordCreated')
						}

						this.$router.push(this.$store.getters.GET_PATHS.reportsConsolidation)
						break
					}
					/**
					 * Validation Error
					 */
					case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
						let errors = response.response.data.errors;
						this.notifyErrorHelper(errors)
						break
					}
					/**
					 * Undefined Error
					 */
					default: {
						this.openNotify('error', 'common_notificationUndefinedError')
					}
				}
			})

		},
	}
}


export const consolidationUnionLabelTableMixin = {

	mixins: [consolidationUnionLabelMixin],

	methods: {
		getDeliveryPriceFromTable(id) {
			let data = {
				'consolidation_union_label_id': id
			}

			this.$store.dispatch('getConsolidationUnionLabelDeliveryPrice', data).then(response => {
				if (!this.getResponseStatus(response)) return this.openNotify('error', 'common_notificationUndefinedError')

				switch (this.getResponseStatus(response)) {
					/**
					 * Success
					 */
					case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
						this.orderPrice = response.data.data.price
						console.log(response, response.data.data.price, this.orderPrice);
						this.changePayOrderPopup(true)

						break
					}
					/**
					 * Validation Error
					 */
					case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
						let errors = response.response.data.errors;
						this.notifyErrorHelper(errors)
						break
					}
					/**
					 * Undefined Error
					 */
					default: {
						this.openNotify('error', 'common_notificationUndefinedError')
					}
				}
			})
		},

	}
}
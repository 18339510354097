<template>
  <ChatSection/>
</template>

<script>
  import ChatSection from "./ChatSection/ChatSection";

  export default {
    name: "ChatPage",

    components:{
      ChatSection,
    }
  }
</script>

<style scoped>

</style>

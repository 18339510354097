<template>
  <modal
          @close="$emit('close')"
          class="calling-courier-modal calling-courier"
  >
    <template slot="header">
      <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['express_CallingCourier'])"></div>
        {{$t(`express_CallingCourier.localization_value.value`)}}
      </div>
    </template>
    <template slot="body">
      <div class="calling-courier__content">
        <div class="calling-courier__txt"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['express_CourierWillTake'])"></div>
          {{$t(`express_CourierWillTake.localization_value.value`)}}
        </div>
        <div class="calling-courier__row custom-row">
          <template v-if="orderPopupData.deliveryTypeId === DHL_DELIVERY_SERVICE.id">
            <div class="calling-courier__col custom-col custom-col--50 custom-col--md-100"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['express_ContactPersonsName'])"></div>
              <DefaultInput
                      :label="$t(`express_ContactPersonsName.localization_value.value`)"
                      v-bind:class="{'ui-no-valid': courier.validation.userName}"
                      :error="courier.validation.userName"
                      :errorTxt="serverError ? courier.validationTxt.userName :
                      $t(`${courier.validationTranslate.userName}.localization_value.value`)"
                      :type="'text'"
                      v-model="courier.callingCourier.userName"
                      @onEnter="submitForm"
              />
            </div>
            <div class="calling-courier__col custom-col custom-col--50 custom-col--md-100"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['express_CourierPhone'])"></div>
              <DefaultInput
                      :label="$t(`express_CourierPhone.localization_value.value`)"
                      v-bind:class="{'ui-no-valid': courier.validation.userPhone}"
                      :error="courier.validation.userPhone"
                      :errorTxt="serverError ? courier.validationTxt.userPhone :
                      $t(`${courier.validationTranslate.userPhone}.localization_value.value`)"
                      :type="'text'"
                      v-model="courier.callingCourier.userPhone"
                      @onEnter="submitForm"
              />
            </div>
          </template>

          <div class="calling-courier__col custom-col custom-col--50 custom-col--md-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['express_TimeGapToPickup'])"></div>
            <DatePickerDefault
                    :label="$t('express_TimeGapToPickup.localization_value.value')"
                    v-bind:class="{'ui-no-valid': courier.validation.timePickup}"
                    :errorTxt="serverError ? courier.validationTxt.timePickup : $t(`${courier.validationTranslate.timePickup}.localization_value.value`)"
                    :error="courier.validation.timePickup"
                    :value="courier.callingCourier.timePickup"
            >
              <template slot="body">
                <date-picker
                        valueType="format"
                        range
                        type="time"
                        format="HH:mm"
                        :placeholder="'HH:mm'"
                        :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                        :time-picker-options="timePickerOptions"
                        v-model="courier.callingCourier.timePickup"
                ></date-picker>
              </template>
            </DatePickerDefault>
          </div>
          <div class="calling-courier__col custom-col custom-col--50 custom-col--md-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['express_ArrivalDate'])"></div>
            <DatePickerDefault
                    :label="$t('express_ArrivalDate.localization_value.value')"
                    v-bind:class="{'ui-no-valid': courier.validation.arrivalDate}"
                    :errorTxt="serverError ? courier.validationTxt.arrivalDate : $t(`${courier.validationTranslate.arrivalDate}.localization_value.value`)"
                    :error="courier.validation.arrivalDate"
                    :value="courier.callingCourier.arrivalDate"
            >
              <template slot="body">
                <date-picker
                        valueType="format"
                        :format="'MM/DD/YY'"
                        :placeholder="'mm/dd/yy'"
                        :disabled-date="(date) => getCourierDays(date)"
                        :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                        v-model="courier.callingCourier.arrivalDate"
                ></date-picker>
              </template>
            </DatePickerDefault>
          </div>

          <template v-if="orderPopupData.deliveryTypeId === FEDEX_DELIVERY_SERVICE.id">
            <div class="calling-courier__col custom-col custom-col--50 custom-col--md-100"
                 v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['express_BuildingPart'])"></div>
              <DefaultSelect
                      :label="$t('express_BuildingPart.localization_value.value')"
                      :options="optionsBuildingPart"
                      :optionsLabel="'name'"
                      :selected="optionsBuildingPart[0]"
                      @change="changeBuildingPart"
              />
            </div>

            <div class="calling-courier__col custom-col"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate([
							'express_AlternativeAddress',
						])"></div>
              <DefaultCheckbox
                      v-model="courier.callingCourier.isAlternativeAddress"
                      :label="$t('express_AlternativeAddress.localization_value.value')"
                      @click="courier.callingCourier.isAlternativeAddress = !courier.callingCourier.isAlternativeAddress"
              />
            </div>

          </template>

          <!--<div class="calling-courier__col custom-col custom-col&#45;&#45;50 custom-col&#45;&#45;md-100">-->
          <!--<DefaultInput-->
          <!--v-bind:class="{'ui-no-valid': validation.time}"-->
          <!--:error="validation.time"-->
          <!--:errorTxt="serverError ? validationTxt.time : 'This field is required'"-->
          <!--:label="'Time Gap to pickup'"-->
          <!--:type="'text'"-->
          <!--v-model="time"-->
          <!--/>-->
          <!--</div>-->
          <!--<div class="calling-courier__col custom-col custom-col&#45;&#45;50 custom-col&#45;&#45;md-100">-->
          <!--<DefaultInput-->
          <!--v-bind:class="{'ui-no-valid': validation.date}"-->
          <!--:error="validation.date"-->
          <!--:errorTxt="serverError ? validationTxt.date : 'This field is required'"-->
          <!--:label="'Arrival Date'"-->
          <!--:type="'text'"-->
          <!--v-model="date"-->
          <!--/>-->
          <!--</div>-->
          <div class="calling-courier__col custom-col"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate([
              'express_PickupCourierComment',
              'express_PleaseBeCareful',
            ])"></div>
            <TextareaDefault
                    :label="$t('express_PickupCourierComment.localization_value.value')"
                    :placeholder="$t('express_PleaseBeCareful.localization_value.value')"
                    v-model="courier.callingCourier.comment"
                    :error="courier.validation.comment"
                    :errorTxt="serverError ? courier.validationTxt.comment :
                      $t(`${courier.validationTranslate.comment}.localization_value.value`)"
                    :type="'text'"
                    v-bind:class="{'ui-no-valid': courier.validation.comment}"
            />
          </div>
        </div>

        <template v-if="orderPopupData.deliveryTypeId === FEDEX_DELIVERY_SERVICE.id && courier.callingCourier.isAlternativeAddress">
          <div class="calling-courier__row custom-row">
            <div class="calling-courier__col custom-col custom-col--50 custom-col--md-100"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['express_ContactPersonsName'])"></div>
              <DefaultInput
                      :label="$t(`express_ContactPersonsName.localization_value.value`)"
                      v-bind:class="{'ui-no-valid': courier.validation.userName}"
                      :error="courier.validation.userName"
                      :errorTxt="serverError ? courier.validationTxt.userName :
                        $t(`${courier.validationTranslate.userName}.localization_value.value`)"
                      :type="'text'"
                      v-model="courier.callingCourier.userName"
                      @onEnter="submitForm"
              />
            </div>
            <div class="calling-courier__col custom-col custom-col--50 custom-col--md-100"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['express_CourierPhone'])"></div>
              <DefaultInput
                      :label="$t(`express_CourierPhone.localization_value.value`)"
                      v-bind:class="{'ui-no-valid': courier.validation.userPhone}"
                      :error="courier.validation.userPhone"
                      :errorTxt="serverError ? courier.validationTxt.userPhone :
                        $t(`${courier.validationTranslate.userPhone}.localization_value.value`)"
                      :type="'text'"
                      v-model="courier.callingCourier.userPhone"
                      @onEnter="submitForm"
              />
            </div>

            <div class="calling-courier__col custom-col custom-col--50 custom-col--md-100"
                 v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate([
                        'common_address',
                      ])"></div>
              <DefaultInput
                      :label="$t('common_address.localization_value.value')"
                      :type="'text'"
                      v-bind:class="{'ui-no-valid': courier.callingCourier.Address.validation.address}"
                      :errorTxt="$t(`${courier.callingCourier.Address.validationTranslate.address}.localization_value.value`)"
                      :error="courier.callingCourier.Address.validation.address"
                      v-model="courier.callingCourier.Address.delivery.address"
              />
            </div>
            <div class="calling-courier__col custom-col custom-col--50 custom-col--md-100"
                 v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate([
                        'common_city',
                      ])"></div>
              <DefaultInput
                      :label="$t('common_city.localization_value.value')"
                      v-bind:class="{'ui-no-valid': courier.callingCourier.Address.validation.city}"
                      :errorTxt="$t(`${courier.callingCourier.Address.validationTranslate.city}.localization_value.value`)"
                      :error="courier.callingCourier.Address.validation.city"
                      v-model="courier.callingCourier.Address.delivery.city"
              />
            </div>

            <div class="calling-courier__col custom-col custom-col--50 custom-col--md-100"
                 v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate([
                        'common_zipCode',
                      ])"></div>
              <DefaultInput
                  :label="$t('common_zipCode.localization_value.value')"
                  v-bind:class="{'ui-no-valid': courier.callingCourier.Address.validation.zip}"
                  :errorTxt="$t(`${courier.callingCourier.Address.validationTranslate.zip}.localization_value.value`)"
                  :error="courier.callingCourier.Address.validation.zip"
                  v-model="courier.callingCourier.Address.delivery.zip"
              />
            </div>

            <div class="calling-courier__col custom-col custom-col--50 custom-col--md-100"
                 v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate([
                        'common_region',
                      ])"></div>
              <DefaultInput
                      :label="$t('common_region.localization_value.value')"
                      v-bind:class="{'ui-no-valid': courier.callingCourier.Address.validation.region}"
                      :errorTxt="$t(`${courier.callingCourier.Address.validationTranslate.region}.localization_value.value`)"
                      :error="courier.callingCourier.Address.validation.region"
                      v-model="courier.callingCourier.Address.delivery.region"
              />
            </div>

            <div class="calling-courier__col custom-col custom-col--50 custom-col--md-100"
                 v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate([
                        'common_country',
                      ])"></div>

              <DefaultSelect
                      :options="optionsCountries"
                      :label="$t('common_country.localization_value.value')"
                      v-bind:class="{'ui-no-valid': courier.callingCourier.Address.validation.country}"
                      :errorTxt="$t(`${courier.callingCourier.Address.validationTranslate.country}.localization_value.value`)"
                      :error="courier.callingCourier.Address.validation.country"
                      :selected="courier.callingCourier.Address.delivery.country"
                      :optionsLabel="'label'"
                      :clearable="false"
                      @change="changeCountry"
              />
            </div>
          </div>
        </template>

        <div class="calling-courier__alert"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['express_CourierPleaseMakeSure'])"></div>
          {{$t('express_CourierPleaseMakeSure.localization_value.value')}}
        </div>
      </div>
    </template>
    <template slot="footer">
      <div class="calling-courier__btn"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
              'common_cancel',
              'common_submit',
            ])"></div>
        <span
                class="site-link site-link--alt calling-courier__btn-i"
                @click="$emit('close')"
        >
          {{ $t('common_cancel.localization_value.value') }}
        </span>

        <MainButton
                :value="$t('common_submit.localization_value.value')"
                class="calling-courier__btn-i"
                v-on:click.native="submitForm"
        />
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from '../../../../commonModals/Modal.vue'
  import MainButton from '../../../../UI/buttons/MainButton/MainButton.vue'
  import TextareaDefault from '../../../../UI/textarea/TextareaDefault/TextareaDefault.vue'
  import DefaultInput from '../../../../UI/inputs/DefaultInput/DefaultInput.vue'
  import DatePickerDefault from "../../../../UI/inputs/DatePickerDefault/DatePickerDefault";
  import DatePicker from 'vue2-datepicker'
  import {CallingCourier} from "./models/CallingCourier";
  import {
    DHL_DELIVERY_SERVICE,
    EXPRESS_FEDEX_BUILDING_PARTS,
    FEDEX_DELIVERY_SERVICE
  } from "../../../../../staticData/staticVariables";
  import DefaultSelect from "../../../../UI/selectiones/DefaultSelect/DefaultSelect";
  import DefaultCheckbox from "../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import {countriesMixin} from "../../../../../mixins/commonMixins/countriesMixin";

  export default {
    name: "CallingCourierPopup",
    components: {
      DefaultCheckbox,
      DefaultSelect,
      DatePickerDefault,
      DatePicker,
      Modal,
      MainButton,
      TextareaDefault,
      DefaultInput,
    },

    mixins: [countriesMixin],

    props: {
      orderPopupData: Object,
      availableCourierTime: Object,
    },

    data() {
      return {
        courier: new CallingCourier(),

        serverError: false,

        optionsBuildingPart: EXPRESS_FEDEX_BUILDING_PARTS,
        DHL_DELIVERY_SERVICE: DHL_DELIVERY_SERVICE,
        FEDEX_DELIVERY_SERVICE: FEDEX_DELIVERY_SERVICE,

        optionsCountries: [],

        timePickerOptions:{
          start: '08:00',
          step: '00:15',
          end: '18:00'
        },
      }
    },



    mounted() {

      this.courier.setUserName(this.orderPopupData.name)
      this.courier.setUserPhone(this.orderPopupData.phone)
      this.courier.setArrivalDate(this.$moment(this.orderPopupData.deliveryDate).format('MM/DD/YY'))

      // if(this.orderPopupData.deliveryTypeId === FEDEX_DELIVERY_SERVICE.id){
      this.courier.callingCourier.Address.setAddress(this.orderPopupData.address)
      this.courier.callingCourier.Address.setCity(this.orderPopupData.city)
      this.courier.callingCourier.Address.setCountry(this.orderPopupData.country)
      this.courier.callingCourier.Address.setRegion(this.orderPopupData.region)
      this.courier.callingCourier.Address.setZip(this.orderPopupData.zip)
      // }

      this.$store.dispatch('fetchCountries').then(() => {
        let countries = this._.toArray(this.$store.getters.getCountries)
        this.optionsCountries = this.getCountriesForRecipient(countries)
      })


      this.changeBuildingPart(this.optionsBuildingPart[0])

      if(this.availableCourierTime){
        this.timePickerOptions = {
          start: this.availableCourierTime['from_time'],
          step: '00:15',
          end: this.availableCourierTime['to_time'],
        }
      }
    },

    methods: {

      changeBuildingPart(val){
        this.courier.setBuildingPart(val)
      },

      changeCountry(val) {
        this.selectedCountry = val;
        this.courier.callingCourier.Address.setCountry(val)

        this.$store.dispatch('getRegionsByCountry', val.id).then(() => {
          this.optionsDistrict = this._.toArray(this.$store.getters.getRegions);
        });
      },

      getCourierDays(date) {
        return (date > new Date().setDate(new Date().getDate() + 10) ||
          date < new Date().setDate(new Date().getDate() - 1))
      },

      // getCourierTime(time) {
      //
      //   let date = new Date()
      //   return (time < new Date(date.getFullYear(), date.getMonth(), date.getDate(), 8, 0) ||
      //     time > new Date(date.getFullYear(), date.getMonth(), date.getDate(), 18, 0));
      //   // return (time > new Date().setDate(new Date().getDate() + 10) ||
      //   //   time < new Date().setDate(new Date().getDate() - 1))
      // },

      changeDistrict(val) {
        this.stateDistrict = val;
      },

      changeFunctionality(val) {
        this.checkedFunctionality = val;
      },

      submitForm() {
        let validation

        if(this.orderPopupData.deliveryTypeId === FEDEX_DELIVERY_SERVICE.id){
          validation = this.courier.callingCourierValidate({
            userName: this.courier.callingCourier.isAlternativeAddress,
            userPhone: this.courier.callingCourier.isAlternativeAddress,
            timePickup: true,
            arrivalDate: true,
            // comment: true,
          }) && this.courier.callingCourierValidateRangeTime();
        }

        if(this.orderPopupData.deliveryTypeId === DHL_DELIVERY_SERVICE.id){
          validation = this.courier.callingCourierValidate({
            userName: true,
            userPhone: true,
            timePickup: true,
            arrivalDate: true,
            // comment: true,
          }) && this.courier.callingCourier.Address.deliveryInfoPartialValidate({
            address: true,
            city: true,
            region: true,
            country: true,
            zip: true,
          })
        }

        if (validation) {
          this.serverError = false;

          let data = this.courier.prepareCourierItem()
          if(this.orderPopupData.deliveryTypeId === FEDEX_DELIVERY_SERVICE.id){
            data = this.courier.prepareCourierFedex()
          }
          data.order_express_id = this.orderPopupData.id

          this.$store.dispatch('callingCourierPopup', {data: data, type: this.orderPopupData.deliveryTypeName}).then((response) => {

            if (!this.getResponseStatus(response)) return this.openNotify('error', 'common_notificationUndefinedError')

            switch (this.getResponseStatus(response)) {
              /**
               * Success
               */
              case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
                this.openNotify('success', 'common_notificationRecordChanged')
                this.$emit('close')
                this.$emit('reload')
                break
              }
              /**
               * Validation Error
               */
              case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
                let errors = response.response.data.errors;
                if(errors?.courier_time_window) {
                  return this.openCustomErrorPopup(errors.courier_time_window[0])
                }
                this.notifyErrorHelper(errors)
                break
              }
              /**
               * Undefined Error
               */
              default: {
                this.openNotify('error', 'common_notificationUndefinedError')
              }
            }
          });

        }

      },

      openCustomErrorPopup(txt) {
        let text = {
          title: '',
          txt: '',
          customTxt: txt,
          yes: '',
          no: 'common_cancel'
        }

        let confirm = () => {
          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)
      },

    }
  }

</script>

<style lang="scss">
  @import "./CallingCourierPopup";
</style>

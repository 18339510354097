
/*********** Common Variables   ***************/
export const EN_LANG = 'en';
export const RU_LANG = 'ru';
export const UA_LANG = 'ua';
export const LV_LANG = 'lv';
export const PL_LANG = 'pl';

export const DEVELOPER = 'developer';

export const TRANSLATE_MODE_KEY = 'PdCx0L7QtNGPINC60LDQutCw0YjQsA';
export const TRANSLATE_MODE_VARIABLE = 'PdC00LA';

// export const PERMISSIONS = {
//   IMPERSONALIZATION_MODE: 'impersonalization-mode',
//   VIEW_ADMIN_ROUTE_PREFIX: 'view-admin-route-prefix',
//   translationMode: 'translation-mode',
//   createNotification : 'create-notification',
//   deleteNotification : 'delete-notification',
//   updateNotification : 'update-notification',
// }

export const ADMIN_ROUTE_STORAGE = 'c2tsYWR1c2E'

export const USD = {
  id: 4,
  name: 'USD',
  value: 'USD',
}

export const UAH = {
  id: 158,
  name: 'UAH',
  value: 'UAH',
}

export const EUR = {
  id: 5,
  name: 'EUR',
  value: 'EUR',
}

export const GBP = {
  id: 67,
  name: 'GBP',
  value: 'GBP',
}

export const CURRENCIES = {
  USD,
  UAH,
  EUR,
  GBP,
}

export const CURRENCIES_ARRAY = [
  USD,
  UAH,
  EUR,
  GBP,
]


export const UKRAINIAN_TREASURES_SITE = 'https://ukrainiantreasures.com/listing'

export const perPageVarsArray = [
  {value: 25},
  {value: 50},
  {value: 100}
]

/*************  Shipping Companies Variables  ***********/

export const USPS_SHIPPING_COMPANY = {
  id: 5,
  name: "USPS",
  ico: '/img/company-icons-group/radio-pictures-ico-usps.png',
}

export const DPD_SHIPPING_COMPANY = {
  id: 9,
  deliveryCompanyId: 7,
  deliveryServiceCountryPriceId: 8,
  name: "DPD",
  ico: '',
}

export const POSTNL_SHIPPING_COMPANY = {
  id: 10,
  deliveryCompanyId: 8,
  deliveryServiceCountryPriceId: 28,
  name: "PostNL",
  ico: '',
}

export const GLOBAL24_SHIPPING_COMPANY = {
  id: 14,
  deliveryCompanyId: 14,
  deliveryServiceCountryPriceId: 29,
  name: "Global24",
  ico: '',
}

export const POST_AT_METHOD = {
  id: 11,
  deliveryCompanyId: 9,
  deliveryServiceCountryPriceId: 29,
  name: "Post AT",
  ico: '',
}

export const POST_NL_VAT_METHOD = {
  id: 12,
  deliveryCompanyId: 10,
  deliveryServiceCountryPriceId: 29,
  name: "Post NL(paid VAT)",
  ico: '',
}

export const COLISSIMO_METHOD = {
  id: 13,
  deliveryCompanyId: 11,
  deliveryServiceCountryPriceId: 29,
  name: "Colissimo",
  ico: '',
}

export const DHL_WARENPOST_METHOD = {
  id: 14,
  deliveryCompanyId: 12,
  deliveryServiceCountryPriceId: 29,
  name: "DHL Warenpost",
  ico: '',
}

export const DHL_PAKET_METHOD = {
  id: 15,
  deliveryCompanyId: 13,
  deliveryServiceCountryPriceId: 29,
  name: "DHL Paket",
  ico: '',
}

export const GSL_METHOD = {
  id: 16,
  deliveryCompanyId: 14,
  deliveryServiceCountryPriceId: 29,
  name: "GSL",
  ico: '',
}

export const CORREOS_METHOD = {
  id: 17,
  deliveryCompanyId: 15,
  deliveryServiceCountryPriceId: 29,
  name: "Correos",
  ico: '',
}

export const DPD_VAT_METHOD = {
  id: 19,
  deliveryCompanyId: 17,
  deliveryServiceCountryPriceId: 29,
  name: "DPD (paid VAT)",
  ico: '',
}

export const APC_CAMBRIDGE = {
  id: 11,
  deliveryCompanyId: 19,
  deliveryServiceCountryPriceId: 11,
  name: "APC Cambridge",
  ico: '',
}

export const ROYAL_MAIL_COMPANY = {
  id: 32,
  deliveryCompanyId: 21,
  deliveryServiceCountryPriceId: 4,
  name: "Royal Mail",
  ico: '',
}

export const ROYAL_MAIL_GLOBAL_COMPANY = {
  id: 18,
  deliveryCompanyId: 16,
  deliveryServiceCountryPriceId: 4,
  name: "Royal Mail",
  ico: '',
}

/*************  City Variables  ***********/

export const CITY_MINSK = {
  id: 3,
  country_id: 20,
  code: "MINSK01",
}

export const CITY_LVIV = {
  id: 7,
  country_id: 220,
  code: "lviv001",
}

export const CITY_KIEV = {
  id: 1,
  country_id: 220,
  code: "KIEV01",
}

// export const BELARUS_COUNTRY_ID = 20
// export const RUSSIA_COUNTRY_ID = 176




/*************  Regions Variables  ***********/

export const PUERTO_RICO_REGION = {
  code:"PR",
  country_id:223,
  id:3664,
  label:"Puerto Rico",
}


/*************  Country Variables  ***********/

export const RUSSIA_COUNTRY_GROUP = 1
export const UKRAINE_COUNTRY_GROUP = 1
export const BELARUS_COUNTRY_GROUP = 1


export const RUSSIA_COUNTRY_ID = 176

export const UKRAINIAN_COUNTRY_ID = 220
export const CZECH_COUNTRY_ID = 56
export const LATVIA_COUNTRY_ID = 117
export const USA_COUNTRY_ID = 223
export const UNITED_KINGDOM_ID = 222
export const AUSTRALIA_ID = 13
export const CANADA_COUNTRY_ID = 38
export const MEXICO_COUNTRY_ID = 138
export const POLAND_COUNTRY_ID = 170

export const SDT_COUNTRY_GROUP_ID = 8



/*************  Shops Variables  ***********/

export const SHOPS_ADMIN_TYPES = {
  valid: 'valid',
  notValid: 'not_valid',
  cancel: 'cancel',
}

export const SHOP_TYPES = {
  woocommerce: {
    id: 6,
    name: 'WooCommerce'
  },
  opencart: {
    id: 5,
    name: 'OpenCart'
  },
  etsy: {
    id: 1,
    name: 'Etsy',
  },
  ukrainiantreasures: {
    id: 12,
    name: 'Ukrainiantreasures',
  },
}

export const DEFAULT_SHOP_TYPES = {
  etsy: {
    id: 1,
    type: 'etsy',
    name: 'Etsy',
    ico: '/img/shops-group/etsy-shop-icon.png',
  },
  ebay: {
    id: 3,
    type: 'ebay',
    name: 'Ebay',
    ico: '/img/shops-group/ebay-shop-icon.png'
  },
  amazonmws: {
    id: 2,
    type: 'amazonmws',
    name: 'Amazon',
    ico: '/img/shops-group/amazon-shop-icon.png'
  },
  other: {
    type: 'other',
    name: 'Other',
    ico: '',
  },
  shopify: {
    id: 10,
    type: 'shopify',
    name: 'Shopify',
    ico: '',
  },
}


/*************  Payment Variables  ***********/

export const PAYMENT_TYPE = {
  payPall: 'PayPall',
  authorize: 'Authorize',
  braintree: 'braintree',
  payoneer: 'payoneer',
}

export const PAYMENT_TYPE_NAME = {
  payPall: 'paypal',
  authorize: 'authorize',
  braintree: 'braintree',
}

export const TRANSACTION_SLUICE_TYPES = {
  INTERNAL: 'internal',
  EXTERNAL: 'external',
}



/*************  Customers Payments Variables  ***********/

export const CUSTOMERS_PAYMENTS_STATUSES = {
  SUCCESS_TRANSACTION_STATUS: 'success',  //Успешная трансакция
  OUTPUT_TRANSACTION_STATUS: 'output',  //Успешная трансакция
  CANCELED_TRANSACTION_STATUS: 'canceled',  //Отменена транзакция
  IN_PROGRESS_TRANSACTION_STATUS: 'in_process',
  REFUND_TRANSACTION_STATUS: 'refund',   // Возврат транзакции
  BLOCKED_TRANSACTION_STATUS: 'blocked',   // Заблокированая транзакция
  DENIED_TRANSACTION_STATUS: 'denied',   // PayPal отклонил трансакцию
  ON_HOLD_STATUS: 'on_hold',   // Транзакции в ожидании смени статуса от платежной системи
  ERROR_STATUS: 'error',   // Ошибка Транзакции
  SYSTEM_TRANSACTION_STATUS: 'other', // Транзакции которые создает админ
}

export const CUSTOMERS_PAYMENTS_TRACKING_NUMBER_STATE = {
  IN_PROGRESS: 'in_progress',
}

export const PAYMENTS_SYSTEMS = {
  paypal: {
    name: 'PayPal',
    value: 'paypal',
    id: 1,
  },
  authorize: {
    name: 'Authorize',
    value: 'authorize',
    id: 2,
  },
  braintree: {
    name: 'Braintree',
    value: 'braintree',
    id: 3,
  },
  other: {
    name: 'Other',
    value: 'other',
    id: -1,
  },
}

export const AMOUNT_DEFAULT_VALUE = '0.00'



/*************  Customers Payments Variables  ***********/


export const FORBIDDEN_TRANSACTION_STATUSES = {
  IN_PROCESS_STATUS: 'in_process',
  ERROR_STATUS: 'error',
  NEW_STATUS: null,
}



/*************  User Profile Variables  ***********/

export const MAX_SIZE_AVATAR_IMG = 2000




/*************  My payments Variables  ***********/

export const TRANSACTION_SLUICE = {
  INTERNAL_TRANSACTION_SLUICE: 'internal',
  EXTERNAL_TRANSACTION_SLUICE: 'external'
}


/*************  New payments Payoneer Variables  ***********/

export const NEW_PAYMENTS_PAYONEER_STATUSES = {
  COMPLETE: 'complete',
  NEW: 'new'
}




/*************  Account Type Variables ***********/

export const ACCOUNT_TYPE = {
  INDIVIDUAL: 1,
  BUSINESS: 2,
}

/*************  Account Type Archive Status Variables ***********/

export const ACCOUNT_TYPE_STATUS_ARCHIVE = {
  INACTIVE: 0,
  ACTIVE: 1,
}


/*************  Account Bank Variables  ***********/

export const ACCOUNT_BANK_TYPE = {
  WORLD_BANK: {
    id: 1,
    name: 'world'
  },
  UKRAINIAN_BANK: {
    id: 2,
    name: 'ukrainian'
  },
  CUSTOM_BANK:{
    id: 3,
    name: 'business'
  },
  CUSTOM_ACCOUNT: {
    id: 4,
    name: 'email'
  }
}

export const ACCOUNT_BANK_SKLADUSA_USER_ACCOUNT = {
  id: 2,
  name: 'SkladUSA User Account',
}
export const ACCOUNT_BANK_PAYONEER = {
  id: 3,
  name: 'Payoneer',
  translation: 'requestFunds_PayoneerOutput'
}
export const ACCOUNT_BANK_PAYPAL = {
  id: 5,
  name: 'PayPal',
}
export const ACCOUNT_BANK_SWIFT = {
  id: 7,
  name: 'SWIFT',
}
export const ACCOUNT_BANK_CUSTOM_BANK_ACCOUNT = {
  id: 8,
  name: 'Custom Bank Account',
}
export const ACCOUNT_BANK_PAYONEER_SECOND = {
  id: 16,
  name: 'Payoneer (secondary account)',
}
export const ACCOUNT_BANK_EPAYMENTS = {
  id: 18,
  name: 'ePayments',
  translation: 'requestFunds_EpaymentsOutput'
}
export const ACCOUNT_BANK_BENTO_VISA_CARD = {
  id: 21,
  name: 'Bento Visa Card',
  translation: 'requestFunds_BentoOutput'
}
export const ACCOUNT_BANK_KIT_GROUP = {
  id: 28,
  name: 'KIT GROUP',
}
export const ACCOUNT_BANK_RIA_INTERNATIONAL = {
  id: 29,
  name: 'RIA INTERNATIONAL',
}
export const ACCOUNT_BANK_CRYPTO = {
  id: 32,
  name: 'Cryptocurrency',
  translation: 'requestFunds_cryptoOutput'
}
export const ACCOUNT_BANK_PRIVAT = {
  id: 1,
  name: 'PRIVAT',
  translation: 'requestFunds_PrivatOutput'
}
export const ACCOUNT_BANK_MONOBANK = {
  id: 10,
  name: 'PRIVAT',
  translation: 'requestFunds_PrivatOutput'
}
export const ACCOUNT_BANK_WISE = {
  id: 33,
  name: 'WISE',
  translation: 'requestFunds_WiseOutput'
}
export const ACCOUNT_BANK_RAMP = {
  id: 42,
  name: 'RAMP',
  translation: 'requestFunds_RampOutput'
}
export const WITHOUT_API_TYPE = {
  id: -1,
  name: 'WITHOUT API',
  translation: 'requestFunds_WithoutAPIOutput'
}

export const ACCOUNT_BANK_LIST = {
  ACCOUNT_BANK_SKLADUSA_USER_ACCOUNT: ACCOUNT_BANK_SKLADUSA_USER_ACCOUNT,
  ACCOUNT_BANK_PAYONEER: ACCOUNT_BANK_PAYONEER,
  ACCOUNT_BANK_PAYPAL: ACCOUNT_BANK_PAYPAL,
  ACCOUNT_BANK_SWIFT: ACCOUNT_BANK_SWIFT,
  ACCOUNT_BANK_CUSTOM_BANK_ACCOUNT: ACCOUNT_BANK_CUSTOM_BANK_ACCOUNT,
  ACCOUNT_BANK_PAYONEER_SECOND: ACCOUNT_BANK_PAYONEER_SECOND,
  ACCOUNT_BANK_EPAYMENTS: ACCOUNT_BANK_EPAYMENTS,
  ACCOUNT_BANK_BENTO_VISA_CARD: ACCOUNT_BANK_BENTO_VISA_CARD,
  ACCOUNT_BANK_KIT_GROUP: ACCOUNT_BANK_KIT_GROUP,
  ACCOUNT_BANK_RIA_INTERNATIONAL: ACCOUNT_BANK_RIA_INTERNATIONAL,
  ACCOUNT_BANK_WISE: ACCOUNT_BANK_WISE,
  ACCOUNT_BANK_CRYPTO: ACCOUNT_BANK_CRYPTO,
  ACCOUNT_BANK_RAMP: ACCOUNT_BANK_RAMP,
}







/*************  Shipment Variables  ***********/

// export const ORDER_INBOUND_TYPE = {
//   FBM: 'fbm',
//   FBA: 'fba',
//   FBAReturn: 'fba-return',
//   FBMReturn: 'fbm-return',
//   LabelReturn: 'label-return',
// }

export const ORDER_INBOUND_STATUS = {
  STATUS_IN_PROGRESS: 1,
  STATUS_COMPLETE: 2,
  STATUS_DRAFT: 3,
  STATUS_SHIPMENT: 4,
  STATUS_CHARGED: 5,
}

export const ORDER_INBOUND_TYPE = {
  FBM: {
    id: 1,
    name: 'FBM',
    value: 'fbm',
    return: false,
  },
  FBA: {
    id: 2,
    name: 'FBA',
    value: 'fba',
    return: false,
  },
  FBMReturn: {
    id: 3,
    name: 'FBM Return',
    value: 'fbm-return',
    return: true,
  },
  FBAReturn: {
    id: 4,
    name: 'FBA Return',
    value: 'fba-return',
    return: true,
  },
}

export const ORDER_INBOUND_NAV = {
  FBM: {
    id: 1,
    name: 'FBM',
  },
  FBA: {
    id: 2,
    name: 'FBA',
  },
  RETURNS: {
    id: 3,
    name: 'Returns',
  },
}






/*************  Consolidation Variables  ***********/

export const ORDERS_CONSOLIDATION_TYPE = {
  // FEDEX: 'fedex',
  FEDEX: 'usa-fedex',
  DHL_USA: 'usa-dhl',
  DHL: 'dhl',
  TNT: 'tnt',
  // DPD: 'dpd',
  DPD: 'eu',
  EU_PRODUCT: 'eu_product',
  FROM_YOUR_CITY: 'your_city',
}

export const CONSOLIDATION_TYPE_FEDEX = {
  id: 2,
  name: 'fedex',
  label: 'fedex',
  delivery_service_id: 2
}

export const CONSOLIDATION_TYPE_DHL = {
  id: 3,
  name: 'dhl',
  label: 'dhl',
  delivery_service_id: 1
}

export const CONSOLIDATION_TYPE_DHL_FROM_YOUR_CITY = {
  id: 4,
  name: 'dhl_from_your_city',
  label: 'dhl-from-your-city',
  delivery_service_id: 1
}

export const CONSOLIDATION_TYPE_TNT = {
  id: 5,
  name: 'tnt',
  label: 'tnt',
  delivery_service_id: 7
}

export const CONSOLIDATION_TYPE_FEDEX_FROM_YOUR_CITY = {
  id: 6,
  name: 'fedex_from_your_city',
  label: 'fedex-from-your-city',
  delivery_service_id: 2
}

export const CONSOLIDATION_TYPE_DPD = {
  id: 1,
  name: 'dpd',
  label: 'dpd',
  delivery_service_id: 8
}

export const CONSOLIDATION_TYPE_EU_PRODUCT = {
  id: 7,
  name: 'eu_product',
  label: 'eu-prod',
  delivery_service_id: 30
}

export const CONSOLIDATION_TYPE_OWN_CARRIER = {
  id: 8,
  name: 'own_carrier',
  label: 'own-carrier',
  delivery_service_id: 33
}

export const ORDERS_CONSOLIDATION_TYPE_FROM_URL = [
  {
    fromUrl: ORDERS_CONSOLIDATION_TYPE.FEDEX,
    type: CONSOLIDATION_TYPE_FEDEX.name,
    typeObj: CONSOLIDATION_TYPE_FEDEX,
  },
  {
    fromUrl: ORDERS_CONSOLIDATION_TYPE.DHL_USA,
    type: CONSOLIDATION_TYPE_DHL.name,
    typeObj: CONSOLIDATION_TYPE_DHL,
  },
  {
    fromUrl: ORDERS_CONSOLIDATION_TYPE.DPD,
    type: CONSOLIDATION_TYPE_DPD.name,
    typeObj: CONSOLIDATION_TYPE_DPD,
  },
  {
    fromUrl: ORDERS_CONSOLIDATION_TYPE.EU_PRODUCT,
    type: CONSOLIDATION_TYPE_EU_PRODUCT.name,
    typeObj: CONSOLIDATION_TYPE_EU_PRODUCT,
  },
  {
    fromUrl: ORDERS_CONSOLIDATION_TYPE.FROM_YOUR_CITY,
    type: CONSOLIDATION_TYPE_FEDEX_FROM_YOUR_CITY.name,
    typeObj: CONSOLIDATION_TYPE_FEDEX_FROM_YOUR_CITY,
  },
]

export const CONSOLIDATION_TYPES = {
  CONSOLIDATION_TYPE_FEDEX,
  CONSOLIDATION_TYPE_DHL,
  CONSOLIDATION_TYPE_DHL_FROM_YOUR_CITY,
  CONSOLIDATION_TYPE_TNT,
  CONSOLIDATION_TYPE_DPD,
  CONSOLIDATION_TYPE_EU_PRODUCT,
  CONSOLIDATION_TYPE_FEDEX_FROM_YOUR_CITY,
  CONSOLIDATION_TYPE_OWN_CARRIER
}

export const CONSOLIDATION_TYPES_ARRAY = [
  CONSOLIDATION_TYPE_FEDEX,
  CONSOLIDATION_TYPE_DHL,
  CONSOLIDATION_TYPE_DHL_FROM_YOUR_CITY,
  CONSOLIDATION_TYPE_TNT,
  CONSOLIDATION_TYPE_DPD,
  CONSOLIDATION_TYPE_EU_PRODUCT,
  CONSOLIDATION_TYPE_FEDEX_FROM_YOUR_CITY,
  CONSOLIDATION_TYPE_OWN_CARRIER
]

export const CONSOLIDATION_TYPES_FROM_YOUR_CITY = {
  CONSOLIDATION_TYPE_DHL_FROM_YOUR_CITY,
  CONSOLIDATION_TYPE_FEDEX_FROM_YOUR_CITY,
  CONSOLIDATION_TYPE_OWN_CARRIER
}

export const CONSOLIDATION_TYPES_FROM_YOUR_CITY_ARRAY = [
  CONSOLIDATION_TYPE_DHL_FROM_YOUR_CITY,
  CONSOLIDATION_TYPE_FEDEX_FROM_YOUR_CITY,
  CONSOLIDATION_TYPE_OWN_CARRIER
]

export const CONSOLIDATION_STATUS_IN_PROGRESS = {
  name: 'in_progress',
  value: 'in_progress',
  translation: 'consolidations_inProgressStatus',
}

export const CONSOLIDATION_STATUS_DEPARTED = {
  name: 'departed',
  value: 'departed',
  translation: 'consolidations_departedStatus',
}

export const CONSOLIDATION_STATUS_RECEIVED = {
  name: 'received',
  value: 'received',
  translation: 'consolidations_receivedStatus',
}

export const CONSOLIDATION_STATUS_COUNTED = {
  name: 'counted',
  value: 'counted',
  translation: 'consolidations_countedStatus',
}

export const CONSOLIDATION_STATUSES = {
  CONSOLIDATION_STATUS_IN_PROGRESS,
  CONSOLIDATION_STATUS_DEPARTED,
  CONSOLIDATION_STATUS_RECEIVED,
  CONSOLIDATION_STATUS_COUNTED
}

export const CONSOLIDATION_SYSTEM_STATUS_COMPLETED = {
  name: 'Completed',
  value: 'completed'
}

export const CONSOLIDATION_SYSTEM_STATUS_CANCELED = {
  name: 'Completed',
  value: 'canceled'
}

export const CONSOLIDATION_SYSTEM_STATUS_IN_PROGRESS = {
  name: 'In Progress',
  value: 'in_progress'
}

export const CONSOLIDATION_SYSTEM_STATUS_WAIT_FOR_PAY = {
  name: 'Wait for pay',
  value: 'wait_for_pay'
}

export const CONSOLIDATION_SYSTEM_STATUS_WAIT_FOR_LABEL = {
  name: 'Wait for making label',
  value: 'wait_for_making_label'
}

export const CONSOLIDATION_SYSTEM_STATUSES = {
  CONSOLIDATION_SYSTEM_STATUS_COMPLETED,
  CONSOLIDATION_SYSTEM_STATUS_IN_PROGRESS,
  CONSOLIDATION_SYSTEM_STATUS_WAIT_FOR_PAY,
  CONSOLIDATION_SYSTEM_STATUS_WAIT_FOR_LABEL
}

export const CONSOLIDATION_SYSTEM_STATUSES_ARRAY = [
  CONSOLIDATION_SYSTEM_STATUS_COMPLETED,
  CONSOLIDATION_SYSTEM_STATUS_IN_PROGRESS,
  CONSOLIDATION_SYSTEM_STATUS_WAIT_FOR_PAY,
  CONSOLIDATION_SYSTEM_STATUS_WAIT_FOR_LABEL
]

export const CONSOLIDATION_STATUSES_ARRAY = [
  CONSOLIDATION_STATUS_IN_PROGRESS,
  CONSOLIDATION_STATUS_DEPARTED,
  CONSOLIDATION_STATUS_RECEIVED,
  CONSOLIDATION_STATUS_COUNTED
]


/*************  FBM Variables  ***********/

// export const ORDERS_FBM_TYPE = {
//   CONSOLIDATION: 'consolidation',
//   WAREHOUSE: 'warehouse',
//   LABEL: 'label',
//   EASY: 'easy',
// }

export const FBM_ORDER_CONSOLIDATION = {
  name: 'consolidation',
  label: 'Consolidation',
  value: 'consolidation',
  id: 1
}

export const FBM_ORDER_LABEL = {
  name: 'label',
  label: 'Label',
  value: 'user_create',
  id: 3
}

export const FBM_ORDER_WAREHOUSE = {
  name: 'warehouse',
  label: 'Warehouse',
  value: 'sklad_create',
  id: 2
}

export const FBM_ORDER_TYPES = {
  FBM_ORDER_CONSOLIDATION: FBM_ORDER_CONSOLIDATION,
  FBM_ORDER_LABEL: FBM_ORDER_LABEL,
  FBM_ORDER_WAREHOUSE: FBM_ORDER_WAREHOUSE,
}

export const FBM_SHIPPING_FEDEX = {
  id: 2,
  delivery_type_id: 3,
  delivery_service_code: 'fedex',
  name: "FedEx",
  ico: '/img/company-icons-group/tab-fedex.svg',
}

export const FBM_SHIPPING_USPS = {
  id: 5,
  delivery_type_id: 3,
  delivery_service_code: 'usps',
  name: "USPS",
  ico: '/img/company-icons-group/radio-pictures-ico-usps.png',
}

export const FBM_SHIPPING_LANDMARK = {
  id: 10,
  delivery_type_id: 1,
  name: "Landmark",
  ico: '/img/company-icons-group/radio-pictures-ico-landmark.svg',
}

export const FBM_SHIPPING_APC = {
  id: 11,
  delivery_type_id: 1,
  name: "APC",
  ico: '/img/company-icons-group/radio-pictures-ico-apc.svg',
}

export const FBM_SHIPPING_DHL = {
  id: 6,
  delivery_type_id: 1,
  name: "DHL Worldwide",
  ico: '/img/company-icons-group/novaposhta-logo-thead.png',
}

export const FBM_SHIPPING_TYPES = {
  FBM_SHIPPING_FEDEX,
  FBM_SHIPPING_USPS,
  FBM_SHIPPING_LANDMARK,
  FBM_SHIPPING_APC,
  FBM_SHIPPING_DHL,
}

export const FBM_SHIPPING_COMPANIES = [
  FBM_SHIPPING_FEDEX,
  FBM_SHIPPING_USPS,
  FBM_SHIPPING_LANDMARK,
  FBM_SHIPPING_APC,
  FBM_SHIPPING_DHL,
]


export const FBM_FROM_TRANSACTIONS = {
  ORDER: {
    name: 'order',
    value: 'from_order_external',
  },
  PAYMENT: {
    name: 'payment',
    value: 'from_payment_transaction',
  }
}

export const FBM_IN_PROGRESS_STATUS = {
  name: 'in_progress',
  value: 'in_progress',
  translation: 'fbm_inProgress',
}

export const FBM_COMPLETED_STATUS = {
  name: 'completed',
  value: 'completed',
  translation: 'fbm_completed',
}

export const FBM_CHARGED_STATUS = {
  name: 'charged',
  value: 'charged',
  translation: 'fbm_charged',
}

export const FBM_DRAFT_STATUS = {
  name: 'draft',
  value: 'draft',
  translation: 'fbm_draft',
}

export const FBM_COPY_STATUS = {
  name: 'copy',
  value: 'copy',
  translation: 'status_Copy',
}

export const FBM_STOP_STATUS = {
  name: 'stop',
  value: 'stop',
  translation: 'common_stop',
}


export const FBM_STATUSES = {
  FBM_IN_PROGRESS_STATUS,
  FBM_CHARGED_STATUS,
  FBM_COMPLETED_STATUS,
  FBM_DRAFT_STATUS,
  FBM_STOP_STATUS,
}

export const FBM_ALL_STATUSES = {
  FBM_IN_PROGRESS_STATUS,
  FBM_CHARGED_STATUS,
  FBM_COMPLETED_STATUS,
  FBM_DRAFT_STATUS,
  FBM_COPY_STATUS,
}

export const FBM_STATUSES_ARRAY = [
  FBM_IN_PROGRESS_STATUS,
  FBM_CHARGED_STATUS,
  FBM_COMPLETED_STATUS,
  FBM_DRAFT_STATUS,
]

export const FBM_GIFT_PAPERS = {
  BLANK: {
    id: 1,
    name: 'Blank',
    translation: 'fbm_giftPapersBlank',
    translationName: 'fbm_giftPapersBlankName'
  },
  BALLOONS: {
    id: 2,
    name: "Baloons",
    translation: 'fbm_giftBalLoons',
    translationName: 'fbm_giftBalLoonsName'
  },
  BABY: {
    id: 3,
    name: "Baby",
    translation: 'fbm_giftBaby',
    translationName: 'fbm_giftBabyName'
  },
  VALENTINES: {
    id: 4,
    name: "Valentines",
    translation: 'fbm_giftValentines',
    translationName: 'fbm_giftValentinesName'
  },
  EASTER: {
    id: 5,
    name: "Easter",
    translation: 'fbm_giftEaster',
    translationName: 'fbm_giftEasterName'
  },
  CHRISTMAS: {
    id: 8,
    name: "Christmas",
    translation: 'fbm_giftChristmas',
    translationName: 'fbm_giftChristmasName'
  },
  ENGRAVING: {
    id: 9,
    name: "Heart - engraving",
    translation: 'fbm_giftEngraving',
    translationName: 'fbm_giftEngravingName'
  },
  SANTA_EXPRESS: {
    id: 12,
    name: "Santa Express - engraving",
    translation: 'fbm_giftSantaExpress',
    translationName: 'fbm_giftSantaExpressName'
  },
  CHRISTMAS_TREE: {
    id: 13,
    name: "Christmas Tree - engraving",
    translation: 'fbm_giftChristmasTree',
    translationName: 'fbm_giftChristmasTreeName'
  },
  CHRISTMAS_GIFT_TAG: {
    id: 14,
    name: "Snowflake Gift Tag - engraving",
    translation: 'fbm_giftChristmasGiftTag',
    translationName: 'fbm_giftChristmasGiftTagName'
  },
}



/*************  EASY Order Variables  ***********/

export const EASY_ORDER_WAREHOUSE_TYPE = {
  name: 'warehouse',
  label: 'Warehouse',
  value: 'from_sklad',
  id: 1
}

export const EASY_ORDER_ECONOM_TYPE = {
  name: 'econom',
  label: 'Econom',
  value: 'econom',
  id: 2
}

export const EASY_ORDER_EXPRESS_TYPE = {
  name: 'express',
  label: 'Express',
  value: 'express_fedex',
  id: 3
}

export const EASY_ORDER_TYPES = {
  EASY_ORDER_WAREHOUSE_TYPE,
  EASY_ORDER_ECONOM_TYPE,
  EASY_ORDER_EXPRESS_TYPE,
}

export const EASY_ORDER_TYPES_ARRAY = [
  EASY_ORDER_WAREHOUSE_TYPE,
  EASY_ORDER_ECONOM_TYPE,
  EASY_ORDER_EXPRESS_TYPE,
]






/*************  FBA Variables  ***********/
export const FBA_FILE_TYPE = {
  SHIPPING_LABEL: {
    id: 1,
    name: "shippingLabel"
  },
  AMAZON_FNSKU: {
    id: 2,
    name: "amazonFNSKU"
  },
}

export const FBA_STATUS_IN_PROGRESS = {
  id: 1,
  translation: 'fba_statusInProgress',
  name: "In Progress",
  value: 'in_progress',
}
export const FBA_STATUS_COMPLETED = {
  id: 2,
  translation: 'fba_statusCompleted',
  name: "Completed",
  value: 'completed',
}


export const FBA_ORDER_STATUSES = {
  FBA_STATUS_IN_PROGRESS,
  FBA_STATUS_COMPLETED
}

export const FBA_ORDER_STATUS = [
  FBA_STATUS_IN_PROGRESS,
  FBA_STATUS_COMPLETED,
  // {
  //   id: 3,
  //   name: "Closed",
  //   value: 'closed',
  // }
]



/*************  Proform Variables  ***********/

export const PROFORM_CATEGORY_SEARCH = 2

export const PROFORM_CATEGORY_SAVED = 3

export const PROFORM_TYPE_NAMING = 1

export const PROFORM_TYPE_MATERIAL = 2

export const PROFORM_TYPE_SUBMATERIAL = 4




/*************  Delivery Services  ***********/

export const DHL_DELIVERY_SERVICE = {
  name: 'dhl',
  label: 'DHL',
  id: 1,
  ico: '/img/company-icons-group/tab-dhl.svg',
}
export const DHLEXPRESS_DELIVERY_SERVICE = {
  name: 'dhlexpress',
  label: 'DHL',
  id: 1,
  ico: '/img/company-icons-group/tab-dhl.svg',
}
export const FEDEX_DELIVERY_SERVICE = {
  name: 'fedex',
  label: 'FedEx',
  id: 2,
  ico: '/img/company-icons-group/tab-fedex.svg',
}
export const FEDEXSMARTPOST_DELIVERY_SERVICE = {
  name: 'fedexsmartpost',
  label: 'FedEx',
  id: 2,
  ico: '/img/company-icons-group/tab-fedex.svg',
}
export const TNT_DELIVERY_SERVICE = {
  name: 'tnt',
  label: 'TNT',
  id: 7,
  ico: '/img/company-icons-group/tab-tnt.svg',
}

export const DPD_DELIVERY_SERVICE = {
  name: 'dpd',
  label: 'DPD',
  id: 8
}

export const USPS_DELIVERY_SERVICE = {
  name: 'usps',
  label: 'USPS',
  id: 5,
  ico: '/img/company-icons-group/shipping-company/usps.svg',
}


export const LANDMARK_DELIVERY_SERVICE = {
  id: 10,
  name: "landmark",
  label: 'Landmark',
  // ico: '/img/company-icons-group/radio-pictures-ico-usps.png',
}

export const APC_DELIVERY_SERVICE = {
  id: 11,
  deliveryMethodId: 8,
  name: "apc",
  label: "APC",
  ico: '/img/company-icons-group/radio-pictures-ico-apc.svg',
}

export const APC_CAMBRIDGE_DELIVERY_SERVICE = {
  id: 19,
  name: "apc",
  label: "APC",
  ico: '/img/company-icons-group/radio-pictures-ico-apc.svg',
}

export const UKRPOSHTA_DELIVERY_SERVICE = {
  id: 16,
  name: "ukrposhta",
  label: "Ukrposhta",
  ico: '/img/company-icons-group/radio-pictures-ico-usps.png',
}

export const NOVAPOSHTA_DELIVERY_SERVICE = {
  id: 18,
  name: "novaposhta",
  label: "NovaPoshta",
  ico: '/img/company-icons-group/radio-pictures-ico-usps.png',
}

export const BELPOCHTA_DELIVERY_SERVICE = {
  id: 19,
  name: "belpochta",
  label: "Belpochta",
  ico: '/img/company-icons-group/radio-pictures-ico-usps.png',
}

export const CDEK_DELIVERY_SERVICE = {
  id: 20,
  name: "cdek",
  label: "CDEK",
  ico: '/img/company-icons-group/radio-pictures-ico-usps.png',
}

export const MEEST_DELIVERY_SERVICE = {
  id: 21,
  name: "meest",
  label: "MEEST",
  ico: '/img/company-icons-group/radio-pictures-ico-usps.png',
}

export const POSTNL_DELIVERY_SERVICE = {
  id: 28,
  name: "postnl",
  label: "post_nl",
  ico: '/img/company-icons-group/table-postnl-lg.svg',
}

export const GLOBAL24_DELIVERY_SERVICE = {
  id: 29,
  name: "global24",
  label: "g24",
  ico: '',
}

export const EU_PRODUCT_DELIVERY_SERVICE = {
  id: 30,
  name: "eu_product",
  label: "eu-prod",
  ico: '',
}

export const OWN_CARRIER_DELIVERY_SERVICE = {
  id: 33,
  name: "own_product",
  label: "own-carrier",
  ico: '',
}

export const DEUTSCHE_POST_DELIVERY_SERVICE = {
  id: 35,
  name: "deutsche_post",
  label: "DeutschePost",
  ico: '',
}

export const NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE = {
  id: 36,
  name: "npg",
  label: "NovaPoshta",
  ico: '/img/company-icons-group/radio-pictures-ico-usps.png',
}

export const ROYAL_MAIL_DELIVERY_SERVICE = {
  id: 4,
  name: "royal-mail",
  label: "RoyalMail",
  ico: '',
}

export const GLOBAL_DELIVERY_SERVICES = {
  DHL_DELIVERY_SERVICE,
  FEDEX_DELIVERY_SERVICE,
  TNT_DELIVERY_SERVICE,
  DPD_DELIVERY_SERVICE,
  USPS_DELIVERY_SERVICE,
  LANDMARK_DELIVERY_SERVICE,
  APC_DELIVERY_SERVICE,
  UKRPOSHTA_DELIVERY_SERVICE,
  NOVAPOSHTA_DELIVERY_SERVICE,
  BELPOCHTA_DELIVERY_SERVICE,
  CDEK_DELIVERY_SERVICE,
  MEEST_DELIVERY_SERVICE,
}

export const SHIPMENT_METHODS = {
  DHL_DELIVERY_SERVICE,
  FEDEX_DELIVERY_SERVICE,
  TNT_DELIVERY_SERVICE,
}


/*************  Shipping Cost Variables  ***********/

export const SHIPPING_COST_DELIVERY_SERVICES = {
  DHL_DELIVERY_SERVICE,
  FEDEX_DELIVERY_SERVICE,
  TNT_DELIVERY_SERVICE,
  LANDMARK_DELIVERY_SERVICE,
  DPD_DELIVERY_SERVICE,
  EU_PRODUCT_DELIVERY_SERVICE
}




/*************  Express Variables  ***********/


export const EXPRESS_DELIVERY_SERVICES = {
  DHL_DELIVERY_SERVICE,
  FEDEX_DELIVERY_SERVICE,
  TNT_DELIVERY_SERVICE,
  NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE,
}

export const EXPRESS_DELIVERY_STATUSES = {
  IN_PROGRESS_STATUS: 'in_progress',
  COMPLETED_STATUS: 'completed',
  COPY_STATUS: 'copy',
  WAIT_FOR_PAY_STATUS: 'wait_for_pay',
  WAIT_FOR_MAKING_LABEL_STATUS: 'wait_for_making_label'
}

export const EXPRESS_FROM_TRANSACTIONS = {
  ORDER: {
    name: 'order',
    value: 'from_order_external',
  },
  PAYMENT: {
    name: 'payment',
    value: 'from_payment_transaction',
  }
}


export const EXPRESS_MAKE_LABEL_STATUSES = {
  NOT_PAYED: 'not_payed',
}

export const ORDER_TYPE_EXPRESS = 'express'
export const ORDER_TYPE_CONSOLIDATION = 'consolidation'

export const EXPRESS_FEDEX_BUILDING_PARTS = [
  {
    name: 'Office',
    value: 'OFFICE',
  },
  {
    name: 'House',
    value: 'HOUSE',
  },
  {
    name: 'Apartment',
    value: 'APARTMENT',
  },
]

/** FEDEX PACKAGING TYPES */
export const FEDEX_PACKAGING_TYPES = {
  HIMSELF_FEDEX_PACKAGING_TYPE: {
    name: 'YOUR PACKING',
    translate: 'express_himselfPackaging',
    value: 'himself_packaging'
  },
  FEDEX_PACKAGING_TYPE: {
    name: 'FEDEX PAK',
    translate: 'express_fedexPackaging',
    value: 'fedex_packaging'
  },
  ENVELOPE_FEDEX_PACKAGING_TYPE: {
    name: 'FEDEX ENVELOPE',
    translate: 'express_fedexEnvelopePackaging',
    value: 'fedex_envelope_packaging'
  },
}

export const EXPRESS_DHL_COUNTRY_TYPES = {
  PL: 'PL',
  UA: 'UA',
}




/*************  Internal Variables  ***********/


export const INTERNAL_STATUSES = {
  IN_PROGRESS: {
    id: 1
  },

  COMPLETE: {
    id: 2,
  },

  CLOSE: {
    id: 3,
  },

  COPY: {
    id: 4,
  },
}

export const INTERNAL_DELIVERY_SERVICES = {
  UKRPOSHTA_DELIVERY_SERVICE,
  NOVAPOSHTA_DELIVERY_SERVICE,
  BELPOCHTA_DELIVERY_SERVICE,
  CDEK_DELIVERY_SERVICE
}

export const NOVAPOSHTA_SERVICE_TYPES = {
  warehouseWarehouse:{
    name: 'warehouseWarehouse',
    value: 'WarehouseWarehouse'
  },
  doorsWarehouse: {
    name: 'doorsWarehouse',
    value: 'DoorsWarehouse'
  }
}

export const WAREHOUSE_KIEV = {
  "id": 1,
  "code": "KIEV01",
}

export const NOVAPOSHTA_LABEL_FILES_TYPES = {
  labelFile: 'labelFile',
  markingFile: 'markingFile',
}

export const CDEK_LABEL_FILES_TYPES = {
  labelFile: 'labelFile',
  markingFile: 'markingFile',
  form103AFile: 'form103AFile',
}

export const UKRPOSHTA_LABEL_FILES_TYPES = {
  labelFile: 'labelFile',
}






/*************  Main Settings Variables  ***********/

export const OUTPUT_KIT_GROUP_MINIMUM_LIMIT_SETTING = 'output_kit_group_minimum_limit'
export const OUTPUT_UA_CARD_DAY_LIMIT_SETTING = 'output_ua_card_day_limit'
export const OUTPUT_UA_CARD_WEEK_LIMIT_SETTING = 'output_ua_card_week_limit'
export const OUTPUT_UA_CARD_MONTH_LIMIT_SETTING = 'output_ua_card_month_limit'

export const OUTPUT_COMMISSION_500_SETTING = 'output_ua_commission_charge_500'
export const OUTPUT_COMMISSION_1000_SETTING = 'output_ua_commission_charge_1000'
export const OUTPUT_COMMISSION_3000_SETTING = 'output_ua_commission_charge_3000'

export const DHL_PERCENT_UA_SETTING = 'percent_dhl_ua'
export const DHL_PERCENT_US_SETTING = 'percent_dhl_us'
export const DHL_PERCENT_BY_SETTING = 'percent_dhl_by'

export const PERCENT_CDEK_DEFAULT = 'percent_cdek_default'

export const TNT_PERCENT_DEFAULT = 'percent_tnt_default'
export const TNT_PERCENT_VIP = 'percent_tnt_vip'

export const FEDEX_PERCENT_DEFAULT = 'percent_fedex_default'
export const FEDEX_PERCENT_RUSSIA = 'percent_fedex_russia'
export const FEDEX_PERCENT_BALTIC = 'percent_fedex_baltic_plus'
export const FEDEX_PERCENT_POLAND = 'percent_fedex_poland'
export const FEDEX_PERCENT_GB = 'percent_fedex_gb'
export const NOVAPOSHTA_GLOBAL_PERCENT_DEFAULT = 'percent_npg_default'


export const USER_NEGATIVE_BALANCE_MAX_DAYS = 'user_negative_balance_max_days'
export const USER_NEGATIVE_BALANCE_MIN_LIMIT = 'user_negative_balance_min_limit'
export const USER_NEGATIVE_BALANCE_BLOCK_MAX_DAYS = 'max_days_with_negative_balance'
export const USER_NEGATIVE_BALANCE_BLOCK_MIN_LIMIT = 'negative_balance_limit_for_restriction'

export const PERCENT_ORDER_OUTBOUND_INSURANCE_COST = 'percent_order_outbound_insurance_cost'
export const ORDER_FEE_PER_ITEM_OVER_THREE = 'order_fee_per_item_over_three'
export const EU_CONSOLIDATION_VAT = 'eu_consolidation_vat'
export const PRODUCT_CONSOLIDATION_VAT = 'product_consolidation_vat'
export const IRELAND_EXPRESS_VAT = 'ireland_express_vat'

export const ONE_HOUR_ENGRAVING_COST = 'one_hour_engraving_cost'
export const ENGRAVING_TARIFF_PER_AREA = 'engraving_tariff_per_area'
export const ENGRAVING_TARIFF = 'engraving_tariff'
export const ENGRAVING_TARIFF_DIFFERENT = 'engraving_tariff_different'
export const ENGRAVING_ADJUSTING_THE_LAYOUT = 'engraving_adjusting_the_layout'
export const ENGRAVING_TARIFF_PER_ORDER = 'engraving_tariff_per_order'
export const ENGRAVING_DISCOUNT = 'engraving_discount'






/*************  Fund Request Variables  ***********/
export const REQUEST_FUNDS_TYPES = {
  INDIVIDUAL: {
    id: 1,
    value: 'individual'
  },
  BUSINESS: {
    id: 2,
    value: 'business'
  },
}

export const REQUEST_FUNDS_TRNS_TYPES = {
  INDIVIDUAL: {
    id: 1,
    value: 'individual'
  },
  AUTOMATICALLY: {
    id: 2,
    value: 'automatically'
  },
}


export const REQUEST_FUNDS_STATUSES = {
  SUCCESS_OUTPUT_STATUS: {
    value: 'success',
    name: 'Success',
    translation: 'requestFunds_cardStatusPaid'
  },
  NEW_OUTPUT_STATUS: {
    value: 'new',
    name: 'New',
    translation: 'requestFunds_New'
  },
  IN_PROCESS_OUTPUT_STATUS: {
    value: 'in_process',
    name: 'In process',
    translation: 'requestFunds_InProcess'
  },
  WAIT_CONFIRM_OUTPUT_STATUS: {
    value: 'wait_confirmation',
    name: 'Wait confirm ',
    translation: 'requestFunds_InProcess'
  },
  REJECTED_OUTPUT_STATUS: {
    value: 'rejected',
    name: 'Rejected',
    translation: 'requestFunds_cardStatusRejected'
  },
  CANCELED_OUTPUT_STATUS: {
    value: 'canceled',
    name: 'Canceled',
    translation: 'requestFunds_cardStatusUnapproved'
  },
  ERROR_OUTPUT_STATUS: {
    value: 'error',
    name: 'Error',
    translation: 'requestFunds_cardStatusError'
  },
}


export const REQUEST_FUNDS_STATUSES_ARRAY = [
  REQUEST_FUNDS_STATUSES.SUCCESS_OUTPUT_STATUS,
  REQUEST_FUNDS_STATUSES.NEW_OUTPUT_STATUS,
  REQUEST_FUNDS_STATUSES.CANCELED_OUTPUT_STATUS,
]


/*************  Invoices Variables  ***********/

export const INVOICES_STATUS_PAYED = {
  name: 'payed',
  translation: 'invoices_payed',
  value: 'payed',
}

export const INVOICES_STATUS_WAIT_FOR_PAY = {
  name: 'wait_for_pay',
  translation: 'invoices_waitForPay',
  value: 'wait_for_pay',
}

export const INVOICES_STATUS_REFUNDED = {
  name: 'refunded',
  translation: 'invoices_refunded',
  value: 'refunded',
}

export const INVOICES_STATUS_ERROR = {
  name: 'error',
  translation: 'invoices_error',
  value: 'error',
}

export const INVOICES_STATUS_CANCELLED = {
  name: 'canceled',
  translation: 'invoices_canceled',
  value: 'canceled',
}

export const INVOICES_STATUS_DRAFT = {
  name: 'draft',
  translation: 'invoices_draft',
  value: 'draft',
}

export  const INVOICES_STATUSES = {
  INVOICES_STATUS_PAYED,
  INVOICES_STATUS_WAIT_FOR_PAY,
  INVOICES_STATUS_REFUNDED,
  INVOICES_STATUS_ERROR,
  INVOICES_STATUS_CANCELLED,
  INVOICES_STATUS_DRAFT,
}

export  const INVOICES_ARRAY_STATUSES = [
  INVOICES_STATUS_PAYED,
  INVOICES_STATUS_WAIT_FOR_PAY,
  INVOICES_STATUS_REFUNDED,
  INVOICES_STATUS_ERROR,
  INVOICES_STATUS_CANCELLED,
  INVOICES_STATUS_DRAFT,
]

export  const INVOICES_ARRAY_STATUSES_FILTER = [
  INVOICES_STATUS_PAYED,
  INVOICES_STATUS_CANCELLED,
  INVOICES_STATUS_REFUNDED,
]

/*************  Invoice Types  ***********/

export const INVOICES_TYPES = {
  INVOICES_TYPE_ORDER: {
    id: 1,
  },
  INVOICES_TYPE_LISTING: {
    id: 2,
  },
  INVOICES_TYPE_ORDER_FBM: {
    id: 3,
  },
  INVOICES_TYPE_ORDER_FBA: {
    id: 4,
  },
  INVOICES_TYPE_STORAGE_USAGE: {
    id: 5,
  },
  INVOICES_TYPE_CUSTOM: {
    id: 6,
  },
  INVOICES_TYPE_DISPOSAL: {
    id: 7,
  },
  INVOICES_TYPE_SMS: {
    id: 8,
  },
  INVOICES_TYPE_MEEST: {
    id: 9,
  },
}

export const INVOICES_MODELS = {
  INVOICES_MODEL_ORDER_INTERNAL: {
    name: 'order_internal',
  },
  INVOICES_MODEL_ORDER_FBM: {
    name: 'order_fbm',
  },
  INVOICES_MODEL_ORDER_FBA: {
    name: 'order_fba',
  },
  INVOICES_MODEL_ORDER_INBOUND: {
    name: 'order_inbound',
  },
  INVOICES_MODEL_CONSOLIDATION: {
    name: 'consolidation',
  },
  INVOICES_MODEL_ORDER_EXPRESS: {
    name: 'order_express',
  },
}

export const INVOICES_VARIANTS = {
  INVOICES_VARIANT_ORDER_NP: {
    name: 'order_nova_poshta',
  },
  INVOICES_VARIANT_ORDER_CDEK: {
    name: 'order_cdek',
  },
  INVOICES_VARIANT_ORDER_FBM: {
    name: 'order_fbm',
  },
  INVOICES_VARIANT_ORDER_FBA: {
    name: 'order_fba',
  },
  INVOICES_VARIANT_ORDER_INBOUND: {
    name: 'order_inbound',
  },
  INVOICES_VARIANT_CONSOLIDATION: {
    name: 'consolidation',
  },
  INVOICES_VARIANT_ORDER_EXPRESS: {
    name: 'order_express',
  },
  INVOICES_VARIANT_OTHER: {
    name: 'other',
  },
}

/*************  Expenses Variables  ***********/

export  const EXPENSES_SYSTEM_TYPES = {
  PAYEE_TYPE: {
    name: 'expenses_payee',
    translation: 'expenses_payee',
    value: 'payee_expense',
  },
  DEFAULT_TYPE: {
    name: 'expenses_default',
    translation: 'expenses_default',
    value: 'default_expense',
  },
  BANK_TYPE: {
    name: 'expenses_bank',
    translation: 'expenses_bank',
    value: 'bank_expense',
  },
  DEFAULT_INCOME_TYPE: {
    name: 'expenses_incomeType',
    translation: 'otherIncome_typeOfIncome',
    value: 'default_income',
  },
}


/*************  Fill Claim Variables  ***********/

export  const FILL_CLAIM_SHIPPING_COMPANIES= {
  FBM_SHIPPING_FEDEX,
  FBM_SHIPPING_USPS,
  FBM_SHIPPING_LANDMARK,
  FBM_SHIPPING_APC,
  FBM_SHIPPING_DHL,
  TNT_DELIVERY_SERVICE
}


/*************  Fill Claim Variables  ***********/

export const SYSTEM_ROLE_DEVELOPER = {
  id: 1,
  name: 'developer',
  translation: 'common_developerRole',
  value: 'developer',
}
export const SYSTEM_ROLE_SUPER_ADMIN = {
  id: 2,
  name: 'super_admin',
  translation: 'common_superAdminRole',
  value: 'super_admin',
}
export const SYSTEM_ROLE_ADMIN = {
  id: 3,
  name: 'admin',
  translation: 'common_adminRole',
  value: 'admin',
}
export const SYSTEM_ROLE_USER = {
  id: 4,
  name: 'user',
  translation: 'common_userRole',
  value: 'user',
}
export const SYSTEM_ROLE_SUB_USER = {
  id: 5,
  name: 'sub_user',
  translation: 'common_subUserRole',
  value: 'sub_user',
}

export const SYSTEM_ROLES = {
  SYSTEM_ROLE_DEVELOPER,
  SYSTEM_ROLE_SUPER_ADMIN,
  SYSTEM_ROLE_ADMIN,
  SYSTEM_ROLE_USER,
  SYSTEM_ROLE_SUB_USER,
}



/*************  PayPal Cases Statuses  ***********/

export const PAYPAL_CASES_STATUS_OPENED = {
  name: 'opened',
  translation: 'payPalCases_opened',
  value: 'opened',
}

export const PAYPAL_CASES_STATUS_CLOSED = {
  name: 'closed',
  translation: 'payPalCases_closed',
  value: 'closed',
}

export const PAYPAL_CASES_STATUS_PAYPAL_REFUND = {
  name: 'paypal_refund',
  translation: 'payPalCases_paypalRefund',
  value: 'paypal_refund',
}

export const PAYPAL_CASES_STATUS_SYSTEM_REFUND = {
  name: 'system_refund',
  translation: 'payPalCases_systemRefund',
  value: 'system_refund',
}

export  const PAYPAL_CASES_STATUSES = {
  PAYPAL_CASES_STATUS_OPENED,
  PAYPAL_CASES_STATUS_CLOSED,
  PAYPAL_CASES_STATUS_PAYPAL_REFUND,
  PAYPAL_CASES_STATUS_SYSTEM_REFUND
}

export  const PAYPAL_CASES_ARRAY_STATUSES = [
  PAYPAL_CASES_STATUS_OPENED,
  PAYPAL_CASES_STATUS_CLOSED,
  PAYPAL_CASES_STATUS_PAYPAL_REFUND,
  PAYPAL_CASES_STATUS_SYSTEM_REFUND
]

/************* Payment Methods  ***********/

export const PAYMENT_METHODS = {
  USER_BALANCE_METHOD: {
    id: 1,
  },
  PAYMENT_SYSTEM_METHOD: {
    id: 2,
  }
}

/*********** Consolidation Delivery Services ***********/

export const CONSOLIDATION_DELIVERY_SERVICES = {
  UKRPOSHTA_DELIVERY_SERVICE,
  NOVAPOSHTA_DELIVERY_SERVICE,
  MEEST_DELIVERY_SERVICE
}

export const CONSOLIDATION_DELIVERY_SERVICES_ARRAY = [
  // UKRPOSHTA_DELIVERY_SERVICE,
  NOVAPOSHTA_DELIVERY_SERVICE,
  // MEEST_DELIVERY_SERVICE
]

export const CONSOLIDATION_UNION_SHIPPING_COMPANIES = {
  FEDEX_DELIVERY_SERVICE,
  DHL_DELIVERY_SERVICE,
  TNT_DELIVERY_SERVICE
}

export const CONSOLIDATION_UNION_SHIPPING_COMPANIES_ARRAY = [
  FEDEX_DELIVERY_SERVICE,
  DHL_DELIVERY_SERVICE,
  TNT_DELIVERY_SERVICE
]

export const CONSOLIDATION_APC_DELCON_SHIPPING_COMPANIES = {
  FEDEX_DELIVERY_SERVICE,
  DHL_DELIVERY_SERVICE,
  TNT_DELIVERY_SERVICE,
  USPS_DELIVERY_SERVICE,
  APC_DELIVERY_SERVICE,
}

export const CONSOLIDATION_APC_DELCON_SHIPPING_COMPANIES_ARRAY = [
  FEDEX_DELIVERY_SERVICE,
  DHL_DELIVERY_SERVICE,
  TNT_DELIVERY_SERVICE,
  USPS_DELIVERY_SERVICE,
  APC_DELIVERY_SERVICE,
]

export const WAREHOUSE_STORAGE_DOCUMENT_TYPES = {
  TYPE_INCOME: 10,
  TYPE_DISPOSAL: 20,
  TYPE_INVENTORY: 30,
  TYPE_INVENTORY_REQUEST: 40,
  TYPE_DISPOSAL_REQUEST: 50,
}

export const DISPOSAL_SUBTYPES = {
  INCOME: {
    label: 'disposal_income',
    value: 'income'
  },
  DISPOSAL: {
    label: 'disposal_disposal',
    value: 'disposal'
  }
}

export const DISPOSAL_PRODUCT_TYPE = {
  TRANSIT: {
    label: 'disposal_inTransit',
    value: "in_transit",
  },
  RESERV: {
    label: 'disposal_reserv',
    value: "reserv",
  },
  WAREHOUSE: {
    label: 'disposal_warehouse',
    value: "warehouse",
  },
  DAMAGE: {
    label: 'disposal_damage',
    value: "damage",
  },
}

export const INVENTORY_TYPES = {
  BY_PRODUCT: 'by_product',
  BY_USER: 'by_user',
  CUSTOM: 'custom',
  MOVE: 'move',
  GENERAL: 'general',
}

export const TRACKING_LOG = {
  NO_INFO: 'no_info',
  INFO_SENT: 'info_sent',
  IN_DELIVERY: 'in_delivery',
  IN_TRANSIT: 'in_transit',
  IN_RETURN: 'in_return',
  DELIVERED: 'delivered',
  RETURNED: 'returned',
  EXCEPTION: 'exception',
  NOT_FOUND: 'not_found',
  NOT_VALID: 'not_valid',
  OUT_FOR_DELIVERY: 'out_for_delivery',
}

/*********** Shipping cost calc from warehouses *************/

export const SHIPPING_COST_CALC_WAREHOUSE_UKRAINE = {
  name: 'ua',
  id: 1,
}

export const SHIPPING_COST_CALC_WAREHOUSE_LATVIA = {
  name: 'lv',
  id: 11,
}

export const SHIPPING_COST_CALC_WAREHOUSE_USA = {
  name: 'us',
  id: 10,
}

export const SHIPPING_COST_CALC_WAREHOUSE_POLAND = {
  name: 'pl',
  id: 13,
}

export const SHIPPING_COST_CALC_WAREHOUSE_GREAT_BRITAIN = {
  name: 'gb',
  id: 16,
}

export const SHIPPING_COST_CALC_WAREHOUSES = {
  SHIPPING_COST_CALC_WAREHOUSE_UKRAINE,
  SHIPPING_COST_CALC_WAREHOUSE_LATVIA,
  SHIPPING_COST_CALC_WAREHOUSE_USA,
  SHIPPING_COST_CALC_WAREHOUSE_POLAND,
  SHIPPING_COST_CALC_WAREHOUSE_GREAT_BRITAIN,
}

/************ Transaction types for multisearch ******************/

export const MULTISEARCH_PAYMENT_TRANSACTION = {
  name: 'payment_transactions',
  value: 'payment_transactions',
}

export const MULTISEARCH_COMPLAINT_TRANSACTION = {
  name: 'complaints',
  value: 'complaints',
}

export const MULTISEARCH_FORBIDDEN_PAYMENT_TRANSACTION = {
  name: 'forbidden_payment_transaction',
  value: 'forbidden_payment_transaction',
}

export const MULTISEARCH_BALANCE_OUTPUT_TRANSACTION = {
  name: 'balance_output',
  value: 'balance_output',
}

export const MULTISEARCH_REFUND_TRANSACTION = {
  name: 'refunds',
  value: 'refunds',
}

export const MULTISEARCH_TRANSACTION_TYPES = {
  MULTISEARCH_PAYMENT_TRANSACTION,
  MULTISEARCH_COMPLAINT_TRANSACTION,
  MULTISEARCH_FORBIDDEN_PAYMENT_TRANSACTION,
  MULTISEARCH_BALANCE_OUTPUT_TRANSACTION,
  MULTISEARCH_REFUND_TRANSACTION
}

export const MULTISEARCH_TRANSACTION_TYPES_ARRAY = [
  MULTISEARCH_PAYMENT_TRANSACTION,
  MULTISEARCH_COMPLAINT_TRANSACTION,
  MULTISEARCH_FORBIDDEN_PAYMENT_TRANSACTION,
  MULTISEARCH_BALANCE_OUTPUT_TRANSACTION,
  MULTISEARCH_REFUND_TRANSACTION
]

/************ Etsy Track Setting for Shops ******************/

export const ETSY_SHOP_TRACK_SETTING_SEND_CREATED = {
  id: 1,
  translation: 'shops_sendAsSoonAsCreated'
}

export const ETSY_SHOP_TRACK_SETTING_SEND_ARRIVED_MINNESOTA = {
  id: 2,
  translation: 'shops_sendAfterArrival'
}

export const ETSY_SHOP_TRACK_SETTING_DO_NOT_SEND = {
  id: 3,
  translation: 'shops_doNotSendFromAPI'
}

export const ETSY_SHOP_TRACK_SETTINGS = {
  ETSY_SHOP_TRACK_SETTING_SEND_CREATED,
  ETSY_SHOP_TRACK_SETTING_SEND_ARRIVED_MINNESOTA,
  ETSY_SHOP_TRACK_SETTING_DO_NOT_SEND,
}

export const ETSY_SHOP_TRACK_SETTINGS_ARRAY = [
  ETSY_SHOP_TRACK_SETTING_SEND_CREATED,
  ETSY_SHOP_TRACK_SETTING_SEND_ARRIVED_MINNESOTA,
  ETSY_SHOP_TRACK_SETTING_DO_NOT_SEND,
]


/************** RETURN GOODS ******************/

export const RETURN_GOODS_TYPES = {
  MY_RETURNS: 'my-returns',
  PROCESSED_RETURNS: 'processed-returns',
  UNPROCESSED_RETURNS: 'unprocessed-returns',
  TRASH: 'trash',
}

/************** CONSOLIDATION WAREHOUSES ******************/

export const KIEV_WAREHOUSE = {
  code: 'KIEV01',
  id: 1,
}

export const DNEPR_WAREHOUSE = {
  code: 'DNEPR01',
  id: 2,
}

export const MINSK_WAREHOUSE = {
  code: 'MINSK01',
  id: 3,
}

export const KHARKIV_WAREHOUSE = {
  code: 'Kharkiv01',
  id: 4,
}

export const ODESA_WAREHOUSE = {
  code: 'Odesa01',
  id: 5,
}

export const LVIV_WAREHOUSE = {
  code: 'lviv001',
  id: 7,
}

export const ZAPORIZHIA_WAREHOUSE = {
  code: 'Zaporizhzhia1',
  id: 9,
}

export const BLAINE_WAREHOUSE = {
  code: 'Blaine001',
  name: 'blaine',
  id: 10,
  storage_id: 1,
}

export const RIGA_WAREHOUSE = {
  code: 'Riga1',
  id: 11,
}

export const LODZ_WAREHOUSE = {
  code: 'PL_LODZ',
  name: 'lodz',
  id: 13,
  storage_id: 2,
}

export const PYRZOWICE_WAREHOUSE = {
  code: 'PL_PYRZOWICE',
  name: 'Pyrzowice',
  id: 14,
}

export const CAMBRIDGE_WAREHOUSE = {
  code: 'UK_01',
  name: 'cambridge',
  id: 16,
  storage_id: 3,
}

export const CONSOLIDATION_UNION_WAREHOUSES = {
  KIEV_WAREHOUSE,
  DNEPR_WAREHOUSE,
  // MINSK_WAREHOUSE,
  KHARKIV_WAREHOUSE,
  ODESA_WAREHOUSE,
  LVIV_WAREHOUSE,
  ZAPORIZHIA_WAREHOUSE,
  LODZ_WAREHOUSE,
  CAMBRIDGE_WAREHOUSE,
  // BLAINE_WAREHOUSE,
  RIGA_WAREHOUSE,
}

export const CONSOLIDATION_UNION_WAREHOUSES_ARRAY = [
  KIEV_WAREHOUSE,
  DNEPR_WAREHOUSE,
  // MINSK_WAREHOUSE,
  KHARKIV_WAREHOUSE,
  ODESA_WAREHOUSE,
  LVIV_WAREHOUSE,
  ZAPORIZHIA_WAREHOUSE,
  LODZ_WAREHOUSE,
  CAMBRIDGE_WAREHOUSE,
  // BLAINE_WAREHOUSE,
  RIGA_WAREHOUSE,
]

/************** SEARCH BY TRACK NUMBER SECTIONS ******************/
export const SEARCH_TRACK_SECTION_CONSOLIDATION = 'found_in_consolidations'
export const SEARCH_TRACK_SECTION_EXPRESS = 'found_in_orders_express'
export const SEARCH_TRACK_SECTION_FBA = 'found_in_orders_fba'
export const SEARCH_TRACK_SECTION_FBM = 'found_in_orders_fbm'
export const SEARCH_TRACK_SECTION_INBOUND = 'found_in_orders_inbound'
export const SEARCH_TRACK_SECTION_INTERNAL = 'found_in_orders_internal'

export const SEARCH_TRACK_SECTIONS = {
  SEARCH_TRACK_SECTION_CONSOLIDATION,
  SEARCH_TRACK_SECTION_EXPRESS,
  SEARCH_TRACK_SECTION_FBA,
  SEARCH_TRACK_SECTION_FBM,
  SEARCH_TRACK_SECTION_INBOUND,
  SEARCH_TRACK_SECTION_INTERNAL,
}

export const SEARCH_TRACK_SECTIONS_ARRAY = [
  SEARCH_TRACK_SECTION_CONSOLIDATION,
  SEARCH_TRACK_SECTION_EXPRESS,
  SEARCH_TRACK_SECTION_FBA,
  SEARCH_TRACK_SECTION_FBM,
  SEARCH_TRACK_SECTION_INBOUND,
  SEARCH_TRACK_SECTION_INTERNAL,
]




/************** CONSOLIDATION WAREHOUSES ******************/

export const DASHBOARD_DRAGGABLE = {
  BALANCE: 'balance',
  PAYONEER_BALANCE: 'payoneer-balance',
  RETURN_PARCELS: 'return_parcels',
  RETURN_PARCEL_UNDEFINED: 'return_parcel_undefined',
  RETURN_PARCEL_ORDERS: 'return_parcel_orders',
  RETURN_PARCEL_REQUESTS: 'return_parcel_requests',
  INVOICES: 'invoices',
  SHOPS: 'shops',
  LISTINGS: 'listings',
  SHIP_AMAZON: 'ship_amazon',
  FBA_INVOICES: 'fba_invoices',
  PAYPAL_TRANSACTIONS: 'paypal_transactions',
  CALCULATE_SHIPPING: 'calculate_shipping',
  CALCULATE_ENGRAVING_SHIPPING: 'calculate_engraving_shipping',
  DELIVERY_STATISTIC: 'delivery_statistic',
  SENT_SMS: 'sent_sms',
  HEARTY_FROZEN_TRANSACTIONS: 'hearty_frozen_transactions',
  STORAGE_WITHOUT_SHIPPING: 'storage_without_shipping',
  RAMP: 'request_ramp_card',
  CREATE_REQUEST_FUNDS: 'create-request-funds',
}

/******************** EXPRESS SEND STATUSES **************************/

export const EXPRESS_SEND_STATUS_SUCCESS = {
  value: 'Success',
  translation: 'express_success'
}

export const EXPRESS_SEND_STATUS_ERROR = {
  value: 'Error',
  translation: 'express_error'
}

export const EXPRESS_SEND_STATUSES = {
  EXPRESS_SEND_STATUS_SUCCESS,
  EXPRESS_SEND_STATUS_ERROR
}

export const EXPRESS_SEND_STATUSES_ARRAY = [
  EXPRESS_SEND_STATUS_SUCCESS,
  EXPRESS_SEND_STATUS_ERROR
]

/************** FEDEX EXPRESS PACKAGING FOR SHIPPING CALC *******************/

export const FEDEX_EXPRESS_HIMSELF_PACKAGING = {
  label: 'himself_packaging',
  translation: 'shippingCost_himselfPack'
}

export const FEDEX_EXPRESS_FEDEX_PACK = {
  label: 'fedex_pack',
  translation: 'shippingCost_fedexPack'
}

export const FEDEX_EXPRESS_FEDEX_ENVELOP = {
  label: 'fedex_envelop',
  translation: 'shippingCost_fedexEnvelop'
}


/************** ORDER PRICES *******************/

export const ORDER_PRICE_FBM = {
  name: 'fbm',
  label: 'FBM',
  value: 'fee_order_fbm',
}

export const ORDER_PRICE_FBA = {
  name: 'fba',
  label: 'FBA',
  value: 'fee_order_fba',
}

export const ORDER_PRICE_CREATE_LABEL = {
  name: 'iCreateLabel',
  label: 'I Create Label',
  value: 'fee_self_create_label',
}

export const ORDER_PRICE_SKLADUSA_FEE = {
  name: 'skladusaFee',
  label: 'Skladusa Fee',
  value: 'fee_order',
}

export const ORDER_PRICE_ZONE_SKIPPING = {
  name: 'zoneSkipping',
  label: 'Zone Skipping',
  value: 'zone_skipping',
}

export const ORDER_PRICE_TYPES = {
  ORDER_PRICE_FBM,
  ORDER_PRICE_FBA,
  ORDER_PRICE_CREATE_LABEL,
  ORDER_PRICE_SKLADUSA_FEE,
  ORDER_PRICE_ZONE_SKIPPING,
}


/************** SHIPPING COMPANY FOR ORDERS *******************/

export const DELIVERY_DESTINATIONS = {
  FOR_USA: {
    id: 1,
    label: 'shippingCompany_forUsa'
  },
  FOR_NOT_USA: {
    id: 2,
    label: 'shippingCompany_forNotUsa'
  }
}

/************** FAST ORDERS STATUSES *******************/

export const FAST_ORDER_STATUS_NEW = {
  name: 'new',
  value: 'new',
  translation: 'fastOrders_newStatus',
}

export const FAST_ORDER_STATUS_IN_PROGRESS = {
  name: 'in_process',
  value: 'in_process',
  translation: 'fastOrders_inProgressStatus',
}

export const FAST_ORDER_STATUS_SUCCESS = {
  name: 'success',
  value: 'success',
  translation: 'fastOrders_successStatus',
}

export const FAST_ORDER_STATUSES = {
  FAST_ORDER_STATUS_NEW,
  FAST_ORDER_STATUS_IN_PROGRESS,
  FAST_ORDER_STATUS_SUCCESS,
}

export const FAST_ORDER_STATUSES_ARRAY = [
  FAST_ORDER_STATUS_NEW,
  FAST_ORDER_STATUS_IN_PROGRESS,
  FAST_ORDER_STATUS_SUCCESS,
]

/************** TRACK TEST TYPES *******************/

export const TRACK_TEST_TYPE_FBM = {
  name: 'order_fbm',
  value: 'order_fbm',
  translation: 'trackTest_typeFBM',
}

export const TRACK_TEST_TYPE_EXPRESS = {
  name: 'order_express',
  value: 'order_express',
  translation: 'trackTest_typeExpress',
}

export const TRACK_TEST_TYPE_TRANSACTION = {
  name: 'transaction',
  value: 'transaction',
  translation: 'trackTest_typeTransaction',
}

export const TRACK_TEST_TYPES = {
  TRACK_TEST_TYPE_FBM,
  TRACK_TEST_TYPE_EXPRESS,
  TRACK_TEST_TYPE_TRANSACTION,
}

export const TRACK_TEST_TYPES_ARRAY = [
  TRACK_TEST_TYPE_FBM,
  TRACK_TEST_TYPE_EXPRESS,
  TRACK_TEST_TYPE_TRANSACTION,
]


/***********  CHAT VARIABLES **********/
const STATUS_WAIT = {
  id: 1,
  translate: 'chat_statusWait'
}
const STATUS_SENT = {
  id: 2,
  translate: 'chat_statusSent'
}
const STATUS_READ = {
  id: 3,
  translate: 'chat_statusRead'
}
const STATUS_DELIVERED = {
  id: 4,
  translate: 'chat_statusDelivered'
}
const STATUS_ERROR = {
  id: 5,
  translate: 'chat_statusError'
}
const STATUS_UNDELIVERABLE = {
  id: 6,
  translate: 'chat_statusUndeliverable'
}

export const CHAT_STATUSES = {
  STATUS_WAIT,
  STATUS_SENT,
  STATUS_READ,
  STATUS_DELIVERED,
  STATUS_ERROR,
  STATUS_UNDELIVERABLE,
}

/***********  CONSOLIDATION UNION LABEL FILE TYPES **********/
const TYPE_FILE_INVOICE = 'proforma'
const TYPE_FILE_TSCA = 'tsca'
const TYPE_FILE_DBTS = 'dbts'
const TYPE_FILE_COOF = 'coof'

export const CONSOLIDATION_UNION_LABEL_FILE_TYPES = {
  TYPE_FILE_INVOICE,
  TYPE_FILE_TSCA,
  TYPE_FILE_DBTS,
  TYPE_FILE_COOF,
}

/***********  FILL CLAIM FEDEX & DHL TYPES **********/
const FILL_CLAIM_SHIPPING_TYPE_FEDEX_EXPRESS = {
  name: 'Fedex express',
  value: 'express',
  shipping_company_id: 2,
  translation: 'fillClaim_fedexExpress'
}

const FILL_CLAIM_SHIPPING_TYPE_FEDEX_USA = {
  name: 'Fedex from USA',
  value: 'from_usa',
  shipping_company_id: 2,
  translation: 'fillClaim_fedexFromUsa'
}

export const FILL_CLAIM_SHIPPING_TYPES_FEDEX = {
  FILL_CLAIM_SHIPPING_TYPE_FEDEX_EXPRESS,
  FILL_CLAIM_SHIPPING_TYPE_FEDEX_USA,
}

export const FILL_CLAIM_SHIPPING_TYPES_FEDEX_ARRAY = [
  FILL_CLAIM_SHIPPING_TYPE_FEDEX_EXPRESS,
  FILL_CLAIM_SHIPPING_TYPE_FEDEX_USA,
]


const FILL_CLAIM_SHIPPING_TYPE_DHL_EXPRESS = {
  name: 'From Ukraine express',
  value: 'express',
  shipping_company_id: 1,
  translation: 'fillClaim_dhlExpress'
}

const FILL_CLAIM_SHIPPING_TYPE_DHL_USA = {
  name: 'From USA',
  value: 'from_usa',
  shipping_company_id: 1,
  translation: 'fillClaim_dhlFromUsa'
}

export const FILL_CLAIM_SHIPPING_TYPES_DHL = {
  FILL_CLAIM_SHIPPING_TYPE_DHL_EXPRESS,
  FILL_CLAIM_SHIPPING_TYPE_DHL_USA,
}

export const FILL_CLAIM_SHIPPING_TYPES_DHL_ARRAY = [
  FILL_CLAIM_SHIPPING_TYPE_DHL_EXPRESS,
  FILL_CLAIM_SHIPPING_TYPE_DHL_USA,
]

/***************** LAST LOGIN FILTER VARIABLES ************************/
const LAST_LOGIN_10_DAYS = {
  name: '10 days',
  value: 10,
  translation: 'userModule_10Days'
}

const LAST_LOGIN_30_DAYS = {
  name: '30 days',
  value: 30,
  translation: 'userModule_30Days'
}

const LAST_LOGIN_60_DAYS = {
  name: '60 days',
  value: 60,
  translation: 'userModule_60Days'
}

const LAST_LOGIN_90_DAYS = {
  name: '90 days',
  value: 90,
  translation: 'userModule_90Days'
}

export const LAST_LOGIN_DAYS_ARRAY = [
  LAST_LOGIN_10_DAYS,
  LAST_LOGIN_30_DAYS,
  LAST_LOGIN_60_DAYS,
  LAST_LOGIN_90_DAYS,
]



/***************** HEARTYSAN ************************/

export const HEARTYSAN_USER_TYPES = {
  HEARTYSAN_MANUFACTURER: {
    'id': 1,
    'name': 'Manufacturer',
  },
  HEARTYSAN_SELLER: {
    'id': 2,
    'name': 'Seller',
  },
}

export const HEARTYSAN_STATUS_ACTIVE = {
  'value': 'active',
  'name': 'active',
  'translation': 'heartysan_active',
}

export const HEARTYSAN_STATUS_DRAFT = {
  'value': 'draft',
  'name': 'draft',
  'translation': 'heartysan_draft',
}

export const HEARTYSAN_STATUSES = {
  HEARTYSAN_STATUS_ACTIVE,
  HEARTYSAN_STATUS_DRAFT
}

export const HEARTYSAN_STATUSES_ARRAY = [
  HEARTYSAN_STATUS_ACTIVE,
  HEARTYSAN_STATUS_DRAFT
]

/** MARKETPLACE REVIEWS **/

export const MARKETPLACE_REVIEWS_STATUS_APPROVED = {
  name: 'approve',
  value: 'approve',
  translation: 'marketplaceReviews_approved',
}

export const MARKETPLACE_REVIEWS_STATUS_UNAPPROVED = {
  name: 'unapproved',
  value: 'unapproved',
  translation: 'marketplaceReviews_unapproved',
}

export const MARKETPLACE_REVIEWS_STATUSES = {
  MARKETPLACE_REVIEWS_STATUS_APPROVED,
  MARKETPLACE_REVIEWS_STATUS_UNAPPROVED
}

export const MARKETPLACE_REVIEWS_STATUSES_ARRAY = [
  MARKETPLACE_REVIEWS_STATUS_APPROVED,
  MARKETPLACE_REVIEWS_STATUS_UNAPPROVED
]



/** MEEST STATUSES **/

export const MEEST_STATUSES = {
  MEEST_STATUS_IN_PROGRESS: {
    translation: 'common_InProgress',
    name: "In Progress",
    value: 'in_progress'
  },
  MEEST_STATUS_COLLECTED: {
    translation: 'common_collected',
    name: "Collected",
    value: 'collected'
  },
  MEEST_STATUS_IN_TRANSIT: {
    translation: 'common_inTransit',
    name: "In transit",
    value: 'in_transit'
  },
  MEEST_STATUS_RECEIVED: {
    translation: 'common_received',
    name: "Received",
    value: 'received'
  },
  MEEST_STATUS_COMPLETED: {
    translation: 'common_delivered',
    name: "Completed",
    value: 'completed'
  },
}

export const MEEST_STATUSES_ARRAY = [
  MEEST_STATUSES.MEEST_STATUS_IN_PROGRESS,
  MEEST_STATUSES.MEEST_STATUS_COLLECTED,
  MEEST_STATUSES.MEEST_STATUS_IN_TRANSIT,
  MEEST_STATUSES.MEEST_STATUS_RECEIVED,
  MEEST_STATUSES.MEEST_STATUS_COMPLETED,
]



/** CONSOLIDATION MEEST STATUSES **/
export const CONSOLIDATION_MEEST_STATUSES = {
  CONSOLIDATION_MEEST_STATUS_IN_PROGRESS: {
    translation: 'common_InProgress',
    name: "In Progress",
    value: 'in_progress'
  },
  CONSOLIDATION_MEEST_STATUS_IN_TRANSIT: {
    translation: 'common_inTransit',
    name: "In transit",
    value: 'in_transit'
  },
  CONSOLIDATION_MEEST_STATUS_RECEIVED: {
    translation: 'common_received',
    name: "Received",
    value: 'received'
  },
  CONSOLIDATION_MEEST_STATUS_COMPLETED: {
    translation: 'common_delivered',
    name: "Completed",
    value: 'completed'
  },
}

export const CONSOLIDATION_MEEST_STATUSES_ARRAY = [
  CONSOLIDATION_MEEST_STATUSES.CONSOLIDATION_MEEST_STATUS_IN_PROGRESS,
  CONSOLIDATION_MEEST_STATUSES.CONSOLIDATION_MEEST_STATUS_IN_TRANSIT,
  CONSOLIDATION_MEEST_STATUSES.CONSOLIDATION_MEEST_STATUS_RECEIVED,
  // CONSOLIDATION_MEEST_STATUSES.CONSOLIDATION_MEEST_STATUS_COMPLETED,
]





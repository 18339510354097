<template>
  <ConsolidationUnionEditAdmin
      v-if="loaded"
      :CU="CU"
      :warehousesData="warehousesData"
      :carriers="carriers"
      :shippingCompanies="shippingCompanies"
      @saveConsolidationUnion="saveConsolidationUnion"
  />
</template>

<script>
  import {ConsolidationUnion} from "../../models/ConsolidationUnion";
  import {consolidationUnionMixin} from "../../../../../mixins/consolidationUnionMixins/consolidationUnionMixin";
  import {
    CONSOLIDATION_UNION_SHIPPING_COMPANIES_ARRAY,
    CONSOLIDATION_UNION_WAREHOUSES_ARRAY
  } from "../../../../../staticData/staticVariables";
  import ConsolidationUnionEditAdmin from "./ConsolidationUnionEditAdmin/ConsolidationUnionEditAdmin";

  export default {
    name: "ConsolidationUnionEdit",

    components: {
      ConsolidationUnionEditAdmin,
    },

    mixins: [
      consolidationUnionMixin
    ],

    data() {
      return {
        CU: new ConsolidationUnion(),
        warehousesData: [],
        carriers: [],
        shippingCompanies: [],
        loaded: false,
      }
    },

    created() {
      if(this.downloadPermissions){
        if(!this.checkPermission()) return
      }

      this.initConsolidationUnionEdit()
    },

    methods: {

      initConsolidationUnionEdit() {
        this.$store.dispatch('getConsolidationUnionCreate').then(response => {
          let respData = this.getRespData(response)
          this.warehousesData = respData.warehouses.filter(item => {
            return CONSOLIDATION_UNION_WAREHOUSES_ARRAY.find(findItem => {
              return findItem.id === item.id
            })
          })
          this.shippingCompanies = respData.delivery_services.filter(item => {
            return CONSOLIDATION_UNION_SHIPPING_COMPANIES_ARRAY.find(findItem => {
              return findItem.id === item.id
            })
          })

          this.carriers = respData?.carriers || []

          this.$store.dispatch('getConsolidationUnion', this.$route.params.id).then(() => {
            this.CU.setConsolidationUnionItem(this.$store.getters.getConsolidationUnionItem, this.warehousesData, this.shippingCompanies, this.carriers, this)
            this.loaded = true
          })
        }).catch(error => this.createErrorLog(error))
      },

    }
  }
</script>

<style scoped>

</style>
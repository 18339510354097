<template>
  <modal
      @close="$emit('close')"
      class="custom-popup-modal custom-popup"
  >
    <template slot="header">
      <div
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['heartysan_dealCreate'])"></span>
        {{$t('heartysan_dealCreate.localization_value.value')}}
      </div>
    </template>
    <template slot="body">
      <div class="custom-popup__content">
        <div class="custom-row">
          <div class="custom-col custom-col--50"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <span class="admin-edit" @click="editTranslate(['heartysan_dealPrice'])"></span>
            <InputSum
                :label="$t('heartysan_dealPrice.localization_value.value')"
                v-bind:class="{'ui-no-valid': Deal.validation.price}"
                :error="Deal.validation.price"
                :errorTxt="$t(`${Deal.validationTranslate.price}.localization_value.value`)"
                :placeholder="'0'"
                :icoType="'dollar'"
                v-model="Deal.data.price"
            />
          </div>
<!--          <div class="custom-col custom-col&#45;&#45;50"-->
<!--               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--            <span class="admin-edit" @click="editTranslate(['chat_contactName'])"></span>-->
<!--            <DefaultInput-->
<!--                :label="$t('chat_contactName.localization_value.value')"-->
<!--                v-bind:class="{'ui-no-valid': chat.validation.name}"-->
<!--                :errorTxt="$t(`${chat.validationTranslate.name}.localization_value.value`)"-->
<!--                :error="chat.validation.name"-->
<!--                v-model="chat.data.name"-->
<!--            />-->
<!--          </div>-->
        </div>
      </div>
    </template>
    <template slot="footer">
      <div class="custom-popup__btn align-items-center d-flex flex-wrap"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['common_cancel','common_create'])"></span>
        <span
            class="site-link site-link--alt custom-popup__btn-i mr-4 mt-3 mb-3"
            @click="$emit('close')"
        >
          {{$t('common_cancel.localization_value.value')}}
        </span>
        <MainButton
            v-if="type === 'create'"
            class="custom-popup__btn-i "
             :value="$t('common_create.localization_value.value')"
            @click.native="save"
        />
        <MainButton
            v-if="type === 'edit'"
            class="custom-popup__btn-i "
             :value="$t('common_update.localization_value.value')"
            @click.native="save"
        />
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from "../../../../commonModals/Modal";
  import MainButton from "../../../../UI/buttons/MainButton/MainButton";
  import {HeartysanDeal} from "@/components/modules/HeartysanChatModule/models/HeartysanDeal";
  import InputSum from "@/components/UI/inputs/InputSum/InputSum";

  export default {
    name: "CreateDealPopup",
    components: {
      InputSum,
      Modal,
      MainButton,
    },

    props: {
      currentChat: Object,
      type: String,
      id: Number,
    },

    data() {
      return {
        Deal: new HeartysanDeal(),
      }
    },

    mounted() {
      console.log(this.currentChat);
      if(this.type === 'edit') {
        this.$store.dispatch('getHeartysanDeal', this.id).then(response => {
          let data = this.getRespData(response).deal
          this.Deal.setItem(data)
        }).catch(error => this.createErrorLog(error))
      }
    },

    methods: {
      save() {
        if(!this.Deal.firstValidation()) return

        let data = this.Deal.prepareSave()
        data.manufacturer_user_id = this.currentChat.manufacturer_user_id
        data.seller_user_id = this.currentChat.seller_user_id
        data.hearty_product_id = this.currentChat.hearty_product_id

        let typeDispatch = 'createHeartysanDeal'

        if(this.type === 'edit') {
          typeDispatch = 'updateHeartysanDeal'
          data = {
            data: data,
            id: this.id

          }
        }

        this.$store.dispatch(typeDispatch, data).then(response => {
          switch (this.getResponseStatus(response)) {
              /**
               * Success
               */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {

              if (this.type === 'edit') {
                this.openNotify('success', 'common_notificationRecordChanged')
                this.sendFirstMessage(this.currentChat, this.type === 'edit', data.data)
              } else {
                this.openNotify('success', 'common_notificationRecordCreated')
                this.sendFirstMessage(this.currentChat, this.type === 'edit', data)
              }
              this.$emit('close')
              this.$emit('reload')
              break
            }
              /**
               * Validation Error
               */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
              /**
               * Undefined Error
               */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }
        })
      },

      sendFirstMessage(chat, edit = false, dataMessage = false) {
        let message = edit ? this.$t('heartysan_createDealUpdateMessage.localization_value.value') : this.$t('heartysan_createDealMessage.localization_value.value')
        let data = {
          'message': message + ' $' + dataMessage.deal_price,
          'system': 1
        }

        let typeDispatch = 'createMessageHeartysanChat'

        this.$store.dispatch(typeDispatch, {data: data, id: chat.id}).then(response => {
          let resp = this.getRespData(response)
          this.$router.push(this.$store.getters.GET_PATHS.heartysanChat + `?chatId=${resp.chat.id}&productId=${resp.chat.product.id}`)
        })
      },
    }
  }
</script>

<style scoped>

</style>

<template>
  <div class="table-card__item-content"
       :class="{show: show}"
  >
    <div class="table-card__item-head">

      <div class="table-card__item-head-label">
        <div class="table-card__item-head-label-i">
          <template v-if="CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_DHL_FROM_YOUR_CITY.id === item.consolidation_destination.id">
            {{ $t('consolidations_fedex.localization_value.value') }} ({{$t('consolidations_yourCity.localization_value.value')}})
          </template>
          <template v-if="CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_FEDEX_FROM_YOUR_CITY.id === item.consolidation_destination.id">
            {{ $t('consolidations_fedex.localization_value.value') }} ({{$t('consolidations_yourCity.localization_value.value')}})
          </template>
          <template v-else-if="CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_OWN_CARRIER.id === item.consolidation_destination.id">
            {{ $t('consolidations_ownCarrier.localization_value.value') }}
          </template>
          <template v-else-if="CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_FEDEX.delivery_service_id === item.consolidation_destination.delivery_service_id">
            {{ $t('consolidations_USA.localization_value.value') }}
          </template>
          <template v-else-if="CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_DHL.id === item.consolidation_destination.id">
            {{ $t('consolidations_DHLUSA.localization_value.value') }}
          </template>
          <template v-else-if="CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_DPD.delivery_service_id === item.consolidation_destination.delivery_service_id">
            {{ $t('consolidations_Europe.localization_value.value') }}
          </template>
          <template v-else-if="CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_EU_PRODUCT.delivery_service_id === item.consolidation_destination.delivery_service_id">
            {{ $t('consolidations_EuropeProducts.localization_value.value') }}
            <template v-if="item.warehouse_storage && item.warehouse_storage.name">
              ({{item.warehouse_storage.name}})
            </template>
          </template>
          <template v-else>
            —
          </template>
        </div>
      </div>

      <div class="table-card__item-head-row d-flex">

        <DefaultCheckbox
            class="mt-1"
            :selectedNow="selectedNow"
            :dataValue="item.id"
        />
        <router-link
            :to="$store.getters.GET_PATHS.ordersConsolidationDetail + '/' +
												getConsolidationType(item) + '/' + item.id"
            class="table-link btn-style">
          #{{ item.id }}
        </router-link>
      </div>
      <div class="table-card__item-head-row">
        <div class="table-card__item-head-txt">
          {{ item.created_at | moment("DD MMM, YYYY") }}
        </div>
<!--        <div class="table-card__item-head-txt">-->
<!--          {{ item.product_entities_count }} units-->
<!--        </div>-->
      </div>
    </div>
    <transition name="slide">
      <div class="table-card__item-body"
           v-if="show"
      >
        <div class="table-card__item-row custom-row">
          <div class="table-card__item-col custom-col" v-if="isAdmin">
            <div class="table-card__item-label">
              {{$t('common_user.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <TableUserColumn
                  :item="item"
                  :mobile="true"
              />
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('consolidations_shippingMethod.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <template
                  v-if="CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_FEDEX.delivery_service_id === item.consolidation_destination.delivery_service_id"
              >
                {{$t('consolidations_fedex.localization_value.value')}}
              </template>
              <template
                  v-if="CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_DHL.delivery_service_id === item.consolidation_destination.delivery_service_id"
              >
                {{$t('consolidations_dhl.localization_value.value')}}
              </template>
              <template
                  v-if="CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_TNT.delivery_service_id === item.consolidation_destination.delivery_service_id"
              >
                {{$t('consolidations_tnt.localization_value.value')}}
              </template>
              <template v-if="item.consolidation_destination.api === 1">
                ({{$t('consolidations_yourCity.localization_value.value')}})
              </template>
              <template
                  v-if="item.consolidation_destination.api === 0 &&
                  CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_DHL.delivery_service_id === item.consolidation_destination.delivery_service_id
              ">
                {{$t('consolidations_express.localization_value.value')}}
              </template>
              <template v-if="item.consolidation_destination.api === 0 && item.warehouseData !== null">
                ({{item.warehouseData.currentTranslate.name}})
              </template>
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('consolidations_shipped.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              {{ item.shipped_at | moment("DD MMM, YYYY") }}
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('consolidations_received.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <template v-if="item.received_at">
                {{ item.received_at | moment("DD MMM, YYYY") }}
              </template>
              <template v-else>
                —
              </template>
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('consolidations_Quantity.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              {{ item.product_entities_count }}
            </div>
          </div>
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{$t('consolidations_trackingNumber.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <span class="admin-edit"
                        @click="editTranslate(['importO_trackingNumber', 'importO_addNumber'])"></span>
                <template v-if="item.user_tracking_number !== null && item.user_tracking_number !== ''">
                  <!--<span>{{item.tracking_number}}</span>-->
                  <div class="d-flex align-items-center">
                    <v-popover
                        class="site-tooltip"
                        offset="5"
                        placement="top"
                        trigger="hover"
                    >
                      <div class="table-link btn-style ellipsis">
                        {{item.user_tracking_number}}
                      </div>
                      <template slot="popover">
                        <p>{{ item.user_tracking_number }}</p>
                      </template>
                    </v-popover>
                    <div class="table-ico">
                      <!--v-if="item.tracking_sent === 0"-->
                      <v-popover
                          class="site-tooltip"
                          offset="5"
                          placement="top-left"
                          @show="hideEditField = true"
                          @hide="hideEditField = false"
                      >
                        <span class="site-link">
                            <LinkButton
                                :type="'edit'"
                            />
                        </span>
                        <template slot="popover">
                          <div class="section-label mb-2">{{ $t('importO_trackingNumber.localization_value.value') }}
                          </div>
                          <InputEditField
                              :value="item.user_tracking_number"
                              :propId="item.id"
                              :hideEditField="hideEditField"
                              :disabledBtn="$store.getters.getConsolidationBtn"
                              @onChange="(inputItem) => {$emit('changeTrackingNumberConsolidation', inputItem)}"
                          />
                          <a v-close-popover ref="closePopover" class="tooltip__close"></a>
                        </template>
                      </v-popover>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div class="">
                    <v-popover
                        class="site-tooltip"
                        offset="5"
                        placement="top-left"
                        @show="hideEditField = true"
                        @hide="hideEditField = false"
                    >
                      <span class="table-link btn-style">{{ $t('importO_addNumber.localization_value.value') }}</span>

                      <template slot="popover">
                        <div class="section-label mb-2">{{ $t('importO_trackingNumber.localization_value.value') }}
                        </div>
                        <InputEditField
                            :value="''"
                            :propId="item.id"
                            :hideEditField="hideEditField"
                            :disabledBtn="$store.getters.getConsolidationBtn"
                            @onChange="(inputItem) => {$emit('changeTrackingNumberConsolidation', inputItem)}"
                        />

                        <a v-close-popover ref="closePopover" class="tooltip__close"></a>
                      </template>
                    </v-popover>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>

        <div class="table-card__item-btn" style="gap: 10px;">
<!--          <ManagerButton @reloadManagerBtn="$store.commit('setGlobalUpdateManagerButton', Math.random()) "-->
<!--                         :key="$store.getters.getGlobalUpdateManagerButton">-->

            <template v-if="isFromYourCityType(item) &&
                  item.file_entities && item.file_entities.length > 0 && getFileUUID(item.file_entities, 'pdf', 'userDocumentPackageFileType') &&
                  (!item.warehouse || isAdmin)" >
              <LinkButton
                  :value="$t(`${item.consolidation_destination_id === CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_FEDEX_FROM_YOUR_CITY.id ? 'consolidation_fedexDocs' : 'common_DocsDHL'}.localization_value.value`)"
                  :link="$store.getters.GET_PATHS.getPdfFile + '/' + getFileUUID(item.file_entities, 'pdf', 'userDocumentPackageFileType')"
                  :type="'docs'"
                  :target="'_blank'"
              />
            </template>

            <template >
              <LinkButton
                  v-if="isAdmin || (!isAdmin && item.prepay === 0 && (item.in_draft || item.status === CONSOLIDATION_STATUSES.CONSOLIDATION_STATUS_IN_PROGRESS.value))"
                  :value="item.in_draft ? $t('common_EditDraft.localization_value.value') : $t('common_edit.localization_value.value')"
                  :type="'edit'"
                  :link="$store.getters.GET_PATHS.ordersConsolidationEditing + '/' + getConsolidationType(item) + '/' + item.id"
              />
            </template>

            <template >
              <LinkButton
                  v-if="isAdmin || (!isAdmin && item.prepay === 0 && (item.in_draft || item.status === CONSOLIDATION_STATUSES.CONSOLIDATION_STATUS_IN_PROGRESS.value))"
                  :value="$t('common_delete.localization_value.value')"
                  :type="'delete'"
                  @click.native="$emit('removeItem')"
              />
            </template>

            <template v-if="!(item.in_draft || item.consolidation_destination.api) && (item.prepay === 1 || isAdmin || !isUserPrepayment)" >
              <LinkButton
                  :value="$t('common_Proforma.localization_value.value')"
                  :type="'proforma'"
                  @click.native="$emit('getConsolidationProform', item.id)"
              />
            </template>
            <template v-if="isFromYourCityType(item) &&
                  item.file_entities && item.file_entities.length > 0 && getFileUUID(item.file_entities, 'docx', 'proformFileType')" >
              <LinkButton
                  :value="$t('common_Proforma.localization_value.value')"
                  :type="'proforma'"
                  @click.native="$emit('getConsolidationApiProform', getFileUUID(item.file_entities, 'docx', 'proformFileType'))"
              />
            </template>
            <template v-if="!item.in_draft && (item.prepay === 1 || isAdmin || !isUserPrepayment)">
              <LinkButton
                  :value="$t('common_Marking.localization_value.value')"
                  :type="'mark'"
                  @click.native="$emit('getConsolidationMarking', {id: item.id})"
              />
            </template>
<!--            <template v-if="!item.in_draft" >-->
<!--              <LinkButton-->
<!--                  :value="'Docs DHL'"-->
<!--                  :type="'docs'"-->
<!--              />-->
<!--            </template>-->
<!--            <template v-if="!item.in_draft" >-->
<!--              <LinkButton-->
<!--                  :value="'№ Reference'"-->
<!--                  :type="'reference'"-->
<!--              />-->
<!--            </template>-->
<!--            <template v-if="!item.in_draft" >-->
<!--              <LinkButton-->
<!--                  :value="'BookingGref'"-->
<!--                  :type="'mark'"-->
<!--              />-->
<!--            </template>-->
            <template v-if="!item.in_draft && (item.prepay === 1 || isAdmin || !isUserPrepayment)" slot="item">
              <LinkButton
                  v-if="!item.in_draft"
                  :value="$t('common_termalPrint.localization_value.value')"
                  :type="'print'"
                  @click.native="$emit('thermalPrint', {id: item.id})"
              />
            </template>

            <template v-if="isFromYourCityType(item) &&
                    !isAdmin && (!item.file_entities || !getFileUUID(item.file_entities, 'pdf', 'labelFileType'))" >
              <LinkButton
                  :value="$t('common_CreateLabel.localization_value.value')"
                  :type="'dog'"
                  @click.native="$emit('showBuyLabelPopup' )"
              />
            </template>
<!--            <template v-if="item.consolidation_destination_id === CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_FEDEX_FROM_YOUR_CITY.id-->
<!--                  && isAdmin && (!item.file_entities || !getFileUUID(item.file_entities, 'pdf', 'labelFileType'))" >-->
<!--              <LinkButton-->
<!--                  :value="$t('common_ConfirmLabel.localization_value.value')"-->
<!--                  :type="'dog'"-->
<!--                  @click.native="$emit('showBuyLabelPopup', true)"-->
<!--              />-->
<!--            </template>-->

            <template v-if="item.prepay === 0 && !isAdmin && isUserPrepayment && !isUserHasEasyOrder &&
                  item.consolidation_destination_id !== CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_FEDEX_FROM_YOUR_CITY.id &&
                  item.consolidation_destination_id !== CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_DHL_FROM_YOUR_CITY.id &&
                  item.consolidation_destination_id !== CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_OWN_CARRIER.id" >
              <LinkButton
                  :value="$t('consolidations_pay.localization_value.value')"
                  :type="'dollar'"
                  @click.native="$emit('payConsolidation')"
              />
            </template>

            <template v-if="((item.prepay === 1 && isUkrainian) || isAdmin || !isUserPrepayment) && !item.order_internal
                  && (item.status === CONSOLIDATION_STATUSES.CONSOLIDATION_STATUS_IN_PROGRESS.value || item.status === CONSOLIDATION_STATUSES.CONSOLIDATION_STATUS_DEPARTED.value)
                  && item.consolidation_destination_id !== CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_FEDEX_FROM_YOUR_CITY.id
                  && item.consolidation_destination_id !== CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_DHL_FROM_YOUR_CITY.id
                  && item.consolidation_destination_id !== CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_OWN_CARRIER.id" >
              <LinkButton
                  :value="$t('consolidations_novaPoshta.localization_value.value')"
                  :link="$store.getters.GET_PATHS.novaPoshtaCreation + '/' + ORDER_TYPE_CONSOLIDATION + '/' + item.id"
                  :type="'pickup'"
              />
            </template>
            <template v-if="((item.prepay === 1 && isUkrainian) || isAdmin || !isUserPrepayment)
                    && isInternalNovaPoshta(item) && !isInternalLabelCreated(item.order_internal)" >
              <LinkButton
                  :value="$t('consolidations_novaPoshtaEdit.localization_value.value')"
                  :link="$store.getters.GET_PATHS.novaPoshta + '/' + item.order_internal.id"
                  :type="'pickup'"
              />
            </template>
            <template v-if="((item.prepay === 1 && isUkrainian) || isAdmin || !isUserPrepayment)
                    && isInternalNovaPoshta(item) && isInternalDraft(item.order_internal)" >
              <LinkButton
                  :value="$t('poshta_CreateNovaPoshtaLabel.localization_value.value')"
                  :type="'dog'"
                  @click.native="$emit('createLabelNovaPoshta')"
              />
            </template>
            <template v-if="isInternalNovaPoshta(item) && isInternalLabelCreated(item.order_internal)" >
              <LinkButton
                  :value="$t('consolidations_novaPoshtaShow.localization_value.value')"
                  :link="$store.getters.GET_PATHS.novaPoshta + '/show/' + item.order_internal.id"
                  :type="'pickup'"
                  :target="'_blank'"
              />
            </template>
            <template v-if="isInternalNovaPoshta(item) && isInternalLabelCreated(item.order_internal)" >
              <LinkButton
                  :value="$t('poshta_Label.localization_value.value')"
                  :link="$store.getters.GET_PATHS.getPdfFile + '/' + getNovaPoshtaLabelUUID(item.order_internal.file_entries)"
                  :type="'mark'"
                  :target="'_blank'"
              />
            </template>

            <template v-if="!item.in_draft && isAdmin && (item.consolidation_destination_id === CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_FEDEX.id || item.consolidation_destination_id === CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_DHL.id)" >
              <LinkButton
                  :value="$t('common_downloadInnerLabels.localization_value.value')"
                  :type="'mark'"
                  @click.native="$emit('downloadInnerLabels')"
              />
            </template>

            <template v-if="isInternalNovaPoshta(item) && isInternalLabelCreated(item.order_internal)" >
              <LinkButton
                  :value="$t('poshta_Marking.localization_value.value')"
                  :link="$store.getters.GET_PATHS.getPdfFile + '/' + getNovaPoshtaMarkingUUID(item.order_internal.file_entries)"
                  :type="'mark'"
                  :target="'_blank'"
              />
            </template>

            <!--если из своего города, есть лэйба, не от имени склада или же админ-->
            <template v-if="!item.in_draft && item.consolidation_destination_id === CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_FEDEX_FROM_YOUR_CITY.id &&
                  item.file_entities && item.file_entities.length > 0 && getFileUUID(item.file_entities, 'pdf', 'labelFileType')
                  && (!item.warehouse || isAdmin) && ((!item.pickup_data && !item.cancel_pickup_data) || !item.pickup_data)" >
              <LinkButton
                  :value="$t('common_CallPickup.localization_value.value')"
                  :type="'pickup'"
                  @click.native="$emit('showCallingCourierPopup')"
              />
            </template>

            <template v-if="!item.in_draft && item.consolidation_destination_id === CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_FEDEX_FROM_YOUR_CITY.id &&
                  item.file_entities && item.file_entities.length > 0 && getFileUUID(item.file_entities, 'pdf', 'labelFileType')
                  && (!item.warehouse || isAdmin) && (!item.cancel_pickup_data && item.pickup_data)" >
              <LinkButton
                  :value="$t('common_CancelCourier.localization_value.value')"
                  :type="'pickup'"
                  @click.native="$('openCancelCourierPopup')"
              />
            </template>

            <template v-if="!item.in_draft && item.consolidation_destination_id === CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_FEDEX_FROM_YOUR_CITY.id &&
                  item.file_entities && item.file_entities.length > 0 && getFileUUID(item.file_entities, 'pdf', 'labelFileType')
                  && (!item.warehouse || isAdmin) && item.pickup_data" >
              <LinkButton
                  :value="$t('common_courierInfo.localization_value.value')"
                  :type="'pickup'"
                  @click.native="$('showCourierDetailPopup')"
              />
            </template>

<!--            <template v-if="!isFromYourCityType(item) && item.status === 'completed' && !isUserHasEasyOrder">-->
<!--              <LinkButton-->
<!--                  :value="$t('common_stop.localization_value.value')"-->
<!--                  :type="'pickup'"-->
<!--                  @click.native="$emit('stopOrderConsolidation')"-->
<!--              />-->
<!--            </template>-->
<!--          </ManagerButton>-->
        </div>
      </div>
    </transition>
    <div class="table-card__item-footer">
      <div class="table-card__item-show"
           @click="show = !show"
      >
        {{$t('common_showMore.localization_value.value')}}
      </div>
    </div>
  </div>
</template>

<script>
  import LinkButton from "../../../../../../../UI/buttons/LinkButton/LinkButton";
  import DefaultCheckbox from "../../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import {CONSOLIDATION_TYPES} from "../../../../../../../../staticData/staticVariables";
  import InputEditField from "../../../../../../../UI/inputs/InputEditField/InputEditField";
  // import ManagerButton from "../../../../../../../UI/buttons/ManagerButton/ManagerButton";
  import {consolidationMixin} from "../../../../../../../../mixins/consolidationMixins/consolidationMixin";
  import {novaPoshtaOrdersMixins, novaPoshtaOrdersTableMixins} from "../../../../../../../../mixins/internalOrdersMixins/novaPoshtaOrdersMixin";
  import {ORDERS_CONSOLIDATION_TYPE} from "../../../../../../../../staticData/staticVariables";
  import {ORDER_TYPE_CONSOLIDATION} from "../../../../../../../../staticData/staticVariables";
  import {CONSOLIDATION_STATUSES} from "../../../../../../../../staticData/staticVariables";
  import TableUserColumn from "../../../../../../../coreComponents/TableComponents/TableUserColumn/TableUserColumn";
  import {consolidationMixinHelper} from "@/mixins/consolidationMixins/consolidationMixinHelper";

  export default {
    name: "ConsolidationTableMobile",

    components: {
      DefaultCheckbox,
      TableUserColumn,
      // ManagerButton,
      InputEditField,
      LinkButton,
    },

    mixins: [
      consolidationMixin,
      novaPoshtaOrdersMixins,
      novaPoshtaOrdersTableMixins,
      consolidationMixinHelper,
    ],

    props: {
      item: Object,
      navTabs: Object,
      selectedNow: Boolean,
    },

    data() {
      return {
        show: false,
        hideEditField: false,

        CONSOLIDATION_TYPES: CONSOLIDATION_TYPES,
        ORDERS_CONSOLIDATION_TYPE: ORDERS_CONSOLIDATION_TYPE,
        CONSOLIDATION_STATUSES: CONSOLIDATION_STATUSES,
        ORDER_TYPE_CONSOLIDATION: ORDER_TYPE_CONSOLIDATION,
      }
    },

  }
</script>

<style scoped>
.table-card__item-body .ellipsis {
  max-width: 170px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
}

.table-card__item-head-label-i {
  font-size: 14px;
  width: 135px;
  text-align: right;
}
</style>

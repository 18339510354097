import {checkValid} from "../../../globalModels/functions/ModelsValidate";


export function ShippingMethodsForOrder() {

  this.checkValid = checkValid

  this.data = {
    id: '',
    name: '',
    shippingCompany: '',
  }

  this.validation = {
    shippingCompany: false,
    name: false,
  }

  this.validationTranslate = {
    shippingCompany: '',
    name: '',
  }

  this.validationTxt = {
    shippingCompany: false,
    name: false,
  }

  /**
   * Getters
   */
  this.getId = () => {
    return this.data.id
  }
  this.getName = () => {
    return this.data.name
  }
  this.getShippingCompany = () => {
    return this.data.shippingCompany
  }

  /**
   * Setters
   */
  this.setId = (val) => {
    this.data.id = val
  }
  this.setName = (val) => {
    this.data.name = val
  }
  this.setShippingCompany = (val) => {
    this.data.shippingCompany = val
  }

}


/**
 * Global Setters
 */

ShippingMethodsForOrder.prototype.setItem = function (val) {

  this.setShippingCompany(val.shipping_company)
  this.setName(val.name)
  this.setId(val.id)
}


/**
 * Functions
 */


/**
 * Validations
 */

ShippingMethodsForOrder.prototype.firstValidation = function () {

  let data = this.data

  let validationItems = {
    name: data.name,
    shippingCompany: data.shippingCompany?.id,
  }

  let validationOptions = {
    name: {type: ['empty']},
    shippingCompany: {type: ['empty']},
  }


  return this.checkValid(validationItems, validationOptions)
}


/**
 * Prepare Data
 */

ShippingMethodsForOrder.prototype.prepareSave = function () {

  let data = {
    "name": this.getName(),
    "shipping_company_id": this.getShippingCompany()?.id,
  }

  return data
}

<template>
    <div class="table-card__item-content"
         :class="{show: show}"
    >
      <div class="table-card__item-head">
        <div class="table-card__item-head-row d-flex">
          <DefaultCheckbox
              v-if="item.status !== 'departed'"
              class="empty-label"
              :selectedNow="selectedNow"
              :dataValue="item.id"
          />
          <StatusIcoBtn v-else :type="'delivered'" class="mr-2" />
          <div class="table-card__item-number table-card__item-number--small ellipsis"
               @click="$emit('changeInfoPopup', item)"
          >
            {{item.delivery_service.name}}: {{item.tracking_number}}
          </div>
        </div>
      </div>
      <transition name="slide">
        <div class="table-card__item-body"
             v-if="show"
        >
          <div class="table-card__item-row custom-row">
            <div class="table-card__item-col custom-col">
              <div class="table-card__item-label">
                {{$t('common_user.localization_value.value')}}
              </div>
              <div class="table-card__item-info">
                <LinkButton
                    v-if="item.admin"
                    :value="item.admin.full_name"
                    :link="$store.getters.GET_PATHS.mainSettingsUsers + `?userId=${item.admin.id}`"
                />
              </div>
            </div>
          </div>

          <div class="table-card__item-row custom-row">
            <div class="table-card__item-col custom-col custom-col--50">
              <div class="table-card__item-label">
                {{$t('apcDelcon_shippingCompany.localization_value.value')}}
              </div>
              <div class="table-card__item-info">
                {{item.delivery_service.name}}
              </div>
            </div>
            <div class="table-card__item-col custom-col custom-col--50">
              <div class="table-card__item-label">
                {{$t('apcDelcon_trackingNumber.localization_value.value')}}
              </div>
              <div class="table-card__item-info">
                <v-popover
                    class="site-tooltip"
                    offset="5"
                    placement="top"
                    trigger="hover"
                >
                  <div class="ellipsis">{{ item.tracking_number }}</div>
                  <template slot="popover">
                    <p>{{ item.tracking_number }}</p>
                  </template>
                </v-popover>
              </div>
            </div>

            <div class="table-card__item-col custom-col custom-col--50">
              <div class="table-card__item-label">
                {{$t('apcDelcon_comment.localization_value.value')}}
              </div>
              <div class="table-card__item-info">
                <template>
                  <!--<span>{{item.tracking_number}}</span>-->
                  <v-popover
                      class="site-tooltip"
                      offset="5"
                      placement="top"
                      trigger="hover"
                  >
                    <div class="ellipsis">{{ item.comment }}</div>
                    <template slot="popover">
                      <p>{{ item.comment }}</p>
                    </template>
                  </v-popover>
                </template>
              </div>
            </div>

            <div v-if="navTabs.sent.active" class="table-card__item-col custom-col custom-col--50">
              <div class="table-card__item-label">
                {{$t('apcDelcon_sendDate.localization_value.value')}}
              </div>
              <div class="table-card__item-info">
                {{ item.send_at | moment("DD MMM, YYYY") }}
              </div>
            </div>

          </div>

          <div class="table-card__item-btn pl-2">
<!--            <div class="table-card__item-btn-i">-->
<!--              <LinkButton-->
<!--                  :value="'Pro-Forma Invoice'"-->
<!--                  :type="'print'"-->
<!--              />-->
<!--            </div>-->
            <div class="table-card__item-btn-i">
              <LinkButton
                  :value="$t('common_edit.localization_value.value')"
                  :type="'edit'"
                  :link="$store.getters.GET_PATHS.reportsConsolidationEdit + '/' + item.id"
              />
            </div>
            <div class="table-card__item-btn-i">
              <LinkButton
                  :value="$t('common_delete.localization_value.value')"
                  :type="'delete'"
                  @click.native="$emit('removeItem')"
              />
            </div>
          </div>
        </div>
      </transition>
      <div class="table-card__item-footer">
        <div class="table-card__item-show"
             @click="show = !show"
        >
          {{$t('common_showMore.localization_value.value')}}
        </div>
      </div>
    </div>
</template>

<script>
  import LinkButton from "../../../../../../../UI/buttons/LinkButton/LinkButton";
  // import DefaultCheckbox from "../../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import DefaultCheckbox from "../../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import StatusIcoBtn from "../../../../../../../UI/status/StatusIcoBtn/StatusIcoBtn";

  export default {
    name: "ConsolidationUnionTableMobile",

    components: {
      StatusIcoBtn,
      DefaultCheckbox,
      LinkButton,
    },

    props: {
      item: Object,
      navTabs: Object,
      selectedNow: Boolean,
    },

    data() {
      return {
        show: false,
      }
    },

    methods: {

    },

  }
</script>

<style scoped>

  .table-card .ellipsis {
    max-width: 120px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    display: inline-block;
  }

  .table-card__item-head .ellipsis {
    max-width: initial;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    display: inline-block;
  }

  .table-card__item-head-row {
    flex-wrap: nowrap;
  }
</style>

<template>
  <HeartysanChatSection/>
</template>

<script>
  import HeartysanChatSection from "./HeartysanChatSection/HeartysanChatSection";

  export default {
    name: "HeartysanChatPage",

    components:{
      HeartysanChatSection,
    },

  }
</script>

<style scoped>

</style>

<template>
  <div class="action-table-btn btn-style"
       :class="{
         'boxes-type': type === 'boxes',
         'pdf': type === 'pdf',
         'print': type === 'print',
         'dog': type === 'dog',
         'dollar': type === 'dollar',
         'label2': type === 'label2',
       }"
  >
    <div class="action-table-btn__txt"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <span v-if="withTranslate" class="admin-edit" @click="editTranslate([title])"></span>
      {{$t(`${title}.localization_value.value`)}}
    </div>
  </div>
</template>

<script>
  export default {
    name: "TopTableBoxesBtn",

    props: {
      title: String,
      type: String,
      withTranslate: {
        type: Boolean,
        default: true,
      },
    }
  }
</script>

<style lang="scss">
  @import "../../../../scss/colors";
  @import "../../../../scss/mixins/mixins";

  .action-table-btn{
    &__txt{
      position: relative;
      padding-left: 22px;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: $accent;

      @include for-1600 {
        font-size: 16px;
      }

      @include for-1120 {
        font-size: 14px;
      }

    }

    &.boxes-type .action-table-btn__txt:before{
      content: "";
      position: absolute;
      left: 0;
      top: 2px;
      display: block;
      width: 14px;
      height: 14px;
      background: url("../../../../assets/img/common/consolidation-icon.svg") center center no-repeat;
    }

    &.pdf .action-table-btn__txt:before{
      content: "";
      position: absolute;
      left: 0;
      top: 2px;
      display: block;
      width: 14px;
      height: 14px;
      background: url("../../../../assets/img/common/pdf-icon.svg") center center no-repeat;
    }

    &.print .action-table-btn__txt:before{
      content: "";
      position: absolute;
      top: 1px;
      left: 0;
      display: block;
      width: 16px;
      height: 16px;
      background: url("../../../../assets/img/common/print-accent-icon.svg") center/contain no-repeat;
    }

    &.dog .action-table-btn__txt:before{
      content: "";
      position: absolute;
      top: 1px;
      left: 0;
      display: block;
      width: 16px;
      height: 16px;
      background: url("../../../../assets/img/common/dog-icon.svg") center/contain no-repeat;
    }

    &.dollar .action-table-btn__txt:before{
      content: "";
      position: absolute;
      top: 1px;
      left: 0;
      display: block;
      width: 18px;
      height: 18px;
      background: url("../../../../assets/img/common/dollar2-icon.svg") center/contain no-repeat;
    }

    &.label2 .action-table-btn__txt:before{
      content: "";
      position: absolute;
      top: 1px;
      left: 0;
      display: block;
      width: 18px;
      height: 18px;
      background: url("../../../../assets/img/common/label-orange.svg") center/contain no-repeat;
    }
  }
</style>

<template>
  <APCDelconTableAdmin
      :navTabs="navTabs"
      :countFilterParams="countFilterParams"
      :filterGetParams="filterGetParams"
      @changeTab="changeTab"
      @resetFilter="resetFilter"
      @changeFilter="changeFilter"
      @showMore="showMore"
      @reload="reload"
      @removeItem="removeItem"
      @setTrackForSelectedUnions="setTrackForSelectedUnions"
  />
</template>

<script>
  import APCDelconTableAdmin from "./APCDelconTableAdmin/APCDelconTableAdmin";
  import {routeFilter} from "../../../../../mixins/routeFilterMixins/routeFilter";

  export default {
    name: "APCDelconTable",

    components: {
      APCDelconTableAdmin,
    },

    mixins: [routeFilter],

    computed: {
      router: function() {
        return this.$route.query
      }
    },

    watch: {
      router: function(newRoute) {
        if(Object.keys(newRoute).length === 0){
          this.changeTab('all')
        }
      },

      loadUserAuthorizedData: function () {
        // set tab active and visible by permissions
        if(this.downloadPermissions){
          this.checkTabPermission()
        }
      },

      downloadPermissions() {
        this.checkTabPermission()
      }
    },

    data() {
      return {
        forPage: this.$store.getters.getAPCDelconForPage,
        page: 1,
        onePage: 1,
        filterType: '',

        filterConsolidationId: '',
        filterAPCDelconId: '',

        filterShippingCompanyId: '',
        filterWarehouseId: '',

        filterTrackingNumber: '',

        filterUserId: '',
        filterUserName: '',

        filteAdminId: '',
        filterAdminName: '',

        countFilterParams: 0,
        filterGetParams: {},

        navTabs: {
          all: {
            active: true,
            label: this.$t('apcDelcon_all.localization_value.value'),
            name: 'all',
            id: false,
            permissionVisible: true,
          },
          sent: {
            active: false,
            label: this.$t('apcDelcon_sent.localization_value.value'),
            name: 'sent',
            id: false,
            permissionVisible: true,
          },
        },
      }
    },

    mounted() {
      if(this.$route.query.type !== undefined){
        this.navTabs.all.active = false
        this.navTabs[this.$route.query.type].active = true
      }

      if(this.loadUserAuthorizedData){
        // set tab active and visible by permissions
        if(this.downloadPermissions){
          this.checkTabPermission()
        }
      }
    },

    methods: {
      checkTabPermission() {

        // this.activeTabByPermissions('navTabs', {
        //   [DHL_DELIVERY_SERVICE.name]: this.PERMISSIONS.ORDER_EXPRESS_DHL,
        //   [FEDEX_DELIVERY_SERVICE.name]: this.PERMISSIONS.ORDER_EXPRESS_FEDEX,
        //   [TNT_DELIVERY_SERVICE.name]: this.PERMISSIONS.ORDER_EXPRESS_TNT,
        //   [DPD_DELIVERY_SERVICE.name]: this.PERMISSIONS.ORDER_EXPRESS_FEDEX,
        // })
        //
        // this.hideTabAllIfVisibleOnlyOne('navTabs', 'all', ['permissionVisible'])
        //
        // if(!this._.find(this.navTabs, {active: true}).permissionVisible){
        //   this.setActivePermissionVisibleTab('navTabs')
        //   return
        // }

        this.filter()
      },

      getFilterParam() {
        this.filterGetParams = {
          page: this.$route.query.page,
          count: this.$route.query.count,
          type: this.$route.query.type,

          consolidationUnionId: this.$route.query.consolidationUnionId,
          consolidationId: this.$route.query.consolidationId,

          shippingCompanyId: this.$route.query.shippingCompanyId,
          warehouseId: this.$route.query.warehouseId,

          trackingNumber: this.$route.query.trackingNumber,

          userId: this.$route.query.userId,
          userName: this.$route.query.userName,

          adminId: this.$route.query.adminId,
          adminName: this.$route.query.adminName,
        }
      },

      filter(next = false) {
        this.getFilterParam()

        let url

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(next, this)

        // generate other filter if has get params in URL
        this.generateParamsFilterFromUrl(this)

        // Generate filter URL
        url = this.generateFilterUrl(
            generateMainFilter.page,
            generateMainFilter.forPage
        )

        this.$store.commit('setAPCDelconFilter', window.location.search)

        if (next) this.$store.commit('setNextAPCDelconPage', true)
        this.$store.dispatch('fetchAPCDelcon', url).then(() => {
          this.$store.commit('setNextAPCDelconPage', false)
        })


        this.checkCountFilter(['userName', 'type', 'adminName'])
      },

      generateFilterUrl(page, forPage) {
        let myQuery = this.createQueryBuilderObject({withUserNewMark: true})

        this.generateFilterQueryParams(
            myQuery,
            {
              filterConsolidationId: 'byConsolidationId',
              filterAPCDelconId: 'APCDelconId',

              filterShippingCompanyId: 'ConsolidationOrderUnionDeliveryServiceId',
              // filterWarehouseId: 'APCDelconWarehouseId',

              filterTrackingNumber: 'ConsolidationOrderUnionTrackingNumber',
              filterUserId: 'byConsolidationUserId',
              filterAdminId: 'APCDelconAdminId',
            },
        )

        if (this.filterType === 'sent') {
          myQuery.where('ConsolidationOrderUnionStatus', 'departed')
        }
         else {
           myQuery.where('withoutDeparted', 1)
         }


        return this.generateGetParamsFromQuery(myQuery, forPage, page)


      },

      removeItem(id){
        let text = {
          title: 'consolidations_deleteConsolidation',
          txt: '',
          yes: 'common_yes',
          no: 'common_no'
        }

        let confirm = () => {

          this.$store.dispatch('deleteAPCDelcon', id).then((response) => {
            switch (this.getResponseStatus(response)) {
                /**
                 * Success
                 */
              case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
                this.openNotify('success', 'common_notificationRecordDeleted')
                //filter to upgrade table after deleting an item
                this.filter()
                break
              }
                /**
                 * Validation Error
                 */
              case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
                let errors = response.response.data.errors;
                this.notifyErrorHelper(errors)
                break
              }
                /**
                 * Undefined Error
                 */
              default: {
                this.openNotify('error', 'common_notificationUndefinedError')
              }
            }
          })

          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)
      },

      setTrackForSelectedUnions(selectedOption, trackNumber) {
        let selectedIds = this.getCheckedRows('row-name')
        if (!selectedIds?.length || !selectedOption || !trackNumber) return

        let requestData = {
          'ids': selectedIds,
          'delivery_service_id': selectedOption.id,
          'tracking_number': trackNumber
        }

        let text = {
          title: 'common_AreYouSure',
          txt: '',
          yes: 'common_yes',
          no: 'common_cancel'
        }

        let confirm = () => {
          this.$store.dispatch('APCDelconDepartConsolidations', requestData).then(response => {
            switch (this.getResponseStatus(response)) {
                /**
                 * Success
                 */
              case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
                this.openNotify('success', 'common_notificationRecordChanged')
                //filter to upgrade table after changing items
                this.filter()
                break
              }
                /**
                 * Validation Error
                 */
              case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
                let errors = response.response.data.errors;
                this.notifyErrorHelper(errors)
                break
              }
                /**
                 * Undefined Error
                 */
              default: {
                this.openNotify('error', 'common_notificationUndefinedError')
              }
            }
          })

          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)
      },

    }
  }
</script>

<style scoped>

</style>
<template>
  <div class="order-create__row custom-row">
    <div class="custom-col custom-col--33  custom-col--md-50 custom-col--sm-100"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate([
      'consolidationUnion_trackingNumber',
      ])"></div>
      <DefaultInput
          :label="$t('consolidationUnion_trackingNumber.localization_value.value')"
          v-bind:class="{'ui-no-valid': CU.validation.trackingNumber}"
          :errorTxt="$t(`${CU.validationTranslate.trackingNumber}.localization_value.value`)"
          :error="CU.validation.trackingNumber"
          v-model="CU.data.trackingNumber"
      />
    </div>
    <div class="custom-col custom-col--33  custom-col--md-50 custom-col--sm-100"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate([
      'consolidationUnion_shippingCompany',
      ])"></div>
      <DefaultSelect
          :options="shippingCompanies"
          :optionsLabel="'name'"
          :label="$t('consolidationUnion_shippingCompany.localization_value.value')"
          v-bind:class="{'ui-no-valid': CU.validation.shippingCompany}"
          :errorTxt="$t(`${CU.validationTranslate.shippingCompany}.localization_value.value`)"
          :error="CU.validation.shippingCompany"
          @change="(val) => {CU.setShippingCompany(val)}"
          :selected="CU.data.shippingCompany"
      />
    </div>
    <div class="custom-col custom-col--33  custom-col--md-50 custom-col--sm-100"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate([
      'consolidationUnion_warehouse',
      ])"></div>
      <DefaultSelect
          :options="warehousesData"
          :optionsLabel="'name'"
          :label="$t('consolidationUnion_warehouse.localization_value.value')"
          v-bind:class="{'ui-no-valid': CU.validation.warehouse}"
          :errorTxt="$t(`${CU.validationTranslate.warehouse}.localization_value.value`)"
          :error="CU.validation.warehouse"
          :otherValue="'currentTranslation'"
          @change="(val) => {CU.setWarehouse(val)}"
          :selected="CU.data.warehouse"
      />
    </div>
    <div class="custom-col custom-col--33  custom-col--md-50 custom-col--sm-100"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate([
      'consolidationUnion_sendState',
      ])"></div>
      <DefaultSelect
          v-bind:class="{'ui-no-valid': CU.validation.carrier}"
          :options="carriers"
          :optionsLabel="'label'"
          :label="$t('consolidationUnion_sendState.localization_value.value')"
          :errorTxt="$t(`${CU.validationTranslate.carrier}.localization_value.value`)"
          :error="CU.validation.carrier"
          :selected="CU.data.carrier"
          :clearable="false"
          @change="(val) => {CU.setCarrier(val)}"
      />
    </div>
  </div>
</template>

<script>
import DefaultInput from "../../../../UI/inputs/DefaultInput/DefaultInput";
import DefaultSelect from "../../../../UI/selectiones/DefaultSelect/DefaultSelect";

export default {
  name: "FieldsBlock",

  components: {
    DefaultSelect,
    DefaultInput
  },

  props: {
    CU: Object,
    warehousesData: Array,
    carriers: Array,
    shippingCompanies: Array,
  }
}
</script>

<style scoped>

</style>
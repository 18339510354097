<template>
  <modal
          @close="$emit('close')"
          class="custom-popup-modal custom-popup shipping-company-popup"
  >
    <template slot="header">
      <template v-if="openType === 'edit'">
        <div
                v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['shippingCompany_NewShippingCompanyForOrder'])"></div>
          {{$t('shippingCompany_NewShippingCompanyForOrder.localization_value.value')}}
        </div>
      </template>
      <template v-if="openType === 'create'">
        <div
                v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['shippingCompany_NewShippingCompanyForOrder'])"></div>
          {{$t('shippingCompany_NewShippingCompanyForOrder.localization_value.value')}}
        </div>
      </template>
    </template>
    <template slot="body">
      <div class="custom-popup__content">
        <div class="shipping-company-popup__body">
          <div class="shipping-company-popup__content">
            <div class="custom-row">
              <div class="custom-col custom-col--50 mb-3"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['shippingCompany_ShippingCompanyTitle'])"></div>
                <DefaultInput
                        :label="$t('shippingCompany_ShippingCompanyTitle.localization_value.value')"
                        v-bind:class="{'ui-no-valid': SMFO.validation.name}"
                        :error="SMFO.validation.name"
                        :errorTxt="$t(`${SMFO.validationTranslate.name}.localization_value.value`)"
                        v-model="SMFO.data.name"
                />
              </div>
              <div class="custom-col custom-col--50 mb-3"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['shippingCompany_shippingCompanyForOrder'])"></div>
                <DefaultSelect
                        :label="$t('shippingCompany_shippingCompanyForOrder.localization_value.value')"
                        :options="options"
                        :optionsLabel="'name'"
                        v-bind:class="{'ui-no-valid': SMFO.validation.shippingCompany}"
                        :error="SMFO.validation.shippingCompany"
                        :errorTxt="$t(`${SMFO.validationTranslate.shippingCompany}.localization_value.value`)"
                        :selected="SMFO.data.shippingCompany"
                        @change="val => SMFO.data.shippingCompany = val"
                />
              </div>
            </div>
          </div>
        </div>

      </div>
    </template>
    <template slot="footer">
      <div class="custom-popup__btn align-items-center d-flex flex-wrap"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_cancel', 'common_create'])"></div>
        <MainButton
                class="custom-popup__btn-i secondary"
                :value="$t('common_cancel.localization_value.value')"
                @click.native="$emit('close')"
        />
        <MainButton
                v-if="openType === 'create'"
                class="custom-popup__btn-i "
                :value="$t('common_create.localization_value.value')"
                @click.native="save(false)"
        />
        <MainButton
                v-else
                class="custom-popup__btn-i "
                :value="$t('common_edit.localization_value.value')"
                @click.native="save(true)"
        />
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from "../../../../commonModals/Modal";
  import MainButton from "../../../../UI/buttons/MainButton/MainButton";
  import DefaultInput from "../../../../UI/inputs/DefaultInput/DefaultInput";
  import DefaultSelect from "@/components/UI/selectiones/DefaultSelect/DefaultSelect";
  import {ShippingMethodsForOrder} from "@/components/modules/ShippingCompanyModule/models/ShippingMethodsForOrder";


  export default {
    name: "ShippingMethodsForOrderPopup",
    components: {
      DefaultSelect,
      Modal,
      MainButton,
      DefaultInput,
    },

    props: {
      openType: String,
      deliverySystemId: Number,
    },

    data() {
      return {
        SMFO: new ShippingMethodsForOrder(),
        options: [],
      }
    },

    mounted() {

      this.$store.dispatch('getShippingMethodsCreate').then(response => {
        this.options = this.getRespData(response)?.shipping_companies
      })

      if (this.openType === 'edit') {
        this.$store.dispatch('getShippingMethodsForOrder', this.deliverySystemId).then(response => {
          this.SMFO.setItem(this.getRespData(response))
        }).catch(error => this.createErrorLog(error))
      }

    },

    methods: {
      changeFile(data) {
        this.SMFO.data.Files.setFiles([data])
      },

      save(edit = false) {
        if (!this.SMFO.firstValidation()) return

        let data = this.SMFO.prepareSave()
        let saveType = 'createShippingMethodsForOrder'

        if (edit) {
          data = {
            id: this.SMFO.getId(),
            data: data
          }
          saveType = 'updateShippingMethodsForOrder'
        }

        this.$store.dispatch(saveType, data).then(response => {
          if (!this._.has(response, 'data')) return this.openNotify('error', 'common_notificationUndefinedError')

          switch (this.getResponseStatus(response)) {
            /**
             * Success
             */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {

              if (edit) {
                this.openNotify('success', 'common_notificationRecordChanged')
              } else {
                this.openNotify('success', 'common_notificationRecordCreated')
              }
              this.$emit('reload')
              this.$emit('close')
              break
            }
            /**
             * Validation Error
             */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
            /**
             * Undefined Error
             */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }
        })


      },

    }
  }
</script>

<style scoped lang="scss">

  @import "../../../../../scss/colors";
  @import "../../../../../scss/mixins/mixins";

  .shipping-company-popup {

    &__body {
      display: flex;

      @include for-680 {
        flex-direction: column;
      }
    }

    &__content {
      width: 100%;

      @include from-680 {
        padding-right: 20px;
      }
    }

    &__file {
      max-width: 105px;
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-bottom: 15px;

      @include for-680 {
        justify-content: center;
        align-items: center;
        max-width: 100%;
        margin-bottom: 35px;
      }

      input[type="file"] {
        display: none;
      }
    }

    &__file-label {
      display: flex;
      flex-direction: column;
      cursor: pointer;
    }

    &__file-img {
      display: flex;
      flex-direction: column;

      img {
        width: 105px;
        height: 105px;
        object-fit: contain;
      }
    }

    &__file-text {
      margin-top: 10px;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: $brown;
    }

  }

  .custom-popup__btn {

  }

  .custom-popup__btn-i {
    margin-right: 10px;
    min-width: 122px;
    width: fit-content;
    margin-bottom: 10px;

    &.secondary {
      border-width: 2px;
    }
  }

  /deep/ .modal-component__inner{
    max-width: 700px;
  }

</style>

<template>
  <modal
      @close="$emit('close')"
      class="custom-popup-modal custom-popup"
  >
    <template slot="header">
      <div
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['chat_createChat'])"></span>
        {{$t('chat_createChat.localization_value.value')}}
      </div>
    </template>
    <template slot="body">
      <div class="custom-popup__content">
        <div class="custom-row">
          <div class="custom-col custom-col--50"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <span class="admin-edit" @click="editTranslate(['chat_phoneNumber'])"></span>
            <DefaultInput
                :label="$t('chat_phoneNumber.localization_value.value')"
                v-bind:class="{'ui-no-valid': chat.validation.phone}"
                :errorTxt="$t(`${chat.validationTranslate.phone}.localization_value.value`)"
                :error="chat.validation.phone"
                v-model="chat.data.phone"
            />
          </div>
          <div class="custom-col custom-col--50"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <span class="admin-edit" @click="editTranslate(['chat_contactName'])"></span>
            <DefaultInput
                :label="$t('chat_contactName.localization_value.value')"
                v-bind:class="{'ui-no-valid': chat.validation.name}"
                :errorTxt="$t(`${chat.validationTranslate.name}.localization_value.value`)"
                :error="chat.validation.name"
                v-model="chat.data.name"
            />
          </div>
        </div>
      </div>
    </template>
    <template slot="footer">
      <div class="custom-popup__btn align-items-center d-flex flex-wrap"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['common_cancel','common_create'])"></span>
        <span
            class="site-link site-link--alt custom-popup__btn-i mr-4 mt-3 mb-3"
            @click="$emit('close')"
        >
          {{$t('common_cancel.localization_value.value')}}
        </span>
        <MainButton
            class="custom-popup__btn-i "
             :value="$t('common_create.localization_value.value')"
            @click.native="save"
        />
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from "../../../../commonModals/Modal";
  import MainButton from "../../../../UI/buttons/MainButton/MainButton";
  import DefaultInput from "@/components/UI/inputs/DefaultInput/DefaultInput";
  import {Chat} from "@/components/modules/ChatModule/models/Chat";

  export default {
    name: "AddChatPopup",
    components: {
      DefaultInput,
      Modal,
      MainButton,
    },

    props: {
      type: {
        type: String,
        default: 'create'
      },
      modalOrderPriceId: Number,
      navTabs: Object,
    },

    data() {
      return {
        chat: new Chat(),
      }
    },

    mounted() {

    },

    methods: {
      save() {
        if(!this.chat.firstValidation()) return

        let data = this.chat.prepareSave()
        data.user_id = this.getCurrentUser?.id

        let typeDispatch = 'createChat'

        this.$store.dispatch(typeDispatch, data).then(response => {
          switch (this.getResponseStatus(response)) {
              /**
               * Success
               */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {

              this.openNotify('success', 'common_notificationRecordCreated')
              this.$emit('close')
              this.$emit('reload')
              break
            }
              /**
               * Validation Error
               */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
              /**
               * Undefined Error
               */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }
        })
      },
    }
  }
</script>

<style scoped>

</style>

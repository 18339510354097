<template>
  <div class="fragment">

    <div class="content-top-line content-top-line--with-bottom-line">
      <div class="content-tabs content-tabs--separator">
        <template v-for="(item, index) in navTabs">
          <div class="content-tabs__item"
               :key="index"
               v-bind:class="{active: item.active}"
               @click="$emit('changeTab', item.name)"
          >
            <div class="content-tabs__ico"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <span class="admin-edit" @click="editTranslate([item.label])"></span>
              <template>
                {{ $t(item.label + '.localization_value.value')}}
              </template>
            </div>
          </div>
        </template>
      </div>

    </div>

    <div class="horiz-block mt-4 mb-4">
      <div class="horiz-block__inner d-flex justify-content-end">
        <div
            v-if="navTabs.shippingCompany.active"
                v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['common_shippingCreateCompany'])"></div>
          <MainButton class="white-space-nowrap scoped-btn"
                      :value="$t('common_shippingCreateCompany.localization_value.value')"
                      @click.native="changeShippingCompanyPopup(true)"
          >
          </MainButton>
        </div>
        <div
            v-if="navTabs.shippingCompanyForOrder.active"
                v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['common_shippingCreateCompany'])"></div>
          <MainButton class="white-space-nowrap scoped-btn"
                      :value="$t('common_shippingCreateCompany.localization_value.value')"
                      @click.native="isModalShippingCompanyForOrderPopup = true"
          >
          </MainButton>
        </div>
        <div
            v-if="navTabs.shippingMethodsForOrder.active"
                v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['common_shippingCreateCompany'])"></div>
          <MainButton class="white-space-nowrap scoped-btn"
                      :value="$t('common_shippingCreateCompany.localization_value.value')"
                      @click.native="isModalShippingMethodsForOrderPopup = true"
          >
          </MainButton>
        </div>
      </div>
    </div>

    <ShippingCompanyPopup
      v-if="isModalShippingCompanyPopup"
      :openType="'create'"
      @reload="$emit('reload')"
      @close="changeShippingCompanyPopup(false)"
    />

    <ShippingCompanyForOrderPopup
      v-if="isModalShippingCompanyForOrderPopup"
      :openType="'create'"
      @reload="$emit('reload')"
      @close="isModalShippingCompanyForOrderPopup = false"
    />

    <ShippingMethodsForOrderPopup
      v-if="isModalShippingMethodsForOrderPopup"
      :openType="'create'"
      @reload="$emit('reload')"
      @close="isModalShippingMethodsForOrderPopup = false"
    />

  </div>
</template>



<script>
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  // import SearchEngine from "../../../../../../coreComponents/SearchEngine/SearchEngine";
  import ShippingCompanyPopup from "../../../../popups/ShippingCompanyPopup/ShippingCompanyPopup";
  import ShippingCompanyForOrderPopup from "../../../../popups/ShippingCompanyForOrderPopup/ShippingCompanyForOrderPopup";
  import ShippingMethodsForOrderPopup
    from "@/components/modules/ShippingCompanyModule/popups/ShippingMethodsForOrderPopup/ShippingMethodsForOrderPopup";


  export default {
    name: "ShippingCompanyHead",
    components: {
      ShippingMethodsForOrderPopup,
      MainButton,
      ShippingCompanyPopup,
      ShippingCompanyForOrderPopup,
    },

    props: {
      navTabs: Object,
    },

    watch: {
      activeTab(newVal) {
        this.tab = newVal
      }
    },

    data(){
      return{
        tab: this.activeTab,
        countFilterParams: 0,
        input01: '',
        options: [{}],
        isModalShippingCompanyPopup: false,
        isModalShippingCompanyForOrderPopup: false,
        isModalShippingMethodsForOrderPopup: false,
      }
    },

    methods: {

      changeTab(id) {
        this.$emit('changeTab', id)
      },

      changeDate() {
        console.log('changeDate function');
      },

      openHTMLCodePopup(){
        this.changeGenPayPalBtnPopup(false)
        this.changeCodeHTMLPopup(true)
      },


      changeShippingCompanyPopup(val){
        this.isModalShippingCompanyPopup = val
      },
    }
  }
</script>

<style scoped lang="scss">
  @import "../../../../../../../scss/mixins/mixins";
  @import "../../../../../../../scss/colors";

  .scoped-btn{
    height: 40px;
    font-size: 16px;
    line-height: 19px;
    font-weight: 500;
  }
</style>


<template>
  <transition v-if="true">
    {{type}}
    <router-link :to="link"
                 class="link-btn"
                 v-if="link"
                 :class="{'hide' : hide === true,
                         'disabled' : disabled === true,
                         'width--min-content' : minContentWidth === true,
                         'ws-break-spaces' : type === 'cn22'}"
                 :target="target"
    >
      <div class="link-btn__ico"
           v-if="type"
           :class="{'disabled' : disabled === true}"
      >
        <CopyIconSVG v-if="type === 'copy'"/>
        <DeleteIconSVG  v-if="type === 'delete'"/>
        <EditIconSVG  v-if="type === 'edit'"/>
        <DocsIconSVG  v-if="type === 'docs'"/>
        <DocsIconSVG  v-if="type === 'cn22'"/>
        <DogIconSVG  v-if="type === 'dog'"/>
        <ParcelIcoSVG style="width: 20px; top: 1px; position: relative;" v-if="type === 'parcel'"/>
        <AtmIconSVG  v-if="type === 'atm'"/>
        <ProformaIconSVG  v-if="type === 'proforma'"/>
        <PickupIconSVG  v-if="type === 'pickup'"/>
        <ReturnIconSVG  v-if="type === 'return'"/>
        <SendToIconSVG  v-if="type === 'sendTo'"/>
        <LabelIconSVG  v-if="type === 'label'"/>
        <ReferenceIconSVG  v-if="type === 'reference'"/>
        <LetterIconSVG  v-if="type === 'letter'"/>
        <CloudMsgIconSVG  v-if="type === 'cloud-msg'"/>
        <PinIconSVG  v-if="type === 'pin'"/>
        <StatisticSVG  v-if="type === 'statistic'"/>
        <PrintSVG  v-if="type === 'print'"/>
        <Mark  v-if="type === 'mark'"/>
        <HouseSVG  v-if="type === 'house'"/>
        <TransferSVG  v-if="type === 'tranasfer'"/>
        <TransferSVG  v-if="type === 'transfer'"/>
        <BarcodeSVG  v-if="type === 'barcode'"/>
        <DollarSVG  v-if="type === 'dollar'"/>
        <Dollar2SVG  v-if="type === 'dollar2'"/>
        <PayAccBalSVG  v-if="type === 'pay-acc-bal'"/>
        <ProductSVG  v-if="type === 'product'"/>
        <LabelSVG  v-if="type === 'label2'"/>
        <img alt="ico" v-if="type === 'np'" src="/img/UI-group/np.png"/>
      </div>{{ value }}
    </router-link>

    <div class="link-btn" v-if="!link"
         @click="open = true"
         :id="'id-' + _uid"
         :class="{'hide' : hide === true,
                 'disabled' : disabled === true,
                 'width--min-content' : minContentWidth === true,
                 'ws-break-spaces' : type === 'cn22'}"
    >
      <div class="link-btn__ico"
           v-if="type"
           :class="{'disabled' : disabled === true}">
        <CopyIconSVG v-if="type === 'copy'"/>
        <DeleteIconSVG  v-if="type === 'delete'"/>
        <EditIconSVG  v-if="type === 'edit'"/>
        <DocsIconSVG  v-if="type === 'docs'"/>
        <DocsIconSVG  v-if="type === 'cn22'"/>
        <DogIconSVG  v-if="type === 'dog'"/>
        <ParcelIcoSVG style="width: 20px; top: 1px; position: relative;" v-if="type === 'parcel'"/>
        <AtmIconSVG  v-if="type === 'atm'"/>
        <ProformaIconSVG  v-if="type === 'proforma'"/>
        <PickupIconSVG  v-if="type === 'pickup'"/>
        <ReturnIconSVG  v-if="type === 'return'"/>
        <SendToIconSVG  v-if="type === 'sendTo'"/>
        <LabelIconSVG  v-if="type === 'label'"/>
        <ReferenceIconSVG  v-if="type === 'reference'"/>
        <LetterIconSVG  v-if="type === 'letter'"/>
        <CloudMsgIconSVG  v-if="type === 'cloud-msg'"/>
        <PinIconSVG  v-if="type === 'pin'"/>
        <StatisticSVG  v-if="type === 'statistic'"/>
        <PrintSVG  v-if="type === 'print'"/>
        <Mark  v-if="type === 'mark'"/>
        <HouseSVG  v-if="type === 'house'"/>
        <TransferSVG  v-if="type === 'tranasfer'"/>
        <TransferSVG  v-if="type === 'transfer'"/>
        <BarcodeSVG  v-if="type === 'barcode'"/>
        <DollarSVG  v-if="type === 'dollar'"/>
        <Dollar2SVG   v-if="type === 'dollar2'"/>
        <PayAccBalSVG  v-if="type === 'pay-acc-bal'"/>
        <ProductSVG  v-if="type === 'product'"/>
        <LabelSVG  v-if="type === 'label2'"/>
        <img alt="ico" v-if="type === 'np'" src="/img/UI-group/np.png"/>
      </div>{{ value }}

      <div class="link-btn-date"
           v-if="datePicker === true"
      >
        <DatePickerDefault
          class="link-btn-date__inner"
        >
          <template slot="body">
            <date-picker
                class=""
                v-model="date"
                valueType="format"
                :range="datePickerRange != undefined && datePickerRange === false ? false : true"
                :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                :format="datePickerFormat"
                range-separator=" - "
                @change="selectDate"
                :open="open"
                :popup-class="'mobile-center'"
            >
              <template slot="footer">
                <div class="date-picker__footer d-flex align-items-center">
                  <span class="site-link mr-3"
                        @click="cancelFunc">
                    {{ $t('common_cancel.localization_value.value') }}
                  </span>
                  <MainButton
                      :value="datePickerTxt"
                      @click.native="datePickerBtn"
                  />
                </div>
              </template>
            </date-picker>
          </template>
        </DatePickerDefault>
      </div>
    </div>

  </transition>

</template>

<script>
  import DatePicker from 'vue2-datepicker'
  import DatePickerDefault from "../../inputs/DatePickerDefault/DatePickerDefault"
  import MainButton from "../MainButton/MainButton"
  import CopyIconSVG from '../../../../../public/img/UI-group/copy-icon.svg?inline'
  import DeleteIconSVG from '../../../../../public/img/UI-group/delete-icon.svg?inline'
  import EditIconSVG from '../../../../../public/img/UI-group/edit-icon.svg?inline'
  import DocsIconSVG from '../../../../../public/img/UI-group/docs-icon.svg?inline'
  import DogIconSVG from '../../../../../public/img/UI-group/dog-icon.svg?inline'
  import ParcelIcoSVG from '../../../../../public/img/UI-group/parcel-ico.svg?inline'
  import AtmIconSVG from '../../../../../public/img/UI-group/atm-icon.svg?inline'
  import ProformaIconSVG from '../../../../../public/img/UI-group/proforma-icon.svg?inline'
  import PickupIconSVG from '../../../../../public/img/UI-group/pickup-icon.svg?inline'
  import ReturnIconSVG from '../../../../../public/img/UI-group/return-icon.svg?inline'
  import SendToIconSVG from '../../../../../public/img/UI-group/sendArrow.svg?inline'
  import LabelIconSVG from '../../../../../public/img/UI-group/label-icon.svg?inline'
  import ReferenceIconSVG from '../../../../../public/img/UI-group/reference-icon.svg?inline'
  import LetterIconSVG from '../../../../../public/img/UI-group/letter-icon.svg?inline'
  import CloudMsgIconSVG from '../../../../../public/img/UI-group/cloud-msg-icon.svg?inline'
  import PinIconSVG from '../../../../../public/img/UI-group/pin-icon.svg?inline'
  import StatisticSVG from '../../../../../public/img/UI-group/statistic-icon.svg?inline'
  import PrintSVG from '../../../../../public/img/UI-group/print-icon.svg?inline'
  import Mark from '../../../../../public/img/UI-group/mark.svg?inline'
  import HouseSVG from '../../../../../public/img/UI-group/house-icon.svg?inline'
  import TransferSVG from '../../../../../public/img/common/transfer.svg?inline'
  import DollarSVG from '../../../../../public/img/UI-group/dollar-icon.svg?inline'
  import Dollar2SVG from '../../../../../public/img/UI-group/dollar2-icon.svg?inline'
  import PayAccBalSVG from '../../../../../public/img/UI-group/pay-acc-bal-icon.svg?inline'
  import BarcodeSVG from '../../../../../public/img/UI-group/barcode-icon.svg?inline'
  import ProductSVG from '../../../../../public/img/UI-group/product-icon.svg?inline'
  import LabelSVG from '../../../../../public/img/UI-group/label.svg?inline'
  import {datePickerMixin} from "../../../../mixins/datePickerMixin/datePickerMixin";


  export default {
    name: "LinkButton",
    components: {
      DatePicker,
      DatePickerDefault,
      MainButton,
      CopyIconSVG,
      DeleteIconSVG,
      EditIconSVG,
      DocsIconSVG,
      DogIconSVG,
      ParcelIcoSVG,
      AtmIconSVG,
      ProformaIconSVG,
      PickupIconSVG,
      ReturnIconSVG,
      SendToIconSVG,
      LabelIconSVG,
      ReferenceIconSVG,
      LetterIconSVG,
      CloudMsgIconSVG,
      PinIconSVG,
      StatisticSVG,
      PrintSVG,
      Mark,
      HouseSVG,
      TransferSVG,
      DollarSVG,
      Dollar2SVG,
      PayAccBalSVG,
      BarcodeSVG,
      ProductSVG,
      LabelSVG,
    },

    mixins: [datePickerMixin],

    props: [
      'value',
      'type',
      'link',
      'datePicker',
      'datePickerTxt',
      'datePickerRange',
      'datePickerFormat',
      'hide',
      'disabled',
      'target',
      'minContentWidth',
    ],

    data(){
      return {
        date: this.value && this.value.length > 0 ? [this.$moment(this.value[0]).format('DD MMM YY'), this.$moment(this.value[1]).format('DD MMM YY')] : '',
        open: false,
      }
    },

    watch: {
      value(newVal) {
        this.date = this.value && this.value.length > 0 ? [this.$moment(newVal[0]).format('DD MMM YY'), this.$moment(newVal[1]).format('DD MMM YY')] : ''
      }
    },

    mounted() {
      this.closeHiddenDatePicker(this, this._uid)
    },

    beforeDestroy() {
      this.removeEventCloseDatePicker(this)
    },

    methods: {
      selectDate(date) {
        this.$emit('changeDate', date)
      },

      datePickerBtn(){
        this.$emit('datePickerBtn')

        this.open = false
      },

      cancelFunc() {
        this.open = false
        this.date = ''
      }
    }
  }
</script>

<style lang="scss">
  @import "../../../../scss/colors";

  .link-btn{
    display: flex;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: $brown;
    transition: .3s;
    align-items: center;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    &.width--min-content {
      width: min-content;
    }

    &.disabled{
      pointer-events: none;
      cursor: no-drop;
      color: $silver;
      opacity: .5;
      transition: none!important;

      * {
        fill: $silver;
        transition: none!important;
      }
    }

    &.accent{
      color: $accent;

      &:hover{
        opacity: .7;

        .link-btn__ico svg{
          filter: initial;
        }
      }
    }

    &.underline{
      text-decoration: underline;
    }

    &:hover{
      color: $accent;

      .link-btn__ico svg{
        filter: brightness(1.47);
      }
    }

    &__ico{
      margin-right: 6px;

      svg{
        transition: .3s;
      }
    }

    &.margin-ico-0 &__ico{
      margin-right: 0;
    }

    &.black{
      color: $black;
      display: flex;
      flex-direction: row-reverse;

      &:hover{
        opacity: .7;
      }

      svg path{
        fill: $black;
      }
    }

    /*.link-btn-date {
      transform: translateY(-15px) translateX(-20px);
    }*/

    &.hide{
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
    }

  }

  .ws-break-spaces{
    white-space: break-spaces!important;
  }

</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fragment"},[_c('div',{staticClass:"horiz-block"},[_c('div',{staticClass:"horiz-block__inner"},[(!_vm.navTabs.sent.active)?_c('SetTrackNumberForm',{attrs:{"options":_vm.shippingCompanies},on:{"setTrackForSelectedUnions":(selectedOption, trackNumber) => _vm.$emit('setTrackForSelectedUnions', selectedOption, trackNumber)}}):_vm._e(),(_vm.navTabs.sent.active && !_vm.isMobileFunc())?_c('div',{staticClass:"filter-parcel-track",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
          'consolidationUnion_acceptInMN',
          ])}}}),_c('div',{staticClass:"filter-parcel-track__item wfc"},[_c('div',{staticClass:"accept-mn-link btn-style",on:{"click":function($event){return _vm.$emit('acceptInMinnesota')}}},[_c('div',{staticClass:"accept-mn-link__ico"},[_c('AcceptMNIco')],1),_vm._v(" "+_vm._s(_vm.$t('consolidationUnion_acceptInMN.localization_value.value'))+" ")])])]):_vm._e(),(!_vm.navTabs.sent.active)?_c('div',{staticClass:"horiz-block__btn",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
        'common_AddNew',
        ])}}}),_c('router-link',{attrs:{"to":_vm.$store.getters.GET_PATHS.reportsConsolidationCreate}},[_c('MainButton',{staticClass:"btn-fit-content ml-2",attrs:{"value":_vm.$t('common_AddNew.localization_value.value'),"ico":true}},[_c('template',{slot:"ico"},[_c('PlusIconSVG',{staticClass:"btn-icon-plus"})],1)],2)],1)],1):_vm._e()],1)]),_c('div',{staticClass:"content-top-line content-top-line--with-bottom-line",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
          'consolidationUnion_all',
          'consolidationUnion_Kiev',
          'consolidationUnion_Dnepr',
          'consolidationUnion_Minsk',
          'consolidationUnion_Kharkiv',
          'consolidationUnion_Odesa',
          'consolidationUnion_Lviv',
          'consolidationUnion_Zaporizhia',
          'consolidationUnion_Riga',
          'consolidationUnion_Lodz',
          'consolidationUnion_Cambridge',
          'consolidationUnion_sent',
          'consolidationUnion_send',
          'consolidationUnion_inditateTrackNum',
          'consolidationUnion_setForSelected',
          'consolidationUnion_acceptInMN',
        ])}}}),(Object.keys(_vm.$store.getters.getUserProfile).length > 0)?_c('div',{staticClass:"content-tabs content-tabs--separator",class:{'loading-tabs': _vm.$store.getters.getConsolidationUnionLoading === true,}},[_vm._l((_vm.navTabs),function(item,index){return [(item.permissionVisible)?_c('div',{key:index + _vm.loadUserRoleAndAuthorizedData,staticClass:"content-tabs__item",class:{active: item.active},on:{"click":function($event){return _vm.$emit('changeTab', item.name)}}},[_c('div',{staticClass:"content-tabs__ico"},[(item.ico)?[_c('img',{attrs:{"src":item.ico,"alt":"company-ico","width":item.icoWidth}})]:[_vm._v(" "+_vm._s(item.label)+" ")]],2),_c('span',{staticClass:"content-tabs__item-small"},[_vm._v(" "+_vm._s(item.subLabel)+" ")])]):_vm._e()]})],2):_vm._e(),_c('div',{staticClass:"content-top-line-wrap"},[_c('ToggleFilterButton',{attrs:{"count":_vm.countFilterParams,"showFilter":_vm.showFilter},on:{"toggleFilter":function($event){return _vm.$emit('toggleFilter')}}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }
export function validation(validationItems, validationOptions) {

  // console.log(validationItems);
  // console.log(validationOptions + '123');

  let validation = [];
  let validationError = [];
  let isValidate = true;

  Object.keys(validationOptions).map((item) => {

    if (Object.prototype.hasOwnProperty.call(validationItems, item)) {

      validationOptions[item].type.map((type) => {

        switch (true) {
          case type === 'empty':
            if (!fieldEmpty(validationItems[item])) {
              validation[item] = true
              validationError[item] = 'error_required'
              isValidate = false
            }
            break;

          case type === 'empty-array':
            if (!emptyArray(validationItems[item])) {
              validation[item] = true
              validationError[item] = 'error_required'
              isValidate = false
            }
            break;

          case type === 'email':
            if (!fieldEmail(validationItems[item])) {
              validation[item] = true
              validationError[item] = 'error_email'
              isValidate = false
            }
            break;

          case type === 'phone':
            if (!fieldPhone(validationItems[item])) {
              validation[item] = true
              validationError[item] = 'error_phone'
              isValidate = false
            }
            break;

          case type === 'password':
            if (!fieldPassword(validationItems[item])) {
              validation[item] = true
              validationError[item] = 'error_password'
              isValidate = false
            }
            break;

          case type === 'numeric':
            if (!fieldNumbers(validationItems[item])) {
              validation[item] = true
              validationError[item] = 'error_numeric'
              isValidate = false
            }
            break;

          case type === 'not-zero':
            if (!fieldNotZero(validationItems[item])) {
              validation[item] = true
              validationError[item] = 'common_MoreThanZero'
              isValidate = false
            }
            break;

          case type === 'is-integer':
            if (!isInteger(validationItems[item])) {
              validation[item] = true
              validationError[item] = 'common_noInteger'
              isValidate = false
            }
            break;

          case type === 'checked':
            if (!fieldChecked(validationItems[item])) {
              validation[item] = true
              validationError[item] = 'error_checked'
              isValidate = false
            }
            break;

          case type === 'minLength' && validationOptions[item]?.minLength > 0:
            if (validationItems[item].length < parseInt(validationOptions[item]?.minLength)) {
              validation[item] = true
              validationError[item] = 'error_minLength'
              isValidate = false
            }
            // if(!fieldNumbers(validationItems[item])) {
            //   validation[item] = true
            //   isValidate = false
            // }
            break;


          case type === 'latin':
            if (!isLatinSymbols(validationItems[item])) {
              validation[item] = true
              validationError[item] = 'error_latin'
              isValidate = false
            }
            break;

          case type === 'onlyLettersAndNumbers':
            if (!isOnlyLettersAndNumbers(validationItems[item])) {
              validation[item] = true
              validationError[item] = 'error_latin'
              isValidate = false
            }
            break;

          case type === 'onlyLetters':
            if (!isOnlyLetters(validationItems[item])) {
              validation[item] = true
              validationError[item] = 'error_latin'
              isValidate = false
            }
            break;

          case type === 'onlyCyrillic':
            if (!isOnlyCyrillicSymbols(validationItems[item])) {
              validation[item] = true
              validationError[item] = 'error_onlyCyrillic'
              isValidate = false
            }
            break;

          case type === 'file':
            if (validationItems[item].length === 0) {
              validation[item] = true
              validationError[item] = 'error_file'
              isValidate = false
            }
            break;

          default:
            break;
        }

      })

    }
  })

  return {validation, isValidate, validationError};
}


export function emptyArray(item) {
  if (item && Array.isArray(item) && item.length > 0 && item !== []) {
    return true;
  } else {
    return false;
  }
}

export function fieldEmpty(item) {
  if (item !== '' && item !== null && item !== undefined) {
    return true;
  } else {
    return false;
  }
}

export function fieldChecked(item) {
  if (item === true) {
    return true;
  } else {
    return false;
  }
}


export function fieldEmail(fieldEmail) {
  if(fieldEmail === '') return true

  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(fieldEmail);
}

export function fieldPhone(field) {
  if(field === '') return true

  var reg = /^\S((\+\s)?([0-9]{1,})?-?\s?(\([0-9]{1,}\s?\))?)?\s?[\s?0-9]{1,}\S$/

  return reg.test(field);
  // return field.length > 10 && field.length < 20
}


export function fieldNumbers(field) {
  // var reg = /^\d+$/;
  //var reg = /^[\d.,]*$/;  deprecated 29.06.2022
  var reg = /^-?[0-9][\d.,]*$/;

  return reg.test(parseFloat(field).toString());
}

export function fieldNotZero(field) {
  if(parseFloat(field) > 0) return true
  return false
}

export function fieldPassword(fieldPassword) {
  var reg =  /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{11,}/;

  return reg.test(fieldPassword) && hasSpecialSymbol(fieldPassword)
}

export function isLatinSymbols(txt) {
  // return !(/[а-я]/i.test(txt))
  return /^[a-zA-Z0-9 ,.'-]*$/.test(txt)
}

export function isOnlyLettersAndNumbers(txt) {
  return /^[a-zA-Z0-9]+$/.test(txt);
}

export function isOnlyLetters(txt) {
  return /^[\p{L}]+$/u.test(txt);
}

export function isOnlyCyrillicSymbols(txt) {
  return !(/[a-z]/i.test(txt))
}

export function isInteger(num) {
  return (parseFloat(num) ^ 0) === parseFloat(num);
}


export function hasNumber(value) {
  return value.replace(/[^0-9]/g,"").length > 0
}

export function hasUpperCase(value) {
  return value.replace(/[^A-Z]/g,"").length > 0
}

export function hasLowerCase(value) {
  return value.replace(/[^a-z]/g,"").length > 0
}

export function hasSpecialSymbol(value) {
  return value.search(/(?=.*[!@#$%^&*])/g,"") === 0
}

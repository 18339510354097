<template>
  <div class="fragment">
    <template v-if="$store.getters.getAPCDelcon.length > 0 && !$store.getters.getAPCDelconLoading">
      <div class="site-table-wrap small-table"
           v-if="activeTab !== 7"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
          'apcDelcon_id',
          'common_user',
          'apcDelcon_shippingCompany',
          'apcDelcon_trackingNumber',
          'apcDelcon_comment',
          'apcDelcon_sendDate',
          'common_manage',
          'common_edit',
          'common_delete',
          ])"></div>
        <table class="site-table" v-if="!isMobileFunc()">
          <thead>
          <tr>
            <th></th>
            <th>{{$t('apcDelcon_id.localization_value.value')}}</th>
            <th>{{$t('common_user.localization_value.value')}}</th>
            <th>{{$t('apcDelcon_shippingCompany.localization_value.value')}}</th>
            <th>{{$t('apcDelcon_trackingNumber.localization_value.value')}}</th>
            <th>{{$t('apcDelcon_comment.localization_value.value')}}</th>
            <th v-if="navTabs.sent.active">{{$t('apcDelcon_sendDate.localization_value.value')}}</th>
            <th>{{$t('common_manage.localization_value.value')}}</th>
          </tr>
          </thead>
          <tbody>

          <tr
              v-for="(item, index) in $store.getters.getAPCDelcon"
              :key="index"
          >
            <td>
              <DefaultCheckbox
                  v-if="item.status !== 'departed'"
                  class="empty-label"
                  :selectedNow="selectedNow"
                  :dataValue="item.id"
              />
              <StatusIcoBtn v-else :type="'delivered'" class="mr-2" />
            </td>
            <td>
              <v-popover
                  class="site-tooltip"
                  offset="5"
                  placement="top"
                  trigger="hover"
              >
                <div class="ellipsis">
                  <span class="table-link btn-style"
                    @click="changeInfoPopup(item)"
                  >
                    {{item.delivery_service.name}}: {{item.tracking_number}}
                  </span>
                </div>
                <template slot="popover">
                  <p>{{item.delivery_service.name}}: {{item.tracking_number}}</p>
                </template>
              </v-popover>
            </td>
            <td>
              <LinkButton
                  v-if="item.admin"
                  :value="item.admin.full_name"
                  :link="$store.getters.GET_PATHS.mainSettingsUsers + `?userId=${item.admin.id}`"
              />
            </td>
            <td>
              {{item.delivery_service.name}}
            </td>
            <td>
              <v-popover
                  class="site-tooltip"
                  offset="5"
                  placement="top"
                  trigger="hover"
              >
                <div class="ellipsis">{{ item.tracking_number }}</div>
                <template slot="popover">
                  <p>{{ item.tracking_number }}</p>
                </template>
              </v-popover>
            </td>
            <td class="white-space-line">
              <div class="table-row">
                <div class="d-flex align-center">
                    <v-popover
                        class="site-tooltip"
                        offset="5"
                        placement="top"
                        trigger="hover"
                    >
                      <div class="ellipsis">{{ item.comment }}</div>
                      <template slot="popover">
                        <p>{{ item.comment }}</p>
                      </template>
                    </v-popover>

                </div>
              </div>
            </td>
            <td v-if="navTabs.sent.active">
              {{ item.send_at | moment("DD MMM, YYYY") }}
            </td>
            <td align="right">
              <div class="table-row">
                <div class="table-right table-manage-list table-manage-list--small">

                  <ManagerButton @reloadManagerBtn="$store.commit('setGlobalUpdateManagerButton', Math.random()) " :key="$store.getters.getGlobalUpdateManagerButton"
                      class="right"
                  >
                    <template slot="item">
                      <LinkButton
                          :value="$t('common_edit.localization_value.value')"
                          :type="'edit'"
                          :link="$store.getters.GET_PATHS.reportsAPCDelconEdit + '/' + item.id"
                      />
                    </template>
                    <template slot="item">
                      <LinkButton
                          v-if="item.status !== 'received'"
                          :value="$t('common_delete.localization_value.value')"
                          :type="'delete'"
                          @click.native="$emit('removeItem', item.id)"
                      />
                    </template>
                  </ManagerButton>
                </div>
              </div>
            </td>
          </tr>

          </tbody>
        </table>

        <div class="table-card" v-if="isMobileFunc()">
          <div class="table-card__list">
            <div class="table-card__item"
                 v-for="(item, index) in $store.getters.getAPCDelcon"
                 :key="index"
            >
              <APCDelconTableMobile
                  :item="item"
                  :navTabs="navTabs"
                  :selectedNow="selectedNow"
                  @changeInfoPopup="changeInfoPopup"
                  @removeItem="$emit('removeItem', item.id)"
              />
            </div>

          </div>
        </div>

        <div class="table-bottom-btn">
          <div class="table-bottom-btn__inner">
            <ShowMore
                v-if="$store.getters.getAPCDelconCommonList.next_page_url !== null && $store.getters.getAPCDelcon.length > 0"
                @click.native="$emit('showMore')"
                v-bind:class="{'disabled-btn' : $store.getters.getNextAPCDelconPage}"
                :count="$store.getters.getAPCDelconCommonList.total - $store.getters.getAPCDelconForPage < $store.getters.getAPCDelconForPage ?
                  $store.getters.getAPCDelconCommonList.total - $store.getters.getAPCDelconForPage:
                  $store.getters.getAPCDelconForPage"
            />
<!--            <ExportBtn-->
<!--                @click.native="$emit('downloadFiles')"-->
<!--                class="table-bottom-btn__right"/>-->
          </div>
        </div>
      </div>
    </template>

    <template v-if="$store.getters.getAPCDelconLoading === false && $store.getters.getAPCDelcon.length === 0">
      <NoResult
          :countFilterParams="countFilterParams"
          :title="$t('common_noDataYet.localization_value.value')"
          :titleWithFilter="$t('common_noResult.localization_value.value')"
          :text="$t('common_noDataYetTxt.localization_value.value')"
          :textWithFilter="$t('common_noResultSeems.localization_value.value')"
          @resetFilter="$emit('resetFilter')"
      />
    </template>



    <!--<ProductsToWarehouse @close="closePopup" v-if="isModalProductsToWarehouse"/>-->
    <InfoPopup
      v-if="isModalInfoPopup"
      :infoPopupItem="infoPopupItem"
      @close="isModalInfoPopup = false"
    ></InfoPopup>

  </div>
</template>

<script>
  // import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  // import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
  import ShowMore from "../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
  import LinkButton from "../../../../../../UI/buttons/LinkButton/LinkButton";
  import ManagerButton from "../../../../../../UI/buttons/ManagerButton/ManagerButton";
  // import ExportBtn from '../../../../../../coreComponents/Buttons/Export/Export'
  import {mixinDetictingMobile} from '../../../../../../../mixins/mobileFunctions'
  import DefaultCheckbox from "../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import InfoPopup from "../../../../popups/InfoPopup/InfoPopup";
  import APCDelconTableMobile from "./APCDelconTableMobile/APCDelconTableMobile";
  import NoResult from "../../../../../../coreComponents/NoResult/NoResult";
  import StatusIcoBtn from "../../../../../../UI/status/StatusIcoBtn/StatusIcoBtn";
  // import ValueHelper from "../../../../../../coreComponents/ValueHelper/ValueHelper";
  // import TooltipBtn from "../../../../../../UI/tooltips/TooltipBtn/TooltipBtn";
  // import ProductsToWarehouse from "../../../../popups/ProductsToWarehouse/ProductsToWarehouse";


  export default {
    name: "APCDelconTable",

    components: {
      StatusIcoBtn,
      // ValueHelper,
      NoResult,
      APCDelconTableMobile,
      // TooltipBtn,
      // MainButton,
      // DefaultInput,
      ShowMore,
      // ExportBtn,
      LinkButton,
      ManagerButton,
      // ProductsToWarehouse,
      DefaultCheckbox,
      InfoPopup,
    },

    mixins: [mixinDetictingMobile],

    props: {
      selectedNow: Boolean,
      countFilterParams: Number,
      activeTab: Number,
      navTabs: Object,
      consoUsers: Array,
    },


    data() {
      return {
        orderId: '',
        showFilter: false,
        isMobile: false,

        show1: false,
        show2: false,

        isModalInfoPopup: false,
        infoPopupItemId: 0,
      }
    },

    methods: {
      closePopup(){
        // this.isModalProductsToWarehouse = false
      },

      changeInfoPopup(item){
        this.isModalInfoPopup = true
        this.infoPopupItem = item
      },

      changeCommentAPCDelcon({value, id}) {
        this.$store.dispatch('updateCommentAPCDelcon', {
          id: id,
          data: {comment: value}
        }).then((response) => {
          switch (this.getResponseStatus(response)) {
              /**
               * Success
               */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
              this.openNotify('success', 'common_notificationRecordChanged')
              let consolidationUnions = this.$store.getters.getAPCDelcon
              this._.find(consolidationUnions, {id: id}).comment = value
              break
            }
              /**
               * Validation Error
               */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
              /**
               * Undefined Error
               */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }
        })
      },

      changeConsoUserAPCDelcon(id, localConsoUser = false) {
        this.selectedConsoUser = localConsoUser ? localConsoUser : this.selectedConsoUser

        if (!this.selectedConsoUser) return

        this.$store.dispatch('updateConsoUserAPCDelcon', {
          id: id,
          data: {consolidation_responsible_person_id: this.selectedConsoUser?.id}
        }).then((response) => {
          switch (this.getResponseStatus(response)) {
              /**
               * Success
               */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
              this.openNotify('success', 'common_notificationRecordChanged')
              let consolidationUnions = this.$store.getters.getAPCDelcon
              this._.find(consolidationUnions, {id: id}).consolidation_responsible_person = this.selectedConsoUser
              // to prevent any odd behaviour when opening 2nd and further inputs
              this.selectedConsoUser = null
              break
            }
              /**
               * Validation Error
               */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
              /**
               * Undefined Error
               */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }
        })
      }

    }

  }

</script>

<style scoped lang="scss">
  .comment-row{
    overflow: hidden;
    text-overflow: ellipsis;
    width: 356px;
    position: relative;
    display: inline-block;
    white-space: nowrap;
  }

  .table-manage-list {
    width: 240px;


    .manager-btn {
      max-width: 370px;
      width: 100%;
    }
  }


  @media (max-width: 1900px){
    .comment-row{
      width: 250px;
    }
  }

  @media (max-width: 1400px){
    .comment-row{
      width: 200px;
    }
  }

  .site-table thead th:first-child,
  .site-table tbody td:first-child{
    padding-right: 0;
  }

  .site-table thead th:nth-child(2),
  .site-table tbody td:nth-child(2){
    padding-left: 0;
  }

  .site-table thead th:last-child,
  .site-table tbody td:last-child{
    padding-right: 5px;
    padding-top: 0;
    padding-bottom: 0;
  }

  .small-table th,
  .small-table td{
    white-space: nowrap;
  }

  .site-table .ellipsis {
    max-width: 180px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    display: inline-block;
  }

</style>

<template>
  <APCDelconEditAdmin
      :APCD="APCD"
      :ordersFBM="ordersFBM"
      :shippingCompanies="shippingCompanies"
      @saveAPCDelcon="saveAPCDelcon"
  />
</template>

<script>
  import {APCDelcon} from "../../models/APCDelcon";
  import {APCDelconMixin} from "../../../../../mixins/APCDelcon/APCDelconMixin";
  import {
    CONSOLIDATION_APC_DELCON_SHIPPING_COMPANIES_ARRAY
  } from "../../../../../staticData/staticVariables";
  import APCDelconEditAdmin from "./APCDelconEditAdmin/APCDelconEditAdmin";

  export default {
    name: "APCDelconEdit",

    components: {
      APCDelconEditAdmin,
    },

    mixins: [
      APCDelconMixin
    ],

    data() {
      return {
        APCD: new APCDelcon(),
        shippingCompanies: [],
        ordersFBM: [],
      }
    },

    created() {
      if(this.downloadPermissions){
        if(!this.checkPermission()) return
      }

      this.initAPCDelconEdit()
    },

    methods: {
      initAPCDelconEdit() {
        this.$store.dispatch('getAPCDelcon', this.$route.params.id).then(() => {

          let ordersIds = this.$store.getters.getAPCDelconItem.orders_fbm.length ? this.$store.getters.getAPCDelconItem.orders_fbm.map(item => {
            return item.id
          }) : []
          this.$store.dispatch('getAPCDelconCreate', {order_fbm_ids: ordersIds}).then(response => {
            let respData = this.getRespData(response)

            this.ordersFBM = respData.order_fbm_list

            this.shippingCompanies = respData.delivery_services.filter(item => {
              return CONSOLIDATION_APC_DELCON_SHIPPING_COMPANIES_ARRAY.find(findItem => {
                return findItem.id === item.id
              })
            })

            this.APCD.setAPCDelconItem(this.$store.getters.getAPCDelconItem, this.shippingCompanies, this.ordersFBM, this)

          }).catch(error => this.createErrorLog(error))
        }).catch(error => this.createErrorLog(error))

      },

    }
  }
</script>

<style scoped>

</style>
<template>
  <modal
      @close="$emit('close')"
      class="custom-popup"
  >
    <template slot="header">
      {{infoPopupItem.delivery_service.name}}: {{infoPopupItem.id}} {{ infoPopupItem.created_at | moment("DD MMM, YYYY") }}
    </template>
    <template slot="body">
      <div class="custom-popup__content"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
          'consolidationUnion_id',
          'consolidationUnion_concolidation',
          'common_manage',
          'common_close',
          ])"></div>
        <table v-if="items.length > 0" class="site-table">
          <thead>
            <tr>
              <th>{{$t('consolidationUnion_id.localization_value.value')}}</th>
              <th>{{$t('consolidationUnion_concolidation.localization_value.value')}}</th>
              <th class="text-right">{{$t('common_manage.localization_value.value')}}</th>
            </tr>
          </thead>
          <tbody>
            <tr
                v-for="(item, index) in items"
                :key="index"
            >
              <td>
                {{index + 1}}
              </td>
              <td>
                {{$t('consolidationUnion_concolidation.localization_value.value')}} №{{item.consolidation_id}}: {{item.delivery_service.name}}
              </td>
              <td class="pr-2" align="right">
                <div class="table-right table-manage-list table-manage-list--small">
                  <ManagerButton @reloadManagerBtn="$store.commit('setGlobalUpdateManagerButton', Math.random()) " :key="$store.getters.getGlobalUpdateManagerButton"
                      class="right"
                  >
                    <template slot="item">
                      <LinkButton
                          :value="$t('common_edit.localization_value.value')"
                          :type="'edit'"
                          :target="'_blank'"
                          :link="$store.getters.GET_PATHS.ordersConsolidationEditing + '/' + getConsolidationTypeByDestinationId(item.consolidation_destination.id) + '/' + item.consolidation_id"
                      />
                    </template>
                  </ManagerButton>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

      </div>
    </template>
    <template slot="footer">
      <div class="d-flex flex-wrap mt-2 scoped-btns">

            <span class="site-link site-link--alt mr-3 mb-2 mt-2"
                  @click="$emit('close')"
            >
              {{$t('common_close.localization_value.value')}}
            </span>

      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from '../../../../commonModals/Modal.vue'
  import ManagerButton from "../../../../UI/buttons/ManagerButton/ManagerButton";
  import LinkButton from "../../../../UI/buttons/LinkButton/LinkButton";
  import {consolidationMixin} from "../../../../../mixins/consolidationMixins/consolidationMixin";


  export default {
    name: "InfoPopup",
    components: {
      Modal,
      ManagerButton,
      LinkButton,
    },

    mixins: [
        consolidationMixin
    ],

    props: {
      infoPopupItem: Object,
    },

    data(){
      return{
        items: []
      }
    },

    created() {
      this.$store.dispatch('getConsolidationUnionConsolidations', this.infoPopupItem.id).then(response => {
        this.items = this.getRespData(response)
      })
    }
  }

</script>

<style lang="scss" scoped>
  .scoped-width{
    min-width: 118px;
    width: fit-content;
  }


  .table-manage-list {
    width: 120px;

    .manager-btn {
      max-width: 175px;
    }
  }

  .site-table tbody td{
    padding: 12px 15px;
  }

</style>

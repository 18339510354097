<template>
	<div class="fragment">
		<ConsolidationEditingAdmin
        v-if="isAdmin && loading"
        :consolidation="consolidation"
        :consolidationUnions="consolidationUnions"
        :destinationData="destinationData"
        :deliveryServices="deliveryServices"
        :orderFees="orderFees"
        :loadButtons="loadButtons"
        :showPromoFields="showPromoFields"
        @prepareSaveConsolidation="prepareSaveConsolidation"
        @printLabels="printLabels"
        @print="printPage"
        @thermalPrint="thermalPrint({id: $route.params.id})"
        @openOrderNotification="orderNotificationPopup = true; orderNotificationPopupTypeInfo = false"
        @createLabel="createLabel"
        @refundLabel="refundLabel"
        @downloadInnerLabels="downloadInnerLabels($route.params.id)"
    />

		<ConsolidationEditingUser
        v-if="!isAdmin && loading"
        :consolidation="consolidation"
        :destinationData="destinationData"
        :deliveryServices="deliveryServices"
        :loadingFedexWarehouses="loadingFedexWarehouses"
        :showPromoFields="showPromoFields"
        @prepareSaveConsolidation="prepareSaveConsolidation"
        @printLabels="printLabels"
        @createLabel="createLabel"
    />


    <OrderNotificationPopup
        v-if="orderNotificationPopup"
        :orderNotificationData="consolidation.data.orderNotification"
        :typeInfo="orderNotificationPopupTypeInfo"
        :orderType="'order_consolidation'"
        :orderId="consolidation.data.id"
        @close="orderNotificationPopup = false"
        @reload="reloadNotification"
    />

    <AuthorizePaymentPopup
        v-if="authorizePaymentPopup"
        :countMoney="authorizePaySum"
        :authorizePayData="authorizePayData"
        :authorizeError="authorizeError"
        :serverError="serverError"
        :changeCloseToSaveBtn="true"
        @close="()=>{$router.push($store.getters.GET_PATHS.ordersConsolidation)}"
        @submitForm="submitAuthorizeInvoicePay"
    />

    <BraintreePaymentPopup
        v-if="braintreePaymentPopup"
        :amount="authorizePaySum"
        :payData="authorizePayData"
        :invoicePayId="authorizeInvoiceId"
        :changeCloseToSaveBtn="true"
        @submitForm="afterPay"
        @close="()=>{$router.push($store.getters.GET_PATHS.ordersConsolidation)}"
    />

    <ThankYouPopup
        v-if="thankYouPopupPopup"
        @close="() => {$router.push($store.getters.GET_PATHS.ordersConsolidation)}"
        :title="'common_paySuccessTitle'"
        :text="'common_paySuccessText'"
        :linkLastText="'common_paySuccessTextLink'"
        :linkLastTextHref="$store.getters.GET_PATHS.financeMyPayments + '?type=authorize'"
    />
	</div>
</template>

<script>
	// import OrdersEditingConsolidationUser from "./_OrdersEditingConsolidationUser/OrdersEditingConsolidationUser";
	import ConsolidationEditingAdmin from "./ConsolidationEditingAdmin/ConsolidationEditingAdmin";
  import {consolidationMixin} from "../../../../../mixins/consolidationMixins/consolidationMixin";
  import {proformMixins} from "../../../../../mixins/proformMixins/proformMixins";
  import {Consolidation} from "../../models/Consolidation";
  import ConsolidationEditingUser from "./ConsolidationEditingUser/ConsolidationEditingUser";
  import {
    CONSOLIDATION_TYPE_DHL_FROM_YOUR_CITY, CONSOLIDATION_TYPE_FEDEX_FROM_YOUR_CITY,
    CONSOLIDATION_TYPES,
    CONSOLIDATION_TYPES_ARRAY, CONSOLIDATION_TYPES_FROM_YOUR_CITY_ARRAY, CONSOLIDATION_UNION_SHIPPING_COMPANIES_ARRAY,
    ORDERS_CONSOLIDATION_TYPE, ORDERS_CONSOLIDATION_TYPE_FROM_URL
  } from "../../../../../staticData/staticVariables";
  import {userAvatarMixin} from "../../../../../mixins/usersMixins/userAvatarMixin";
  import {queryFilterMixin} from "@/mixins/routeFilterMixins/queryFilterMixin";
  import OrderNotificationPopup from "@/components/coreComponents/Popups/OrderNotificationPopup/OrderNotificationPopup";
  import AuthorizePaymentPopup from "../../../../coreComponents/Popups/AuthorizePaymentPopup/AuthorizePaymentPopup";
  import BraintreePaymentPopup from "../../../../coreComponents/Popups/BraintreePaymentPopup/BraintreePaymentPopup";
  import ThankYouPopup from "../../../../coreComponents/Popups/ThankYouPopup/ThankYouPopup";
  import {paymentBlockFunc} from "../../../../../mixins/dashboardMixins/paymentBlockFunc";

	export default {
		name: "OrdersEditingConsolidation",
		components: {
      ThankYouPopup,
      BraintreePaymentPopup,
      AuthorizePaymentPopup,
      OrderNotificationPopup,
      ConsolidationEditingUser,
			// OrdersEditingConsolidationUser,
			ConsolidationEditingAdmin
		},

    mixins: [
      consolidationMixin,
      proformMixins,
      userAvatarMixin,
      queryFilterMixin,
      paymentBlockFunc
    ],

    watch: {
      // loadUserAuthorizedData: function () {
      //   this.initConsolidationEdit()
      // },

      getOrderNotification(value) {
        if(value && this.isAdmin) {
          this.orderNotificationPopupTypeInfo = true
          this.orderNotificationPopup = true
        }
      },
    },


    computed: {
      getOrderNotification() {
        return this.consolidation.data.orderNotification?.active === 1
      }
    },

    created() {
      if(this.downloadPermissions){
        if(!this.checkPermission()) return
      }

      this.initializeProductProformArray(this.consolidation.data.proformOrders, false).then(() => {
        if (this.isAdmin) {
          window.addEventListener('beforeunload', this.beforeWindowUnload)
        }
        this.initConsolidationEdit()
      })

    },

    beforeDestroy() {
		  if (this.isAdmin) {
        window.removeEventListener('beforeunload', this.beforeWindowUnload)
      }
    },

    beforeRouteLeave (to, from, next) {
		  if (this.isAdmin) {
        if (this.confirmStay()){
          next(false)
        } else {
          next()
        }
      }
		  else {
		    next()
      }
    },

    data() {
      return {
        consolidation: new Consolidation(),
        destinationData: [],
        destinationWarehouseData: [],
        consolidationUnions: [],
        orderFees: [],
        deliveryServices: [],
        loading: false,
        loadButtons: false,
        loadingFedexWarehouses: false,

        orderNotificationPopup: false,
        orderNotificationPopupTypeInfo: false,

        serverError: false,
        authorizePayData: {},
        authorizePaySum: -1,
        authorizeInvoiceId: -1,
        authorizePaymentPopup: false,
        braintreePaymentPopup: false,
        thankYouPopupPopup: false,
        authorizeError: false,
        showPromoFields: false,
      }
    },

    methods: {

      beforeWindowUnload(e) {
        if (this.confirmStay()) {
          e.preventDefault()
          e.returnValue = ''
        }
      },

      confirmStay() {
        return !window.confirm(this.$t('common_confirmLeave.localization_value.value').toString())
      },

      reloadNotification() {
        this.$store.dispatch('getConsolidationEdit', this.$route.params.id).then(() => {
          this.consolidation.setOrderNotification(this.$store.getters.getConsolidationItem?.orderNotification)
        })
      },

      reloadOrder() {
        this.loading = false
        this.consolidation.resetProductsForCellArray()
        this.consolidation.resetProformProductsForTable()
        this.initConsolidationEdit()
      },

      async initConsolidationEdit() {

        this.responseData = await this.$store.dispatch('getConsolidationCreate').then(response => {
          return this.getRespData(response)
        }).catch(error => this.createErrorLog(error))

        /**
         * Show promo codes
         */
        !this.responseData?.promocodes_available ? this.showPromoFields = false : this.showPromoFields = true

        /**
         * Set currentDeliveryMethod
         */
        let currentDeliveryMethod = this._.find(ORDERS_CONSOLIDATION_TYPE_FROM_URL, {fromUrl: this.$route.params.type})?.typeObj
        console.log('current delivery method ',currentDeliveryMethod);

        /**
         * set destination data only for our delivery service
         */
        this.destinationData = this.responseData.destinations.filter((item) => {
          return item.delivery_service_id === currentDeliveryMethod.delivery_service_id
        })

        //temp fix, delete later
        if (currentDeliveryMethod.name === 'fedex' || currentDeliveryMethod.name === 'dhl') {
          this.destinationData = this.destinationData.filter((item) => {
            return item.api !== 1
          })
        }

        /**
         *  set destination data only for from your city type
         */
        if (this.$route.params.type === ORDERS_CONSOLIDATION_TYPE.FROM_YOUR_CITY) {
          this.destinationData = this.responseData.destinations.filter((item) => {
            return CONSOLIDATION_TYPES_FROM_YOUR_CITY_ARRAY.find(findItem => {

              if(this.isLatvian && (findItem.id === CONSOLIDATION_TYPE_DHL_FROM_YOUR_CITY.id || findItem.id === CONSOLIDATION_TYPE_FEDEX_FROM_YOUR_CITY.id)) {
                return false
              }

              return +findItem.id === +item.id
            })
          })

          this.destinationWarehouseData = this.responseData.destinationWarehouses
          //set default destination warehouse
          if (this.destinationWarehouseData?.length > 0) {
            this.consolidation.setRecipientWarehouse(this.destinationWarehouseData[0])
          }
        }

        //ВРЕМЕННЫЙ ФИКС, СПРОСИТЬ У БЭКА ПОЧЕМУ АПИ ФЛАЖОК ВКЛЮЧЕН (Вова)
        if (this.$route.params.type === ORDERS_CONSOLIDATION_TYPE.EU_PRODUCT) {
          this.destinationData[0].api = 0
        }

        console.log(this.destinationData);

        this.orderFees = this.responseData?.order_fees_list.sort((a, b) => {return a.cost - b.cost})

        const query = new this.Query();
        let url = '?';
        let myQuery = query
            .for('posts')

        url = url + myQuery.limit(100000).page(1).url().split('?')[1]

        this.deliveryServices = await this.$store.dispatch('fetchShippingCompany', url).then(responseDelivery => {
          return this.getRespPaginateData(responseDelivery)
        }).catch(error => this.createErrorLog(error))

        let editConsolidation = await this.$store.dispatch('getConsolidationEdit', this.$route.params.id).then((response) => {
          return this.getRespData(response)
        }).catch(error => this.createErrorLog(error))

        /**
         * CHECK IF CONSOLIDATION TYPE == TYPE IN URL
         */
        if((!this.isFromYourCity && editConsolidation?.consolidation_destination_id !== currentDeliveryMethod?.id) ||
            (this.isFromYourCity && editConsolidation?.consolidation_destination_id !== CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_OWN_CARRIER.id
                && editConsolidation?.consolidation_destination_id !== CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_FEDEX_FROM_YOUR_CITY.id
                && editConsolidation?.consolidation_destination_id !== CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_DHL_FROM_YOUR_CITY.id) ) {
          return this.$router.push(this.$store.getters.GET_PATHS.notFound)
        }

        if (this.isAdmin) {
          /**
           * Alert if sipping price > $200
           */
          if(editConsolidation?.consolidation_price_amount_alert) {
            let text = {
              title: '',
              txt: '',
              customTxt: editConsolidation?.consolidation_price_amount_alert,
              yes: '',
              no: 'common_cancel'
            }
            let confirm = () => {
              this.$store.commit('setConfirmDeletePopup', false)
              return true
            }
            this.deleteFunc(text, confirm)
          }

          /**
           * fetch Consolidation Union
           */
          const queryUnion = new this.Query();
          let urlUnion = '?';
          let myQueryUnion = queryUnion
              .for('posts')

          myQueryUnion.where('byProcessed', false)
          myQueryUnion.where('byConsolidationDestinationId', editConsolidation?.consolidation_destination_id)

          if(editConsolidation.consolidation_union_id){
            myQueryUnion.where('orWhereId', editConsolidation.consolidation_union_id)
          }

          urlUnion = urlUnion + myQueryUnion.limit(10000).page(1).url().split('?')[1]

          await this.$store.dispatch('fetchConsolidationUnion', urlUnion).then(responseUnion => {
            this.consolidationUnions = this.getRespPaginateData(responseUnion)
          }).catch(error => this.createErrorLog(error))
        }

        this.consolidation.setConsolidationItem(
            editConsolidation,
            this.destinationData,
            this,
            this.deliveryServices,
            this.orderFees,
            this.isAdmin ? this.consolidationUnions : false,
            this.destinationWarehouseData
        )

        this.setWarehouses(currentDeliveryMethod)
        this.loadButtons = true

        this.loading = true

        if (this.consolidation.getOldDeliveryService()) {
          this.setShippingCompanies(CONSOLIDATION_UNION_SHIPPING_COMPANIES_ARRAY)
        }
        else {
          let userCountry = this.getUserContactDataFromUser(this.consolidation.data.User.getUser())?.country?.id
          this.$store.dispatch('getShippingCompanyByCountryId', userCountry).then(response => {
            this.deliveryServices = this.getRespData(response)
          }).catch(error => this.createErrorLog(error))
          // this.setShippingCompanies(CONSOLIDATION_DELIVERY_SERVICES_ARRAY)
        }

        /**
         * Set avatar file for admin
         */
        if (this.isAdmin) {
          //set data so we don't need to rework function
          if (this.consolidation.data.User?.user?.small_avatar_file && this.consolidation.data.User?.user?.small_avatar_file.length > 0) {
            this.consolidation.data.User.user.avatar_files = this.consolidation.data.User?.user?.small_avatar_file
          }
          this.setUserAvatar(this.consolidation.data.User)
        }

      },

      _OLD_initConsolidationEdit() {
        this.$store.dispatch('getConsolidationCreate').then(response => {

          let realParamsType = this.$route.params.type

          if (this.$route.params.type === ORDERS_CONSOLIDATION_TYPE.FEDEX) {
            realParamsType = CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_FEDEX.name
          }
          if (this.$route.params.type === ORDERS_CONSOLIDATION_TYPE.DHL_USA) {
            realParamsType = CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_DHL.name
          }
          if (this.$route.params.type === ORDERS_CONSOLIDATION_TYPE.DPD) {
            realParamsType = CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_DPD.name
          }

          let currentDeliveryMethod = null
          this.responseData = this.getRespData(response)

          if (!this.responseData?.promocodes_available) {
            this.showPromoFields = false
          }
          else {
            this.showPromoFields = true
          }

          if (this.$route.params.type !== ORDERS_CONSOLIDATION_TYPE.FROM_YOUR_CITY) {
            currentDeliveryMethod = this._.find(CONSOLIDATION_TYPES_ARRAY, {name: realParamsType})

            //get destination data only for our delivery service
            this.destinationData = this.responseData.destinations.filter((item) => {
              return item.delivery_service_id === currentDeliveryMethod.delivery_service_id
            })

            //temp fix, delete later
            if (currentDeliveryMethod.name === 'fedex' || currentDeliveryMethod.name === 'dhl') {
              this.destinationData = this.destinationData.filter((item) => {
                return item.api !== 1
              })
            }
          }
          else {
            currentDeliveryMethod =  this._.find(CONSOLIDATION_TYPES_ARRAY, {name: CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_FEDEX_FROM_YOUR_CITY.name})

            //get destination data only for from your city type
            this.destinationData = this.responseData.destinations.filter((item) => {
              return CONSOLIDATION_TYPES_FROM_YOUR_CITY_ARRAY.find(findItem => {
                return +findItem.id === +item.id
              })
            })

            this.destinationWarehouseData = this.responseData.destinationWarehouses
            if (this.destinationWarehouseData?.length > 0) {
              this.consolidation.setRecipientWarehouse(this.destinationWarehouseData[0])
            }
          }

          this.orderFees = this.responseData?.order_fees_list.sort((a, b) => {return a.cost - b.cost})

          const query = new this.Query();
          let url = '?';
          let myQuery = query
              .for('posts')
          // let allowedDeliveryServicesIds = CONSOLIDATION_DELIVERY_SERVICES_ARRAY.map(item => {
          //   return item.id
          // }).join(',')
          //
          // console.log(allowedDeliveryServicesIds);

          // myQuery.where('DeliveryServiceIds', allowedDeliveryServicesIds)

          url = url + myQuery.limit(100000).page(1).url().split('?')[1]

          this.$store.dispatch('fetchShippingCompany', url).then(responseDelivery => {
            this.deliveryServices = this.getRespPaginateData(responseDelivery)

            this.$store.dispatch('getConsolidationEdit', this.$route.params.id).then(() => {
              /**
               * CHECK IF CONSOLIDATION TYPE == TYPE IN URL
               */
              if((!this.isFromYourCity && this.$store.getters.getConsolidationItem?.consolidation_destination_id !== currentDeliveryMethod?.id) ||
                  (this.isFromYourCity && this.$store.getters.getConsolidationItem?.consolidation_destination_id !== CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_OWN_CARRIER.id
                      && this.$store.getters.getConsolidationItem?.consolidation_destination_id !== CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_FEDEX_FROM_YOUR_CITY.id
                      && this.$store.getters.getConsolidationItem?.consolidation_destination_id !== CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_DHL_FROM_YOUR_CITY.id) ) {
                return this.$router.push(this.$store.getters.GET_PATHS.notFound)
              }

              if (this.isAdmin) {
                const queryUnion = new this.Query();
                let urlUnion = '?';
                let myQueryUnion = queryUnion
                    .for('posts')

                myQueryUnion.where('byProcessed', false)

                if(this.$store.getters.getConsolidationItem.consolidation_union_id){
                  myQueryUnion.where('orWhereId', this.$store.getters.getConsolidationItem.consolidation_union_id)
                }

                urlUnion = urlUnion + myQueryUnion.limit(10000).page(1).url().split('?')[1]

                this.$store.dispatch('fetchConsolidationUnion', urlUnion).then(responseUnion => {
                  this.consolidationUnions = this.getRespPaginateData(responseUnion)

                  //ВРЕМЕННЫЙ ФИКС, СПРОСИТЬ У БЭКА ПОЧЕМУ АПИ ФЛАЖОК ВКЛЮЧЕН (Вова)
                  if (this.$route.params.type === ORDERS_CONSOLIDATION_TYPE.EU_PRODUCT) {
                    this.destinationData[0].api = 0
                  }
                  this.consolidation.setConsolidationItem(this.$store.getters.getConsolidationItem, this.destinationData, this, this.deliveryServices,
                      this.orderFees, this.consolidationUnions, this.destinationWarehouseData)

                  // Alert if sipping price > $200
                  if(this.$store.getters.getConsolidationItem?.consolidation_price_amount_alert) {
                    let text = {
                      title: '',
                      txt: '',
                      customTxt: this.$store.getters.getConsolidationItem?.consolidation_price_amount_alert,
                      yes: '',
                      no: 'common_cancel'
                    }
                    let confirm = () => {
                      this.$store.commit('setConfirmDeletePopup', false)
                      return true
                    }
                    this.deleteFunc(text, confirm)
                  }

                  this.setWarehouses(currentDeliveryMethod)
                  this.loadButtons = true

                  this.loading = true

                  if (this.consolidation.getOldDeliveryService()) {
                    this.setShippingCompanies(CONSOLIDATION_UNION_SHIPPING_COMPANIES_ARRAY)
                  }
                  else {
                    let userCountry = this.getUserContactDataFromUser(this.consolidation.data.User.getUser())?.country?.id
                    this.$store.dispatch('getShippingCompanyByCountryId', userCountry).then(response => {
                      this.deliveryServices = this.getRespData(response)
                    })
                    // this.setShippingCompanies(CONSOLIDATION_DELIVERY_SERVICES_ARRAY)
                  }

                  //set data so we don't need to rework function
                  if (this.consolidation.data.User?.user?.small_avatar_file && this.consolidation.data.User?.user?.small_avatar_file.length > 0) {
                    this.consolidation.data.User.user.avatar_files = this.consolidation.data.User?.user?.small_avatar_file
                  }
                  this.setUserAvatar(this.consolidation.data.User)
                }).catch(error => this.createErrorLog(error))


              }
              else {
                //ВРЕМЕННЫЙ ФИКС, СПРОСИТЬ У БЭКА ПОЧЕМУ АПИ ФЛАЖОК ВКЛЮЧЕН (Вова)
                if (this.$route.params.type === ORDERS_CONSOLIDATION_TYPE.EU_PRODUCT) {
                  this.destinationData[0].api = 0
                }
                this.consolidation.setConsolidationItem(this.$store.getters.getConsolidationItem, this.destinationData, this, this.deliveryServices, this.orderFees,
                    false, this.destinationWarehouseData)

                this.setWarehouses(currentDeliveryMethod)
                this.loadButtons = true

                this.loading = true

                if (this.consolidation.getOldDeliveryService()) {
                  this.setShippingCompanies(CONSOLIDATION_UNION_SHIPPING_COMPANIES_ARRAY)
                }
                else {
                  let userCountry = this.getUserContactDataFromUser(this.consolidation.data.User.getUser())?.country?.id
                  this.$store.dispatch('getShippingCompanyByCountryId', userCountry).then(response => {
                    this.deliveryServices = this.getRespData(response)
                  })
                  // this.setShippingCompanies(CONSOLIDATION_DELIVERY_SERVICES_ARRAY)
                }
              }
            }).catch(error => this.createErrorLog(error))


          })



        }).catch(error => this.createErrorLog(error))
      },

      setShippingCompanies(allowedShippingCompanies) {
        //change list depending on whether consolidation got a new delivery service from conso union
        this.deliveryServices = this.deliveryServices.filter(item => {
          return allowedShippingCompanies.find(findItem => {
            return findItem.id === item.id
          })
        })
      },

      setWarehouses(currentDeliveryMethod) {
        // If Consolidation Fedex => set warehouses from fetchWarehousesFromConsolidation
        if (currentDeliveryMethod.name === 'fedex' || currentDeliveryMethod.name === 'dpd' || currentDeliveryMethod.name === 'eu_product' || currentDeliveryMethod.name === 'dhl') {
          let myQuery = this.createQueryBuilderObject()
          let userContacts = this.getUserContactDataFromUser(this.consolidation.data.User.user)

          if(this.getUserAlternativeContact(this.consolidation.data.User.user)) {
            userContacts = this.getUserAlternativeContact(this.consolidation.data.User.user)
          }

          let countryGroupIds = userContacts?.country_group?.ids

          myQuery.params({
            delivery_service_id: currentDeliveryMethod.delivery_service_id,
            country_group_id: countryGroupIds && countryGroupIds.length > 0 ? countryGroupIds.join(',') : ''
          })

          let url = this.generateGetParamsFromQuery(myQuery, 1000, 0)

          this.$store.dispatch('fetchWarehousesFromConsolidation', url).then(response => {
            this.consolidation.data.fedexWarehouses = this.getRespData(response)
            this.loadingFedexWarehouses = true
          }).catch(error => this.createErrorLog(error))
        } else {
          this.loadingFedexWarehouses = true
        }
      },

      printPage() {
        window.print()
      },

      createLabel() {
        this.prepareSaveConsolidation({draft: false, edit: true, createLabel: true})
      },

      submitAuthorizeInvoicePay(data){
        this.payAuthorizeInvoice({data: data, invoiceId: this.authorizeInvoiceId}).then(status => {
          if(status){
            this.$store.dispatch('consolidationAfterInvoicePayStatusUpdate', this.consolidation.getId())
          }
          this.authorizePaymentPopup = false

          setTimeout(() => {
            this.changeThankYouPopupPopup(true)
          }, 200)
        })
      },

      afterPay() {
        this.$store.dispatch('consolidationAfterInvoicePayStatusUpdate', this.consolidation.getId())
        this.braintreePaymentPopup = false

        this.changeThankYouPopupPopup(true)
      },

      refundLabel() {

        let text = {
          title: 'common_AreYouSure',
          txt: '',
          yes: 'common_confirm',
          no: 'common_confirmNo'
        }

        let confirm = () => {

          this.$store.dispatch('consolidationRefundLabel', {consolidation_id: this.consolidation.getId()}).then((response) => {
            switch (this.getResponseStatus(response)) {
                /**
                 * Success
                 */
              case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {

                this.$store.commit('setConfirmDeletePopup', false)
                this.consolidation.setLabelFileUUID('') // reset for conso reload to hide docs button
                this.openNotify('success', 'common_notificationRecordChanged')
                this.reloadOrder()

                break
              }
                /**
                 * Validation Error
                 */
              case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
                let errors = response.response.data.errors;
                this.notifyErrorHelper(errors)
                break
              }
                /**
                 * Undefined Error
                 */
              default: {
                this.openNotify('error', 'common_notificationUndefinedError')
              }
            }
          })
        }

        this.deleteFunc(text, confirm)
      },

    }
	}
</script>

<style scoped>

</style>

<style media="print">

.print-btn-head{
  max-width: 167px;
}

@page { size: portrait; }

@media print {
  * {
    -webkit-print-color-adjust: exact;
  }
  body .noPrintable {
    display: none !important;
  }

  .default-layout__page-wrap{
    padding-left: 0 !important;
  }
  .manager-btn{
    display: none !important;
  }

  .detail-cards__section, .detail-cards__aside {
    margin-bottom: 0;
  }

  .site-table-wrap.table-small .site-table {
    min-width: unset;
  }

  .cellsForPrint {
    display: inline !important;
    word-break: keep-all;
  }

  .for-print-table {
    display: block !important;
  }

  .search-engine-block {
    display: none;
  }

  .default-layout__page-wrap {
    min-height: unset;
  }
}
</style>

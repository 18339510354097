<template>
  <AccordBlock ref="fbmScanBlock" >
    <template slot="header">
      <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fbm_scanner'])"></div>
        {{ $t('fbm_scanner.localization_value.value') }}
      </div>
    </template>

    <template slot="body">


      <div class="custom-row">
        <div class="custom-col custom-col--50 custom-col--md-100"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['fbm_scannerHere'])"></div>
          <div class="section-label">
            <DefaultInput
                ref="fbmScanInput"
                    :label="$t('fbm_scannerHere.localization_value.value')"
                    :type="'text'"
                    v-model="scan"
                    :autocompleteOff="true"
                    @change="changeScanner"
            />

          </div>
        </div>

        <div v-if="!isLabel && FBM.data.productsForCellArray.length > 0 && _.has(_.first(FBM.data.productsForCellArray).data.Product.product.productObject, 'id')">
          <div class="custom-col">
            <div class="d-flex align-center" style="height: 42px;">
<!--              <template v-for="(item, index) in warehouses">-->
                <RadioDefault
                    class="white-space-line width-initial mr-3"
                    v-if="FBM.data.Order.data.warehouse"
                    :label="FBM.data.Order.data.warehouse.name"
                    :name="'warehouse'"
                    :value="true"
                    :disabled="true"
                />
<!--              </template>-->
            </div>
          </div>
        </div>
      </div>

      <ProductsAdminBlock
              :OrderModel="FBM"
              :productsForCellArray="FBM.data.productsForCellArray"
              :OrderUser="FBM.data.User"
              :productLabel="'name_for_fbm'"
              :products="FBM.data.Products"

              :count="false"
              :price="!checkSelectedUSACountry"
              :description="!checkSelectedUSACountry"
              :hsCode="!checkSelectedUSACountry"
              :byStorageId="FBM.data.Order.data.warehouse.id"

              :showProductCheckbox="_.has(FBM.data.Order.data.oldStatus,'name') && FBM.data.Order.data.oldStatus.name !== 'completed'"

              @removeProduct="index => removeProduct(null, index)"
              @changeProductName="changeProductName"
              @changeProductQuantity="changeProductQuantity"
              @addProduct="addDefaultProduct"

              @recalculateFees="$emit('recalculateFees')"
      />

    </template>
  </AccordBlock>
</template>

<script>
  import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
  import AccordBlock from "../../../../../../coreComponents/AccordBlock/AccordBlock";
  import {mixinDetictingMobile} from "../../../../../../../mixins/mobileFunctions";
  // import DefaultCheckbox from "../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  // import LinkButton from "../../../../../../UI/buttons/LinkButton/LinkButton";
  // import ProductEditing from "../../../../popups/ProductEditing/ProductEditing";
  import {FBMMixins, FBMProductMixin} from "../../../../../../../mixins/FBMMixins/FBMMixins";
  import ProductsAdminBlock
    from "../../../../../../coreComponents/ProductCellsManageForOrders/ProductsAdminBlock/ProductsAdminBlock";
  import RadioDefault from "@/components/UI/radiobuttons/RadioDefault/RadioDefault";

  export default {
    name: "Scanner",
    components: {
      RadioDefault,
      // ProductEditing,
      // LinkButton,
      // DefaultCheckbox,
      DefaultInput,
      AccordBlock,
      ProductsAdminBlock,
    },

    mixins: [mixinDetictingMobile, FBMProductMixin, FBMMixins],

    props: {
      FBM: Object,
      products: Array,
    },

    mounted() {
      this.getWarehouseStorage(true)

      //[0] is an input
      if (this.$refs?.fbmScanInput?.$el?.children?.length) {
        this.$refs.fbmScanInput.$el.children[0].focus()
      }
      if (this.$refs?.fbmScanBlock?.$el) {
        this.$refs.fbmScanBlock.$el.scrollIntoView({behavior: 'smooth'})
      }
    },

    data() {
      return {
        scan: '',
        warehouses: [],
      }
    },

    methods: {
      changeScanner(val){
        let added = false
        if(val.length > 0){
          this.FBM.data.productsForCellArray.map(item => {
            if(added || item.data.scannedCount === item.data.productCount) return
            if(item.data.Product.product.productObject.upc_code === val || item.data.Product.product.productObject.sku_code === val){
              item.addScannedProduct()
              added = true
            }
          })

          //packaging scanner
          this.FBM.data.packagingTypes.forEach(item => {
            item.packing_prices.forEach(priceItem => {
              if (priceItem.barcode === val) {
                this.FBM.setPackagingAdminPrice(priceItem)
                this.FBM.setPackagingAdminType(item)
              }
            })
          })
          this.FBM.data.giftPackagingTypes.forEach(item => {
            item.packing_prices.forEach(priceItem => {
              if (priceItem.barcode === val) {
                this.FBM.setGiftPackagingAdminPrice(priceItem)
                this.FBM.setGiftPackagingAdminType(item)
              }
            })
          })

          //order price scan
          if (val.toLowerCase().indexOf('fbm-') === 0) {
            let orderPriceId = val.split('-')[1]
            let orderPriceItem = null

            if (orderPriceId) {
              orderPriceItem = this.$store.getters.getOrdersFBMItem.order_fees_list.find(item => {
                return +item.id === +orderPriceId
              })
            }

            if (orderPriceItem) {
              this.FBM.setOrderPrice(orderPriceItem)
            }
            else {
              this.scan = ''
              return
            }
          }

          //skladusa fee scan
          if (val.toLowerCase().indexOf('orf-') === 0) {

            if (val.split('-').length !== 3) {
              this.scan = ''
              return
            }

            let skladusaFeeValue = val.split('-')[2]

            if (skladusaFeeValue) {
              this.FBM.setSkladUsaFee(skladusaFeeValue)
            }
          }

          //skladusa size scan
          if (val.toLowerCase().indexOf('osi-') > -1) {
            this.FBM.data.orderSizes.forEach(item => {
              let scanSize = Number(val.split('-')[1])
              if(scanSize === item.size) {
                this.setFirstAdminDimensions(item.size)
              }
            })
            this.$emit('recalculateOrderFee')
          }

          //skladusa size reset scan
          if (val.toLowerCase().indexOf('reset-s') > -1) {
            this.FBM.data.AdminDimensions.setDimensionsHeight('')
            this.FBM.data.AdminDimensions.setDimensionsWidth('')
            this.FBM.data.AdminDimensions.setDimensionsLength('')
          }

        }

        this.scan = ''
      },

      addDefaultProduct(){
        this.FBM.addProduct()
      },

      setFirstAdminDimensions(size) {
        if(this.FBM.data.AdminDimensions.getDimensionsHeight() === '') {
          this.FBM.data.AdminDimensions.setDimensionsHeight(size)
          return
        }
        if(this.FBM.data.AdminDimensions.getDimensionsWidth() === '') {
          this.FBM.data.AdminDimensions.setDimensionsWidth(size)
          return
        }
        if(this.FBM.data.AdminDimensions.getDimensionsLength() === '') {
          this.FBM.data.AdminDimensions.setDimensionsLength(size)
          return
        }

      },
    },


    // methods: {
    //   toggleProductEditing(val, item) {
    //     this.productEditing = val
    //     this.productEditingItem = item
    //   }
    // }
  }
</script>

<style scoped lang="scss">
  .max-200{
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
  }

  .manage-block{
    min-width: 150px;
    display: flex;
    justify-content: flex-end;

    @media (max-width: 1700px) {
      min-width: auto;
    }
  }

  .site-table-mobile__item{
    background: #f8f4ee;
  }
</style>

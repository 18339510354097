<template>
	<div class="page-content">
		<Breadcrumb/>
    <SearchEngine
        v-if="isAdmin"
    />


		<router-view></router-view>

	</div>
</template>

<script>
	import Breadcrumb from '../../templates/Breadcrumb/Breadcrumb.vue'
  import SearchEngine from "../../coreComponents/SearchEngine/SearchEngine";

	export default {
		name: "ExpressOrdersModule",

		components:{
      SearchEngine,
			Breadcrumb,
		},

    watch: {
      isUserHasEasyOrder(newVal) {
        if(newVal) {
          this.$router.push(this.$store.getters.GET_PATHS.notFound)
        }
      }
    },

		data(){
			return {
			}
		},

    mounted() {
      if(this.isUserHasEasyOrder) {
        this.$router.push(this.$store.getters.GET_PATHS.notFound)
      }
    },

    methods: {

		}

	}
</script>

<style lang="scss">

</style>

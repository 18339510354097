<template>
  <div class="filter-parcel-track">
    <div class="filter-parcel-track__item"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate([
          'consolidationUnion_shippingCompany',
          ])"></div>
      <DefaultSelect
          class="w-100 white border-light"
          :options="options"
          :optionsLabel="'name'"
          :label="$t('consolidationUnion_shippingCompany.localization_value.value')"
          @change="changeShippingCompany"
          :selected="selectedOption"
      />
    </div>
    <div class="filter-parcel-track__item"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate([
          'consolidationUnion_inditateTrackNum',
          ])"></div>
      <InputEditField
          class="w-100 white border-light"
          :label="$t('consolidationUnion_inditateTrackNum.localization_value.value')"
          v-model="trackNumber"
      />
    </div>
    <div class="filter-parcel-track__item wfc"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate([
          'consolidationUnion_setForSelected',
          ])"></div>
      <MainButton class="btn-fit-content white-space-nowrap"
                  :value="$t('consolidationUnion_setForSelected.localization_value.value')"
                  @click.native="$emit('setTrackForSelectedUnions', selectedOption, trackNumber)"
      >
      </MainButton>
    </div>

  </div>
</template>

<script>
import DefaultSelect from "../../../../../../../UI/selectiones/DefaultSelect/DefaultSelect";
import InputEditField from "../../../../../../../UI/inputs/InputEditField/InputEditField";
import MainButton from "../../../../../../../UI/buttons/MainButton/MainButton";
// import {CONSOLIDATION_UNION_SHIPPING_COMPANIES_ARRAY} from "../../../../../../../../staticData/staticVariables";

export default {
  name: "SetTrackNumberForm",

  components: {
    MainButton,
    InputEditField,
    DefaultSelect
  },

  props: {
    options: Array,
  },

  data() {
    return {
      trackNumber: '',
      selectedOption: null
    }
  },

  // created() {
  //   this.$store.dispatch('getConsolidationUnionCreate').then(response => {
  //     let respData = this.getRespData(response)
  //     this.options = respData.delivery_services.filter(item => {
  //       return CONSOLIDATION_UNION_SHIPPING_COMPANIES_ARRAY.find(findItem => {
  //         return findItem.id === item.id
  //       })
  //     })
  //   })
  // },

  methods: {
    changeShippingCompany(val) {
      this.selectedOption = val
    },
  }
}
</script>

<style scoped lang="scss">
@import "../../../../../../../../scss/mixins/mixins";

.filter-parcel-track{
  display: flex;
  width: 100%;

  @include for-1120{
    flex-direction: column;
  }

  @include for-680{
    margin-bottom: 15px;
  }

  &__item{
    max-width: 314px;
    width: 100%;
    min-width: 220px;
    display: flex;
    margin-right: 15px;
    align-items: center;

    ::v-deep .edit-field-input__btn {
      cursor: default;
    }

    &.wfc{
      min-width: initial;
    }

    @include for-1120{
      margin-bottom: 15px;

      &:last-child{
        margin-bottom: 0;
      }
    }

    @include for-680{
      max-width: 100%;
    }


    button{
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      height: 40px;
    }
  }
}
</style>
<template>
  <modal
      @close="$emit('close')"
      class="custom-popup-modal custom-popup"
  >
    <template slot="header">
      <div
          v-if="type !== 'accept'"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['heartysan_dealShow'])"></span>
        {{$t('heartysan_dealShow.localization_value.value')}}
      </div>
      <div
          v-if="type === 'accept'"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['heartysan_dealAccept'])"></span>
        {{$t('heartysan_dealAccept.localization_value.value')}}
      </div>
    </template>
    <template slot="body">
      <div class="custom-popup__content">
        <div class="custom-row">
          <div class="custom-col">
            <div class="block-table__label admin-edit-item">
              <span class="admin-edit" @click="editTranslate(['heartysan_dealPrice'])"></span>
              {{ $t('heartysan_dealPrice.localization_value.value') }}
            </div>
            <div class="block-table__content">
              <ValueHelper
                  :value="Deal.data.price"
              />
            </div>
          </div>

          <div class="custom-col" v-if="(type === 'canCancel' && (isCanceledByManufacturer || isCanceledBySeller)) || type === 'reCreate'">
            <div class="block-table__content">
              <div class="color-red" v-if="type === 'canCancel'">
                {{ $t('heartysan_dealRequestForCancel.localization_value.value') }}
              </div>
              <div class="color-red" v-if="type === 'reCreate'">
                {{ $t('heartysan_dealCanceled.localization_value.value') }}
              </div>
            </div>
          </div>

        </div>
      </div>
    </template>
    <template slot="footer">
      <div class="custom-popup__btn align-items-center d-flex flex-wrap"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['common_cancel','common_create'])"></span>
        <span
            class="site-link site-link--alt custom-popup__btn-i mr-4 mt-3 mb-3"
            @click="$emit('close')"
        >
          {{$t('common_cancel.localization_value.value')}}
        </span>
        <MainButton
            v-if="type === 'deaccept'"
            class="custom-popup__btn-i wfc "
             :value="$t('heartysan_dealDeaccept.localization_value.value')"
            @click.native="deaccept"
        />
        <MainButton
            v-if="type === 'accept'"
            class="custom-popup__btn-i wfc "
             :value="$t('heartysan_dealAccepted.localization_value.value')"
            @click.native="accept"
        />
        <MainButton
            v-if="type === 'canCancel'"
            class="custom-popup__btn-i wfc "
             :value="$t('heartysan_dealCancel.localization_value.value')"
            @click.native="cancel"
        />
        <MainButton
            v-if="type === 'reCreate' && !isUserHeartyManufacturer"
            class="custom-popup__btn-i wfc "
             :value="$t('heartysan_dealReCreate.localization_value.value')"
            @click.native="reCreate"
        />
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from "../../../../commonModals/Modal";
  import MainButton from "../../../../UI/buttons/MainButton/MainButton";
  import {HeartysanDeal} from "@/components/modules/HeartysanChatModule/models/HeartysanDeal";
  import ValueHelper from "@/components/coreComponents/ValueHelper/ValueHelper";

  export default {
    name: "ShowDealPopup",
    components: {
      ValueHelper,
      Modal,
      MainButton,
    },

    props: {
      currentChat: Object,
      deal: Object,
      type: String,
      isUserHeartyManufacturer: Boolean,
      isCanceledBySeller: Boolean,
      isCanceledByManufacturer: Boolean,
      id: Number,
    },

    data() {
      return {
        Deal: new HeartysanDeal(),
      }
    },

    mounted() {
      // if(this.type === 'edit') {
        this.$store.dispatch('getHeartysanDeal', this.id).then(response => {
          let data = this.getRespData(response).deal
          this.Deal.setItem(data)
        }).catch(error => this.createErrorLog(error))
      // }
    },

    methods: {
      accept() {
        if(!this.Deal.firstValidation()) return

        let data = this.id
        let typeDispatch = ''

        if(this.isUserHeartyManufacturer) {
          typeDispatch = 'heartisanManufacturerAccept'
          data = {
            id: data,
            data: {
              deal_price: this.Deal.getPrice(this.currentChat)
            }
          }
        }

        if(!this.isUserHeartyManufacturer) {
          typeDispatch = 'heartisanSellerAccept'
        }

        this.$store.dispatch(typeDispatch, data).then(response => {
          switch (this.getResponseStatus(response)) {
              /**
               * Success
               */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {

              if(this.isUserHeartyManufacturer) {
                this.sendFirstMessage(this.currentChat, 'acceptManufacturer')
              }
              if(!this.isUserHeartyManufacturer) {
                this.sendFirstMessage(this.currentChat, 'acceptSeller')
              }

              this.openNotify('success', 'common_notificationRecordChanged')
              this.$emit('close')
              this.$emit('reload')
              break
            }
              /**
               * Validation Error
               */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
              /**
               * Undefined Error
               */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }
        })
      },

      deaccept() {
        if(!this.Deal.firstValidation()) return

        let id = this.id
        let typeDispatch = 'heartisanManufacturerDeaccept'

        this.$store.dispatch(typeDispatch, id).then(response => {
          switch (this.getResponseStatus(response)) {
              /**
               * Success
               */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {

              this.sendFirstMessage(this.currentChat, 'deacceptManufacturer')

              this.openNotify('success', 'common_notificationRecordChanged')
              this.$emit('close')
              this.$emit('reload')
              break
            }
              /**
               * Validation Error
               */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
              /**
               * Undefined Error
               */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }
        })
      },

      cancel() {
        let text = {
          title: 'common_AreYouSure',
          txt: '',
          yes: 'common_confirmDelete',
          no: 'common_confirmNo'
        }

        let confirm = () => {

          if(!this.Deal.firstValidation()) return

          let id = this.id
          let typeDispatch = ''

          if(this.isUserHeartyManufacturer) {
            typeDispatch = 'heartisanManufacturerCancel'
          }

          if(!this.isUserHeartyManufacturer) {
            typeDispatch = 'heartisanSellerCancel'
          }

          this.$store.dispatch(typeDispatch, id).then(response => {
            switch (this.getResponseStatus(response)) {
                /**
                 * Success
                 */
              case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {

                if(this.isUserHeartyManufacturer) {
                  this.sendFirstMessage(this.currentChat, 'cancelManufacturer')
                }
                if(!this.isUserHeartyManufacturer) {
                  this.sendFirstMessage(this.currentChat, 'cancelSeller')
                }

                this.openNotify('success', 'common_notificationRecordChanged')
                this.$emit('close')
                this.$emit('reload')
                break
              }
                /**
                 * Validation Error
                 */
              case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
                let errors = response.response.data.errors;
                this.notifyErrorHelper(errors)
                break
              }
                /**
                 * Undefined Error
                 */
              default: {
                this.openNotify('error', 'common_notificationUndefinedError')
              }
            }
          })

          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)


      },

      reCreate() {
        if(!this.Deal.firstValidation()) return

        let id = this.id
        let typeDispatch = 'heartisanDealReactivate'

        this.$store.dispatch(typeDispatch, id).then(response => {
          switch (this.getResponseStatus(response)) {
              /**
               * Success
               */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {

              this.sendFirstMessage(this.currentChat, 'recreatedDeal')

              this.openNotify('success', 'common_notificationRecordChanged')
              this.$emit('close')
              this.$emit('reload')
              break
            }
              /**
               * Validation Error
               */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
              /**
               * Undefined Error
               */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }
        })
      },


      sendFirstMessage(chat, type = false) {
        if(!type) return

        let message = ''
        switch (type) {
          case 'acceptManufacturer' :
            message = this.$t('heartysan_manufacturerAcceptDeal.localization_value.value') + ' ($' + this.Deal.data.price + ')'
            break
          case 'acceptSeller' :
            message = this.$t('heartysan_sellerAcceptDeal.localization_value.value') + ' ($' + this.Deal.data.price + ')'
            break
          case 'deacceptManufacturer' :
            message = this.$t('heartysan_deacceptManufacturer.localization_value.value') + ' ($' + this.Deal.data.price + ')'
            break
          case 'cancelManufacturer' :
            message = this.$t('heartysan_cancelManufacturer.localization_value.value')
            if(this.Deal.data.status !== 'canceled') message += '. ' + this.$t('heartysan_cancelConfirm.localization_value.value')
            break
          case 'cancelSeller' :
            message = this.$t('heartysan_cancelSeller.localization_value.value')
            if(this.Deal.data.status !== 'canceled') message += '. ' + this.$t('heartysan_cancelConfirm.localization_value.value')
            break
          case 'recreatedDeal' :
            message = this.$t('heartysan_recreatedDealSeller.localization_value.value') + ' ($' + this.Deal.data.price + ')'
            break
        }

        let data = {
          'message': message,
          'system': 1,
        }

        let typeDispatch = 'createMessageHeartysanChat'

        this.$store.dispatch(typeDispatch, {data: data, id: chat.id}).then(response => {
          let resp = this.getRespData(response)
          this.$router.push(this.$store.getters.GET_PATHS.heartysanChat + `?chatId=${resp.chat.id}&productId=${resp.chat.product.id}`)
        })
      },
    }
  }
</script>

<style scoped>

</style>

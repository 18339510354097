<template>
  <DisposalOrdersCreationUser
      :DO="DO"
      @prepareSave="prepareSave"
      @saveInDraft="saveInDraft"
  />
</template>

<script>
  import DisposalOrdersCreationUser from "./DisposalOrdersCreationBlock/DisposalOrdersCreationBlock.vue";
  import { DisposalOrders } from "../../models/DisposalOrders";
  import {disposalMixin} from "@/mixins/disposalMixins/disposalMixin";

  export default {
    name: "DisposalOrdersCreation",

    components:{
      DisposalOrdersCreationUser,
    },

    mixins: [disposalMixin],

    watch: {
      loadUserAuthorizedData() {
        this.DO.data.User.setUser(this.loadUserAuthorizedData.user);
      }
    },
    data() {
      return {
        DO: new DisposalOrders(),
      }
    },
    mounted() {
      if (this.loadUserAuthorizedData && !this.isAdmin) {
        this.DO.data.User.setUser(this.loadUserAuthorizedData.user);
      }
    },
    methods: {

    },
  }
</script>

<style scoped>

</style>

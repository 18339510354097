<template>
  <div>
    <template v-if="$store.getters.getInvoices.length > 0 && $store.getters.getInvoicesLoading !== true">
      <div class="site-table-wrap invoices-table"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
            'invoices_ID',
            'invoices_Date',
            'common_user',
            'invoices_OrderNumber',
            'invoices_Payment',
            'invoices_TrackParcelNumber',
            'invoices_Packaging',
            'fbm_shippingCost',
            'fbm_GiftMessage',
            'fbm_engravingPrice',
            'fba_skladUsaFee',
            'fbm_OrderPrice',
            'fba_skladUsaFee',
            'invoices_fbmSkladUSAInsurance',
            'invoices_fbmInsuranceEasyPost',
            'invoices_Total',
            'common_manage',
          ])"></div>
        <div class="site-table-wrap invoices-table" v-if="!isMobileFunc()">
          <table class="site-table">
            <thead>
            <tr>
              <th></th>
              <th>
                {{ $t('invoices_ID.localization_value.value') }}
                <SortButton :sortParams="sortParams" :defaultSort="'DESC'" :sortName="'sortId'" @changeSort="data => $emit('changeSort', data)"/>
              </th>
              <th>
                {{ $t('invoices_Date.localization_value.value') }}
                <SortButton :sortParams="sortParams" :sortName="'sortDate'" @changeSort="data => $emit('changeSort', data)"/>
              </th>
              <th v-if="isAdmin">
                {{$t('common_user.localization_value.value')}}
              </th>
              <th>
                {{ $t('invoices_OrderNumber.localization_value.value') }}
                <SortButton :sortParams="sortParams" :sortName="'sortOrderNumber'" @changeSort="data => $emit('changeSort', data)"/>
              </th>
              <th>
                {{ $t('invoices_Payment.localization_value.value') }}
                <SortButton :sortParams="sortParams" :sortName="'sortPayment'" @changeSort="data => $emit('changeSort', data)"/>
              </th>
              <th>
                {{ $t('invoices_TrackParcelNumber.localization_value.value') }}
              </th>
              <th v-if="isAdmin">
                {{ $t('invoices_SkladusaCost.localization_value.value') }}
              </th>
              <th v-if="isAdmin">
                {{ $t('invoices_ConsolidationCost.localization_value.value') }}
              </th>
              <th v-if="!isUserHasEasyOrder">
                {{ $t('fbm_shippingCost.localization_value.value') }}
                <SortButton :sortParams="sortParams" :sortName="'sortShipping'" @changeSort="data => $emit('changeSort', data)"/>
              </th>
              <th v-if="!isUserHasEasyOrder">
                {{ $t('fbm_OrderPrice.localization_value.value') }}
                <SortButton :sortParams="sortParams" :sortName="'sortOrder'" @changeSort="data => $emit('changeSort', data)"/>
              </th>
              <th v-if="!isUserHasEasyOrder">
                {{ $t('invoices_Packaging.localization_value.value') }}
                <SortButton :sortParams="sortParams" :sortName="'sortPackaging'" @changeSort="data => $emit('changeSort', data)"/>
              </th>
              <th v-if="!isUserHasEasyOrder">
                {{ $t('fbm_GiftMessage.localization_value.value') }}
                <SortButton :sortParams="sortParams" :sortName="'sortGiftMessage'" @changeSort="data => $emit('changeSort', data)"/>
              </th>
              <th v-if="!isUserHasEasyOrder">
                {{ $t('fbm_engravingPrice.localization_value.value') }}
                <SortButton :sortParams="sortParams" :sortName="'sortCustomization'" @changeSort="data => $emit('changeSort', data)"/>
              </th>
              <th v-if="!isUserHasEasyOrder">
                {{ $t('fba_skladUsaFee.localization_value.value') }}
                <SortButton :sortParams="sortParams" :sortName="'sortSkladUSAFee'" @changeSort="data => $emit('changeSort', data)"/>
              </th>
              <th v-if="!isUserHasEasyOrder">
                {{ $t('invoices_fbmSkladUSAInsurance.localization_value.value') }}
                <SortButton :sortParams="sortParams" :sortName="'sortSkladUSAInsurance'" @changeSort="data => $emit('changeSort', data)"/>
              </th>
              <th v-if="!isUserHasEasyOrder">
                {{ $t('invoices_fbmInsuranceEasyPost.localization_value.value') }}
                <SortButton :sortParams="sortParams" :sortName="'sortInsurance'" @changeSort="data => $emit('changeSort', data)"/>
              </th>
              <th>
                {{ $t('invoices_Total.localization_value.value') }}
                <SortButton :sortParams="sortParams" :sortName="'sortTotal'" @changeSort="data => $emit('changeSort', data)"/>
              </th>
              <th :style="{width: isUserHasEasyOrder ? '180px' : 'initial'}">
                {{$t('common_manage.localization_value.value')}}
              </th>
            </tr>
            </thead>
            <tbody>

            <tr v-for="(item, index) in $store.getters.getInvoices" :key="index">
              <td>
                <DefaultCheckbox
                        class="empty-label"
                        :selectedNow="selectedNow"
                        :dataValue="item.id"
                />
              </td>
              <td>
                <span class="table-link btn-style"
                      @click="showInvoice(item.id)"
                >
                   #{{item.id}}
                </span>
              </td>
              <td>{{ item.created_at | moment("DD MMM, YYYY") }}</td>
              <td v-if="isAdmin">
                <TableUserColumn
                        :item="item"
                />
              </td>
              <td>
                <template v-if="item.invoice_instance && item.invoice_instance.instance && isUserHasEasyOrder">
                  <router-link
                      class="table-link btn-style"
                      :to="$store.getters.GET_PATHS.easyOrderShow + '/' + getOrderFBMTypeById(item.invoice_instance.instance.order_type_id).name + '/' + item.invoice_instance.instance.id"
                  >
                    {{item.invoice_instance.instance.id}}
                  </router-link>
                </template>
                <template v-else-if="item.invoice_instance && item.invoice_instance.instance">
                  <router-link
                          class="table-link btn-style"
                          :to="$store.getters.GET_PATHS.ordersFBM + '/show/' + getOrderFBMTypeById(item.invoice_instance.instance.order_type_id).name + '/' + item.invoice_instance.instance.id"
                  >
                    {{item.invoice_instance.instance.id}}
                  </router-link>
                </template>
                <template v-if="item.invoice_instance && !item.invoice_instance.instance">
                  {{item.invoice_instance.model_id}}
                </template>
              </td>
              <td>
                <div class="d-flex align-items-center" >

                  <TooltipTextHelper :fullTextWidth="300">
                    <template slot="text">
                      <template v-if="item.status === 'payed'">
                        <StatusIcoBtn :type="'active'" class="mr-1"/>  {{$t('common_Paid.localization_value.value')}}
                      </template>
                      <template v-else>
                        <StatusIcoBtn :type="'cancel'" class="mr-1"/>  {{$t('status_Canceled.localization_value.value')}}
                      </template>
                    </template>
                    <template slot="paragraph">
                      <div class="status-tooltip">
                        <template v-if="item.status === 'payed'">
                          <StatusIcoBtn :type="'active'" class="mr-1"/>  {{$t('common_Paid.localization_value.value')}}
                        </template>
                        <template v-else>
                          <StatusIcoBtn :type="'cancel'" class="mr-1"/>  {{$t('status_Canceled.localization_value.value')}}
                        </template>
                      </div>
                    </template>
                  </TooltipTextHelper>

                </div>
              </td>
              <td>
                <TooltipTextHelper :fullTextWidth="80">
                  <template slot="text">
                    <ValueHelper :value="item" :deep="'invoice_instance.instance.tracking_number'"/>
                  </template>
                  <template slot="paragraph">
                    <ValueHelper :value="item" :deep="'invoice_instance.instance.tracking_number'"/>
                  </template>
                </TooltipTextHelper>
              </td>
              <td v-if="isAdmin">
                <ValueHelper :value="item"
                             :deep="'real_shipping_cost'"
                             :costType="true"
                             :costCurrencyType="'dollar'"
                />
              </td>
              <td v-if="isAdmin">
                <ValueHelper :value="item"
                             :deep="'consolidation_cost'"
                             :costType="true"
                             :costCurrencyType="'dollar'"
                />
              </td>
              <td class="text-right" v-if="!isUserHasEasyOrder">
                <ValueHelper
                        :value="item"
                        :deep="'net_cost_amount'"
                        :costType="true"
                        :costCurrencyType="'dollar'"
                />
              </td>
              <td class="text-right" v-if="!isUserHasEasyOrder">
                <ValueHelper
                    :value="item.order_price_amount"
                    :costType="true"
                    :costCurrencyType="'dollar'"
                />
              </td>
              <td class="text-right" v-if="!isUserHasEasyOrder">
                <ValueHelper
                    :value="item"
                    :deep="'packaging_amount'"
                    :costType="true"
                    :costCurrencyType="'dollar'"
                />
              </td>
              <td class="text-right" v-if="!isUserHasEasyOrder">
                <ValueHelper
                        :value="item.gift_message_amount"
                        :costType="true"
                        :costCurrencyType="'dollar'"
                />
              </td>
              <td class="text-right" v-if="!isUserHasEasyOrder">
                <ValueHelper
                    v-if="item.on_demand_cost && Number(item.on_demand_cost) > 0"
                        :value="item.on_demand_cost"
                        :costType="true"
                        :costCurrencyType="'dollar'"
                />
                <template v-else>—</template>
<!--                <ValueHelper-->
<!--                    v-if="item.embroidery_cost && Number(item.embroidery_cost) > 0"-->
<!--                        :value="item.embroidery_cost"-->
<!--                        :costType="true"-->
<!--                        :costCurrencyType="'dollar'"-->
<!--                />-->
<!--                <ValueHelper-->
<!--                    v-if="item.tshirt_painting_cost && Number(item.tshirt_painting_cost) > 0"-->
<!--                        :value="item.tshirt_painting_cost"-->
<!--                        :costType="true"-->
<!--                        :costCurrencyType="'dollar'"-->
<!--                />-->
              </td>
              <td class="text-right" v-if="!isUserHasEasyOrder">
                <ValueHelper
                        :value="item"
                        :deep="'fee_amount'"
                        :costType="true"
                        :costCurrencyType="'dollar'"
                />
              </td>
              <td class="text-right" v-if="!isUserHasEasyOrder">
                <ValueHelper
                        :value="item"
                        :deep="'summary_data.insurance_sklad_usa'"
                        :costType="true"
                        :costCurrencyType="'dollar'"
                />
              </td>
              <td class="text-right" v-if="!isUserHasEasyOrder">
                <ValueHelper
                        :value="item"
                        :deep="'summary_data.insurance_from_delivery_system'"
                        :costType="true"
                        :costCurrencyType="'dollar'"
                />
              </td>
              <td :class="{'text-right' : !isUserHasEasyOrder}">
                <ValueHelper
                        :value="item.total_amount"
                        :costType="true"
                        :costCurrencyType="'dollar'"
                />
              </td>

              <td>
                <div class="table-right table-manage-list">
                  <ManagerButton @reloadManagerBtn="$store.commit('setGlobalUpdateManagerButton', Math.random()) " :key="$store.getters.getGlobalUpdateManagerButton">
                    <template slot="item">
                      <LinkButton
                          :value="$t('mypayments_printInvoice.localization_value.value')"
                          :type="'print'"
                          :datePicker="true"
                          @changeDate="changeDate"
                          @datePickerBtn="$emit('printInvoice', {id: [item.id], date: dateInvoice})"
                          :popup-class="'mobile-center'"
                          :datePickerRange="false"
                          :datePickerTxt="$t('invoices_print.localization_value.value')"
                          :datePickerFormat="'YYYY-MM-DD'"
                      />
                      <LinkButton
                          v-if="isAdmin && item.status === INVOICES_STATUS_PAYED.value"
                          :value="$t(`invoices_refund.localization_value.value`)"
                          :type="'return'"
                          @click.native="$emit('refundInvoice', item.id)"
                      />
                      <LinkButton
                          v-if="isAdmin"
                          :value="$t(`common_edit.localization_value.value`)"
                          :type="'edit'"
                          :link="$store.getters.GET_PATHS.financeInvoices + '/edit/' + item.id"
                      />
                    </template>
                  </ManagerButton>

                </div>
              </td>
            </tr>

            </tbody>
          </table>

        </div>


        <div class="table-card" v-else>
          <div class="table-card__list">
            <div class="table-card__item"
                 v-for="(item, index) in $store.getters.getInvoices" :key="index">
              <InvoicesFBMTableMobile
                      :item="item"
                      :selectedNow="selectedNow"
                      @showInvoice="showInvoice(item.id)"
                      @changeDate="(date) => changeDate(date)"
                      @printInvoice="$emit('printInvoice', {id: [item.id], date: dateInvoice})"
                      @refundInvoice="(data) => $emit('refundInvoice', data)"
              />
            </div>
          </div>
        </div>

        <div class="table-bottom-btn">
          <div class="table-bottom-btn__inner">
            <ShowMore
                v-if="$store.getters.getInvoicesCommonList.next_page_url !== null && $store.getters.getInvoices.length > 0"
                @click.native="$emit('showMore')"
                v-bind:class="{'disabled-btn' : $store.getters.getNextInvoicesPage}"
                :count="$store.getters.getInvoicesCommonList.total - $store.getters.getInvoicesForPage * $store.getters.getInvoicesCommonList.current_page < $store.getters.getInvoicesForPage ?
                  $store.getters.getInvoicesCommonList.total - $store.getters.getInvoicesForPage * $store.getters.getInvoicesCommonList.current_page:
                  $store.getters.getInvoicesForPage"
            />
            <ExportBtn
                :customType="_.has($store.getters.getInvoicesCommonList, 'addition_export') ? $store.getters.getInvoicesCommonList.addition_export : null"
                @downloadFiles="type => $emit('downloadFiles', type)"
                class="table-bottom-btn__right"/>
          </div>
        </div>

      </div>
    </template>

    <template v-if="$store.getters.getInvoicesLoading === false && $store.getters.getInvoices.length === 0">
      <NoResult
              :countFilterParams="countFilterParams"
              :title="$t('common_noDataYet.localization_value.value')"
              :titleWithFilter="$t('common_noResult.localization_value.value')"
              :text="$t('common_noDataYetTxt.localization_value.value')"
              :textWithFilter="$t('common_noResultSeems.localization_value.value')"
              @resetFilter="$emit('resetFilter')"
      />
    </template>


    <InfoPopup
            v-if="showPopup"
            :typeInvoice="'FBM'"
            @close="toggleShowPopup(false)"
    />
  </div>
</template>

<script>
  import DefaultCheckbox from "../../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import StatusIcoBtn from "../../../../../../../UI/status/StatusIcoBtn/StatusIcoBtn";
  import LinkButton from "../../../../../../../UI/buttons/LinkButton/LinkButton";
  import {mixinDetictingMobile} from "../../../../../../../../mixins/mobileFunctions";
  import NoResult from "../../../../../../../coreComponents/NoResult/NoResult";
  import InfoPopup from "../../../../../popups/InfoPopup/InfoPopup";
  import TableUserColumn from "../../../../../../../coreComponents/TableComponents/TableUserColumn/TableUserColumn";
  import TooltipTextHelper from "../../../../../../../coreComponents/TooltipTextHelper/TooltipTextHelper";
  import ValueHelper from "../../../../../../../coreComponents/ValueHelper/ValueHelper";
  import InvoicesFBMTableMobile from "./InvoicesFBMTableMobile/InvoicesFBMTableMobile";
  import {FBA_ORDER_STATUSES} from "../../../../../../../../staticData/staticVariables";
  import {FBMMixinsHelper} from "../../../../../../../../mixins/FBMMixins/FBMMixins";
  import {INVOICES_STATUS_PAYED} from "../../../../../../../../staticData/staticVariables";
  import ManagerButton from "../../../../../../../UI/buttons/ManagerButton/ManagerButton";
  import ShowMore from "@/components/coreComponents/Buttons/ShowMore/ShowMore";
  import ExportBtn from '../../../../../../../coreComponents/Buttons/Export/Export'
  import SortButton from "@/components/UI/buttons/SortButton/SortButton";

  export default {
    name: "InvoicesFBATable",
    components: {
      SortButton,
      ShowMore,
      ManagerButton,
      InvoicesFBMTableMobile,
      ValueHelper,
      TooltipTextHelper, TableUserColumn, InfoPopup, NoResult, LinkButton, StatusIcoBtn, DefaultCheckbox,
      ExportBtn,
    },

    mixins: [mixinDetictingMobile, FBMMixinsHelper],

    props: {
      selectedNow: Boolean,
      countFilterParams: Number,
      sortParams: Object,
    },

    data() {
      return {
        showPopup: false,
        dateInvoice: this.$moment().format('YYYY-MM-DD'),
        FBA_ORDER_STATUSES: FBA_ORDER_STATUSES,
        INVOICES_STATUS_PAYED: INVOICES_STATUS_PAYED,
      }
    },

    methods: {

      toggleShowPopup(val) {
        this.showPopup = val
      },

      showInvoice(id) {
        this.$store.dispatch('getInvoices', id).then((response) => {
          if(this._.has(response, 'data') && response.data.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE){
            this.toggleShowPopup(true)
          }
        })
      },

      changeDate(value) {
        this.dateInvoice = value
      },

    }

  }
</script>

<style lang="scss" scoped>

.site-table thead th:first-child,
.site-table tbody td:first-child {
  padding-left: 3px;
}

.site-table thead th,
.site-table tbody td {
  padding: 20px 6px;
}

  .invoices-table{
    td{
      white-space: nowrap;
    }

    @media (min-width: 1700px) {
      /*th:last-child,*/
      /*td:last-child{*/
        /*padding-left: 150px;*/
      /*}*/

      .center-padding{
        width: 30%;
      }
    }
  }


   .table-manage-list .manager-btn {
    width: 168px;
  }

</style>

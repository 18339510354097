<template>
  <div class="fragment">
    <div
        v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['fbm_addWeightScale'])"></div>
      <MainButton
          v-if="canUseScale && !foundDevice"
          class="mb-3 wfc"
          :value="$t('fbm_addWeightScale.localization_value.value')"
          @click.native="addWeightScaleDevice"
      />
    </div>

    <div class="order-create__row custom-row">
      <div class="order-create__col custom-col custom-col--66 custom-col--md-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
                          'packaging_NamePackage',
                        ])"></div>
        <SearchSelect
            :options="$store.getters.getProfilesPackaging"
            :label="$t('packaging_NamePackage.localization_value.value')"
            v-bind:class="{'ui-no-valid': packaging.validation.name || packNameError}"
            :errorTxt="serverError ? packaging.validationTxt.name : $t(`${packaging.validationTranslate.name}.localization_value.value`)"
            :error="packaging.validation.name"
            :selected="packaging.packaging.name"
            :optionsLabel="'package_name'"
            :typeSelect="'packaging'"
            :functionSearch="functionSearchPackaging"
            :disabled="allDisabled"
            @change="changePackaging"
        />
        <div class="color-orange-validation mt-2" v-if="packNameError">
          {{$t(`error_required.localization_value.value`)}}
        </div>
      </div>
      <div class="order-create__col custom-col custom-col--33 mb-0 custom-col--md-100"></div>
      <div class="order-create__col custom-col custom-col--16 custom-col--md-25 custom-col--sm-50"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
                          'packaging_Weight',
                        ])"></div>
        <DefaultInput
            :label="$t('packaging_Weight.localization_value.value')"
            :type="'text'"
            :placeholder="'e.g. 10'"
            v-bind:class="{'ui-no-valid': packaging.validation.weight}"
            :errorTxt="serverError ? packaging.validationTxt.weight : $t(`${packaging.validationTranslate.weight}.localization_value.value`)"
            :error="packaging.validation.weight"
            :disabled="allDisabled"
            v-model="packaging.packaging.weight"
            @input.native="changePackagingValue($event, true)"
        />
      </div>
      <div class="order-create__col custom-col custom-col--16 custom-col--md-25 custom-col--sm-50"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
                          'packaging_Height',
                        ])"></div>
        <DefaultInput
            :label="$t('packaging_Height.localization_value.value')"
            :type="'text'"
            :placeholder="'e.g. 10'"
            v-bind:class="{'ui-no-valid': packaging.validation.height}"
            :errorTxt="serverError ? packaging.validationTxt.height : $t(`${packaging.validationTranslate.height}.localization_value.value`)"
            :error="packaging.validation.height"
            :disabled="allDisabled"
            v-model="packaging.packaging.height"
            @input="changePackagingValue"
        />
      </div>
      <div class="order-create__col custom-col custom-col--16 custom-col--md-25 custom-col--sm-50"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
                          'packaging_Width',
                        ])"></div>
        <DefaultInput
            :label="$t('packaging_Width.localization_value.value')"
            :type="'text'"
            :placeholder="'e.g. 10'"
            v-bind:class="{'ui-no-valid': packaging.validation.width}"
            :errorTxt="serverError ? packaging.validationTxt.width : $t(`${packaging.validationTranslate.width}.localization_value.value`)"
            :error="packaging.validation.width"
            :disabled="allDisabled"
            v-model="packaging.packaging.width"
            @input="changePackagingValue"
        />
      </div>
      <div class="order-create__col custom-col custom-col--16 custom-col--md-25 custom-col--sm-50"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
                          'packaging_Length',
                        ])"></div>
        <DefaultInput
            :label="$t('packaging_Length.localization_value.value')"
            :type="'text'"
            :placeholder="'e.g. 10'"
            v-bind:class="{'ui-no-valid': packaging.validation['packageLength']}"
            :errorTxt="serverError ? packaging.validationTxt['packageLength'] : $t(`${packaging.validationTranslate['packageLength']}.localization_value.value`)"
            :error="packaging.validation['packageLength']"
            :disabled="allDisabled"
            v-model="packaging.packaging['length']"
            @input="changePackagingValue"
        />
      </div>

      <div class="order-create__col custom-col custom-col--16 custom-col--md-25 custom-col--sm-50 position-relative"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
                          'express_Volume',
                        ])"></div>
        <DefaultInput
            :label="$t('express_Volume.localization_value.value')"
            :type="'text'"
            :placeholder="''"
            v-bind:class="{'ui-no-valid': packaging.validation.volumeWeight}"
            :errorTxt="serverError ? packaging.validationTxt.volumeWeight : $t(`${packaging.validationTranslate.volumeWeight}.localization_value.value`)"
            :error="packaging.validation.volumeWeight"
            :disabled="true"
            v-model="packaging.packaging.volumeWeight"
        />

        <div class="order-create__product-add-to-profile"
             v-if="$store.getters.getIsAdminRights === 'user' &&
                 showAddProfile"
             @click="addToProfile">

          <template>
            <v-popover
                class="site-tooltip"
                offset="5"
                placement="top"
                trigger="hover"
            >
                      <span>
                        <AddToProfileIco/>
                      </span>
              <template slot="popover">
                {{$t('common_AddProfile.localization_value.value')}}
              </template>
            </v-popover>
          </template>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import SearchSelect from "../../UI/selectiones/SearchSelect/SearchSelect";
import DefaultInput from "../../UI/inputs/DefaultInput/DefaultInput";
import AddToProfileIco from '../../../../public/img/UI-group/add-to-profile-ico.svg?inline'
import {packagingMixin} from "../../../mixins/packagingMixins/packagingMixin";
import MainButton from "@/components/UI/buttons/MainButton/MainButton";
import {scaleMixins} from "@/mixins/scaleMixins/scaleMixin";
export default {
  name: "PackagingBlock",

  components: {
    MainButton,
    DefaultInput,
    SearchSelect,
    AddToProfileIco
  },

  mixins: [packagingMixin, scaleMixins],

  props: {
    packaging: Object,
    userId: {
      type: [Number, String],
      default: null
    },
    canUseScale: {
      type: Boolean,
      default: false
    },
    allDisabled: {
      type: Boolean,
      default: false
    },
  },

  watch: {
    userId: function (){
      this.getProformPackaging()
    },
    loadUserAuthorizedData: function() {
      this.getProformPackaging()
    },
  },

  destroyed() {
    if(navigator.hid) {
      navigator.hid.getDevices().then(devices => {
        let device = this._.find(devices, {vendorId: 2338})
        if(device) {
          device.close();
        }
      })
    }
  },

  data() {
    return {
      options: [{}],
      serverError: false,
      packNameError: false,


      weightScaleFirst: 0,
      weightScaleSecond: 0,
      foundDevice: false,
      productId: '',
      deviceDataArr: [],
    }
  },

  computed: {
    showAddProfile(){
      return this.packaging.packaging.weight !== '' && this.packaging.packaging.height !== '' && this.packaging.packaging.width !== ''
          && this.packaging.packaging.length !== ''
    }
  },

  async mounted() {
    this.getProformPackaging()


    if(this.canUseScale && navigator.hid) {
      await navigator.hid.getDevices().then(async devices => {
        let device = this._.find(devices, {vendorId: 2338})
        if(!device) return
        this.foundDevice = true
        await device.open().then(() => {
          this.changeWeightScale(device)
        })
      })
    }
  },

  methods: {

    addWeightScaleDevice() {
      const vendorId = 0x0922;
      navigator.hid.requestDevice({ filters: [{ vendorId }] })
          .then(devices => {
            if(devices.length > 0) {
              this.foundDevice = true
              devices.map(device => {
                return device.open()
                    .then(() => {
                      this.changeWeightScale(device)
                    })
              })
            }
          })
          .catch(error => {
            console.log(error);
          });
    },

    changeWeightScale(device) {
      device.addEventListener("inputreport", (event) => {
        const { data, device } = event;
        let buffArray = new Uint8Array(data.buffer);

        this.productId = device?.productId
        this.deviceDataArr = buffArray

        let result = this.getScaleDecodeData(buffArray)

        this.weightScaleFirst = result.weightScaleFirst
        this.weightScaleSecond = result.weightScaleSecond
      })
    },


    functionSearchPackaging(val, loading) {
      loading(true)

      if (this.proformSearchTimeoutTime !== undefined) {
        let dateNow = new Date()
        if (dateNow - this.proformSearchTimeoutTime < 500) {
          clearTimeout(this.proformSearchTimeout)
        }
      }

      this.createTimeOut(val, loading)
    },

    createTimeOut(val, loading) {
      this.proformSearchTimeoutTime = new Date()
      this.proformSearchTimeout = setTimeout(() => {
        this.getProformPackaging(val, loading)
      }, 500)
    },

    getProformPackaging(val = false, loading){
      let userId
      if (this.userId)
        userId = this.userId
      else
        userId = this.$store.getters.getUserProfile.id

      if(!userId) return

      const query = new this.Query();
      let url = '?';
      let myQuery = query
          .for('posts')

      myQuery.where('ProformPackagingUserId', userId)

      myQuery.where('ProformPackagingIsTemplate', '1')

      if(val) myQuery.where('ProformPackagingPackageName', val)

      url = url + myQuery.limit(100000).page(0).url().split('?')[1]

      this.$store.dispatch('fetchProfilesPackaging', url).then(() => {
        if(this.$store.getters.getProfilesPackaging.length === 0 && val){
          this.$store.commit('setProfilesPackaging', [{package_name: val}])
          this.packaging.setName({package_name: val})
        }
        if(loading)
          loading(false)
      })

    },

    changePackaging(val) {
      this.packaging.setName(val)
    },

    changePackagingValue(event, isWeight) {
      this.packaging.calcVolumeWeight()
      this.packaging.calcVolumeGeneral()

      if(this.canUseScale &&
          this.foundDevice && event.target.value === ' ' && isWeight) {

        let weight = Number(this.weightScaleFirst) + (Number(this.weightScaleSecond) / 1000)
        this.packaging.packaging.weight = weight

        // let scale = {
        //   first:  'kg',
        //   second: 'gr',
        // }
        //
        // let error = {
        //   stack: `EXPRESS weightScale = ${this.weightScaleFirst}${scale.first} ${this.weightScaleSecond}${scale.second};  productId = ${this.productId};
        //     deviceDataArr =
        //     [0]: ${this.deviceDataArr[0]};
        //     [1]: ${this.deviceDataArr[1]};
        //     [2]: ${this.deviceDataArr[2]};
        //     [3]: ${this.deviceDataArr[3]};
        //     [4]: ${this.deviceDataArr[4]}.`
        // }
        // this.createErrorLog(error)
      }
    },

    addToProfile() {
      if(!this.packaging.packagingValidate())
        return

      if(!this.packaging.getName()?.package_name) {
        this.packNameError = true
        this.openNotify('error', 'express_addToProfileWarning')
        return
      }
      else {
        this.packNameError = false
      }

      let userId = null
      if (this.userId)
        userId = this.userId
      else
        userId = this.$store.getters.getUserProfile.id

      this.packaging.setUserId(userId)

      let data = this.packaging.preparePackagingItem()
      this.createPackage(data)
    },

    createPackage(data) {
      this.$store.dispatch('createProfilesPackaging', data).then((response) => {
        if (this._.has(response, 'data') && response.data.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
          this.openNotify('success', 'common_notificationRecordCreated')

        } else if (response.response.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {

          let errors = response.response.data.errors;
          this.openNotify('error', {txtServer: errors['package_name'][0]})

        } else {
          this.openNotify('error', 'common_notificationUndefinedError')
        }
      })
    },
  }

}
</script>

<style scoped>

</style>

<template>
  <modal
      @close="$emit('close')"
      class="custom-popup medium-size"
  >
    <template slot="header">
      <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fbm_SendFromData', 'fbm_SendFromDataAdd'])"></div>
        <template v-if="type === 'info'"> {{ $t('fbm_SendFromData.localization_value.value') }}</template>
        <template v-if="type === 'create'"> {{ $t('fbm_SendFromDataAdd.localization_value.value') }}</template>
        <template v-if="type === 'edit'"> {{ $t('common_edit.localization_value.value') }}</template>
      </div>
    </template>
    <template slot="body">
      <div class="custom-popup__content">
        <template v-if="type !== 'edit'">
          <div class="custom-row"
               v-for="(item, index) in SendFrom.data.SendFromArray"
               :key="index"
          >

            <div class="custom-col mb-0">
              <div class="order-create__title-horizontal">
                <TitleHorizontal
                    class="mb-2"
                    :value="'#' + (index + 1)"
                    :rightBtn="true"
                    :rightBtnType="'delete'"
                    :rightBtnText="$t('common_delete.localization_value.value')"
                    @rightBtnClick="SendFrom.removeSendFrom(index)"
                />
              </div>
            </div>


            <div class="custom-col custom-col--33 custom-col--sm-100"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['common_name'])"></div>
              <DefaultInput
                  :placeholder="$t('common_name.localization_value.value')"
                  v-bind:class="{'ui-no-valid': item.data.PersonalInformation.validation.personalName}"
                  :errorTxt="$t(`${item.data.PersonalInformation.validationTranslate.personalName}.localization_value.value`)"
                  :error="item.data.PersonalInformation.validation.personalName"
                  v-model="item.data.PersonalInformation.information.personalName"
              />
            </div>

            <div class="custom-col custom-col--33 custom-col--sm-100"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['common_surName'])"></div>
              <DefaultInput
                  :placeholder="$t('common_surName.localization_value.value')"
                  v-bind:class="{'ui-no-valid': item.data.PersonalInformation.validation.personalSurName}"
                  :errorTxt="$t(`${item.data.PersonalInformation.validationTranslate.personalSurName}.localization_value.value`)"
                  :error="item.data.PersonalInformation.validation.personalSurName"
                  v-model="item.data.PersonalInformation.information.personalSurName"
              />
            </div>

            <div class="custom-col custom-col--33 custom-col--sm-100"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['profile_companyName'])"></div>
              <DefaultInput
                  :placeholder="$t('profile_companyName.localization_value.value')"
                  v-bind:class="{'ui-no-valid': item.data.PersonalInformation.validation.personalCompany}"
                  :errorTxt="$t(`${item.data.PersonalInformation.validationTranslate.personalCompany}.localization_value.value`)"
                  :error="item.data.PersonalInformation.validation.personalCompany"
                  v-model="item.data.PersonalInformation.information.personalCompany"
              />
            </div>

            <div class="custom-col"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['orderPrice_UseDefault'])"></div>
              <DefaultCheckbox
                  :label="$t('orderPrice_UseDefault.localization_value.value')"
                  :value="item.data.defaultAddress"
                  @input="(val) => changeDefault(item, val)"
              />
            </div>
          </div>

          <div class="order-create__row custom-row">
            <div class="order-create__col custom-col custom-col--md-100">
              <div class="order-create__product-btn add-product-btn-wrap mb-0"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <span class="order-create__product-btn-i add-product-btn site-link"
                      @click="SendFrom.addSendFrom()">
              + {{ $t('common_AddNew.localization_value.value') }}
              </span>
              </div>
            </div>
          </div>
        </template>


        <template v-else>

          <template
              v-for="(item, index) in SendFrom.data.SendFromArray">
            <div class="custom-row"
                 v-if="index === 0"
                 :key="index"
            >
              <div class="custom-col custom-col--33 custom-col--sm-100"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_name'])"></div>
                <DefaultInput
                    :placeholder="$t('common_name.localization_value.value')"
                    v-bind:class="{'ui-no-valid': item.data.PersonalInformation.validation.personalName}"
                    :errorTxt="$t(`${item.data.PersonalInformation.validationTranslate.personalName}.localization_value.value`)"
                    :error="item.data.PersonalInformation.validation.personalName"
                    v-model="item.data.PersonalInformation.information.personalName"
                />
              </div>

              <div class="custom-col custom-col--33 custom-col--sm-100"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_surName'])"></div>
                <DefaultInput
                    :placeholder="$t('common_surName.localization_value.value')"
                    v-bind:class="{'ui-no-valid': item.data.PersonalInformation.validation.personalSurName}"
                    :errorTxt="$t(`${item.data.PersonalInformation.validationTranslate.personalSurName}.localization_value.value`)"
                    :error="item.data.PersonalInformation.validation.personalSurName"
                    v-model="item.data.PersonalInformation.information.personalSurName"
                />
              </div>

              <div class="custom-col custom-col--33 custom-col--sm-100"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['profile_companyName'])"></div>
                <DefaultInput
                    :placeholder="$t('profile_companyName.localization_value.value')"
                    v-bind:class="{'ui-no-valid': item.data.PersonalInformation.validation.personalCompany}"
                    :errorTxt="$t(`${item.data.PersonalInformation.validationTranslate.personalCompany}.localization_value.value`)"
                    :error="item.data.PersonalInformation.validation.personalCompany"
                    v-model="item.data.PersonalInformation.information.personalCompany"
                />
              </div>

              <div class="custom-col"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['orderPrice_UseDefault'])"></div>
                <DefaultCheckbox
                    :label="$t('orderPrice_UseDefault.localization_value.value')"
                    :value="item.data.defaultAddress"
                    @input="(val) => changeDefault(item, val)"
                />
              </div>
            </div>
          </template>

        </template>


      </div>
    </template>
    <template slot="footer">
      <div class="buy-label__btn d-flex align-items-center"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_cancel'])"></div>
        <span
            class="site-link site-link--alt buy-label__btn-i mr-3"
            @click="$emit('close')"
        >
          {{ $t('common_cancel.localization_value.value') }}
        </span>

        <MainButton
            :value="$t('common_save.localization_value.value')"
            v-bind:class="{'disabled-btn' : $store.getters.getOrderSendFromBtn}"
            class="profiles-package__btn-i"
            @click.native="save"
        />
      </div>
    </template>
  </modal>

</template>

<script>
import Modal from '../../../commonModals/Modal.vue'
import {SendFrom} from "@/components/globalModels/SendFrom";
import MainButton from "@/components/UI/buttons/MainButton/MainButton";
import DefaultInput from "@/components/UI/inputs/DefaultInput/DefaultInput";
import TitleHorizontal from "@/components/coreComponents/TitleHorizontal/TitleHorizontal";
import DefaultCheckbox from "@/components/UI/checkboxes/DefaultCheckbox/DefaultCheckbox";

export default {
  name: "SendFromDataPopup",

  components: {
    DefaultCheckbox,
    TitleHorizontal,
    DefaultInput,
    MainButton,
    Modal,
  },

  props: {
    type: {
      type: String,
      default: 'info',
    },
    id: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      SendFrom: new SendFrom(),
    }
  },

  mounted() {
    if(this.type === 'edit' && this.id > 0) {
      this.$store.dispatch('getOrderSendFrom', this.id).then(response => {
        this.SendFrom.setSendFromItems([this.getRespData(response)])
      })
    }
  },

  methods: {

    changeDefault(item, val) {
      if(val) {
        this.SendFrom.data.SendFromArray.forEach(SendFrom => {
          SendFrom.data.defaultAddress = false
        })
      }
      item.data.defaultAddress = val
    },

    async save() {
      if(!this.SendFrom.sendFromValidate()) {
        return
      }

      let data = this.SendFrom.prepareData()

      if(this.type === 'edit') {
        this.$store.dispatch('updateOrderSendFrom', {id: this.id, data: data[0]}).then((response) => {
          if (!this.getResponseStatus(response)) return this.openNotify('error', 'common_notificationUndefinedError')

          switch (this.getResponseStatus(response)) {
              /**
               * Success
               */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
              this.openNotify('success', 'common_notificationRecordChanged')
              this.$emit('close')
              this.$emit('reload')
              break
            }
              /**
               * Validation Error
               */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
              /**
               * Undefined Error
               */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }

        })

        return
      }

      for await (const [index, item] of data.entries()) {
        await this.$store.dispatch('createOrderSendFrom', item).then((response) => {
          if (!this.getResponseStatus(response)) return this.openNotify('error', 'common_notificationUndefinedError')

          switch (this.getResponseStatus(response)) {
              /**
               * Success
               */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
              if(index === data.length - 1) {
                this.openNotify('success', 'common_notificationRecordCreated')
                this.$emit('successAdd')
                this.$emit('close')
                this.$emit('reload')
              }
              break
            }
              /**
               * Validation Error
               */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
              /**
               * Undefined Error
               */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }

        })
      }



    },
  },

}

</script>

<style scoped>

</style>

<template>
  <ShippingCompanyTableAdmin
          :navTabs="navTabs"
          :countFilterParams="countFilterParams"
          :filterGetParams="filterGetParams"
          @changeTab="changeTab"
          @resetFilter="resetFilter"
          @changeFilter="changeFilter"
          @showMore="showMore"
          @reload="reload"
  />
</template>

<script>
  import ShippingCompanyTableAdmin from "./ShippingCompanyTableAdmin/ShippingCompanyTableAdmin";
  import {routeFilter} from "../../../../../mixins/routeFilterMixins/routeFilter";

  export default {
    name: "ShippingCompanyTable",

    components: {
      ShippingCompanyTableAdmin,
    },

    mixins: [routeFilter],

    data() {
      return {
        forPage: this.$store.getters.getShippingCompanyForPage,
        page: 1,
        onePage: 1,
        filterType: '',

        countFilterParams: 0,
        filterGetParams: {},

        navTabs: {
          shippingCompany: {
            active: true,
            label: 'shippingCompany_shippingCompany',
            name: 'shippingCompany',
          },
          shippingCompanyForOrder: {
            active: false,
            label: 'shippingCompany_shippingCompanyForOrder',
            name: 'shippingCompanyForOrder',
          },
          shippingMethodsForOrder: {
            active: false,
            label: 'shippingCompany_shippingMethodsForOrder',
            name: 'shippingMethodsForOrder',
          },
        },
      }
    },

    mounted() {
      if(this.$route.query.type !== undefined){
        this.navTabs.shippingCompany.active = false
        this.navTabs[this.$route.query.type].active = true
      }

      this.filter()
    },

    methods: {

      getFilterParam() {
        this.filterGetParams = {
          page: this.$route.query.page,
          count: this.$route.query.count,
          type: this.$route.query.type,
        }
      },

      filter(next = false) {
        this.getFilterParam()

        let url

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(next, this)

        // generate other filter if has get params in URL
        this.generateParamsFilterFromUrl(this)

        // Generate filter URL
        url = this.generateFilterUrl(
          generateMainFilter.page,
          generateMainFilter.forPage
        )

        if(this.navTabs.shippingCompany.active){
          if (next) this.$store.commit('setNextShippingCompanyPage', true)
          this.$store.dispatch('fetchShippingCompanyWithPermissions', url).then(() => {
            this.$store.commit('setNextShippingCompanyPage', false)
          })
        }

        if(this.navTabs.shippingCompanyForOrder.active){
          if (next) this.$store.commit('setNextShippingCompanyForOrderPage', true)
          this.$store.dispatch('fetchShippingCompanyForOrder', url).then(() => {
            this.$store.commit('setNextShippingCompanyForOrderPage', false)
          })
        }

        if(this.navTabs.shippingMethodsForOrder.active){
          if (next) this.$store.commit('setNextShippingMethodsForOrderPage', true)
          this.$store.dispatch('fetchShippingMethodsForOrder', url).then(() => {
            this.$store.commit('setNextShippingMethodsForOrderPage', false)
          })
        }



        this.checkCountFilter()
      },

      generateFilterUrl(page, forPage) {
        let myQuery = this.createQueryBuilderObject()
        return this.generateGetParamsFromQuery(myQuery, forPage, page)
      },

    },
  }
</script>

<style scoped>

</style>

<template>
  <div class="fragment">
    <div class="section-label" v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['common_comment',])"></div>
      {{ $t('common_comment.localization_value.value') }}
    </div>
    <div class="order-create__row custom-row">
      <div class="order-create__col custom-col custom-col--66 custom-col--md-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}"
      >
        <div class="admin-edit" @click="editTranslate(['common_comment',])"></div>
        <TextareaDefault
            :label="$t('common_comment.localization_value.value')"
            v-bind:class="{'ui-no-valid': DO.validation.comment}"
            :error="DO.validation.comment"
            :errorTxt="$t(`${DO.validationTranslate.comment}.localization_value.value`)"
            v-model="DO.data.comment"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TextareaDefault from "../../../../../UI/textarea/TextareaDefault/TextareaDefault";
export default {
  name: "Comment",
  components: { TextareaDefault },
  props: {
    DO: Object,
  },
}
</script>

<style scoped>

</style>

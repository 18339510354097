<template>

  <div class="fragment" style="width: 100%">
    <div class="custom-col">
      <div class="d-flex flex-wrap"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_AddProduct',])"></div>
        <RadioDefault
            class="white-space-line width-initial mr-3"
            v-for="(item, index) in warehouses"
            :key="index"
            :label="item.name"
            :name="'warehouse'"
            :value="DO.data.Order.data.warehouse && item.id === DO.data.Order.data.warehouse.id"
            @input="changeWarehouse(item)"
        />
      </div>
    </div>

    <div class="custom-col">
      <div class="d-flex flex-wrap"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['disposal_income','disposal_disposal',])"></div>
        <RadioDefault
            class="white-space-line width-initial mr-3"
            v-for="(item, index) in DISPOSAL_SUBTYPES"
            :key="index"
            :label="$t(`${item.label}.localization_value.value`)"
            :name="'subtypes'"
            :value="DO.data.inventoryType && item.value === DO.data.inventoryType.value"
            @input="DO.data.inventoryType = item"
        />
      </div>
    </div>

    <div class="custom-col">
      <div class="d-flex flex-wrap"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['disposal_inTransit','disposal_reserv','disposal_warehouse','disposal_damage',])"></div>
        <RadioDefault
            class="white-space-line width-initial mr-3"
            v-for="(item, index) in DISPOSAL_PRODUCT_TYPE"
            :key="index"
            :label="$t(`${item.label}.localization_value.value`)"
            :name="'productType'"
            :value="DO.data.skladType && item.value === DO.data.skladType.value"
            @input="DO.data.skladType = item"
        />
      </div>
    </div>

    <div class="order-create__col custom-col">
      <div class="fragment"
           v-if="initialOptionsProducts.length > 0"
           v-bind:class="{'loading-products': productLoading}"
      >
        <SelectProductBlock
            v-for="(item, index) in products"
            :initialOptionsProducts="initialOptionsProducts"
            :key="index+'product'"
            :item="item"
            :price="false"
            :description="false"
            :userId="parseInt(DO.data.User.getUserId())"
            :productLabel="'name_for_fba'"
            :largeCountColumn="true"
            :getProductsDamage="true"
            :getProductsWithCellsData="false"
            @removeProduct="DO.removeProduct(index)"
            @changeProductName="changeProductName"
            @changeProductQuantity="changeProductQuantity"
        />
        <div class="order-create__row custom-row">
          <div class="order-create__col custom-col custom-col--83 custom-col--md-100">
            <div class="add-product-btn-wrap mb-0"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}"
            >
              <div class="admin-edit" @click="editTranslate(['common_AddProduct',])"></div>
              <span class="order-create__product-btn-i add-product-btn site-link"
                    @click="DO.addProduct()"
              >
                + {{ $t('common_AddProduct.localization_value.value') }}
            </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import SelectProductBlock from "../../../../../coreComponents/SelectProductBlock/SelectProductBlock";
import RadioDefault from "@/components/UI/radiobuttons/RadioDefault/RadioDefault";
import {DISPOSAL_PRODUCT_TYPE, DISPOSAL_SUBTYPES} from "@/staticData/staticVariables";
export default {
  name: "Products",
  components: {RadioDefault, SelectProductBlock },
  props: {
    products: {
      type: Array,
      required: () => {
        return true;
      },
    },
    DO: {
      type: Object,
      required: () => {
        return true;
      },
    },
  },

  watch: {
    getUser() {
      this.getProducts()
    }
  },

  data() {
    return {
      initialOptionsProducts: [],
      productLoading: false,
      warehouses: [],

      DISPOSAL_SUBTYPES: DISPOSAL_SUBTYPES,
      DISPOSAL_PRODUCT_TYPE: DISPOSAL_PRODUCT_TYPE,
    }
  },

  computed: {
    getUser(){
      return this.DO.data.User.userId
    }
  },

  mounted() {
    this.$store.dispatch('fetchWarehouseStorage').then((response) => {
      this.warehouses = this.getRespPaginateData(response)

      if(!this.DO.data.Order.data.warehouse) {
        this.DO.data.Order.setWarehouse(this._.first(this.warehouses))
      }

      this.getProducts()
    })

    if(!this.DO.data.inventoryType) {
      this.DO.data.inventoryType = DISPOSAL_SUBTYPES.INCOME
    }

    if(!this.DO.data.skladType) {
      this.DO.data.skladType = DISPOSAL_PRODUCT_TYPE.TRANSIT
    }
  },

  methods: {
    changeWarehouse(val) {
      this.DO.data.Order.setWarehouse(val)
      this.getProducts().then(() => {
        this.products.map((item, index) => {
          let product = this._.find(this.initialOptionsProducts, {id: item.product.productObject?.id})
          if(product) {
            let count = this.products[index].product.count
            this.products[index].setProduct(product)
            this.products[index].setProductCount(count)
          }
        })
      })
    },

    async getProducts(){
      this.productLoading = true
      if(!this.DO.data.User.getUserId()) return

      const query = new this.Query();
      let url = '?';
      let myQuery = query
          .for('posts')

      myQuery.where('ProductUserId', parseInt(this.DO.data.User.getUserId()))

      myQuery.where('byStorageId', this.DO.data.Order.data.warehouse?.id)

      if(!this.withDraft)
        myQuery.where('ProductInDraft', 0)

      if(this.getProductsDamage){
        myQuery.includes('itemsInStorage')
      }
      // myQuery.where('ProductDamage', 1)

      url = url + myQuery.limit(100000).page(parseInt(0)).url().split('?')[1]

      let typeFetch = 'fetchProducts'

      return  this.$store.dispatch(typeFetch, url).then((response) => {
        this.initialOptionsProducts = this.getRespPaginateData(response)
        this.productLoading = false
      })
    },

    changeProductName(item, value) {
      item.setProduct(value);
      // let damageProductQuantity = this._.find(value?.items_in_storage, {damage: true})?.quantity
      // if(damageProductQuantity) {
      //   this.changeProductQuantity(item, damageProductQuantity)
      // }
    },
    changeProductQuantity(item, value) {
      let damageProductQuantity = this._.find(item.product.productObject?.items_in_storage, {damage: true})?.quantity
      if(damageProductQuantity < value) {
        item.setProductCount(value);
        setTimeout(() => {
          item.setProductCount(damageProductQuantity)
        }, 10)
        return
      }
      item.setProductCount(value);
    },
  },
}
</script>

<style scoped>

.loading-products{
  opacity: 0.5;
  pointer-events: none;
}

.width-initial{
  width: initial !important;
}
</style>

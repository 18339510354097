<template>
    <div class="table-card__item-content"
         :class="{show: show}"
    >
      <div class="table-card__item-head">
        <div class="table-card__item-head-row d-flex">
          <DefaultCheckbox
              v-if="item.status !== 'received'"
              class="empty-label"
              :selectedNow="selectedNow"
              :dataValue="item.id"
          />
          <StatusIcoBtn v-else :type="'delivered'" class="mr-2" />
          <div class="table-card__item-number table-card__item-number--small"
               @click="$emit('changeInfoPopup', true)"
          >
            {{item.delivery_service.name}}: {{item.tracking_number}}
          </div>
        </div>
      </div>
      <transition name="slide">
        <div class="table-card__item-body"
             v-if="show"
        >
          <div class="table-card__item-row custom-row">
            <div class="table-card__item-col custom-col">
              <div class="table-card__item-label">
                {{$t('common_user.localization_value.value')}}
              </div>
              <div class="table-card__item-info">
                <LinkButton
                    v-if="item.admin"
                    :value="item.admin.full_name"
                    :link="$store.getters.GET_PATHS.mainSettingsUsers + `?userId=${item.admin.id}`"
                />
              </div>
            </div>
          </div>

          <div class="table-card__item-row custom-row">
            <div class="table-card__item-col custom-col custom-col--50">
              <div class="table-card__item-label">
                {{$t('consolidationUnion_shippingCompany.localization_value.value')}}
              </div>
              <div class="table-card__item-info">
                {{item.delivery_service.name}}
              </div>
            </div>
            <div class="table-card__item-col custom-col">
              <div class="table-card__item-label">
                {{$t('consolidationUnion_comment.localization_value.value')}}
              </div>
              <div class="table-card__item-info flex-column">
                <div class="d-flex">
                  <template v-if="item.form_number !== null && item.form_number !== ''">
                    <v-popover class="site-tooltip" offset="5" placement="top" trigger="hover">

                      <div class="table-link btn-style ellipsis">{{ item.form_number }}</div>

                      <template slot="popover">
                        <p>{{ item.form_number }}</p>
                      </template>

                    </v-popover>
                    <div class="table-ico">
                      <v-popover class="site-tooltip" offset="5" placement="top-left" @show="hideEditField = true" @hide="hideEditField = false">

                        <span class="site-link"><LinkButton :type="'edit'"/></span>

                        <template slot="popover">
                          <div class="section-label mb-2">{{$t('consolidationUnion_formIE599.localization_value.value')}}
                          </div>
                          <InputEditField
                              :value="item.form_number"
                              :propId="item.id"
                              :hideEditField="hideEditField"
                              :disabledBtn="$store.getters.getConsolidationUnionBtn"
                              @onChange="(inputItem) => {$emit('changeFormNumberConsolidationUnion', inputItem)}"
                          />
                          <a v-close-popover ref="closePopover" class="tooltip__close"></a>
                        </template>
                      </v-popover>
                    </div>
                  </template>
                  <template v-else>
                    <div class="">
                      <v-popover class="site-tooltip" offset="5" placement="top-left" @show="hideEditField = true" @hide="hideEditField = false">

                        <span class="table-link btn-style">{{$t('consolidationUnion_addFormIE599.localization_value.value')}}</span>

                        <template slot="popover">
                          <div class="section-label mb-2">{{$t('consolidationUnion_formIE599.localization_value.value')}}</div>
                          <InputEditField
                              :value="''"
                              :propId="item.id"
                              :hideEditField="hideEditField"
                              :disabledBtn="$store.getters.getConsolidationUnionBtn"
                              @onChange="(inputItem) => {$emit('changeFormNumberConsolidationUnion', inputItem)}"
                          />

                          <a v-close-popover ref="closePopover" class="tooltip__close"></a>
                        </template>
                      </v-popover>
                    </div>
                  </template>
                </div>
              </div>
            </div>

            <div class="table-card__item-col custom-col custom-col--50">
              <div class="table-card__item-label">
                {{$t('consolidationUnion_trackingNumber.localization_value.value')}}
              </div>
              <div class="table-card__item-info">
                {{item.tracking_number}}
              </div>
            </div>
            <div class="table-card__item-col custom-col custom-col--50">
              <div class="table-card__item-label">
                {{$t('consolidationUnion_City.localization_value.value')}}
              </div>
              <div class="table-card__item-info">
                {{item.warehouse.currentTranslate.name}}
              </div>
            </div>

            <div class="table-card__item-col custom-col">
              <div class="table-card__item-label">
                {{$t('consolidationUnion_comment.localization_value.value')}}
              </div>
              <div class="table-card__item-info flex-column">
                <div class="d-flex">
                  <template v-if="item.comment !== null && item.comment !== ''">
                    <!--<span>{{item.tracking_number}}</span>-->
                    <v-popover
                        class="site-tooltip"
                        offset="5"
                        placement="top"
                        trigger="hover"
                    >
                      <div class="table-link btn-style ellipsis">{{ item.comment }}</div>
                      <template slot="popover">
                        <p>{{ item.comment }}</p>
                      </template>
                    </v-popover>
                    <div class="table-ico">
                      <!--v-if="item.tracking_sent === 0"-->
                      <v-popover
                          class="site-tooltip"
                          offset="5"
                          placement="top-left"
                          @show="hideEditField = true"
                          @hide="hideEditField = false"
                      >
                          <span class="site-link">
                              <LinkButton
                                  :type="'edit'"
                              />
                          </span>
                        <template slot="popover">
                          <div class="section-label mb-2">{{$t('consolidationUnion_comment.localization_value.value')}}
                          </div>
                          <InputEditField
                              :value="item.comment"
                              :propId="item.id"
                              :hideEditField="hideEditField"
                              :disabledBtn="$store.getters.getConsolidationUnionBtn"
                              @onChange="(inputItem) => {$emit('changeCommentConsolidationUnion', inputItem)}"
                          />
                          <a v-close-popover ref="closePopover" class="tooltip__close"></a>
                        </template>
                      </v-popover>
                    </div>
                  </template>
                  <template v-else>
                    <div class="">
                      <v-popover
                          class="site-tooltip"
                          offset="5"
                          placement="top-left"
                          @show="hideEditField = true"
                          @hide="hideEditField = false"
                      >
                        <span class="table-link btn-style">{{$t('consolidationUnion_addComment.localization_value.value')}}</span>

                        <template slot="popover">
                          <div class="section-label mb-2">{{$t('consolidationUnion_comment.localization_value.value')}}
                          </div>
                          <InputEditField
                              :value="''"
                              :propId="item.id"
                              :hideEditField="hideEditField"
                              :disabledBtn="$store.getters.getConsolidationUnionBtn"
                              @onChange="(inputItem) => {$emit('changeCommentConsolidationUnion', inputItem)}"
                          />

                          <a v-close-popover ref="closePopover" class="tooltip__close"></a>
                        </template>
                      </v-popover>
                    </div>
                  </template>
                </div>

                <div class="d-flex">
                  <div class="table-row" v-if="navTabs.sent.active">
                    <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <span class="admin-edit"
                        @click="editTranslate(['consolidationUnion_consoTag', 'consolidationUnion_addConsoTag'])"></span>
                      <template v-if="item.tag !== null">
                        <span class="btn-style">
                          <span class="color-red alarm-style"
                          >
                            {{ item.tag.name }}
                          </span>
                        </span>
                        <div class="table-ico">
                          <!--v-if="item.tracking_sent === 0"-->
                          <v-popover
                              class="site-tooltip"
                              offset="5"
                              placement="top-left"
                              @show="hideEditField = true"
                              @hide="hideEditField = false"
                          >
                        <span class="site-link">
                            <LinkButton
                                :type="'edit'"
                            />
                        </span>
                            <template slot="popover">
                              <div class="section-label mb-2">{{$t('consolidationUnion_consoTag.localization_value.value')}}
                              </div>
                              <div class="edit-field-input">
                                <div class="edit-field-input__inner">
                                  <DefaultSelect
                                      class="conso-user-select"
                                      :options="consoTags"
                                      :optionsLabel="'name'"
                                      :selected="item.tag"
                                      @change="(val) => {selectedConsoTag = val}"
                                  />
                                  <button class="edit-field-input__btn"
                                          v-bind:class="{'disable-edit-field-btn': disabledConsoUnionBtn}"
                                          @click="$emit('changeTagConsolidationUnion', item.id, selectedConsoTag)"></button>
                                </div>
                              </div>
                              <a v-close-popover ref="closePopover" class="tooltip__close"></a>
                            </template>
                          </v-popover>
                        </div>
                      </template>
                      <template v-else>
                        <div class="">
                          <v-popover
                              class="site-tooltip"
                              offset="5"
                              placement="top-left"
                              @show="hideEditField = true"
                              @hide="hideEditField = false"
                          >
                            <span class="table-link btn-style">{{$t('consolidationUnion_addConsoTag.localization_value.value')}}</span>

                            <template slot="popover">
                              <div class="section-label mb-2">{{$t('consolidationUnion_consoTag.localization_value.value')}}
                              </div>
                              <div class="edit-field-input">
                                <div class="edit-field-input__inner">
                                  <DefaultSelect
                                      class="conso-user-select"
                                      :options="consoTags"
                                      :optionsLabel="'name'"
                                      @change="(val) => {selectedConsoTag = val}"
                                  />
                                  <button class="edit-field-input__btn"
                                          v-bind:class="{'disable-edit-field-btn': disabledConsoUnionBtn}"
                                          @click="$emit('changeTagConsolidationUnion', item.id, selectedConsoTag)"></button>
                                </div>
                              </div>

                              <a v-close-popover ref="closePopover" class="tooltip__close"></a>
                            </template>
                          </v-popover>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>


              </div>
            </div>

            <div v-if="navTabs.sent.active" class="table-card__item-col custom-col custom-col--50">
              <div class="table-card__item-label">
                {{$t('consolidationUnion_Quantity.localization_value.value')}}
              </div>
              <div class="table-card__item-info">
                {{item.consolidation_count}}
              </div>
            </div>

            <div v-if="navTabs.sent.active" class="table-card__item-col custom-col custom-col--50">
              <div class="table-card__item-label">
                {{$t('consolidationUnion_amountCharged.localization_value.value')}}
              </div>
              <div class="table-card__item-info">
                <ValueHelper
                    :value="formatAmountCharged(item.amount_charged)"
                    :replaceValue="'—'"
                />
              </div>
            </div>

            <div v-if="navTabs.sent.active" class="table-card__item-col custom-col custom-col--50">
              <div class="table-card__item-label">
                {{$t('consolidationUnion_sendDate.localization_value.value')}}
              </div>
              <div class="table-card__item-info">
                {{ item.send_at | moment("DD MMM, YYYY") }}
              </div>
            </div>

            <div v-if="navTabs.sent.active" class="table-card__item-col custom-col custom-col--50">
              <div class="table-card__item-label">
                {{$t('consolidationUnion_consoUser.localization_value.value')}}
              </div>
              <div class="table-card__item-info">
                <template v-if="item.consolidation_responsible_person !== null && item.consolidation_responsible_person.name">
                  <span class="table-link btn-style">{{ item.consolidation_responsible_person.name }}</span>
                  <div class="table-ico">
                    <!--v-if="item.tracking_sent === 0"-->
                    <v-popover
                        class="site-tooltip"
                        offset="5"
                        placement="top-left"
                        @show="hideEditField = true"
                        @hide="hideEditField = false"
                    >
                        <span class="site-link">
                            <LinkButton
                                :type="'edit'"
                            />
                        </span>
                      <template slot="popover">
                        <div class="section-label mb-2">{{$t('consolidationUnion_consoUser.localization_value.value')}}
                        </div>
                        <div class="edit-field-input">
                          <div class="edit-field-input__inner">
                            <DefaultSelect
                                class="conso-user-select"
                                :options="adminUsers"
                                :optionsLabel="'name'"
                                :otherValue="'adminItem'"
                                @change="(val) => {selectedConsoUser = val}"
                            />
                            <button class="edit-field-input__btn"
                                    v-bind:class="{'disable-edit-field-btn': disabledConsoUnionBtn}"
                                    @click="$emit('changeConsoUserConsolidationUnion', item.id, selectedConsoUser)"></button>
                          </div>
                        </div>
                        <a v-close-popover ref="closePopover" class="tooltip__close"></a>
                      </template>
                    </v-popover>
                  </div>
                </template>
                <template v-else>
                  <div class="">
                    <v-popover
                        class="site-tooltip"
                        offset="5"
                        placement="top-left"
                        @show="hideEditField = true"
                        @hide="hideEditField = false"
                    >
                      <span class="table-link btn-style">{{$t('consolidationUnion_addConsoUser.localization_value.value')}}</span>

                      <template slot="popover">
                        <div class="section-label mb-2">{{$t('consolidationUnion_consoUser.localization_value.value')}}
                        </div>
                        <div class="edit-field-input">
                          <div class="edit-field-input__inner">
                            <DefaultSelect
                                class="conso-user-select"
                                :options="adminUsers"
                                :optionsLabel="'name'"
                                :otherValue="'adminItem'"
                                @change="(val) => {selectedConsoUser = val}"
                            />
                            <button class="edit-field-input__btn"
                                    v-bind:class="{'disable-edit-field-btn': disabledConsoUnionBtn}"
                                    @click="$emit('changeConsoUserConsolidationUnion', item.id, selectedConsoUser)"></button>
                          </div>
                        </div>

                        <a v-close-popover ref="closePopover" class="tooltip__close"></a>
                      </template>
                    </v-popover>
                  </div>
                </template>
              </div>
            </div>

          </div>

          <div class="table-card__item-btn pl-2">
            <div class="table-card__item-btn-i">
              <LinkButton
                  :value="$t('common_Proforma.localization_value.value')"
                  :type="'proforma'"
                  @click.native="$emit('getConsolidationUnionProform', item.id)"
              />
            </div>
            <div class="table-card__item-btn-i">
              <LinkButton
                  :value="$t('common_edit.localization_value.value')"
                  :type="'edit'"
                  :link="$store.getters.GET_PATHS.reportsConsolidationEdit + '/' + item.id"
              />
            </div>
            <div class="table-card__item-btn-i">
              <LinkButton
                  v-if="item.status !== 'received'"
                  :value="$t('common_delete.localization_value.value')"
                  :type="'delete'"
                  @click.native="$emit('removeItem')"
              />
            </div>


            <div v-if="!navTabs.sent.active && item.consolidation_union_label === null" class="table-card__item-btn-i">
              <LinkButton
                  :value="$t('consolidationUnion_CreateLabel.localization_value.value')"
                  :type="'mark'"
                  :link="$store.getters.GET_PATHS.reportsConsolidationCreateLabel + '/' + item.id"
              />
            </div>
            <div v-if="!navTabs.sent.active && item.consolidation_union_label !== null" class="table-card__item-btn-i">
              <LinkButton
                  :value="$t('consolidationUnion_editLabelParams.localization_value.value')"
                  :type="'mark'"
                  :link="$store.getters.GET_PATHS.reportsConsolidationEditLabel + '/' + item.consolidation_union_label.id"
              />
            </div>
            <div v-if="!navTabs.sent.active && item.consolidation_union_label !== null
                     && item.consolidation_union_label.label_file === null" class="table-card__item-btn-i">
              <LinkButton
                  :value="$t('consolidationUnion_CreateLabel.localization_value.value')"
                  :type="'dog'"
                  @click.native="$emit('createLabelConsolidationUnion', item)"
              />
            </div>
            <div v-if="!navTabs.sent.active && item.consolidation_union_label !== null
                    && item.consolidation_union_label.label_file !== null" class="table-card__item-btn-i">
              <LinkButton
                  :value="$t('poshta_Label.localization_value.value')"
                  :type="'dog'"
                  @click.native="$emit('openFileByUUID', item.consolidation_union_label.label_file)"
              />
            </div>
            <div v-if="!navTabs.sent.active && item.consolidation_union_label !== null
                    && item.consolidation_union_label.proform_document_file !== null" class="table-card__item-btn-i">
              <LinkButton
                  :value="$t('consolidationUnion_invoice.localization_value.value')"
                  :type="'doc'"
                  @click.native="$emit('openFileByUUID', item.consolidation_union_label.proform_document_file)"
              />
            </div>
            <div v-if="!navTabs.sent.active && item.consolidation_union_label !== null
                    && item.consolidation_union_label.fedex_tsca_document_file !== null" class="table-card__item-btn-i">
              <LinkButton
                  :value="$t('consolidationUnion_ToxicSubstance.localization_value.value')"
                  :type="'doc'"
                  @click.native="$emit('openFileByUUID', item.consolidation_union_label.fedex_tsca_document_file)"
              />
            </div>
            <div v-if="!navTabs.sent.active && item.consolidation_union_label !== null
                    && item.consolidation_union_label.fedex_dbts_document_file !== null" class="table-card__item-btn-i">
              <LinkButton
                  :value="$t('consolidationUnion_DropBall.localization_value.value')"
                  :type="'doc'"
                  @click.native="$emit('openFileByUUID', item.consolidation_union_label.fedex_dbts_document_file)"
              />
            </div>
            <div v-if="!navTabs.sent.active && item.consolidation_union_label !== null
                    && item.consolidation_union_label.fedex_coof_document_file !== null" class="table-card__item-btn-i">
              <LinkButton
                  :value="$t('consolidationUnion_certOfOrigin.localization_value.value')"
                  :type="'doc'"
                  @click.native="$emit('openFileByUUID', item.consolidation_union_label.fedex_coof_document_file)"
              />
            </div>

            <div v-if="navTabs.sent.active && item.status === 'departed'" class="table-card__item-btn-i">

              <MainButton
                  class="wfc"
                  :value="$t('consolidationUnion_acceptInMN.localization_value.value')"
                  @click.native="$emit('acceptInMinnesota', item.id)"
              />

              <!--              <LinkButton-->
              <!--                  :value="$t('consolidationUnion_acceptInMN.localization_value.value')"-->
              <!--                  :type="'mark'"-->
              <!--                  @click.native="$emit('acceptInMinnesota', item.id)"-->
              <!--              />-->
            </div>

            <div v-if="navTabs.sent.active" class="table-card__item-btn-i">
              <MainButton
                  class="wfc"
                  :value="$t('consolidationUnion_sendTrackingNum2.localization_value.value')"
                  :disabledBtn="$store.getters.getConsolidationUnionBtn"
                  @click.native="$emit('sendTrack')"
              />
            </div>

          </div>
        </div>
      </transition>
      <div class="table-card__item-footer">
        <div class="table-card__item-show"
             @click="show = !show"
        >
          {{$t('common_showMore.localization_value.value')}}
        </div>
      </div>
    </div>
</template>

<script>
  import LinkButton from "../../../../../../../UI/buttons/LinkButton/LinkButton";
  // import DefaultCheckbox from "../../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import DefaultCheckbox from "../../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import InputEditField from "../../../../../../../UI/inputs/InputEditField/InputEditField";
  import DefaultSelect from "../../../../../../../UI/selectiones/DefaultSelect/DefaultSelect";
  import StatusIcoBtn from "../../../../../../../UI/status/StatusIcoBtn/StatusIcoBtn";
  import ValueHelper from "../../../../../../../coreComponents/ValueHelper/ValueHelper";
  import MainButton from "@/components/UI/buttons/MainButton/MainButton";

  export default {
    name: "ConsolidationUnionTableMobile",

    components: {
      MainButton,
      ValueHelper,
      StatusIcoBtn,
      DefaultSelect,
      InputEditField,
      DefaultCheckbox,
      LinkButton,
    },

    props: {
      item: Object,
      navTabs: Object,
      selectedNow: Boolean,
      consoUsers: Array,
      consoTags: Array,
      adminUsers: Array,
      filterGetParams: Object,
    },

    data() {
      return {
        show: false,
        hideEditField: false,

        disabledConsoUnionBtn: false,
        selectedConsoUser: null,
        selectedConsoTag: null,
      }
    },

    mounted() {
      if(this.filterGetParams.trackingNumber) {
        this.show = true
      }
    },

    methods: {
      formatAmountCharged(amount) {
        if (Number(amount) === amount && amount % 1 !== 0) {
          return parseFloat(amount).toFixed(2)
        }
        else {
          return amount
        }
      },
    },

  }
</script>

<style scoped>
  .conso-user-select {
    min-width: 150px;
  }

  .table-card .ellipsis {
    max-width: 180px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    display: inline-block;
  }


  .alarm-style {
    padding: 2px 4px;
    border: 1px solid #eb5757;
    border-radius: 4px;
    text-transform: uppercase;
    background-color: #eb5757;
    color: white;
    cursor: pointer;
    font-size: 12px;
  }
</style>

<template>
  <ConsolidationUnionTableAdmin
      :navTabs="navTabs"
      :countFilterParams="countFilterParams"
      :filterGetParams="filterGetParams"
      :consoUsers="consoUsers"
      :consoTags="consoTags"
      :adminUsers="adminUsers"
      :shippingCompanies="shippingCompanies"
      :warehouses="warehouses"
      @changeTab="changeTab"
      @resetFilter="resetFilter"
      @changeFilter="changeFilter"
      @showMore="showMore"
      @reload="reload"
      @removeItem="removeItem"
      @setTrackForSelectedUnions="setTrackForSelectedUnions"
      @acceptInMinnesota="acceptInMinnesota"
      @getConsolidationUnionProform="getConsolidationUnionProform"
      @getConsolidationUnionProformByState="getConsolidationUnionProformByState"
      @downloadFiles="downloadFiles"
  />
</template>

<script>
  import ConsolidationUnionTableAdmin from "./ConsolidationUnionTableAdmin/ConsolidationUnionTableAdmin";
  import {routeFilter} from "../../../../../mixins/routeFilterMixins/routeFilter";
  import {
    CONSOLIDATION_UNION_SHIPPING_COMPANIES_ARRAY,
    CONSOLIDATION_UNION_WAREHOUSES, CONSOLIDATION_UNION_WAREHOUSES_ARRAY
  } from "../../../../../staticData/staticVariables";

  export default {
    name: "ConsolidationUnionTable",

    components: {
      ConsolidationUnionTableAdmin,
    },

    mixins: [routeFilter],

    computed: {
      router: function() {
        return this.$route.query
      }
    },

    watch: {
      router: function(newRoute) {
        if(Object.keys(newRoute).length === 0){
          this.changeTab('all')
        }
      },

      loadUserAuthorizedData: function () {
        // set tab active and visible by permissions
        if(this.downloadPermissions){
          this.checkTabPermission()
        }
      },

      downloadPermissions() {
        this.checkTabPermission()
      }
    },

    data() {
      return {
        forPage: this.$store.getters.getConsolidationUnionForPage,
        page: 1,
        onePage: 1,
        filterType: '',

        filterConsolidationId: '',
        filterConsolidationUnionId: '',

        filterShippingCompanyId: '',
        filterWarehouseId: '',

        filterCreatedDate: '',

        filterTrackingNumber: '',

        filterUserId: '',
        filterUserName: '',

        filteAdminId: '',
        filterAdminName: '',

        filterConsoUserId: '',
        filterConsoUserName: '',

        countFilterParams: 0,
        filterGetParams: {},

        consoUsers: [],
        adminUsers: [],
        consoTags: [],
        shippingCompanies: [],
        warehouses: [],

        navTabs: {
          all: {
            active: true,
            label: this.$t('consolidationUnion_all.localization_value.value'),
            name: 'all',
            id: false,
            permissionVisible: true,
          },
          sent: {
            active: false,
            label: this.$t('consolidationUnion_sent.localization_value.value'),
            name: 'sent',
            id: false,
            permissionVisible: true,
          },
          [CONSOLIDATION_UNION_WAREHOUSES.KIEV_WAREHOUSE.code]: {
            active: false,
            label: this.$t('consolidationUnion_Kiev.localization_value.value'),
            name: CONSOLIDATION_UNION_WAREHOUSES.KIEV_WAREHOUSE.code,
            id: CONSOLIDATION_UNION_WAREHOUSES.KIEV_WAREHOUSE.id,
            permissionVisible: true,
          },
          [CONSOLIDATION_UNION_WAREHOUSES.DNEPR_WAREHOUSE.code]: {
            active: false,
            label: this.$t('consolidationUnion_Dnepr.localization_value.value'),
            name: CONSOLIDATION_UNION_WAREHOUSES.DNEPR_WAREHOUSE.code,
            id: CONSOLIDATION_UNION_WAREHOUSES.DNEPR_WAREHOUSE.id,
            permissionVisible: true,
          },
          // [CONSOLIDATION_UNION_WAREHOUSES.MINSK_WAREHOUSE.code]: {
          //   active: false,
          //   label: this.$t('consolidationUnion_Minsk.localization_value.value'),
          //   name: CONSOLIDATION_UNION_WAREHOUSES.MINSK_WAREHOUSE.code,
          //   id: CONSOLIDATION_UNION_WAREHOUSES.MINSK_WAREHOUSE.id,
          //   permissionVisible: true,
          // },
          [CONSOLIDATION_UNION_WAREHOUSES.KHARKIV_WAREHOUSE.code]: {
            active: false,
            label: this.$t('consolidationUnion_Kharkiv.localization_value.value'),
            name: CONSOLIDATION_UNION_WAREHOUSES.KHARKIV_WAREHOUSE.code,
            id: CONSOLIDATION_UNION_WAREHOUSES.KHARKIV_WAREHOUSE.id,
            permissionVisible: true,
          },
          [CONSOLIDATION_UNION_WAREHOUSES.ODESA_WAREHOUSE.code]: {
            active: false,
            label: this.$t('consolidationUnion_Odesa.localization_value.value'),
            name: CONSOLIDATION_UNION_WAREHOUSES.ODESA_WAREHOUSE.code,
            id: CONSOLIDATION_UNION_WAREHOUSES.ODESA_WAREHOUSE.id,
            permissionVisible: true,
          },
          [CONSOLIDATION_UNION_WAREHOUSES.LVIV_WAREHOUSE.code]: {
            active: false,
            label: this.$t('consolidationUnion_Lviv.localization_value.value'),
            name: CONSOLIDATION_UNION_WAREHOUSES.LVIV_WAREHOUSE.code,
            id: CONSOLIDATION_UNION_WAREHOUSES.LVIV_WAREHOUSE.id,
            permissionVisible: true,
          },
          [CONSOLIDATION_UNION_WAREHOUSES.ZAPORIZHIA_WAREHOUSE.code]: {
            active: false,
            label: this.$t('consolidationUnion_Zaporizhia.localization_value.value'),
            name: CONSOLIDATION_UNION_WAREHOUSES.ZAPORIZHIA_WAREHOUSE.code,
            id: CONSOLIDATION_UNION_WAREHOUSES.ZAPORIZHIA_WAREHOUSE.id,
            permissionVisible: true,
          },
          // [CONSOLIDATION_UNION_WAREHOUSES.BLAINE_WAREHOUSE.code]: {
          //   active: false,
          //   label: this.$t('consolidationUnion_Blaine.localization_value.value'),
          //   name: CONSOLIDATION_UNION_WAREHOUSES.BLAINE_WAREHOUSE.code,
          //   id: CONSOLIDATION_UNION_WAREHOUSES.BLAINE_WAREHOUSE.id,
          //   permissionVisible: true,
          // },
          [CONSOLIDATION_UNION_WAREHOUSES.LODZ_WAREHOUSE.code]: {
            active: false,
            label: this.$t('consolidationUnion_Lodz.localization_value.value'),
            name: CONSOLIDATION_UNION_WAREHOUSES.LODZ_WAREHOUSE.code,
            id: CONSOLIDATION_UNION_WAREHOUSES.LODZ_WAREHOUSE.id,
            permissionVisible: true,
          },
          // [CONSOLIDATION_UNION_WAREHOUSES.RIGA_WAREHOUSE.code]: {
          //   active: false,
          //   label: this.$t('consolidationUnion_Riga.localization_value.value'),
          //   name: CONSOLIDATION_UNION_WAREHOUSES.RIGA_WAREHOUSE.code,
          //   id: CONSOLIDATION_UNION_WAREHOUSES.RIGA_WAREHOUSE.id,
          //   permissionVisible: true,
          // },
          [CONSOLIDATION_UNION_WAREHOUSES.CAMBRIDGE_WAREHOUSE.code]: {
            active: false,
            label: this.$t('consolidationUnion_Cambridge.localization_value.value'),
            name: CONSOLIDATION_UNION_WAREHOUSES.CAMBRIDGE_WAREHOUSE.code,
            id: CONSOLIDATION_UNION_WAREHOUSES.CAMBRIDGE_WAREHOUSE.id,
            permissionVisible: true,
          },
        },
      }
    },

    mounted() {
      if(this.$route.query.type !== undefined){
        this.navTabs.all.active = false
        this.navTabs[this.$route.query.type].active = true
      }

      // this.$store.dispatch('fetchConsolidationUsers').then(response => {
      //   this.consoUsers = this.getRespPaginateData(response)
      // })

      this.$store.dispatch('getCommonTag').then(response => {
        this.consoTags = this.getRespPaginateData(response)
      })

      this.$store.dispatch('fetchAdminUsersFromFilter', '?filter[isUSAAdmin]=true').then(response => {
        this.adminUsers = this.getRespData(response)
      })

      this.$store.dispatch('getConsolidationUnionCreate').then(response => {
        let respData = this.getRespData(response)
        this.warehouses = respData.warehouses.filter(item => {
          return CONSOLIDATION_UNION_WAREHOUSES_ARRAY.find(findItem => {
            return findItem.id === item.id
          })
        })
        this.shippingCompanies = respData.delivery_services.filter(item => {
          return CONSOLIDATION_UNION_SHIPPING_COMPANIES_ARRAY.find(findItem => {
            return findItem.id === item.id
          })
        })
      })

      if(this.loadUserAuthorizedData){
        // set tab active and visible by permissions
        if(this.downloadPermissions){
          this.checkTabPermission()
        }
      }
    },

    methods: {
      checkTabPermission() {

        // this.activeTabByPermissions('navTabs', {
        //   [DHL_DELIVERY_SERVICE.name]: this.PERMISSIONS.ORDER_EXPRESS_DHL,
        //   [FEDEX_DELIVERY_SERVICE.name]: this.PERMISSIONS.ORDER_EXPRESS_FEDEX,
        //   [TNT_DELIVERY_SERVICE.name]: this.PERMISSIONS.ORDER_EXPRESS_TNT,
        //   [DPD_DELIVERY_SERVICE.name]: this.PERMISSIONS.ORDER_EXPRESS_FEDEX,
        // })
        //
        // this.hideTabAllIfVisibleOnlyOne('navTabs', 'all', ['permissionVisible'])
        //
        // if(!this._.find(this.navTabs, {active: true}).permissionVisible){
        //   this.setActivePermissionVisibleTab('navTabs')
        //   return
        // }

        this.filter()
      },

      getFilterParam() {
        this.filterGetParams = {
          page: this.$route.query.page,
          count: this.$route.query.count,
          type: this.$route.query.type,

          consolidationUnionId: this.$route.query.consolidationUnionId,
          consolidationId: this.$route.query.consolidationId,

          shippingCompanyId: this.$route.query.shippingCompanyId,
          warehouseId: this.$route.query.warehouseId,

          createdDate: this.$route.query.createdDate,

          trackingNumber: this.$route.query.trackingNumber,

          userId: this.$route.query.userId,
          userName: this.$route.query.userName,

          adminId: this.$route.query.adminId,
          adminName: this.$route.query.adminName,

          consoUserId: this.$route.query.consoUserId,
          consoUserName: this.$route.query.consoUserName,
        }
      },

      filter(next = false) {
        this.getFilterParam()

        let url

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(next, this)

        // generate other filter if has get params in URL
        this.generateParamsFilterFromUrl(this)

        // Generate filter URL
        url = this.generateFilterUrl(
            generateMainFilter.page,
            generateMainFilter.forPage
        )

        this.$store.commit('setConsolidationUnionFilter', window.location.search)

        if (next) this.$store.commit('setNextConsolidationUnionPage', true)
        this.$store.dispatch('fetchConsolidationUnion', url).then(() => {
          this.$store.commit('setNextConsolidationUnionPage', false)
        })


        this.checkCountFilter(['userName', 'type', 'adminName', 'consoUserName'])
      },

      generateFilterUrl(page, forPage) {
        let myQuery = this.createQueryBuilderObject({withUserNewMark: true})

        this.generateFilterQueryParams(
            myQuery,
            {
              filterConsolidationId: 'byConsolidationId',
              filterConsolidationUnionId: 'ConsolidationUnionId',

              filterShippingCompanyId: 'ConsolidationUnionDeliveryServiceId',
              filterWarehouseId: 'ConsolidationUnionWarehouseId',

              filterTrackingNumber: 'ConsolidationUnionTrackingNumber',
              filterUserId: 'byConsolidationUserId',
              filterAdminId: 'ConsolidationUnionAdminId',
              filterConsoUserId: 'byResponsiblePerson',
            },
        )

        let consolidationUnionWarehouseId = ''
        let byProcessedRequest = false
        switch (this.filterType) {
          case CONSOLIDATION_UNION_WAREHOUSES.KIEV_WAREHOUSE.code:
            consolidationUnionWarehouseId = CONSOLIDATION_UNION_WAREHOUSES.KIEV_WAREHOUSE.id
            break
          case CONSOLIDATION_UNION_WAREHOUSES.DNEPR_WAREHOUSE.code:
            consolidationUnionWarehouseId = CONSOLIDATION_UNION_WAREHOUSES.DNEPR_WAREHOUSE.id
            break
          // case CONSOLIDATION_UNION_WAREHOUSES.MINSK_WAREHOUSE.code:
          //   consolidationUnionWarehouseId = CONSOLIDATION_UNION_WAREHOUSES.MINSK_WAREHOUSE.id
          //   break
          case CONSOLIDATION_UNION_WAREHOUSES.ODESA_WAREHOUSE.code:
            consolidationUnionWarehouseId = CONSOLIDATION_UNION_WAREHOUSES.ODESA_WAREHOUSE.id
            break
          case CONSOLIDATION_UNION_WAREHOUSES.LVIV_WAREHOUSE.code:
            consolidationUnionWarehouseId = CONSOLIDATION_UNION_WAREHOUSES.LVIV_WAREHOUSE.id
            break
          case CONSOLIDATION_UNION_WAREHOUSES.ZAPORIZHIA_WAREHOUSE.code:
            consolidationUnionWarehouseId = CONSOLIDATION_UNION_WAREHOUSES.ZAPORIZHIA_WAREHOUSE.id
            break
          // case CONSOLIDATION_UNION_WAREHOUSES.BLAINE_WAREHOUSE.code:
          //   consolidationUnionWarehouseId = CONSOLIDATION_UNION_WAREHOUSES.BLAINE_WAREHOUSE.id
          //   break
          // case CONSOLIDATION_UNION_WAREHOUSES.RIGA_WAREHOUSE.code:
          //   consolidationUnionWarehouseId = CONSOLIDATION_UNION_WAREHOUSES.RIGA_WAREHOUSE.id
          //   break
          case CONSOLIDATION_UNION_WAREHOUSES.KHARKIV_WAREHOUSE.code:
            consolidationUnionWarehouseId = CONSOLIDATION_UNION_WAREHOUSES.KHARKIV_WAREHOUSE.id
            break
          case CONSOLIDATION_UNION_WAREHOUSES.LODZ_WAREHOUSE.code:
            consolidationUnionWarehouseId = CONSOLIDATION_UNION_WAREHOUSES.LODZ_WAREHOUSE.id
            break
          case CONSOLIDATION_UNION_WAREHOUSES.CAMBRIDGE_WAREHOUSE.code:
            consolidationUnionWarehouseId = CONSOLIDATION_UNION_WAREHOUSES.CAMBRIDGE_WAREHOUSE.id
            break
          case 'sent':
            consolidationUnionWarehouseId = ''
            byProcessedRequest = true
            break
        }
        if (consolidationUnionWarehouseId !== '')
          myQuery.where('ConsolidationUnionWarehouseId', consolidationUnionWarehouseId)

        myQuery.where('byProcessed', byProcessedRequest)


        this.filterCreatedDate = this.$route.query.createdDate !== undefined ? this.$route.query.createdDate : ''
        if (this.filterCreatedDate.length > 0) {
          let date = this.generateDateForFilter(this.filterCreatedDate, 'YYYY-MM-DD')
          myQuery.whereIn(
              'betweenCreatedAt',
              date
          )
        }

        return this.generateGetParamsFromQuery(myQuery, forPage, page)


      },

      removeItem(id){
        let text = {
          title: 'consolidations_deleteConsolidation',
          txt: '',
          yes: 'common_yes',
          no: 'common_no'
        }

        let confirm = () => {

          this.$store.dispatch('deleteConsolidationUnion', id).then((response) => {
            switch (this.getResponseStatus(response)) {
                /**
                 * Success
                 */
              case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
                this.openNotify('success', 'common_notificationRecordDeleted')
                //filter to upgrade table after deleting an item
                this.filter()
                break
              }
                /**
                 * Validation Error
                 */
              case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
                let errors = response.response.data.errors;
                this.notifyErrorHelper(errors)
                break
              }
                /**
                 * Undefined Error
                 */
              default: {
                this.openNotify('error', 'common_notificationUndefinedError')
              }
            }
          })

          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)
      },

      setTrackForSelectedUnions(selectedOption, trackNumber) {
        let selectedIds = this.getCheckedRows('row-name')
        if (!selectedIds?.length || !selectedOption || !trackNumber) return

        let requestData = {
          'ids': selectedIds,
          'delivery_service_id': selectedOption.id,
          'tracking_number': trackNumber
        }

        let text = {
          title: 'common_AreYouSure',
          txt: '',
          yes: 'common_yes',
          no: 'common_cancel'
        }

        let confirm = () => {
          this.$store.dispatch('consolidationUnionDepartConsolidations', requestData).then(response => {
            switch (this.getResponseStatus(response)) {
                /**
                 * Success
                 */
              case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
                this.openNotify('success', 'common_notificationRecordChanged')
                //filter to upgrade table after changing items
                this.filter()
                break
              }
                /**
                 * Validation Error
                 */
              case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
                let errors = response.response.data.errors;
                this.notifyErrorHelper(errors)
                break
              }
                /**
                 * Undefined Error
                 */
              default: {
                this.openNotify('error', 'common_notificationUndefinedError')
              }
            }
          })

          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)
      },

      acceptInMinnesota(id = false) {
        let selectedIds = this.getCheckedRows('row-name')
        if (id) {
          selectedIds = [id]
        }

        if (!selectedIds.length) return

        let requestData = {
          'ids': selectedIds,
        }

        let text = {
          title: 'common_AreYouSure',
          txt: '',
          yes: 'common_yes',
          no: 'common_cancel'
        }

        let confirm = () => {
          this.$store.dispatch('consolidationUnionReceiveUnions', requestData).then(response => {
            switch (this.getResponseStatus(response)) {
                /**
                 * Success
                 */
              case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
                this.openNotify('success', 'common_notificationRecordChanged')
                //filter to upgrade table after changing items
                this.filter()
                break
              }
                /**
                 * Validation Error
                 */
              case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
                let errors = response.response.data.errors;
                this.notifyErrorHelper(errors)
                break
              }
                /**
                 * Undefined Error
                 */
              default: {
                this.openNotify('error', 'common_notificationUndefinedError')
              }
            }
          })

          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)
      },

      getConsolidationUnionProformByState(id, state) {
        this.$store.dispatch('getConsolidationUnionProformFileByState', {id, state}).then((response) => {
          switch (this.getResponseStatus(response)) {
              /**
               * Success
               */

            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
              const blob = new Blob(["\ufeff", response.data], {type: 'application/csv'})
              const link = document.createElement('a')
              link.href = URL.createObjectURL(blob)
              link.download = `Proforma ${id}.csv`
              link.click()
              URL.revokeObjectURL(link.href)

              break
            }
              /**
               * Validation Error
               */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
              /**
               * Undefined Error
               */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }

        }).catch(error => this.createErrorLog(error))
      },

      getConsolidationUnionProform(id) {
        let requestData = {
          'consolidation_union_id': id,
        }

        this.$store.dispatch('getConsolidationUnionProformFile', requestData).then((response) => {
          switch (this.getResponseStatus(response)) {
              /**
               * Success
               */

            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
              const blob = new Blob(["\ufeff", response.data], {type: 'application/csv'})
              const link = document.createElement('a')
              link.href = URL.createObjectURL(blob)
              link.download = `Proforma ${id}.csv`
              link.click()
              URL.revokeObjectURL(link.href)

              break
            }
              /**
               * Validation Error
               */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
              /**
               * Undefined Error
               */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }

        }).catch(error => this.createErrorLog(error))
      },

      downloadFiles(type) {
        let forPage, page, url, exportIds

        exportIds = this.getCheckedRows('row-name')

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(false, this)

        page = generateMainFilter.page
        forPage = generateMainFilter.forPage

        url = this.generateFilterUrl(page, forPage, exportIds)

        this.$store.dispatch('getExportConsolidationUnion', {
          systemType: this.filterType,
          filter: url,
          type: type
        }).then((response) => {
          const blob = new Blob([response.data], {type: `application/${type}`})
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = `Consolidations report.${type}`
          link.click()
          URL.revokeObjectURL(link.href)
        })

      },

    }
  }
</script>

<style scoped>

</style>
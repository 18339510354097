var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"detail-page reverse"},[_c('div',{staticClass:"detail-page__left"},[_c('CardLeftBlock',{staticClass:"clear-992",attrs:{"backgroundImage":'product-admin'}},[_c('template',{slot:"body"},[_c('div',{staticClass:"card-detail-left__date"},[_vm._v(" "+_vm._s(_vm._f("moment")(new Date(),"DD MMM, YYYY"))+" ")])])],2)],1),_c('div',{staticClass:"detail-page__right"},[_c('CardRightBlock',[_c('template',{slot:"header"},[_c('div',{staticClass:"order-create__head"},[_c('div',{staticClass:"order-create__head-line"},[_c('div',{staticClass:"order-create__header-col",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                  'consolidationUnion_backTo',
                  ])}}}),_c('router-link',{staticClass:"detail-page__head-back",attrs:{"to":_vm.$store.getters.GET_PATHS.reportsConsolidation}},[_c('LinkBack',{attrs:{"value":_vm.$t('consolidationUnion_backTo.localization_value.value')}})],1)],1)]),_c('div',{staticClass:"order-create__head-title",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                  'consolidationUnion_concolidation',
                  ])}}}),_vm._v(" "+_vm._s(_vm.$t('consolidationUnion_concolidation.localization_value.value'))+" ")]),_c('div',{staticClass:"order-create__head-txt mb-2",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                  'consolidationUnion_consolidationCreateText',
                  ])}}}),_vm._v(" "+_vm._s(_vm.$t('consolidationUnion_consolidationCreateText.localization_value.value'))+" ")])])]),_c('template',{slot:"body"},[_c('div',{staticClass:"order-create__section"},[_c('FieldsBlock',{attrs:{"CU":_vm.CU,"warehousesData":_vm.warehousesData,"carriers":_vm.carriers,"shippingCompanies":_vm.shippingCompanies}}),_c('div',{staticClass:"order-create__row custom-row"},[_c('div',{staticClass:"custom-col custom-col--66 custom-col--md-100",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                  'consolidationUnion_comment',
                  ])}}}),_c('TextareaDefault',{attrs:{"label":_vm.$t('consolidationUnion_comment.localization_value.value')},model:{value:(_vm.CU.data.comment),callback:function ($$v) {_vm.$set(_vm.CU.data, "comment", $$v)},expression:"CU.data.comment"}})],1)]),_c('ScannerBlock',{attrs:{"check":false,"CU":_vm.CU,"isCreate":true},on:{"showWarningWrongStatePopup":_vm.showWarningWrongStatePopup,"saveConsolidationUnion":function($event){return _vm.$emit('saveConsolidationUnion')}}})],1)]),_c('template',{slot:"footer"},[_c('div',{staticClass:"order-create__footer"},[_c('div',{staticClass:"order-create__footer-link",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                  'common_cancel',
                  ])}}}),_c('router-link',{staticClass:"site-link site-link--alt",attrs:{"to":_vm.$store.getters.GET_PATHS.reportsConsolidation}},[_vm._v(" "+_vm._s(_vm.$t('common_cancel.localization_value.value'))+" ")])],1)])])],2)],1),(_vm.isWarningWrongStatePopup)?_c('WrongStateWarningPopup',{on:{"close":function($event){_vm.isWarningWrongStatePopup = false}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"order-create__product custom-row",class:{hover: _vm.isHovering2},on:{"mouseover":function($event){_vm.isHovering2 = _vm.canRemoveProduct},"mouseout":function($event){_vm.isHovering2 = false}}},[_c('div',{staticClass:"order-create__product-col custom-col custom-col--md-100 position-relative d-flex",class:{
         'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate(),
         'custom-col--66': !_vm.typePopup,
         'custom-col--83': _vm.largeNameColumn
         }},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate(['fbm_ProductName', 'fbm_IncludesProducts'])}}}),(_vm.canRemoveProduct)?_c('div',{staticClass:"order-create__product-delete"},[_c('span',{on:{"click":function($event){return _vm.$emit('removeProduct')}}},[_c('DeleteProductBtn')],1)]):_vm._e(),(!_vm.autoloadSelect)?_c('DefaultSelect',{staticClass:"w-100",class:{'ui-no-valid': _vm.item.validation.productObject},attrs:{"label":_vm.$t('fbm_ProductName.localization_value.value'),"options":_vm.optionsProducts,"otherValue":'product',"caption":_vm.productCaption,"optionsLabel":_vm.productLabel,"errorTxt":_vm.serverError ? _vm.item.validationTxt.productObject : _vm.$t(`${_vm.item.validationTranslate.productObject}.localization_value.value`),"error":_vm.item.validation.productObject,"selected":_vm.item.product.productObject,"disabled":_vm.disabled},on:{"change":(val) => _vm.$emit('changeProductName', _vm.item, val)}}):_c('AutoloadSelect',{staticClass:"w-100",class:{'ui-no-valid': _vm.item.validation.productObject},attrs:{"label":_vm.$t('fbm_ProductName.localization_value.value'),"options":_vm.optionsProducts,"otherValue":'product',"caption":_vm.productCaption,"optionsLabel":_vm.productLabel,"errorTxt":_vm.serverError ? _vm.item.validationTxt.productObject : _vm.$t(`${_vm.item.validationTranslate.productObject}.localization_value.value`),"error":_vm.item.validation.productObject,"selected":_vm.item.product.productObject,"disabled":_vm.disabled},on:{"change":(val) => _vm.$emit('changeProductName', _vm.item, val)}})],1),(_vm.count)?_c('div',{staticClass:"order-create__product-col custom-col custom-col--md-100",class:{
         'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate(),
         'custom-col--16': !_vm.typePopup && !_vm.largeCountColumn,
         'custom-col--50': _vm.typePopup,
         'custom-col--33': _vm.largeCountColumn
         }},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate(['fbm_ItemsQty',])}}}),_c('InputQuantity',{class:{'ui-no-valid': _vm.item.validation.count},attrs:{"value":_vm.item.product.count,"label":_vm.$t('fbm_ItemsQty.localization_value.value'),"errorTxt":_vm.serverError ? _vm.item.validationTxt.count : _vm.$t(`${_vm.item.validationTranslate.count}.localization_value.value`),"error":_vm.item.validation.count,"disabled":_vm.disabledAllFields},on:{"change":val => _vm.$emit('changeProductQuantity', _vm.item, val)}})],1):_vm._e(),(_vm.price)?_c('div',{staticClass:"order-create__product-col custom-col custom-col--md-100",class:{
         'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate(),
         'custom-col--16': !_vm.typePopup && !_vm.largeCountColumn,
         'custom-col--50': _vm.typePopup,
         'custom-col--33': _vm.largeCountColumn
         }},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate(['fbm_ProductPrice',])}}}),_c('InputSum',{class:{'ui-no-valid': _vm.item.validation.price},attrs:{"label":_vm.$t('fbm_ProductPrice.localization_value.value'),"icoType":'dollar',"numeric":true,"placeholder":'0',"errorTxt":_vm.$t(`${_vm.item.validationTranslate.price}.localization_value.value`),"error":_vm.item.validation.price,"disabled":_vm.disabledAllFields},model:{value:(_vm.item.product.price),callback:function ($$v) {_vm.$set(_vm.item.product, "price", $$v)},expression:"item.product.price"}})],1):_vm._e(),(_vm.description)?_c('div',{staticClass:"order-create__product-col custom-col",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate(['fbm_ProductDescription',])}}}),_c('DefaultInput',{class:{'ui-no-valid': _vm.item.validation.description},attrs:{"label":_vm.$t('fbm_ProductDescription.localization_value.value'),"errorTxt":_vm.$t(`${_vm.item.validationTranslate.description}.localization_value.value`),"error":_vm.item.validation.description,"autocompleteOff":true,"disabled":_vm.disabledAllFields,"isOnlyLetters":true},model:{value:(_vm.item.product.description),callback:function ($$v) {_vm.$set(_vm.item.product, "description", $$v)},expression:"item.product.description"}})],1):_vm._e(),(_vm.hsCode)?_c('div',{staticClass:"order-create__product-col custom-col custom-col--sm-100",class:{
         'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate(),
         'custom-col--25': !_vm.typePopup && !_vm.largeCountColumn,
         'custom-col--50': _vm.typePopup,
         'custom-col--33': _vm.largeCountColumn
         }},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate(['fbm_ProductHsCode',])}}}),_c('DefaultInput',{class:{'ui-no-valid': _vm.item.validation.hsCode},attrs:{"label":_vm.$t('fbm_ProductHsCode.localization_value.value'),"errorTxt":_vm.$t(`${_vm.item.validationTranslate.hsCode}.localization_value.value`),"error":_vm.item.validation.hsCode,"autocompleteOff":true,"disabled":_vm.disabledAllFields},model:{value:(_vm.item.product.hsCode),callback:function ($$v) {_vm.$set(_vm.item.product, "hsCode", $$v)},expression:"item.product.hsCode"}})],1):_vm._e(),_c('div',{staticClass:"order-create__product-col custom-col custom-col--16 custom-col--md-0 mb-0"})])
}
var staticRenderFns = []

export { render, staticRenderFns }
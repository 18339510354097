<template>
  <div class="table-filter-wrap">
    <template
            v-if="$store.getters.getFillClaim.length > 0 && $store.getters.getFillClaimLoading !== true">
      <div class="site-table-wrap products-table"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
            'fillClaim_Id',
            'fillClaim_TrackingNumber',
            'fillClaim_Created',
            'fillClaim_ShipDate',
            'fillClaim_CloseDate',
            'fillClaim_ReasonToClaim',
            'fillClaim_Value',
            'fillClaim_Comment',
            'fillClaim_ResultStatuses',
            'fillClaim_Status',
            'common_manage',
          ])"></div>
        <table class="site-table" v-if="!isMobileFunc()">
          <thead>
          <tr>
            <th>{{ $t('fillClaim_Id.localization_value.value') }}</th>
            <th>{{ $t('fillClaim_TrackingNumber.localization_value.value') }}</th>
            <th>{{ $t('fillClaim_Created.localization_value.value') }}</th>
            <th v-if="isAdmin"> {{ $t('common_user.localization_value.value')}}</th>
            <th>{{ $t('fillClaim_ShipDate.localization_value.value') }}</th>
            <th>{{ $t('fillClaim_CloseDate.localization_value.value') }}</th>
            <th>{{ $t('fillClaim_ReasonToClaim.localization_value.value') }}</th>
            <th>{{ $t('fillClaim_Value.localization_value.value') }}</th>
            <th>{{ $t('fillClaim_Comment.localization_value.value') }}</th>
            <th>{{ $t('fillClaim_ResultStatuses.localization_value.value') }}</th>
            <th>{{ $t('fillClaim_Status.localization_value.value') }}</th>
            <th>{{ $t('common_manage.localization_value.value') }}</th>
          </tr>
          </thead>
          <tbody>

          <tr v-for="(item, index) in $store.getters.getFillClaim" :key="index">
            <td><span class="table-link btn-style"
                      @click="changeDetailClaimPopup(true, item.id)"
            >#{{item.id}}</span></td>
            <td>
              <v-popover
                  class="site-tooltip"
                  offset="5"
                  placement="top"
                  trigger="hover"
              >
                <div class="table-row ellipsis">
                  {{item.tracking_number}}
                </div>
                <template slot="popover">
                  <p>{{ item.tracking_number }}</p>
                </template>
              </v-popover>
            </td>
            <td>{{ item.created_at | moment("DD MMM, YYYY") }}</td>
            <td v-if="isAdmin">
              <TableUserColumn
                      v-if="isAdmin"
                      :item="item"
              />
            </td>
            <td>{{ item.shipping_date | moment("DD MMM, YYYY") }}</td>
            <td>
              <ValueHelper
                  :value="item.close_date"
                  :date="true"
                  :dateType="'DD MMM, YYYY'"
              />
            </td>
            <td>
              {{$store.getters.getFillClaimCommonList.reasons[item.reason]}}
            </td>
            <td class="text-right">
              <ValueHelper
                      :value="item.declared_value"
              />
            </td>
            <td>
              <TooltipTextHelper
                      :fullTextWidth="150"
                      :text="item.comment"
                      :paragraph="item.comment"
              />
            </td>
            <td>
              <ValueHelper
                      v-if="item.status_result"
                      :value="$store.getters.getFillClaimCommonList.status_results[item.status_result]"
              />
              <span v-else>—</span>
            </td>
            <td>
              <div class="d-flex">
                <StatusIcoBtn v-if="item.status === 'open'" class="mr-1" :type="'delivered'"/>
                <StatusIcoBtn v-if="item.status === 'draft'" class="mr-1" :type="'in-progress'"/>
                <StatusIcoBtn v-if="item.status === 'in_process'" class="mr-1" :type="'in-transit'"/>
                <StatusIcoBtn v-if="item.status === 'close'" class="mr-1" :type="'cancel'"/>
                <div style="position: relative; top: 2px; white-space: nowrap;">
                  {{$store.getters.getFillClaimCommonList.statuses[item.status]}}
                </div>
              </div>
            </td>
            <td>
              <div class="table-right table-manage-list">
                <ManagerButton @reloadManagerBtn="$store.commit('setGlobalUpdateManagerButton', Math.random()) " :key="$store.getters.getGlobalUpdateManagerButton">
                  <template slot="item"
                            v-if="!isStatusClose(item.status) || isAdmin">
                    <LinkButton
                            :value="$t('common_edit.localization_value.value')"
                            :type="'edit'"
                            :link="$store.getters.GET_PATHS.problemsFillClaim + '/' + item.id"
                    />
                  </template>
                  <template slot="item"
                            v-if="isStatusDraft(item.status)">
                    <LinkButton
                            :value="$t('common_delete.localization_value.value')"
                            :type="'delete'"
                            @click.native="deleteItem(item.id)"
                    />
                  </template>
                </ManagerButton>
              </div>
            </td>
          </tr>

          </tbody>
        </table>

        <div class="table-card" v-if="isMobileFunc()">
          <div class="table-card__list">
            <div class="table-card__item"
                 v-for="(item, index) in $store.getters.getFillClaim"
                 :key="index"
            >
              <FillClaimMobileTable
                      :item="item"
                      @deleteItem = "deleteItem(item.id)"
                      @changeDetailClaimPopup="changeDetailClaimPopup(true, item.id)"
              />
            </div>
          </div>
        </div>

        <div class="table-bottom-btn">
          <div class="table-bottom-btn__inner">
            <ShowMore
                    v-if="$store.getters.getFillClaimCommonList.next_page_url !== null && $store.getters.getFillClaim.length > 0"
                    @click.native="$emit('showMore')"
                    v-bind:class="{'disabled-btn' : $store.getters.getNextFillClaimPage}"
                    :count="$store.getters.getFillClaimCommonList.total - $store.getters.getFillClaimForPage * $store.getters.getFillClaimCommonList.current_page < $store.getters.getFillClaimForPage ?
                    $store.getters.getFillClaimCommonList.total - $store.getters.getFillClaimForPage * $store.getters.getFillClaimCommonList.current_page:
                    $store.getters.getFillClaimForPage"
            />
            <ExportBtn
                :customType="_.has($store.getters.getFillClaimCommonList, 'addition_export') ? $store.getters.getFillClaimCommonList.addition_export : null"
                @downloadFiles="type => $emit('downloadFiles', type)"
                class="table-bottom-btn__right"/>
          </div>
        </div>
      </div>
    </template>

    <template
            v-if="$store.getters.getFillClaimLoading === false && $store.getters.getFillClaim.length === 0">
      <NoResult
              :countFilterParams="countFilterParams"
              :title="$t('common_noDataYet.localization_value.value')"
              :titleWithFilter="$t('common_noResult.localization_value.value')"
              :text="$t('common_noDataYetTxt.localization_value.value')"
              :textWithFilter="$t('common_noResultSeems.localization_value.value')"
              @resetFilter="$emit('resetFilter')"
      />
    </template>


    <DetailClaimPopup
            v-if="isModalDetailClaimPopup"
            @close="changeDetailClaimPopup(false)"
            @openPdfPopup="changePdfClaimPopup(true)"
            :id="itemId"
    />

    <PdfClaimPopup
            v-if="isModalPdfClaimPopup"
            @close="changePdfClaimPopup(false)"
    />
  </div>
</template>

<script>
  import ShowMore from "../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
  import LinkButton from "../../../../../../UI/buttons/LinkButton/LinkButton";
  import ManagerButton from "../../../../../../UI/buttons/ManagerButton/ManagerButton";
  import {mixinDetictingMobile} from '../../../../../../../mixins/mobileFunctions'
  import StatusIcoBtn from "../../../../../../UI/status/StatusIcoBtn/StatusIcoBtn";
  import DetailClaimPopup from "../../../../popups/DetailClaimPopup/DetailClaimPopup";
  import PdfClaimPopup from "../../../../popups/PdfClaimPopup/PdfClaimPopup";
  import NoResult from "../../../../../../coreComponents/NoResult/NoResult";
  import FillClaimMobileTable from "./FillClaimMobileTable/FillClaimMobileTable";
  import TableUserColumn from "../../../../../../coreComponents/TableComponents/TableUserColumn/TableUserColumn";
  import ValueHelper from "../../../../../../coreComponents/ValueHelper/ValueHelper";
  import TooltipTextHelper from "../../../../../../coreComponents/TooltipTextHelper/TooltipTextHelper";
  import {fillClaimMixin} from "../../../../../../../mixins/fillClaimMixins/fillClaimMixin";
  import ExportBtn from '../../../../../../coreComponents/Buttons/Export/Export'


  export default {
    name: "FillClaimTable",

    components: {
      TooltipTextHelper,
      ValueHelper,
      TableUserColumn,
      FillClaimMobileTable,
      NoResult,
      ShowMore,
      LinkButton,
      ManagerButton,
      StatusIcoBtn,
      DetailClaimPopup,
      PdfClaimPopup,
      ExportBtn,
    },

    mixins: [mixinDetictingMobile, fillClaimMixin],

    props: {
      countFilterParams: Number,
      createData: Object,
    },

    // mounted() {
    //   this.isMobile = this.isMobileFunc
    //
    //   window.addEventListener('resize', () => {
    //     this.isMobile = this.isMobileFunc
    //   })
    // },

    data() {
      return {
        itemId: '',
        showFilter: false,
        isMobile: false,

        showContent: false,

        isModalDetailClaimPopup: false,
        isModalPdfClaimPopup: false,

        items: [
          {
            item: 'empty',
            draft: false,
            show: false
          },
          {
            item: 'empty',
            draft: true,
            show: false
          },
          {
            item: 'empty',
            draft: false,
            show: false
          },
        ]

      }
    },

    methods: {


      changeDetailClaimPopup(val, id = false) {
        if(id !== false){
          this.itemId = parseInt(id)
        }
        this.isModalDetailClaimPopup = val
      },

      changePdfClaimPopup(val) {
        this.isModalPdfClaimPopup = val
      },

      deleteItem(id) {
        let text = {
          title: 'common_deleteConfirm',
          txt: '',
          yes: 'common_confirm',
          no: 'common_confirmNo'
        }

        let confirm = () => {
          this.$store.dispatch('deleteFillClaim', id).then(response => {
            if (this._.has(response, 'data') && response.data.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
              this.openNotify('success', 'common_notificationRecordDeleted')
              this.$emit('reload')
            } else {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          })

          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)
      },
    }

  }
</script>

<style lang="scss" scoped>
  @import "../../../../../../../scss/mixins/mixins";

  .products-table .table-manage-list .manager-btn {
    width: 165px;
  }

  .products-table .site-table tbody td:last-child {
    padding-right: 15px;
  }

  .products-table .ellipsis {
    max-width: 320px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inherit;
  }


  @include from-1800 {
    th:last-child,
    td:last-child {
      padding-left: 50px !important;
      padding-right: 20px !important;
    }
  }

  @include for-1200 {
    .products-table .ellipsis {
      max-width: 150px;
    }
    .products-table .table-manage-list .manager-btn {
      width: 70px;
    }
  }

  .table-card__item-number {
    margin-bottom: 10px;
  }

  .site-table .ellipsis {
    max-width: 140px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    display: inline-block;
  }

</style>

<template>
  <DisposalOrdersEditBlock
      v-if="loaded"
      :DO="DO"
      @prepareSave="prepareSave"
      @saveInDraft="saveInDraft"
  />
</template>

<script>
  import DisposalOrdersEditBlock from "./DisposalOrdersEditBlock/DisposalOrdersEditBlock.vue";
  import { DisposalOrders } from "../../models/DisposalOrders";
  import {disposalMixin} from "@/mixins/disposalMixins/disposalMixin";

  export default {
    name: "DisposalOrdersEdit",

    components:{
      DisposalOrdersEditBlock,
    },

    mixins: [disposalMixin],

    watch: {
      loadUserAuthorizedData() {
        this.DO.data.User.setUser(this.loadUserAuthorizedData.user);
      }
    },
    data() {
      return {
        DO: new DisposalOrders(),
        loaded: false,
      }
    },
    mounted() {
      // if (this.loadUserAuthorizedData && !this.isAdmin) {
      //   this.DO.data.User.setUser(this.loadUserAuthorizedData.user);
      // }

      let orderId = this.$route.params.id

      let dispatchType = 'getStorageDocumentsEdit'
      if(this.isAdmin) dispatchType = 'getStorageDocumentsDisposal'

      this.$store.dispatch(dispatchType, orderId).then(response => {
        let order = this.getRespData(response)
        this.DO.setItem(order)
        this.loaded = true
      }).catch(error => this.createErrorLog(error))

    },
    methods: {

    },
  }
</script>

<style scoped>

</style>

import { render, staticRenderFns } from "./APCDelconTableMobile.vue?vue&type=template&id=f604fc54&scoped=true"
import script from "./APCDelconTableMobile.vue?vue&type=script&lang=js"
export * from "./APCDelconTableMobile.vue?vue&type=script&lang=js"
import style0 from "./APCDelconTableMobile.vue?vue&type=style&index=0&id=f604fc54&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f604fc54",
  null
  
)

export default component.exports
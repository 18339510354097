import {POLAND_COUNTRY_ID, USA_COUNTRY_ID} from "../staticData/staticVariables";

var _ = require('lodash');

export const filterCountries = (countries) => {

  let country = {
    ua: {
      index: '',
      item: {}
    },
    bel: {
      index: '',
      item: {}
    }
  };

  _.find(countries, (arr, i) => {

    if(arr.label === 'Ukraine') {
      country.ua.index = i;
      country.ua.item = arr;
    }

    // if(arr.label === 'Belarus') {
    //   country.bel.index = i + 1;
    //   country.bel.item = arr;
    // }

    // if(country.ua.index !== '' && country.bel.index !== ''){
    //   return;
    // }

    if(country.ua.index !== ''){
      return;
    }

  });

  if(country.ua.index !== ''){
    countries.splice(country.ua.index, 1);
    countries.unshift(country.ua.item);
  }

  if(country.bel.index !== ''){
    countries.splice(country.bel.index, 1);
    countries.unshift(country.bel.item);
  }

  return {countries, country}
}

export const filterCountriesUaBel = (countries) => {

  let country = {
    ua: {
      index: -1,
      item: {}
    },
    // bel: {
    //   index: -1,
    //   item: {}
    // }
  };

  _.find(countries, (arr, i) => {

  // && country.bel.index !== -1
    if(country.ua.index !== -1 ){
      return;
    }

    if(arr.label === 'Ukraine') {
      country.ua.index = i;
      country.ua.item = arr;
    }

    // if(arr.label === 'Belarus') {
    //   country.bel.index = i;
    //   country.bel.item = arr;
    // }

  });

  // if(country.bel.index !== ''){
  //   countries.splice(country.bel.index, 1)
  //   countries.unshift(country.bel.item)
  // }

  if(country.ua.index !== ''){
    countries.splice(country.ua.index, 1);
    countries.unshift(country.ua.item);
  }

  return {countries, country}
}

export const filterCountryPoland = (countries) => {

  const targetId = POLAND_COUNTRY_ID;

  const targetIndex = countries.findIndex(country => country.id === targetId);

  if (targetIndex !== -1) {
    const [targetCountry] = countries.splice(targetIndex, 1);
    countries.unshift(targetCountry);
  }

  return {countries,
    pl: {
      index: '',
      item: {}
    },
  }
}

export const filterCountriesUSA = (countries) => {

  let country = {
    USA: {
      index: '',
      item: {}
    },
  };

  _.find(countries, (arr, i) => {

    if(arr.id === USA_COUNTRY_ID) {
      country.USA.index = i;
      country.USA.item = arr;
    }

    if(country.USA.index !== ''){
      return;
    }

  });

  if(country.USA.index !== ''){
    countries.splice(country.USA.index, 1);
    countries.unshift(country.USA.item);
  }

  return {countries, country}
}



<template>
  <div class="fragment">
    <template v-if="$store.getters.getShippingMethodsForOrder.length > 0 && $store.getters.getShippingMethodsForOrderLoading !== true">
      <div class="custom-row accounts-table">
        <div class="custom-col custom-col--20 custom-col--xs-100 custom-col--md-50 custom-col--xl-33"
             v-for="(item, index) in $store.getters.getShippingMethodsForOrder"
             :key="index">
          <div class="case-cart">
            <div class="case-cart__inner">
              <div class="case-cart__columns">
                <div class="case-cart__content-block">
                  <div class="case-cart__head">
                    <div class="case-cart__title pt-3 d-flex">
                      <div class="color-black pr-2 name-field">
                        {{ item.name }}
                      </div>
                    </div>
                  </div>
                  <div class="case-cart__body">
                    <div class="case-cart__row custom-row">
                      <div class="case-cart__col custom-col">
                        <div class="case-cart__label"
                             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                          <div class="admin-edit" @click="editTranslate(['shippingCompany_shippingCompanyForOrder'])"></div>
                          {{$t('shippingCompany_shippingCompanyForOrder.localization_value.value')}}
                        </div>
                        <div class="case-cart__content">
                          <ValueHelper
                              :value="item"
                              :deep="'shipping_company.name'"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="case-cart__bottom">
                <div class="case-cart__btn ml-auto"
                     v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}"
                >
                  <div class="admin-edit" @click="editTranslate(['common_delete'])"></div>
                  <CaseCartButton
                      class="secondary-brown"
                      :value="$t('common_delete.localization_value.value')"
                      :ico="true"
                      :typeIco="'delete-brown'"
                      @click.native="removeItem(item.id, index)"
                  >
                  </CaseCartButton>
                </div>
                <div class="case-cart__btn accounts-table__case-cart-button ml-auto"
                     v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}"
                >
                  <div class="admin-edit" @click="editTranslate(['common_edit'])"></div>
                  <CaseCartButton
                      :value="$t('common_edit.localization_value.value')"
                      :ico="true"
                      :typeIco="'edit'"
                      @click.native="editShippingMethodsForOrderPopup(item.id)"
                  >
                  </CaseCartButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="table-bottom-btn">
        <div class="table-bottom-btn__inner">
          <ShowMore
              v-if="$store.getters.getShippingMethodsForOrderCommonList.next_page_url !== null && $store.getters.getShippingMethodsForOrder.length > 0"
              @click.native="$emit('showMore')"
              v-bind:class="{'disabled-btn' : $store.getters.getNextShippingMethodsForOrderPage}"
              :count="$store.getters.getShippingMethodsForOrderCommonList.total - $store.getters.getShippingMethodsForOrderForPage < $store.getters.getShippingMethodsForOrderForPage ?
                  $store.getters.getShippingMethodsForOrderCommonList.total - $store.getters.getShippingMethodsForOrderForPage:
                  $store.getters.getShippingMethodsForOrderForPage"
          />
        </div>
      </div>

    </template>

    <template
        v-if="$store.getters.getShippingMethodsForOrderLoading === false && $store.getters.getShippingMethodsForOrder.length === 0">
      <NoResult
          :countFilterParams="countFilterParams"
          :title="$t('common_noDataYet.localization_value.value')"
          :titleWithFilter="$t('common_noResult.localization_value.value')"
          :text="$t('common_noDataYetTxt.localization_value.value')"
          :textWithFilter="$t('common_noResultSeems.localization_value.value')"
      />
    </template>


    <ShippingMethodsForOrderPopup
        v-if="isModalShippingCompanyPopup"
        :openType="'edit'"
        :deliverySystemId="deliverySystemId"
        @reload="$emit('reload')"
        @close="changeShippingCompanyPopup(false)"
    />

  </div>
</template>

<script>
import {mixinDetictingMobile} from '../../../../../../../../mixins/mobileFunctions'
import CaseCartButton from "../../../../../../../UI/buttons/CaseCartButton/CaseCartButton";
import ShowMore from "../../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
import NoResult from "../../../../../../../coreComponents/NoResult/NoResult";
import {DELIVERY_DESTINATIONS} from "@/staticData/staticVariables";
import ShippingMethodsForOrderPopup
  from "@/components/modules/ShippingCompanyModule/popups/ShippingMethodsForOrderPopup/ShippingMethodsForOrderPopup";
import ValueHelper from "../../../../../../../coreComponents/ValueHelper/ValueHelper";


export default {
  name: "ShippingMethodsForOrderTable",

  components: {
    ValueHelper,
    ShippingMethodsForOrderPopup,
    NoResult,
    ShowMore,
    CaseCartButton,
  },

  mixins: [mixinDetictingMobile],

  props: {
    countFilterParams: Number,
  },


  data() {
    return {
      deliverySystemId: -1,
      showFilter: false,
      isMobile: false,
      showContent: false,
      isModalShippingCompanyPopup: false,

      DELIVERY_DESTINATIONS: DELIVERY_DESTINATIONS,
    }
  },

  methods: {
    getDestinationLabelById(id) {
      return this._.find(DELIVERY_DESTINATIONS, {id: id})?.label
    },

    changeShippingCompanyPopup(val) {
      this.isModalShippingCompanyPopup = val
    },

    editShippingMethodsForOrderPopup(id) {
      this.deliverySystemId = id
      this.changeShippingCompanyPopup(true)
    },


    closePopup() {
      // this.isModalProductsToWarehouse = false
    },

    removeItem(id, index = false) {
      let text = {
        title: 'common_deleteConfirm',
        txt: 'common_deleteItemAsk',
        yes: 'common_confirmDelete',
        no: 'common_confirmNo'
      }

      let confirm = () => {

        this.$store.dispatch('deleteShippingMethodsForOrder', id).then((response) => {

          if (!this._.has(response, 'data')) return this.openNotify('error', 'common_notificationUndefinedError')

          switch (this.getResponseStatus(response)) {
              /**
               * Success
               */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
              if (index !== false) {
                this.$store.getters.getShippingMethodsForOrder.splice(index, 1)
              }
              // this.$emit('reload')

              this.openNotify('success', 'common_notificationRecordDeleted')
              break
            }
              /**
               * Validation Error
               */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
              /**
               * Undefined Error
               */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }

        })

        this.$store.commit('setConfirmDeletePopup', false)
        return true
      }

      this.deleteFunc(text, confirm)


    },

    changeInfoPopup(val) {
      this.isModalInfoPopup = val
    }

  }

}

</script>

<style scoped lang="scss">

.company-svg-ico svg {
  display: inline-flex;
  max-width: 60px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 30px;
}

/deep/ .company-svg-ico svg {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.name-field {
  min-height: 52px;
}
</style>

import {
  DHL_DELIVERY_SERVICE,
  FEDEX_DELIVERY_SERVICE,
  TNT_DELIVERY_SERVICE
} from "../../../../staticData/staticVariables";
import {Express} from "./Express";
const moment = require('moment')

Express.prototype.prepareCreation = function (draft, user, edit = false) {
  let personal = this.express.personal.information,
    delivery = this.express.delivery.delivery,
    packaging = this.express.packaging,
    packagingItem = packaging.preparePackagingItem(),
    ex = this.express,

    data = {
      "user_id": ex.userId !== '' ? ex.userId : user,
      "delivery_service_id": ex.type,

      "tracking_number": ex.systemTrackingNumber,

      "recipient_contact_name": personal.personalName,
      // "recipient_phone_number": personal.personalPhone.replaceAll('-', ''),
      "recipient_phone_number": personal.personalPhone,
      "recipient_email": personal.personalEmail,
      "recipient_address": delivery.address,
      "recipient_additional_address": delivery.address2,
      "recipient_city": delivery.city,
      "recipient_region": delivery.region,
      "recipient_country_id": delivery.country.id,
      "recipient_zip_code": delivery.zip,
      "delivery_date": moment(ex.deliveryDate).format('YYYY-MM-DD'),
      "proform_data": [],
      "proform_packaging": {
        "package_name": packagingItem.package_name,
        "weight": packagingItem.weight,
        "length": packagingItem['length'],
        "width": packagingItem.width,
        "height": packagingItem.height
      },
      "insurance_amount": packaging.getInsurance() ? packaging.getInsurancePrice() : '',
      "in_draft": draft ? 1 : 0,
      "delivery_service_type": this.express.variantLabelPrice,
    }

    // if(this.express.variantLabelPrice === null && ex.type === NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE.id && that) {
    //   that.createErrorLog({stack: 'label price = null'})
    // }

  if(this.express.expressStatusChanged) {
    data['status'] = this.express.expressStatusChanged
  }

  if(FEDEX_DELIVERY_SERVICE.id === this.getExpressType()){
    data['drop_certification'] = ex.dropBallTestCert
    data['toxic_substance'] = ex.toxicSubstanceControlActCert
  }

  data["warehouse_id"] = null;
  data["new_post_tracking_number"] = null;
  if(ex.sendType === ex.sendTypes.warehouse){
    data["send_type"] = "by_warehouse"
    data["warehouse_id"] = ex.sendTypes.warehouse ? ex.warehouse.id : ''
    data["new_post_tracking_number"] = ex.trackingNumber
  } else {
    data["send_type"] = "by_himself"
    data["sender_contact_name"] = ex.senderContactName
    data["sender_phone_number"] = ex.senderPhone
  }

// && ex.isVatNumberByCountry
  if(ex.visibleVatNumber ){
    data['vat_number'] = ex.vatNumber
  } else {
    data['vat_number'] = null
  }

  if(ex.visibleFreightCost) {
      data['freight_cost'] = +ex.freightCost
  }
  else {
      data['freight_cost'] = null
  }

  if(ex.orderPrice !== ''){
    data['total_order_amount'] = ex.orderPrice
  }

  if(ex.typeTransaction.length > 0 && ex.typeTransactionId.length > 0) {
    data['from_model'] = ex.typeTransaction
    data['model_id'] = ex.typeTransactionId
  } else if(ex.orderExternalId > 0 && ex.typeTransaction.length) {
    data['from_model'] = ex.typeTransaction
    data['model_id'] = ex.orderExternalId
  }

  data['available_courier_time'] = null
  data['not_available_courier_time'] = null
  if(ex.type === TNT_DELIVERY_SERVICE.id && ex.sendType === ex.sendTypes.sender){
    data['available_courier_time'] =
      this.express.timeCourierFrom + "|" + this.express.timeCourierTo

    if(ex.lunchBreak){
      data['not_available_courier_time'] =
        this.express.timeNotAvailableFrom + "|" + this.express.timeNotAvailableTo
    } else {
      data['not_available_courier_time'] = null
    }

  }


  if(ex.type === DHL_DELIVERY_SERVICE.id){
    data['drop_certification'] = this.express.dropBallTestCert ? 1 : 0
    data['toxic_substance'] = this.express.toxicSubstanceControlActCert ? 1 : 0
    data['order_express_country_type'] = (this.express.orderCountryType && this.express.user?.user_personal_contact?.country_group?.fromUkraineGroup &&
      !this.express.user?.use_alternative_address) ? this.express.orderCountryType : null
  }

  if(ex.visibleHSCode){
    data['recipient_currency_id'] = ex.currencies !== '' ? ex.currencies.id : null
  }

  let proform = []
  ex.products.map(item => {
    if(this.express.type === FEDEX_DELIVERY_SERVICE.id) {
      item.generateNameForLabel(false)
    }
    proform.push(item.prepareSaveProform(true))
  })

  data['proform_data'] = proform

  if(this.getFedexPackingType() !== ''){
    data['packaging_type'] = this.getFedexPackingType().value
  } else {
    data['packaging_type'] = null
  }

  if(edit) {
    data['deleted_proform_ids'] = {
      proform_data: this.express.proformProductsDeletedIds,
    }
    this.express.proformProductsDeletedIds = []
  }

  if(this.express.novaPoshtaAddress && ex.sendType === ex.sendTypes.sender) {
    data['npg'] = this.express.novaPoshtaAddress
  }

  let promocodes = []
  this.express.promoCodes.map(code => {
    if(code.getCode().length > 0) {
      promocodes.push({code: code.getCode()})
    }
  })

  if(promocodes.length > 0) {
    data['promocodes'] = promocodes
  }

  if(this.express.recipientNeedTax) {
    data['recipient_tax_value'] = this.express.recipientTax
  }

  return data
}

Express.prototype.checkPromoCodePrepareData = function () {
  return {
    'user_id': this.express.user?.id,
    'delivery_service_id': this.express.type,
    'promocodes': this.express.promoCodes.map(code => {return {code: code.getCode()}})
  }
}
<template>
  <div class="fragment">

<!--    <SearchEngine/>-->


    <div class="horiz-block">
      <div class="horiz-block__inner">
        <SetTrackNumberForm
            v-if="!navTabs.sent.active"
            @setTrackForSelectedUnions="(selectedOption, trackNumber) => $emit('setTrackForSelectedUnions', selectedOption, trackNumber)"
        />

        <div class="horiz-block__btn"
          v-if="!navTabs.sent.active"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
        'common_AddNew',
        ])"></div>
          <router-link :to="$store.getters.GET_PATHS.reportsAPCDelconCreate">
            <MainButton class="btn-fit-content ml-2"
                        :value="$t('common_AddNew.localization_value.value')"
                        :ico="true"
            >
              <template slot="ico">
                <PlusIconSVG class="btn-icon-plus" />
              </template>
            </MainButton>
          </router-link>
        </div>
      </div>
    </div>

    <div class="content-top-line content-top-line--with-bottom-line"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate([
          'apcDelcon_all',
          'apcDelcon_sent',
          'apcDelcon_inditateTrackNum',
          'apcDelcon_setForSelected',
        ])"></div>
      <div class="content-tabs content-tabs--separator"
           v-if="Object.keys($store.getters.getUserProfile).length > 0"
      >
        <template v-for="(item, index) in navTabs">
          <div class="content-tabs__item"
               :key="index + loadUserRoleAndAuthorizedData"
               v-bind:class="{active: item.active}"
               v-if="item.permissionVisible"
               @click="$emit('changeTab', item.name)"
          >
            <div class="content-tabs__ico">
              <template v-if="item.ico">
                <img :src="item.ico" alt="company-ico" :width="item.icoWidth">
              </template>
              <template v-else>
                {{item.label}}
              </template>
            </div>
            <span class="content-tabs__item-small">
              {{item.subLabel}}
            </span>
          </div>
        </template>
      </div>

      <div class="content-top-line-wrap">
        <ToggleFilterButton :count="countFilterParams" @toggleFilter="$emit('toggleFilter')" :showFilter="showFilter" />
      </div>
    </div>

    <!--<div class="light-bg-block">-->
      <!--<div class="btn-left-block">-->
        <!--<div class="filter-parcel-track"-->
          <!--v-if="activeTab ===1"-->
        <!--&gt;-->
          <!--<div class="filter-parcel-track__item">-->
            <!--<DefaultSelect-->
                <!--class="w-100"-->
                <!--:options="options"-->
                <!--:label="'Shipping company'"-->
            <!--/>-->
          <!--</div>-->
          <!--<div class="filter-parcel-track__item">-->
            <!--<InputEditField-->
              <!--class="w-100"-->
              <!--:label="'Inditate parcel track number'"-->
              <!--v-model="input01"-->
            <!--/>-->
          <!--</div>-->
          <!--<div class="filter-parcel-track__item wfc">-->
            <!--<MainButton class="btn-fit-content white-space-nowrap"-->
                        <!--:value="'Set for selected'"-->
            <!--&gt;-->
            <!--</MainButton>-->
          <!--</div>-->
        <!--</div>-->
      <!--</div>-->
      <!--<div class="btn-right-block">-->
        <!--<router-link :to="$store.getters.GET_PATHS.financeAPCDelconCreate"-->
                     <!--v-if="activeTab === 1"-->
        <!--&gt;-->
          <!--<MainButton class="btn-fit-content ml-3 white-space-nowrap"-->
                      <!--:value="'+ Add Order'"-->
          <!--&gt;-->
          <!--</MainButton>-->
        <!--</router-link>-->
      <!--</div>-->
    <!--</div>-->



  </div>
</template>



<script>
  import ToggleFilterButton from "../../../../../../UI/buttons/ToggleFilterButton/ToggleFilterButton";
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  // import SearchEngine from "../../../../../../coreComponents/SearchEngine/SearchEngine";
  import SetTrackNumberForm from "./SetTrackNumberForm/SetTrackNumberForm";
  import PlusIconSVG from '../../../../../../../../public/img/UI-group/plus-icon.svg?inline'


  export default {
    name: "ConsolidationUnionHead",
    components: {
      SetTrackNumberForm,
      ToggleFilterButton,
      MainButton,
      // SearchEngine,
      PlusIconSVG,
    },

    props: {
      showFilter: Boolean,
      countFilterParams: Number,
      navTabs: Object,
    },

    computed: {
      activeTabType() {
        return this._.find(this.navTabs, {active: true}).name
      },
    },

    data(){
      return{
        input01: '',
        options: [],
      }
    },

    methods: {

      changeTab(id) {
        this.$emit('changeTab', id)
      },

      changeDate() {
        console.log('changeDate function');
      },

      openHTMLCodePopup(){
        this.changeGenPayPalBtnPopup(false)
        this.changeCodeHTMLPopup(true)
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "../../../../../../../scss/mixins/mixins";
  @import "../../../../../../../scss/colors";



/*
  .light-bg-block{
    min-height: 58px;
    @include for-1120{
      align-items: initial;
    }

    @include for-680{
      flex-direction: column;
    }
  }

  .btn-left-block{
    width: 100%;
  }

  .btn-right-block{
    @include for-680 {
      margin-top: -50px;
    }
  }
*/

  .filter-parcel-track{
    display: flex;
    width: 100%;

    @include for-1120{
      flex-direction: column;
    }

    @include for-680{
      margin-bottom: 15px;
    }

    &__item{
      max-width: 314px;
      width: 100%;
      min-width: 220px;
      display: flex;
      margin-right: 15px;
      align-items: center;

      &.wfc{
        min-width: initial;
      }

      @include for-1120{
        margin-bottom: 15px;

        &:last-child{
          margin-bottom: 0;
        }
      }

      @include for-680{
        max-width: 100%;
      }


      button{
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        height: 40px;
      }
    }
  }

  .horiz-block{
    padding-top: 24px;

    &__inner{
      display: flex;
      align-items: center;
      position: relative;

      @include for-1120{
        align-items: flex-start;
      }

      @include for-680{
        flex-direction: column;
      }
    }

    &__btn{
      @include from-680 {
        margin-left: auto;
      }

      button{
        font-size: 16px;
        line-height: 19px;
        min-width: 110px;
        height: 40px;

        @include for-680 {
          margin-left: 0!important;
        }
      }
    }
  }

  .accept-mn-link{
    padding: 8px;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: $accent;
    transition: .15s;

    &:hover{
      opacity: .7;
    }

    &__ico{
      margin-right: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .content-tabs__item {
    min-width: 142px;
    padding: 0 20px 14px;
  }

  .horiz-block__btn {
    flex-shrink: 0;
  }
</style>


<template>
  <div>
    <ProfilesHead
        @toggleFilter="toggleFilter"
        @filter="$emit('filter')"
        :showFilter="showFilter"
        :navTabs="navTabs"
        @changeTab="(data) => $emit('changeTab', data)"
        :countFilterParams="countFilterParams"
        @reload="$emit('reload')"
    />

    <div class="table-filter-wrap">

      <ProfilesFilter
          v-if="!navTabs.invoiceProduct.active && !navTabs.invoiceSeller.active && !navTabs.sendFrom.active"
          :showFilter="showFilter"
          @close="closeFilter"
          :filterGetParams="filterGetParams"
          @changeFilter="data => $emit('changeFilter', data)"
          @resetFilter="$emit('resetFilter')"
          :selectHandmade="selectHandmade"
          :navTabs="navTabs"
      />

      <ProfilesTable
          @showMore="$emit('showMore')"
          @resetFilter="$emit('resetFilter')"
          :countFilterParams="countFilterParams"
          :navTabs="navTabs"
          @reload="$emit('reload')"
      />
    </div>
  </div>
</template>

<script>

  import ProfilesHead from "./ProfilesHead/ProfilesHead";
  import ProfilesFilter from "./ProfilesFilter/ProfilesFilter";
  import ProfilesTable from "./ProfilesTable/ProfilesTable";

  export default {
    name: "ProfilesTableUser",

    components: {
      ProfilesHead,
      ProfilesFilter,
      ProfilesTable,
    },

    props: {
      navTabs: Object,
      countFilterParams: Number,
      filterGetParams: Object,
      selectHandmade: Array,
    },

    data(){
      return{
        showFilter: false,
      }
    },

    methods: {
      closeFilter() {
        this.showFilter = false
      },

      toggleFilter() {
        this.showFilter = !this.showFilter
      },
    }
  }
</script>

<style scoped>

</style>

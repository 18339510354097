<template>
  <div class="detail-page reverse">
    <div class="detail-page__left">
      <CardLeftBlock
          class="clear-992"
          :backgroundImage="'product-admin'"
      >
        <template slot="body">
          <div class="user-info-admin__large">
            <template v-if="APCD.data.createdAt">
              {{APCD.data.createdAt | moment("DD MMM, YYYY")}}
            </template>
            <template v-else>
              {{ new Date() | moment("DD MMM, YYYY") }}
            </template>
          </div>
        </template>
      </CardLeftBlock>
    </div>

    <div class="detail-page__right">
      <CardRightBlock>
        <template slot="header">
          <div class="order-create__head">
            <div class="order-create__head-line">
              <div class="order-create__header-col"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate([
                  'apcDelcon_backTo',
                  ])"></div>
                <router-link :to="$store.getters.GET_PATHS.reportsAPCDelcon" class="detail-page__head-back">
                  <LinkBack
                      :value="$t('apcDelcon_backTo.localization_value.value')"
                  />
                </router-link>
              </div>
            </div>
            <div class="order-create__head-title"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate([
                  'apcDelcon_apcDelconConso',
                  ])"></div>
              {{$t('apcDelcon_apcDelconConso.localization_value.value')}}
            </div>
            <div class="order-create__head-txt mb-2">

            </div>
          </div>
        </template>

        <template slot="body">
          <div class="order-create__section">
            <ScannerBlock
                :APCD="APCD"
                :ordersFBM="ordersFBM"
            />

            <OrdersFBMBlock
                :APCD="APCD"
                :ordersFBM="ordersFBM"
            />

            <FieldsBlock
                :APCD="APCD"
                :shippingCompanies="shippingCompanies"
            />

            <div class="order-create__row custom-row">
              <div class="custom-col custom-col--md-100"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate([
                  'apcDelcon_comment',
                  ])"></div>
                <TextareaDefault
                    :label="$t('apcDelcon_comment.localization_value.value')"
                    v-model="APCD.data.comment"
                />
              </div>
            </div>

<!--            <div class="order-create__row custom-row"-->
<!--                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--              <div class="admin-edit" @click="editTranslate([-->
<!--                  'consolidationUnion_sendDate',-->
<!--                  ])"></div>-->
<!--              <div class="order-create__col custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;md-50 custom-col&#45;&#45;sm-100">-->
<!--                <DatePickerDefault-->
<!--                    :label="$t('consolidationUnion_sendDate.localization_value.value')"-->
<!--                    :placeholder="$t('common_dateYYYY_MM_DD.localization_value.value')"-->
<!--                    v-bind:class="{'ui-no-valid': APCD.validation.sendAt}"-->
<!--                    :errorTxt="$t(`${APCD.validationTranslate.sendAt}.localization_value.value`)"-->
<!--                    :error="APCD.validation.sendAt"-->
<!--                    v-model="APCD.data.sendAt"-->
<!--                >-->
<!--                  <template slot="body">-->
<!--                    <date-picker-->
<!--                        v-model="APCD.data.sendAt"-->
<!--                        ref="datePicker"-->
<!--                        valueType="format"-->
<!--                        :type="'datetime'"-->
<!--                        :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"-->
<!--                        :format="'YYYY-MM-DD HH:mm'"-->
<!--                        :placeholder="$t('common_dateYYYY_MM_DD.localization_value.value')"-->
<!--                    ></date-picker>-->
<!--                  </template>-->
<!--                </DatePickerDefault>-->
<!--              </div>-->
<!--            </div>-->

          </div>
        </template>
        <template slot="footer">
          <div class="order-create__footer">
            <div class="order-create__footer-link"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate([
                  'common_cancel',
                  ])"></div>
              <router-link :to="$store.getters.GET_PATHS.reportsAPCDelcon"
                           class="site-link site-link--alt"
              >
                {{$t('common_cancel.localization_value.value')}}
              </router-link>

            </div>

            <div class="order-create__footer-btn ml-auto"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate([
                  'common_save',
                  ])"></div>
              <MainButton
                  class="order-create__footer-btn-i"
                  :value="$t('common_save.localization_value.value')"
                  @click.native="$emit('saveAPCDelcon', true)"
              />
            </div>
          </div>
        </template>
      </CardRightBlock>
    </div>

  </div>
</template>

<script>
  import MainButton from "../../../../../UI/buttons/MainButton/MainButton";
  import CardLeftBlock from "../../../../../coreComponents/CardLeftBlock/CardLeftBlock.vue";
  import CardRightBlock from "../../../../../coreComponents/CardRightBlock/CardRightBlock.vue";
  import LinkBack from "../../../../../UI/buttons/LinkBack/LinkBack";
  import TextareaDefault from "../../../../../UI/textarea/TextareaDefault/TextareaDefault";
  import {mixinDetictingMobile} from "../../../../../../mixins/mobileFunctions";
  import FieldsBlock from "../../chunks/FieldsBlock";
  import ScannerBlock from "../../chunks/ScannerBlock";
  // import DatePickerDefault from "../../../../../UI/inputs/DatePickerDefault/DatePickerDefault";
  // import DatePicker from "vue2-datepicker";
  import OrdersFBMBlock from "../../chunks/OrdersFBMBlock";

  export default {
    name: "APCDelconEditAdmin",
    components: {
      OrdersFBMBlock,
      // DatePickerDefault,
      // DatePicker,
      ScannerBlock,
      FieldsBlock,
      MainButton,
      CardLeftBlock,
      CardRightBlock,
      LinkBack,
      TextareaDefault,
    },

    mixins: [mixinDetictingMobile],

    props: {
      APCD: Object,
      ordersFBM: Array,
      shippingCompanies: Array,
    },

    data(){
      return{
        options: [{}],
        valueQuantity: 100,
        comment: 'Please be careful with the vase inside! ',
        input01: '',
        input02: '',
        input03: '',

        optionsMulti: [
          '#49357: $100.4',
          '#49357: $10.4',
          '#49357: $59.2'
        ],
      }
    },
  }
</script>

<style scoped>
  .checkbox-scoped{
    margin-right: -5px;
  }

  td:nth-child(2){
    font-size: 12px;
    line-height: 14px;
  }
</style>

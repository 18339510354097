
export const disposalMixin = {

  methods: {

    saveInDraft(arg) {
      let confirm = () => {
        this.prepareSave(arg)
        this.$store.commit('setSaveInDraftsPopup', false)
        return true
      }

      this.saveInDraftsFunc(confirm)
    },

    prepareSave({ edit = false, draft = false, complete = false }) {
      if (!this.DO.firstValidation(this.isAdmin))
        return

      let data = this.DO.prepareSave(draft, edit, complete)

      let type = 'createDisposalRequest'
      if(this.isAdmin){
        type = 'createStorageDocuments'
      }

      if(edit) {
        type = 'updateDisposalRequest'

        if(this.isAdmin){
          type = 'updateStorageDocuments'
        }

        data = {
          data: data,
          id: this.DO.data.Order.getId()
        }
      }

      this.$store.dispatch(type, data).then(response => {
        if (!this.getResponseStatus(response)) return this.openNotify('error', 'common_notificationUndefinedError')

        switch (this.getResponseStatus(response)) {
          /**
           * Success
           */
          case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
            if (edit) {
              this.openNotify('success', 'common_notificationRecordChanged')
            } else {
              this.openNotify('success', 'common_notificationRecordCreated')
            }

            this.$router.push(this.$store.getters.GET_PATHS.warehouseDisposalOrders)
            break
          }
          /**
           * Validation Error
           */
          case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
            let errors = response.response.data.errors;
            this.notifyErrorHelper(errors)
            break
          }
          /**
           * Undefined Error
           */
          default: {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        }
      })
    }
  }

}

<template>
  <modal
      @close="$emit('close')"
      class="custom-popup medium-size"
  >
    <template slot="header">
      <div class="set__name"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fba_set'])"></div>
        {{ $t(`fba_set.localization_value.value`) }}
<!--        #{{popupType.id === 2 ? setIndex + 1: FBA.data.sets.length + 1}}-->
<!--        <span class="set__type"-->
<!--              v-if="popupType.id === 2"-->
<!--              v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--          <div class="admin-edit" @click="editTranslate(['common_edit'])"></div>-->
<!--          <LinkButton-->
<!--              class="d-inline-flex hover-reset"-->
<!--              :value="$t('common_edit.localization_value.value')"-->
<!--              :type="'edit'"-->
<!--          />-->
<!--        </span>-->
      </div>

    </template>
    <template slot="body">
      <div class="custom-popup__content">
        <div class="custom-row">
          <div class="custom-col custom-col--75 custom-col--sm-75 position-relative d-flex"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['common_sku'])"></div>
            <DefaultInput
              :label="$t('common_sku.localization_value.value')"
              class="w-100"
              v-bind:class="{'ui-no-valid': localSet.validation.skuSet}"
              :error="localSet.validation.skuSet"
              :errorTxt="$t(`${localSet.validationTranslate.skuSet}.localization_value.value`)"
              v-model="localSet.data.skuSet"
            />
          </div>

          <div class="custom-col custom-col--25 custom-col--sm-25 position-relative d-flex"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['common_ItemsQty'])"></div>
            <InputQuantity
              :label="$t('common_ItemsQty.localization_value.value')"
              :value="localSet.data.countSet"
              @change="val => localSet.data.countSet = val"
            />
<!--              v-bind:class="{'ui-no-valid': localSet.validation.countSet}"-->
<!--              :error="localSet.validation.countSet"-->
<!--              :errorTxt="$t(`${localSet.validationTranslate.countSet}.localization_value.value`)"-->
          </div>

          <div class="custom-col" v-if="_.has(localSet.data, 'productsSet') && localSet.data.productsSet.length > 0">
            <template
                    v-for="(item, index) in localSet.data.productsSet">
              <SelectProductBlock
                      v-if="item"
                      :autoloadSelect="true"
                      :initialOptionsProducts="initialOptionsProducts"
                      :byStorageId="FBA.data.Order.data.warehouse.id"
                      :key="index+'product'"
                      :item="item.data.Product"
                      :userId="parseInt(user.userId)"
                      :productLabel="'name_for_fba'"
                      :largeCountColumn="true"
                      :price="false"
                      :description="false"
                      :withDraft="false"
                      @removeProduct="FBA.removeProductForCells(localSet.data.productsSet, index)"
                      @changeProductName="changeProductName"
                      @changeProductQuantity="(product, val) => changeProductQuantity(product, val, item)"
              />
            </template>
          </div>

          <!--<div class="custom-col custom-col&#45;&#45;16 custom-col&#45;&#45;md-50"-->
               <!--v-if="_.has(setDefault, 'productsSet')"-->
          <!--&gt;-->
            <!--<span class="soft-text">-->
              <!--{{setDefault.productsSet.length}} {{$t('fba_item.localization_value.value')}}-->
            <!--</span>-->
          <!--</div>-->
          <div class="custom-col custom-col--50 custom-col--md-50 ml-auto">
            <div class="add-product-btn-wrap mb-0"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['common_AddProduct',])"></div>
              <span class="order-create__product-btn-i add-product-btn site-link"
                    @click="FBA.addProductForCellsToArray(localSet.data.productsSet)">
                + {{ $t('common_AddProduct.localization_value.value') }}
             </span>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template slot="footer">
      <div class="d-flex flex-wrap mt-2 scoped-btns"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
            'common_cancel',
            `fba_${popupType.name}Set`,
            'common_save',
          ])"></div>

            <span class="site-link site-link--alt mr-3 mb-2 mt-2"
                  @click="$emit('close')"
            >
              {{ $t(`common_cancel.localization_value.value`) }}
            </span>

        <MainButton
            class="wfc"
            v-if="isCreatedType"
            :value="$t(`fba_${popupType.name}Set.localization_value.value`)"
            @click.native="createSet"
        />
        <MainButton
            v-if="isUpdatedType"
            class="wfc"
            :value="$t(`common_save.localization_value.value`)"
            @click.native="updateSet"
        />

      </div>
    </template>
  </modal>
</template>

<script>
import Modal from '../../../../commonModals/Modal.vue'
import MainButton from "../../../../UI/buttons/MainButton/MainButton";
import DefaultInput from "../../../../UI/inputs/DefaultInput/DefaultInput";
// import LinkButton from "../../../../UI/buttons/LinkButton/LinkButton";
import SelectProductBlock from "../../../../coreComponents/SelectProductBlock/SelectProductBlock";
import {FBAMixin} from "../../../../../mixins/FBAMixins/FBAMixin";
import {ProductSet} from "../../../../globalModels/ProductSet";
import {productForCellMixin} from "@/mixins/productMixins/productForCellMixin";
import InputQuantity from "@/components/UI/inputs/InputQuantity/InputQuantity";


export default {
  name: "SetPopup",
  components: {
    InputQuantity,
    SelectProductBlock,
    Modal,
    MainButton,
    DefaultInput,
    // LinkButton,
  },

  mixins: [
    FBAMixin,
    productForCellMixin
  ],

  props: {
    FBA: Object,
    sets: Array,
    user: Object,
    popupType: Object,
    setIndex: Number,
    initialOptionsProducts: Array,
  },

  computed: {
    isCreatedType(){
      return this.popupType.id === 1 || this.popupType.id === 3
    },
    isUpdatedType(){
      return this.popupType.id === 2
    },
  },

  data() {
    return {
      localSet: new ProductSet(),
    }
  },

  mounted() {
    // this.FBA.setSetDefault(this.sets[this.setIndex])
    if(this.isCreatedType){
      this.localSet = new ProductSet()
    }
    if(this.isUpdatedType){
      this.localSet = this._.cloneDeep(this.sets[this.setIndex])
    }
  },

  methods: {
    changeProductName(item, val) {
      item.setProduct(val)
    },

    changeProductQuantity(product, val, item) {
      product.setProductCount(val)
      item.data.productCount = val
    },

    createSet(){
      if(!this.localSet.productsInSetValidation()) return
      console.log(this.localSet);
      this.FBA.addNewSet(this.localSet)
      this.localSet.data.productsSet.map(product => {
        this.getCellsForUser(product, this.FBA.data.User.getUserId(), this.FBA.data?.Order.data.warehouse?.id)
      })
      this.$emit('close')
    },

    updateSet(){
      if(!this.localSet.productsInSetValidation()) return
      /**
       * использую set() чтобы get в prepareData выдавал новые данные, а не подтягивал старые
       * (последствие работы cloneDeep)
       */
      this.FBA.data.sets[this.setIndex].setSkuSet(this.localSet.data.skuSet)
      this.FBA.data.sets[this.setIndex].setCountSet(this.localSet.data.countSet)
      this.FBA.data.sets[this.setIndex].setSetProducts(this.localSet.data.productsSet)
      this.FBA.data.sets[this.setIndex].actualizeProductForCellCounts()

      console.log(this.FBA.data.sets[this.setIndex]);
      this.$emit('close')
    },
  },
}

</script>

<style scoped lang="scss">
.scoped-width {
  //max-width: 120px;
}
.scoped-btns {
  display: flex;
  align-items: center;
}
</style>

<template>
	<modal
			@close="$emit('close')"
			class="custom-popup buy-label medium-size"
	>
		<template slot="header">
			<div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
				<div class="admin-edit" @click="editTranslate(['common_buyLabelQuest',])"></div>
				{{$t('common_buyLabelQuest.localization_value.value')}}
			</div>

		</template>
		<template slot="body">
			<div class="buy-label__content">
				<!--<div class="order-create__row custom-row">-->
					<!--<div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
						<!--<div class="admin-edit" @click="editTranslate(['express_DearCustomer',])"></div>-->
						<!--<div class="order-create__col custom-col">-->
							<!--<div class="buy-label-notification" v-html="$t('express_DearCustomer.localization_value.value')"></div>-->
						<!--</div>-->
					<!--</div>-->
				<!--</div>-->


				<!--<div v-if="$store.getters.getExpressLoadingPrice" class="mt-3 mb-3">-->
					<!--{{$t('express_LoadinPrice.localization_value.value')}}-->
				<!--</div>-->

        <div class="date-time"
             v-if="_.has(labelPrice, 'estimated_time_of_arrival') && labelPrice.estimated_time_of_arrival"
						 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
					<div class="admin-edit" @click="editTranslate(['express_EstimatedTimeOfArrival',])"></div>
					{{$t('express_EstimatedTimeOfArrival.localization_value.value')}}
          {{labelPrice.estimated_time_of_arrival | moment("DD/MM/YYYY H:mm:ss")}}
        </div>

				<DotsShadowPreloader
								v-if="Object.keys(labelPrice).length === 0 && $store.getters.getExpressLoadingPrice"
				/>

				<LabelPrice
								v-if="Object.keys(labelPrice).length > 0 && !$store.getters.getExpressLoadingPrice"
								:labelPrice="labelPrice"
                :currentVariant="ex.express.variantLabelPrice"
                @changeVariant="changeVariant"
				/>
								<!--:costDelivery="deliveryServiceId !== FEDEX_DELIVERY_SERVICE.id"-->
				<div v-else
						 class="mt-3 mb-4 error-field position-relative fsz14 text-left">
					{{labelError}}
				</div>

				<div v-if="labelNeedMoneyError"
						 class="mt-0 mb-4 error-field position-relative fsz14">
					{{labelNeedMoneyError}}
				</div>

			</div>
		</template>
		<template slot="footer">
			<div class="buy-label__btn d-flex align-items-center"
					 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
				<div class="admin-edit" @click="editTranslate(['common_cancel','common_payForAuthorize', 'common_buyLabel'])"></div>
        <span
						class="site-link site-link--alt buy-label__btn-i mr-3"
						@click="$emit('close')"
				>
          {{$t('common_cancel.localization_value.value')}}
        </span>

				<MainButton
						:value="$t('common_ConfirmLabel.localization_value.value')"
						class="buy-label__btn-i wfc"
						v-if="confirmation"
						v-bind:class="{'disabled-btn' : $store.getters.getExpressBtn}"
						@click.native="confirmLabel(idCostExpressLabel, deliveryServiceId)"
				/>

				<MainButton
						:value="$t('common_buyLabel.localization_value.value')"
						class="buy-label__btn-i wfc"
						@click.native="makeLabelHandler"
						v-else-if="!$store.getters.getExpressLoadingPrice &&
							Object.keys(labelPrice).length > 0 &&
							!labelNeedMoneyAmount &&
							(Object.keys(labelPrice).length > 0 && parseFloat(labelPrice.price) !== 0)"
						v-bind:class="{'disabled-btn' : $store.getters.getExpressBtn}"

				/>

				<MainButton
						:value="$t('common_payForAuthorize.localization_value.value')"
						class="buy-label__btn-i wfc"
						@click.native="$emit('payForAuthorize', {labelNeedMoneyAmount, invoiceId, userBalance, orderAmount, paySystemCommission})"
						v-if="labelNeedMoneyAmount"
				/>



			</div>
		</template>
	</modal>

</template>

<script>
	import Modal from '../../../commonModals/Modal.vue'
	import MainButton from '../../../UI/buttons/MainButton/MainButton.vue'
	import {expressMixins} from "../../../../mixins/expressMixins/expressMixin";
	import LabelPrice from "../../LabelPrice/LabelPrice";
  import {
    EXPRESS_MAKE_LABEL_STATUSES,
    FEDEX_DELIVERY_SERVICE,
    NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE
  } from "../../../../staticData/staticVariables";
	import DotsShadowPreloader from "../../preloaders/DotsShadowPreloader/DotsShadowPreloader";

	export default {
		name: "BuyLabelPopup",
		components: {
			DotsShadowPreloader,
			LabelPrice,
			Modal,
			MainButton,
		},

		mixins: [expressMixins],

		props: {
			idCostExpressLabel: Number,
      deliveryServiceId: Number,
      confirmation: Boolean,
		},

		data() {
			return {
				labelPrice: {},
				labelError: '',
        userBalance: '',
        orderAmount: '',
        paySystemCommission: '',
        labelNeedMoneyAmount: '',
				labelNeedMoneyError: '',
        invoiceId: '',
        estimatedTime: '',

				FEDEX_DELIVERY_SERVICE: FEDEX_DELIVERY_SERVICE,

        ex: {
          express: {
            variantLabelPrice: null,
          }
        }
			}
		},

		created() {

			this.$store.dispatch('getExpress', this.idCostExpressLabel).then(() => {

			  this.ex.express.variantLabelPrice = this.$store.getters.getExpressItem?.expressOrderInstance?.delivery_service_type

				this.getLabelPriceFromTable(this.$store.getters.getExpressItem)
			})


		},


		methods: {

			makeLabelHandler() {

        let expressType = this.getExpressTypeById(this.deliveryServiceId)

        let dataPayload = {
          order_express_id: this.idCostExpressLabel
        }

        if((FEDEX_DELIVERY_SERVICE.id === expressType.id || NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE.id === expressType.id) && this.labelPrice.price_variant) {
          dataPayload['delivery_service_type'] = this.ex.express.variantLabelPrice
        }

        this.$store.dispatch('makeLabel', {type: expressType.name, dataPayload}).then(response => {
					if (this._.has(response, 'data') &&
									response.data.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {

						let responseData = response.data.data
						if(responseData.status === EXPRESS_MAKE_LABEL_STATUSES.NOT_PAYED){
							this.openNotify('error', {txtServer: responseData.message})
              console.log(3324);
              console.log(responseData.total_amount);

              this.userBalance = responseData.userBalance
              this.orderAmount = responseData.order_amount
              this.paySystemCommission = responseData.payment_system_commission
              this.labelNeedMoneyAmount = responseData.total_amount
							this.labelNeedMoneyError = responseData.message
							this.invoiceId = responseData.invoice_id
              return
						}

						this.$emit('reload')
						this.$emit('close')

					} else if (response.response.data.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {
						let errors = response.response.data.errors

						// if(this._.has(errors, 'api_errors')){
						// 	this.openNotify('error', {txtServer: errors['api_errors'][0]})
						// }

						this.notifyErrorHelper(errors)

						// console.log(errors)
					} else {
						this.openNotify('error', 'common_notificationUndefinedError')
					}
				})
			},
		}

	}

</script>

<style lang="scss" scoped>
  .date-time{
    margin-bottom: 20px;
  }
</style>

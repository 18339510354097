<template>
  <modal
      @close="$emit('close')"
      class="custom-popup"
  >
    <template slot="header">
      <span
              v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit"
            @click="editTranslate(['RequestReturn_confirmSaveTitle'])"></span>

        {{$t('RequestReturn_confirmSaveTitle.localization_value.value')}}
      </span>
    </template>
    <template slot="body">
      <div class="custom-popup__content"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <span class="admin-edit"
                @click="editTranslate([
                    'RequestReturn_confirmSaveCustomer',
                    'RequestReturn_confirmSaveSum',
                    'RequestReturn_confirmSaveWarning',
                    ])"></span>
        <div>
          <div class="fsz16">{{$t('RequestReturn_confirmSaveCustomer.localization_value.value')}}: <b>{{customerName}}</b><br/>
            {{$t('RequestReturn_confirmSaveSum.localization_value.value')}}: <b>${{returnSum}}</b>
          </div>
          <p class="fsz18"><b>{{$t('RequestReturn_confirmSaveWarning.localization_value.value')}}</b></p>
        </div>
      </div>
    </template>
    <template slot="footer">
       <div class="buy-label__btn d-flex align-items-center mt-4"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <span class="admin-edit"
                @click="editTranslate(['common_yes', 'common_confirmNo'])"></span>

         <MainButton
             :value="$t('common_yes.localization_value.value')"
             class="secondary save-drafts__btn-i wfc mb-0"
             @click.native="$emit('yes')"
             v-bind:class="{'disabled-btn' : disabledBtn}"
         />

        <MainButton
            :value="$t('common_confirmNo.localization_value.value')"
            class="buy-label__btn-i wfc ml-3"
            @click.native="$emit('close')"
        />
      </div>
    </template>
  </modal>

</template>

<script>
  import Modal from "../../../../commonModals/Modal";
  import MainButton from "../../../../UI/buttons/MainButton/MainButton";

  export default {
    name: "ConfirmSavePopup",
    components: {
      Modal,
      MainButton,
    },

    props: {
      customerName: {
        type: String,
        default: '',
      },
      returnSum: {
        type: [Number, String],
        default: 0,
      },
      disabledBtn: {
        type: Boolean,
        default: false,
      }
    },


  }

</script>

<style lang="scss">

</style>

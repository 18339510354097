<template>
  <FilterBlock :showFilter="showFilter" :title="$t('common_filterBy.localization_value.value')" @close="closeFilter" @changeFilter="changeFilter">
    <template slot="body">


<!--      <div class="table-filter__item"-->
<!--           v-if="isAdmin"-->
<!--           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--        <span class="admin-edit" @click="editTranslate(['common_userAdmin'])"></span>-->

<!--        <DefaultSelect-->
<!--            :options="adminOptions"-->
<!--            :optionsLabel="'name'"-->
<!--            :otherValue="'adminItem'"-->
<!--            :label="$t('common_userAdmin.localization_value.value')"-->
<!--            @change="(val) => {changeAdminsFilter(val)}"-->
<!--            :selected="adminItem"-->
<!--        />-->
<!--      </div>-->

      <div class="table-filter__item"
           v-if="isAdmin"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['apcDelcon_shippingCompany'])"></span>

        <DefaultSelect
            :options="shippingCompanies"
            :optionsLabel="'name'"
            :label="$t('apcDelcon_shippingCompany.localization_value.value')"
            @change="(val) => {setShippingCompany(val)}"
            :selected="shippingCompanyItem"
        />
      </div>

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['apcDelcon_trackingNumber'])"></span>
        <DefaultInput
            :label="$t('apcDelcon_trackingNumber.localization_value.value')"
            :type="'text'"
            v-model="trackingNumber"
        />
      </div>




    </template>
    <template slot="footer">
      <MainButton @click.native="resetFilter" :value="$t('common_resetFilters.localization_value.value')" class="secondary wfc"/>
      <MainButton @click.native="changeFilter" :value="$t('common_applyFilters.localization_value.value')" class="wfc"/>
    </template>
  </FilterBlock>
</template>

<script>
import FilterBlock from "../../../../../../coreComponents/FilterBlock/FilterBlock";
import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
import {filterFunc} from "../../../../../../../mixins/filterFuncMixin/filterFunc";
import {usersFunc} from "../../../../../../../mixins/usersMixins/usersFunc";
// import SearchSelect from "../../../../../../UI/selectiones/SearchSelect/SearchSelect";
import DefaultSelect from "../../../../../../UI/selectiones/DefaultSelect/DefaultSelect";
import {
  CONSOLIDATION_APC_DELCON_SHIPPING_COMPANIES_ARRAY
} from "../../../../../../../staticData/staticVariables";

export default {
  name: "ConsolidationFilter",
  components: {
    DefaultSelect,
    // SearchSelect,
    FilterBlock,
    MainButton,
    DefaultInput,
  },

  props: {
    showFilter: Boolean,
    countFilterParams: Number,
    filterGetParams: Object,
  },

  mixins: [filterFunc, usersFunc],

  data(){
    return {
      consolidationId: this.filterGetParams.consolidationId ? this.filterGetParams.consolidationId : '',
      consolidationUnionId: this.filterGetParams.consolidationUnionId ? this.filterGetParams.consolidationUnionId : '',

      shippingCompanyId: this.filterGetParams.shippingCompanyId ? this.filterGetParams.shippingCompanyId : '',
      warehouseId: this.filterGetParams.warehouseId ? this.filterGetParams.warehouseId : '',

      trackingNumber: this.filterGetParams.trackingNumber ? this.filterGetParams.trackingNumber : '',

      userName: this.filterGetParams.userName ? this.filterGetParams.userName : '',
      userId: this.filterGetParams.userId ? this.filterGetParams.userId : '',

      adminName: this.filterGetParams.adminName ? this.filterGetParams.adminName : '',
      adminId: this.filterGetParams.adminId ? this.filterGetParams.adminId : '',

      options: [],
      adminOptions: [],
      adminItem: null,
      shippingCompanies: [],
      shippingCompanyItem: null,

      filterCounts: [
        'consolidationId',
        'consolidationUnionId',
        'shippingCompanyId',
        'warehouseId',
        'trackingNumber',
        'userName',
        'userId',
        'adminName',
        'adminId',
      ],
    }
  },

  watch: {
    filterGetParams(newVal) {
      this.conslidationId = newVal.consolidationId ? newVal.consolidationId : ''
      this.conslidationUnionId = newVal.consolidationUnionId ? newVal.consolidationUnionId : ''

      this.shippingCompanyId = newVal.shippingCompanyId ? newVal.shippingCompanyId : ''
      this.warehouseId = newVal.warehouseId ? newVal.warehouseId : ''

      this.userId = newVal.userId ? newVal.userId : ''
      this.userName = newVal.userName ? newVal.userName : ''

      this.adminId = newVal.adminId ? newVal.adminId : ''
      this.adminName = newVal.adminName ? newVal.adminName : ''

      this.trackingNumber = newVal.trackingNumber ? newVal.trackingNumber : ''
    },

  },

  mounted() {
    this.$store.dispatch('getAPCDelconCreate').then(response => {
      let respData = this.getRespData(response)
      this.shippingCompanies = respData.delivery_services.filter(item => {
        return CONSOLIDATION_APC_DELCON_SHIPPING_COMPANIES_ARRAY.find(findItem => {
          return findItem.id === item.id
        })
      })
    })

  },

  methods: {
    closeFilter() {
      this.$emit('close')
    },

    functionSearch(value, loading) {
      loading(true)
      this.getFilteredUsers(value, this, 'options', loading)
    },

    changeUsersFilter(value) {
      this.userId = value.id
      this.userName = value.contacts[0].user_full_name
    },

    changeAdminsFilter(value) {
      this.adminItem = value
      this.adminId = value.id
      this.adminName = value.user_personal_contact.user_full_name
    },

    resetFilter() {
      let data = this.resetFilterParams(this, this.filterCounts)
      this.shippingCompanyItem = null
      this.warehouseItem = null
      this.adminItem = null

      this.$emit('changeFilter', data)
    },

    changeFilter() {
      let data = this.changeFilterParams(this, this.filterCounts)

      this.$emit('changeFilter', data)
    },

    setShippingCompany(val) {
      this.shippingCompanyId = val.id
      this.shippingCompanyItem = val
    },

    setWarehouse(val) {
      this.warehouseId = val.id
      this.warehouseItem = val
    },
  },
}
</script>

<style scoped>

</style>

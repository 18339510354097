<template>
  <div class="order-create__col custom-col mb-0"
     v-if="isRecipientTaxValue">
    <div class="order-create__section-label section-label"
         v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate([
                        'common_vatNumberAdmin',
                      ])"></div>
      {{$t('common_recipientTaxValue.localization_value.value')}}
    </div>

    <div class="custom-row">
      <div class="order-create__product-col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100"
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
                  'common_recipientTaxValue',
                ])"></div>

        <DefaultInput
            :label="FBM.data.shippingPartner.need_recipient_tax_message"
            v-bind:class="{'ui-no-valid': FBM.validation.recipientTax}"
            :error="FBM.validation.recipientTax"
            :errorTxt="$t(`${FBM.validationTranslate.recipientTax}.localization_value.value`)"
            :type="'text'"
            :autocompleteOff="true"
            :maxLength="35"
            v-model="FBM.data.recipientTax"
        />
      </div>
    </div>

</div>

</template>

<script>
import DefaultInput from "@/components/UI/inputs/DefaultInput/DefaultInput";
export default {
name: "TaxBlock",
  components: {DefaultInput},

  props: {
    FBM: Object,
  },

  computed: {
    isRecipientTaxValue() {
      return this.FBM.data.recipientNeedTax
    },

    shippingPartner() {
      return this.FBM.data.shippingPartner
    },
  },

  watch: {
    shippingPartner(newVal) {
      console.log(newVal);
      if(newVal && newVal.need_recipient_tax) {
        this.FBM.data.recipientNeedTax = true
      } else {
        this.FBM.data.recipientNeedTax = false
      }
    },
  },

}
</script>

<style scoped>

</style>
<template>
  <APCDelconCreateAdmin
      :APCD="APCD"
      :shippingCompanies="shippingCompanies"
      :ordersFBM="ordersFBM"
      @saveAPCDelcon="saveAPCDelcon"
  />
</template>

<script>
  import APCDelconCreateAdmin from "./APCDelconCreateAdmin/APCDelconCreateAdmin";
  import {APCDelcon} from "../../models/APCDelcon";
  import {APCDelconMixin} from "../../../../../mixins/APCDelcon/APCDelconMixin";
  import {
    CONSOLIDATION_APC_DELCON_SHIPPING_COMPANIES_ARRAY,
  } from "../../../../../staticData/staticVariables";

  export default {
    name: "APCDelconCreate",

    components: {
      APCDelconCreateAdmin,
    },

    mixins: [
      APCDelconMixin
    ],

    data() {
      return {
        APCD: new APCDelcon(),
        shippingCompanies: [],
        ordersFBM: [],
      }
    },

    created() {
      this.initAPCDelconCreate()
    },

    methods: {
      initAPCDelconCreate() {
        this.$store.dispatch('getAPCDelconCreate').then(response => {
          let respData = this.getRespData(response)
          this.ordersFBM = respData.order_fbm_list
          this.shippingCompanies = respData.delivery_services.filter(item => {
            return CONSOLIDATION_APC_DELCON_SHIPPING_COMPANIES_ARRAY.find(findItem => {
              return findItem.id === item.id
            })
          })
        })
      },

    }
  }
</script>

<style scoped>

</style>
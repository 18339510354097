<template>
  <div class="fragment">
    <template v-if="$store.getters.getShippingCompany.length > 0 && $store.getters.getShippingCompanyLoading !== true">
      <div class="custom-row accounts-table">
        <div class="custom-col custom-col--20 custom-col--xs-100 custom-col--md-50 custom-col--xl-33"
             v-for="(item, index) in $store.getters.getShippingCompany"
             :key="index">
          <div class="case-cart">
            <div class="case-cart__inner">
              <div class="case-cart__columns">
                <div class="case-cart__content-block">
                  <div class="case-cart__head">
                    <div class="case-cart__label-status">
                      <div class="case-cart__label-status-i"
                           v-bind:class="{
                           'active': item.enable,
                           'admin-edit-item': isAdmin || checkImpersonate()
                           }"
                      >
                        <div class="admin-edit" @click="editTranslate([
                        'common_Active',
                        'common_Unactive',
                      ])"></div>
                        <template v-if="item.enable"> {{ $t('common_Active.localization_value.value') }}</template>
                        <template v-else>{{ $t('common_Unactive.localization_value.value') }}</template>
                      </div>
                    </div>
                    <div class="case-cart__title pt-3 d-flex">
                      <div class="case-cart__company-img">
                        <!--<img v-if="item.logo_base64" :src="item.logo_base64" alt="ico">-->
                        <div class="company-svg-ico" v-if="item.svg_logo" v-html="item.svg_logo"></div>
                        <!--<img src="/img/company-icons-group/shipping-company/cdek.svg" alt="ico">-->
                      </div>
                      <span class="color-black  pr-2">
                      {{ item.name }}
                    </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="case-cart__bottom">
                <div class="case-cart__btn ml-auto"
                     v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}"
                >
                  <div class="admin-edit" @click="editTranslate(['common_delete'])"></div>
                  <CaseCartButton
                      class="secondary-brown"
                      :value="$t('common_delete.localization_value.value')"
                      :ico="true"
                      :typeIco="'delete-brown'"
                      @click.native="removeItem(item.id, index)"
                  >
                  </CaseCartButton>
                </div>
                <div class="case-cart__btn accounts-table__case-cart-button ml-auto"
                     v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}"
                >
                  <div class="admin-edit" @click="editTranslate(['common_edit'])"></div>
                  <CaseCartButton
                      :value="$t('common_edit.localization_value.value')"
                      :ico="true"
                      :typeIco="'edit'"
                      @click.native="editShippingCompanyPopup(item.id)"
                  >
                  </CaseCartButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="table-bottom-btn">
        <div class="table-bottom-btn__inner">
          <ShowMore
              v-if="$store.getters.getShippingCompanyCommonList.next_page_url !== null && $store.getters.getShippingCompany.length > 0"
              @click.native="$emit('showMore')"
              v-bind:class="{'disabled-btn' : $store.getters.getNextShippingCompanyPage}"
              :count="$store.getters.getShippingCompanyCommonList.total - $store.getters.getShippingCompanyForPage < $store.getters.getShippingCompanyForPage ?
                  $store.getters.getShippingCompanyCommonList.total - $store.getters.getShippingCompanyForPage:
                  $store.getters.getShippingCompanyForPage"
          />
        </div>
      </div>

    </template>

    <template
        v-if="$store.getters.getShippingCompanyLoading === false && $store.getters.getShippingCompany.length === 0">
      <NoResult
          :countFilterParams="countFilterParams"
          :title="$t('common_noDataYet.localization_value.value')"
          :titleWithFilter="$t('common_noResult.localization_value.value')"
          :text="$t('common_noDataYetTxt.localization_value.value')"
          :textWithFilter="$t('common_noResultSeems.localization_value.value')"
      />
    </template>


    <ShippingCompanyPopup
        v-if="isModalShippingCompanyPopup"
        :openType="'edit'"
        :deliverySystemId="deliverySystemId"
        @reload="$emit('reload')"
        @close="changeShippingCompanyPopup(false)"
    />

  </div>
</template>

<script>
import {mixinDetictingMobile} from '../../../../../../../../mixins/mobileFunctions'
import CaseCartButton from "../../../../../../../UI/buttons/CaseCartButton/CaseCartButton";
import ShippingCompanyPopup from "../../../../../popups/ShippingCompanyPopup/ShippingCompanyPopup";
import ShowMore from "../../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
import NoResult from "../../../../../../../coreComponents/NoResult/NoResult";


export default {
  name: "ShippingCompanyTable",

  components: {
    NoResult,
    ShowMore,
    CaseCartButton,
    ShippingCompanyPopup,
  },

  mixins: [mixinDetictingMobile],

  props: {
    countFilterParams: Number,
  },


  data() {
    return {
      deliverySystemId: -1,
      showFilter: false,
      isMobile: false,
      showContent: false,
      isModalShippingCompanyPopup: false,
    }
  },

  methods: {
    changeShippingCompanyPopup(val) {
      this.isModalShippingCompanyPopup = val
    },

    editShippingCompanyPopup(id) {
      this.deliverySystemId = id
      this.changeShippingCompanyPopup(true)
    },


    closePopup() {
      // this.isModalProductsToWarehouse = false
    },

    cantDeleteProduct() {
      let text = {
        title: 'Ooops',
        txt: 'Sorry, you cannot uninstall this product',
        no: 'Ok'
      }

      let confirm = () => {
        this.$store.commit('setConfirmDeletePopup', false)
        return true
      }

      this.deleteFunc(text, confirm)
    },

    removeItem(id, index = false) {
      let text = {
        title: 'common_deleteConfirm',
        txt: 'common_deleteItemAsk',
        yes: 'common_confirmDelete',
        no: 'common_confirmNo'
      }

      let confirm = () => {

        this.$store.dispatch('deleteShippingCompany', id).then((response) => {

          if (!this._.has(response, 'data')) return this.openNotify('error', 'common_notificationUndefinedError')

          switch (this.getResponseStatus(response)) {
              /**
               * Success
               */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
              if (index !== false) {
                this.$store.getters.getUkrTreasures.splice(index, 1)
              }
              this.$emit('reload')

              this.openNotify('success', 'common_notificationRecordDeleted')
              break
            }
              /**
               * Validation Error
               */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
              /**
               * Undefined Error
               */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }

        })

        this.$store.commit('setConfirmDeletePopup', false)
        return true
      }

      this.deleteFunc(text, confirm)


    },

    changeInfoPopup(val) {
      this.isModalInfoPopup = val
    }

  }

}

</script>

<style scoped lang="scss">

.company-svg-ico svg {
  display: inline-flex;
  max-width: 60px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 30px;
}

/deep/ .company-svg-ico svg {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}
</style>

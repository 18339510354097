<template>
  <div class="fragment">

<!--    {{ FBM.data.shippingPartnerType }}-->
<!--    <br><br>-->
<!--    {{ FBM.data.shippingPartner }}-->
<!--    <br><br>-->
<!--    {{ FBM.data.shippingAPIPrice }}-->
    <OrdersFBMEditingPage
            v-if="loadUserAuthorizedData && !isAdmin && !advancedUI"
            :FBM="FBM"
            @addProformProduct="addProformProduct"
            @removeProformProduct="removeProformProduct"
            @prepareSaveFBM="prepareSaveFBM"
            @changeUser="changeUser"
    />

    <OrdersFBMAdvancedEditingPage
        v-if="loadUserAuthorizedData && !isAdmin && advancedUI && initData"
        :FBM="FBM"
        @addProformProduct="addProformProduct"
        @removeProformProduct="removeProformProduct"
        @prepareSaveFBM="prepareSaveFBM"
        @changeUser="changeUser"
    />

    <OrdersFBMEditingPageAdmin
            v-if="orderLoading && loadUserAuthorizedData && isAdmin && !reloading && initData"
            :FBM="FBM"
            @reloadEditPage="reloadEditPage"
            @addProformProduct="addProformProduct"
            @removeProformProduct="removeProformProduct"
            @prepareSaveFBM="prepareSaveFBMCheckTrackNum"
    />

    <NegativeBalancePopup
            v-if="negativeBalancePopup"
            @close="negativeBalancePopup = false"
    />

    <CommentForAdminPopup
        v-if="commentPopup"
        :FBM="FBM"
        @close="commentPopup = false"
    />
  </div>
</template>

<script>
  import OrdersFBMEditingPage from "./OrdersFBMEditingPage/OrdersFBMEditingPage";
  import {OrderFBM} from "../../models/OrderFBM";
  import {proformMixins} from "../../../../../mixins/proformMixins/proformMixins";
  import {FBMMixins} from "../../../../../mixins/FBMMixins/FBMMixins";
  import OrdersFBMEditingPageAdmin from "./OrdersFBMEditingPageAdmin/OrdersFBMEditingPageAdmin";
  import {userAvatarMixin} from "../../../../../mixins/usersMixins/userAvatarMixin";
  import {vatNumberMixin} from "../../../../../mixins/commonMixins/vatNumberMixin";
  import NegativeBalancePopup from "../../popups/NegativeBalancePopup/NegativeBalancePopup";
  import {hsCodeMixin} from "../../../../../mixins/commonMixins/hsCodeMixin";
  import {
    AUSTRALIA_ID,
    CANADA_COUNTRY_ID,
    MEXICO_COUNTRY_ID,
    USA_COUNTRY_ID
  } from "../../../../../staticData/staticVariables";
  import OrdersFBMAdvancedEditingPage from "./OrdersFBMAdvancedEditingPage/OrdersFBMAdvancedEditingPage";
  import CommentForAdminPopup
    from "@/components/modules/OrdersFBMModule/popups/CommentForAdminPopup/CommentForAdminPopup";

  export default {
    name: "OrdersFBMEditing",

    components: {
      OrdersFBMAdvancedEditingPage,
      NegativeBalancePopup,
      OrdersFBMEditingPage,
      OrdersFBMEditingPageAdmin,
      CommentForAdminPopup,
    },

    mixins: [proformMixins, FBMMixins, userAvatarMixin, vatNumberMixin, hsCodeMixin],

    props: {
      easyOrder: {
        type: Boolean,
        default: false,
      }
    },

    watch: {
      loadUserAuthorizedData: function () {
        this.getFBMUserAuthorizedData()
        this.initFbmOrder()
      },

      selectedCountry(country) {
        if(this.reloading) return


        this.$store.dispatch('getCountryDataById', country.id).then(() => {
          let countryWithCountryGroups = this.$store.getters.getCountryGroup

          this.FBM.setVisibleHSCode(this.getHSCodePermissionsByCountry(countryWithCountryGroups, true))

          // if(!this.initData) return

          this.FBM.setVisibleVatNumber(this.getVatNumberPermissionsByCountry(countryWithCountryGroups), this.FBM.data.visibleVatNumber)

        }).catch(error => this.createErrorLog(error))
        if ((country.id === USA_COUNTRY_ID || country.id === AUSTRALIA_ID || country.id === CANADA_COUNTRY_ID || country.id === MEXICO_COUNTRY_ID) && !this.isAdmin) {
          this.$store.dispatch('getRegionsByCountry', country.id).then(() => {
            this.FBM.setRegions(this._.toArray(this.$store.getters.getRegions));
          }).catch(error => this.createErrorLog(error));
        }
      },

      downloadPermissions() {
        this.checkPermission()
      },
    },

    data() {
      return {
        FBM: new OrderFBM(),

        loadedProformOptions: -1,
        initData: false,

        orderLoading: false,
        negativeBalancePopup: false,
        commentPopup: null,

        reloading: false,
        advancedUI: false,

        isFirstCreateCusClearance: true,
      }
    },

    mounted() {

      if(this.easyOrder) {
        this.FBM.data.easyOrderType = true
      }


      let orderId = this.$route.params.id
      let orderType = this.$route.params.type



      if(this.downloadPermissions){
        if(!this.checkPermission()) return
      }

      /**
       * Set OrderId & TypeFBM from route params
       */
      this.FBM.setOrderId(orderId)
      this.FBM.setTypeFBM(orderType)

      if(this.loadUserAuthorizedData){
        this.advancedUI = this.isExpertUser
        this.initFbmOrder()
      }

      this.$store.dispatch('fetchOrderSize', '?filter[bySizeType]=imperial').then((response) => {
        this.FBM.data.orderSizes = this.getRespPaginateData(response)
      })

    },

    methods: {
      reloadEditPage(openLabel, openGiftFile = false) {
        this.reloading = true
        this.FBM = new OrderFBM()

        this.$store.dispatch('fetchOrderSize', '?filter[bySizeType]=imperial').then((response) => {
          this.FBM.data.orderSizes = this.getRespPaginateData(response)
        })

        let orderId = this.$route.params.id
        let orderType = this.$route.params.type

        /**
         * Set OrderId & TypeFBM from route params
         */
        this.FBM.setOrderId(orderId)
        this.FBM.setTypeFBM(orderType)

        this.initFbmOrder().then(() => {
          this.reloading = false
          if(openLabel){
            let routeData = this.$router.resolve({ path: this.$store.getters.GET_PATHS.getPdfFile + '/' + this.FBM.data.Order.data.orderOBJ?.orderFbmInstance?.label_open_file.uuid})

            window.open(routeData.href, '_blank');
          }
          if(openGiftFile){
            let routeData = this.$router.resolve({ path: this.$store.getters.GET_PATHS.getPdfFile + '/' + this.FBM.data.fileGift.uuid})

            window.open(routeData.href, '_blank');
          }
        })
      },

      initFbmOrder(){

        return this.initializeProductProformArray(this.FBM.data.ProformProducts).then(() => {
          let requestType = 'getOrdersFBMEdit'

          if(this.isAdmin){
            requestType = 'getAdminOrdersFBM'
          }

          if(!this.isAdmin && this.isEasyType) {
            requestType = this.isConsolidation ? 'getEasyOrdersFBMConsolidation' : 'getEasyOrdersFBM'
          }

          return this.$store.dispatch(requestType, this.FBM.data.Order.getId()).then(response => {
            let order = this.getRespData(response)


            if(order.show_negative_balance_message_for_admin && this.isAdmin){
              this.negativeBalancePopup = true
            }

            if(order.orderFbmInstance?.own_label === true && order?.orderFbmInstance?.label_file?.uuid && this.isAdmin && !this.reloading){
              this.showAdminPopupUserLabel(order?.orderFbmInstance?.label_file)
            }

            if(order?.express_poshta_alert && order.express_poshta_alert.length > 0) {
              this.notificationAdmin(order.express_poshta_alert)
            }

            if(order.orderFbmInstance?.comment && order.orderFbmInstance?.comment.length > 0 && this.commentPopup === null && this.isAdmin) {
              this.commentPopup = true
            }


            /**
             * Set FBM Vat files
             */
            let vatNumber = order.orderFbmInstance?.vat_tax
            if(vatNumber?.file_entity && vatNumber?.file_entity.length > 0){
              this.setVatFiles(vatNumber.file_entity)
            }

            // /**
            //  * Set User Label File
            //  */
            // let userLabelFile = order?.orderFbmInstance?.engraving_files
            // if(userLabelFile){
            //   this.setEngravingFile(engravingFiles)
            // }

            /**
             * Set FBM Photo Products
             */
            if(order.photos && Object.keys(order.photos).length > 0) {
              let photo = order.photos
              this.setProductPhoto(photo)
            }

            return this.$store.dispatch('getConsolidationCreate').then(response => {

              let destinationData = this.getRespData(response)?.destinations

              this.FBM.data.orderFbmFees = this.getRespData(response)?.order_fees_list
              /**
               * Set FBM
               */
              this.FBM.setItem(order, this.isAdmin, false, destinationData)

              /**
               * Set Engraving Files
               */

              if(order?.orderFbmInstance?.engravings[0]?.width) {
                order?.orderFbmInstance?.engravings.forEach((engravingItem, engravingIndex) => {
                  if(engravingItem.files.length === 0) {
                    let engravingFiles = order?.orderFbmInstance?.engraving_files
                    if(engravingFiles){
                      this.setEngravingFile(engravingFiles, engravingIndex)
                    }
                  } else {
                    this.setEngravingFile(engravingItem.files, engravingIndex)
                  }
                })
              }



              /**
               * Set Embroidery Files
               */
              let embroideryFiles = order?.orderFbmInstance?.embroidery_files
              if(embroideryFiles){
                this.setEmbroideryFile(embroideryFiles)
              }

              /**
               * Set T-Shirt Painting Files
               */
              let tshirtPaintingFiles = order?.orderFbmInstance?.tshirt_painting_files
              if(tshirtPaintingFiles){
                this.setTShirtPaintingFile(tshirtPaintingFiles)
              }

              /**
               * Set Photo Print Files
               */
              let photoPrintFiles = order?.orderFbmInstance?.photo_print_files
              if(photoPrintFiles){
                this.setPhotoPrintFilesFile(photoPrintFiles)
              }

              /**
               * Set Leather Stamping Files
               */
              let leatherStampingFiles = order?.orderFbmInstance?.leather_stamping_files
              if(leatherStampingFiles){
                this.setLeatherStampingFile(leatherStampingFiles)
              }

              /**
               * Set Leather Stamping Files
               */
              let UVPrintingFiles = order?.orderFbmInstance?.customization_print_u_v_files
              if(UVPrintingFiles){
                this.setUVPrintingFile(UVPrintingFiles)
              }

              /**
               * Set FBM Proform Products
               */
              this.FBM.data.ProformProducts.map((itemProduct, index) => {
                this.initialProformSelectOptions({
                  val: itemProduct.product.proformData.proformCategory,
                  item: itemProduct
                }).then(() => {
                  this.loadedProformOptions = index
                })
              })

              if(this.isAdmin){
                this.setUserAvatar(this.FBM.data.User)
              }
              if (this.isAdmin && !this.isStatusComplete(this.FBM.data.Order.getStatus().value) && this.getUserPlannedDateConsolidation && this.isConsolidation) {
                this.FBM.setReceivedAt(this.$moment(this.getUserPlannedDateConsolidation).format('YYYY-MM-DD'))
              }
              if (this.isAdmin && !this.isStatusComplete(this.FBM.data.Order.getStatus().value) && this.getUserPlannedDateWarehouse && this.isWarehouse) {
                this.FBM.setReceivedAt(this.$moment(this.getUserPlannedDateWarehouse).format('YYYY-MM-DD'))
              }
              if (this.advancedUI && !this.isAdmin && this.$store.getters.GET_COMMON_AUTHORIZED?.user?.small_avatar_file
                  && this.$store.getters.GET_COMMON_AUTHORIZED?.user?.small_avatar_file.length > 0) {
                this.FBM.data.User.user.avatar_files = this.$store.getters.GET_COMMON_AUTHORIZED?.user?.small_avatar_file
                this.setUserAvatar(this.FBM.data.User)
              }

              this.orderLoading = true
              this.initData = true
            }).catch(error => this.createErrorLog(error))
          }).catch(error => this.createErrorLog(error))
        })
      },

      getFBMUserAuthorizedData() {

      },

      showAdminPopupUserLabel(fileLabel) {
        let text = {
          title: 'common_userHasLabelPopup',
          txt: '',
          yes: 'common_userHasLabelDownload',
          no: 'common_close'
        }

        let confirm = () => {
          window.open(this.$store.getters.GET_PATHS.getPdfFile + '/' + fileLabel?.uuid, '_blank')
          return true
        }

        this.deleteFunc(text, confirm)
      },

      notificationAdmin() {
        let text = {
          title: 'common_attentionPaid',
          txt: '',
          no: 'common_close'
        }

        let confirm = () => {
          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)
      },

      addProformProduct(){
        let newIndex = this.FBM.addProformProduct()
        this.initializeProductProformArray(this.FBM.data.ProformProducts, newIndex)
      },

      removeProformProduct(index) {
        this.FBM.removeProformProduct(index)
      },

      prepareSaveFBMCheckTrackNum(data) {

        if(this.FBM.data.Order.getTrackingNum() === '' && this.isStatusComplete(this.FBM.data.Order.getStatus().value) && !this.FBM.data.useEasyPost){
          let text = {
            title: 'fbm_DidNotSetTrackNumber',
            txt: '',
            yes: 'common_save',
            no: 'common_confirmNo'
          }

          let confirm = () => {

            this.prepareSaveFBM(data)

            this.$store.commit('setConfirmDeletePopup', false)
            return true
          }

          this.deleteFunc(text, confirm)
          return
        }

        this.prepareSaveFBM(data)
      },

      // saveFBM({draft = false, edit = false, createLabel = false}) {
      //   console.log(createLabel);
      //   if(this.isAdmin) {
      //     if(!this.FBM.dataAdminValidation()) return
      //   }
      //
      //   let data = this.FBM.prepareFBM(
      //     draft,
      //     this.FBM.data.User.getUserId()
      //   )
      //
      //   console.log(data);
      //
      //   if(draft){
      //     let confirm = () => {
      //       this.saveOrderFBM(draft, edit, data, 'consolidation').then(status => {
      //         this.successSave(status)
      //       })
      //
      //       this.$store.commit('setSaveInDraftsPopup', false)
      //       return true
      //     }
      //
      //     this.saveInDraftsFunc(confirm)
      //   } else {
      //     console.log(3423);
      //     // this.saveOrderInternal(draft, edit, data, 'ukrposhta').then(response => {
      //     //   this.createLabel(response.id)
      //     //   return true
      //     // })
      //   }
      //
      //
      //
      // },


      // successSave(status) {
      //   if(!status) return
      //
      //   this.$router.push(this.$store.getters.GET_PATHS.ordersFBM)
      // }

    },

  }
</script>

<style scoped>

</style>

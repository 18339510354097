var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"action-table-btn btn-style",class:{
       'boxes-type': _vm.type === 'boxes',
       'pdf': _vm.type === 'pdf',
       'print': _vm.type === 'print',
       'dog': _vm.type === 'dog',
       'dollar': _vm.type === 'dollar',
       'label2': _vm.type === 'label2',
     }},[_c('div',{staticClass:"action-table-btn__txt",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[(_vm.withTranslate)?_c('span',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([_vm.title])}}}):_vm._e(),_vm._v(" "+_vm._s(_vm.$t(`${_vm.title}.localization_value.value`))+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }
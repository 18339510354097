<template>
  <div class="fragment">
    <div class="order-create__row custom-row">
      <div class="custom-col custom-col--50 custom-col--sm-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
      'consolidationUnion_sendToWhere',
      'consolidationUnion_contactPerson',
      ])"></div>
        <div class="order-create__section-label section-label">
          {{$t('consolidationUnion_sendToWhere.localization_value.value')}}
        </div>
        <DefaultInput
            :label="$t('consolidationUnion_contactPerson.localization_value.value')"
            v-bind:class="{'ui-no-valid': CUL.validation.contactPerson}"
            :errorTxt="$t(`${CUL.validationTranslate.contactPerson}.localization_value.value`)"
            :error="CUL.validation.contactPerson"
            v-model="CUL.data.contactPerson"
        />
      </div>

      <div class="custom-col custom-col--50 custom-col--sm-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
      'consolidationUnion_sendFrom',
      'consolidationUnion_warehouse',
      ])"></div>
        <div class="order-create__section-label section-label">
          {{$t('consolidationUnion_sendFrom.localization_value.value')}}
        </div>
        <DefaultSelect
            :options="warehousesData"
            :optionsLabel="'name'"
            :label="$t('consolidationUnion_warehouse.localization_value.value')"
            v-bind:class="{'ui-no-valid': CUL.validation.warehouse}"
            :errorTxt="$t(`${CUL.validationTranslate.warehouse}.localization_value.value`)"
            :error="CUL.validation.warehouse"
            :otherValue="'currentTranslation'"
            @change="(val) => {CUL.setWarehouse(val)}"
            :selected="CUL.data.warehouse"
        />
      </div>
    </div>


    <div class="order-create__row custom-row">

    </div>

    <div class="order-create__section-label section-label"
         v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['consolidationUnion_sendDetails'])"></div>
      {{$t('consolidationUnion_sendDetails.localization_value.value')}}
    </div>

    <div class="order-create__row custom-row"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate([
          'common_weightKg',
          'consolidations_lengthCm',
          'consolidations_widthCm',
          'consolidations_heightCm',
          ])"></div>

      <div class="order-create__col custom-col custom-col--25 custom-col--sm-50">
        <DefaultInput
            :label="$t('common_weightKg.localization_value.value')"
            :type="'text'"
            :placeholder="'e.g. 10'"
            v-bind:class="{'ui-no-valid': CUL.data.Dimensions.validation.weight}"
            :errorTxt="$t(`${CUL.data.Dimensions.validationTranslate.weight}.localization_value.value`)"
            :error="CUL.data.Dimensions.validation.weight"
            v-model="CUL.data.Dimensions.data.weight"
            @input.native="acceptNumber($event, 'weight')"
        />
      </div>

      <div class="order-create__col custom-col custom-col--25 custom-col--sm-50">
        <DefaultInput
            :label="$t('consolidations_lengthCm.localization_value.value')"
            :type="'text'"
            :placeholder="'e.g. 10'"
            v-bind:class="{'ui-no-valid': CUL.data.Dimensions.validation.dimensionLength}"
            :errorTxt="$t(`${CUL.data.Dimensions.validationTranslate.dimensionLength}.localization_value.value`)"
            :error="CUL.data.Dimensions.validation.dimensionLength"
            v-model="CUL.data.Dimensions.data.dimensionLength"
            @input.native="acceptNumber($event, 'length')"
        />
      </div>

      <div class="order-create__col custom-col custom-col--25 custom-col--sm-50">
        <DefaultInput
            :label="$t('consolidations_widthCm.localization_value.value')"
            :type="'text'"
            :placeholder="'e.g. 10'"
            v-bind:class="{'ui-no-valid': CUL.data.Dimensions.validation.width}"
            :errorTxt="$t(`${CUL.data.Dimensions.validationTranslate.width}.localization_value.value`)"
            :error="CUL.data.Dimensions.validation.width"
            v-model="CUL.data.Dimensions.data.width"
            @input.native="acceptNumber($event, 'width')"
        />
      </div>

      <div class="order-create__col custom-col custom-col--25 custom-col--sm-50">
        <DefaultInput
            :label="$t('consolidations_heightCm.localization_value.value')"
            :type="'text'"
            :placeholder="'e.g. 10'"
            v-bind:class="{'ui-no-valid': CUL.data.Dimensions.validation.height}"
            :errorTxt="$t(`${CUL.data.Dimensions.validationTranslate.height}.localization_value.value`)"
            :error="CUL.data.Dimensions.validation.height"
            v-model="CUL.data.Dimensions.data.height"
            @input.native="acceptNumber($event, 'height')"
        />
      </div>

      <div class="order-create__col custom-col custom-col--50 custom-col--sm-100">
        <DatePickerDefault
            :label="$t('consolidations_shippingDate.localization_value.value')"
            :placeholder="$t('common_dateYYYY_MM_DD.localization_value.value')"
            v-bind:class="{'ui-no-valid': CUL.validation.plannedSendDate}"
            :errorTxt="$t(`${CUL.validationTranslate.plannedSendDate}.localization_value.value`)"
            :error="CUL.validation.plannedSendDate"
            v-model="CUL.data.plannedSendDate"
        >
          <template slot="body">
            <date-picker
                v-model="CUL.data.plannedSendDate"
                ref="datePicker"
                valueType="format"
                :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                :format="'YYYY-MM-DD'"
                :placeholder="$t('common_dateYYYY_MM_DD.localization_value.value')"
            ></date-picker>
          </template>
        </DatePickerDefault>
      </div>

      <div class="order-create__col custom-col custom-col--50 custom-col--sm-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
          'consolidationUnion_totalPrice',
          ])"></div>
        <DefaultInput
            :label="$t('consolidationUnion_totalPrice.localization_value.value')"
            :type="'text'"
            v-bind:class="{'ui-no-valid': CUL.validation.totalPrice}"
            :errorTxt="$t(`${CUL.validationTranslate.totalPrice}.localization_value.value`)"
            :error="CUL.validation.totalPrice"
            v-model="CUL.data.totalPrice"
        />
      </div>

    </div>

    <div class="order-create__section-label section-label"
         v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['consolidationUnion_insurance'])"></div>
      {{$t('consolidationUnion_insurance.localization_value.value')}}
    </div>
    <div class="order-create__row custom-row">
      <div class="custom-col custom-col--100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
      'consolidationUnion_addInsurance',
      ])"></div>
        <DefaultCheckbox
            v-model="CUL.data.addInsurance"
            :label="$t('consolidationUnion_addInsurance.localization_value.value')"
            @click="CUL.data.addInsurance = !CUL.data.addInsurance"
        />
      </div>
      <div class="custom-col custom-col--50 custom-col--sm-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
      'consolidationUnion_insurance',
      ])"></div>
        <DefaultInput
            :label="$t('consolidationUnion_insurance.localization_value.value')"
            :type="'text'"
            v-bind:class="{'ui-no-valid': CUL.validation.insuranceAmount}"
            :errorTxt="$t(`${CUL.validationTranslate.insuranceAmount}.localization_value.value`)"
            :error="CUL.validation.insuranceAmount"
            v-model="CUL.data.insuranceAmount"
            :disabled="!CUL.data.addInsurance"
        />
      </div>
    </div>

  </div>

</template>

<script>
import DefaultSelect from "../../../../UI/selectiones/DefaultSelect/DefaultSelect";
import DefaultInput from "../../../../UI/inputs/DefaultInput/DefaultInput";
import DatePickerDefault from "../../../../UI/inputs/DatePickerDefault/DatePickerDefault";
import DatePicker from "vue2-datepicker";
import DefaultCheckbox from "../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";

export default {
  name: "LabelSendDetails",
  components: {
    DefaultCheckbox,
    DatePickerDefault,
    DefaultInput,
    DefaultSelect,
    DatePicker
  },
  props: {
    CUL: Object,
    warehousesData: Array,
  },

  data() {
    return {
    }
  },

  created() {
    if (this.CUL.getInsuranceAmount()) {
      this.addInsurance = true
    }
  },

  methods: {
    acceptNumber(event, type) {
      switch (type) {
        case 'weight':
          this.CUL.data.Dimensions.data.weight = event.target.value.replace(',', '.').replace(/[^0-9.]/g, '')
          break
        case 'length':
          this.CUL.data.Dimensions.data.dimensionLength = event.target.value.replace(/\D/g, '')
          break
        case 'width':
          this.CUL.data.Dimensions.data.width = event.target.value.replace(/\D/g, '')
          break
        case 'height':
          this.CUL.data.Dimensions.data.height = event.target.value.replace(/\D/g, '')
          break
        default:
          break
      }
    },
  }
}
</script>

<style scoped>

</style>
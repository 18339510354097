import {checkValid} from "../../../globalModels/functions/ModelsValidate";
// let  = require('lodash');

export function HeartysanDeal() {

  this.checkValid = checkValid

  this.data = {

    price: '',
    // status: '',
}

  this.validation = {
    price: false,
    // status: false,
  }

  this.validationTranslate = {
    price: '',
    // status: '',
  }

  this.validationTxt = {
    price: false,
    // status: false,
  }

}


/*****************   SETTERS   ******************/

/***
 * Pseudo Required Fields start
 */

HeartysanDeal.prototype.setPrice = function (val) {
  this.data.price = val
}

HeartysanDeal.prototype.setStatus = function (val) {
  this.data.status = val
}


/*** **/




/*****************   GETTERS   ******************/

/***
 * Pseudo Required Fields start
 */

HeartysanDeal.prototype.getPrice = function () {
  return this.data.price
}

HeartysanDeal.prototype.getStatus = function () {
  return this.data.status
}

/*** **/

/*******************   FUNCTIONS   *************/

HeartysanDeal.prototype.setItem = function (val) {
  this.setPrice(val.deal_price)
  this.setStatus(val.status)
}

/******************  VALIDATIONS  *****************/


HeartysanDeal.prototype.firstValidation = function () {

  let validationItems = {
    price: this.getPrice(),
    // status: this.getStatus(),
  }

  let validationOptions = {
    price: {type: ['empty']},
    // status: {type: ['empty']},
  }

  return (this.checkValid(validationItems, validationOptions))

}



/******************  PREPARE DATA  *****************/


HeartysanDeal.prototype.prepareSave = function() {

  return {
    "deal_price": this.getPrice(),
    // "contact_status": this.getStatus(),
  }

}
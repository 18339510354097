<template>
  <div class="detail-page chat">
    <div class="detail-page__left"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['chat_createChat', 'chat_findChat', 'chat_findMessage'])"></div>
      <CardLeftBlock
          :backgroundImage="'no-bg'"
      >
        <template slot="body">
          <div>
            <div class="custom-row">
              <div class="custom-col ">
                <MainButton
                    class="w-100"
                    :value="$t('chat_createChat.localization_value.value')"
                    @click.native="createChatPopup = true"
                />
              </div>

              <div class="custom-col">
                <DefaultSelect
                    :options="chatContacts"
                    :label="$t('chat_findChat.localization_value.value')"
                    :optionsLabel="'phone_number'"
                    @change="changeChatUser"
                />
              </div>

              <div class="custom-col">
                <SearchSelect
                    :options="optionsFindMessages"
                    :label="$t('chat_findMessage.localization_value.value')"
                    :functionSearch="functionSearchChat"
                    :selected="foundMessage"
                    :optionsLabel="'message_text'"
                    @change="foundChatMessage"
                />
<!--                    :typeSelect="'chat'"-->
              </div>
            </div>
            <div class="mt-3">
              <div class="chat__items">
                <div class="custom-col chat__item"
                     v-for="(item, index) in chatContacts"
                     :key="index"
                     v-bind:class="{'chat__item--active' : _.has(currentChat, 'id') && currentChat.id === item.id}"
                >
                  <div class="chat__user"
                       @click="changeChatUser(item)"
                  >
                    <div class="chat__user-inner">
                      <div class="chat__user-photo">
                        <img style="background: #ce9e64" src="/img/profile-group/profile-photo-default.svg" alt="img">
                      </div>
                      <div class="chat__user-block">
                        <div class="chat__user-name">
                          {{item.contact_name}}
                        </div>
                        <div class="chat__user-phone">
                          {{item.phone_number}}
                        </div>
                      </div>
                      <div class="chat__user-date">
                        {{ item.created_at | moment("DD MMM, YYYY") }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </CardLeftBlock>
    </div>

    <div class="detail-page__right">
      <CardRightBlock>
        <template slot="header">
          <div class="order-create__head">
            <div class="order-create__head-line">
              <div class="order-create__header-col"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['chat_backTo',])"></div>
                <router-link :to="$store.getters.GET_PATHS.dashboard" class="order-create__head-back">
                  <LinkBack
                      :value="$t('chat_backTo.localization_value.value')"
                  />
                </router-link>
              </div>
            </div>
            <div class="order-create__head-title"
                 v-if="_.has(currentChat, 'id')"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['chat_Messages'])"></div>
              {{ $t('chat_Messages.localization_value.value') }}
              {{currentChat.phone_number}} ({{currentChat.contact_name}})
            </div>
          </div>
        </template>
        <template slot="body">

          <div class="order-create__section chat__block">
            <div class="custom-row">
              <div class="custom-col">
                <div class="messages"
                     v-if="_.has(currentChat, 'id')"
                >

                  <div v-for="(message, index) in chatMessages"
                       :key="index"
                       :ref="chatMessages.length - 1 === index ? 'last' : undefined"
                  >
                    <div class="messages__item-left"
                         :ref="message.message_text"
                    >
                      <div class="messages__logo">
                        <img style="background: #ce9e64" src="/img/profile-group/profile-photo-default.svg" alt="img">
                      </div>
                      <div class="messages__item-left-txt">
                        {{message.message_text}}
                      </div>
                      <div class="messages__item-left-date">
                        {{ message.created_at | moment("DD MMM, YYYY") }}
                        <br>
                        <span v-if="_.find(CHAT_STATUSES, {id: parseInt(message.status)})">
                          {{$t(`${_.find(CHAT_STATUSES, {id: parseInt(message.status)}).translate}.localization_value.value`)}}
                        </span>
                      </div>
                    </div>
                  </div>

<!--                  <div class="messages__item-right">-->
<!--                    <div class="messages__item-right-date">20.20.2010</div>-->
<!--                    <div class="messages__item-right-txt">-->
<!--                      dfgfd dfsgdsfdsfjdns hfdshfgdshfg hds fgh dsgfhdsg hgdsf dsf dhsfg dsgfh dsfhdsg fds fdsfds-->
<!--                    </div>-->
<!--                    <div class="messages__logo">-->
<!--                      <img style="background: #ce9e64" src="/img/profile-group/profile-photo-default.svg" alt="img">-->
<!--                    </div>-->
<!--                  </div>-->
                </div>
              </div>
            </div>
          </div>


        </template>
        <template slot="footer">
          <div class="order-create__footer mt-3" v-if="_.has(currentChat, 'id')">

            <div
                class="w-100 mr-3 position-relative"
                v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['chat_Message'])"></div>
              <DefaultInput
                  :label="$t('chat_Message.localization_value.value')"
                  v-model="message"
              />
              <div class="counts">
                {{getCounters}}
              </div>
            </div>

            <div class="order-create__footer-btn ml-auto"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit"
                   @click="editTranslate(['common_send'])"></div>
              <MainButton
                  class="order-create__footer-btn-i white-space-nowrap__from-680"
                  :value="$t('common_send.localization_value.value')"
                  @click.native="sendMessage"
              />
            </div>

          </div>
        </template>
      </CardRightBlock>

    </div>

    <AddChatPopup
        v-if="createChatPopup"
        @reload="reload"
        @close="createChatPopup = false"
    />

  </div>
</template>

<script>
  import MainButton from "@/components/UI/buttons/MainButton/MainButton";
  import CardLeftBlock from "@/components/coreComponents/CardLeftBlock/CardLeftBlock";
  import CardRightBlock from "@/components/coreComponents/CardRightBlock/CardRightBlock";
  import LinkBack from "@/components/UI/buttons/LinkBack/LinkBack";
  import DefaultSelect from "@/components/UI/selectiones/DefaultSelect/DefaultSelect";
  import DefaultInput from "@/components/UI/inputs/DefaultInput/DefaultInput";
  import AddChatPopup from "@/components/modules/ChatModule/popups/AddChatPopup/AddChatPopup";
  import {queryFilterMixin} from "@/mixins/routeFilterMixins/queryFilterMixin";
  import {CHAT_STATUSES} from "@/staticData/staticVariables";
  import SearchSelect from "@/components/UI/selectiones/SearchSelect/SearchSelect";

  export default {
    name: "ChatSection",
    components: {
      SearchSelect,
      AddChatPopup,
      DefaultInput,
      DefaultSelect,
      LinkBack,
      CardRightBlock,
      CardLeftBlock,
      MainButton
    },

    mixins: [queryFilterMixin],

    computed: {
      getCounters() {
        let typeCount = 160;
        if (/[^a-zA-Z0-9 -\\.\\?@\\|;:]/ig.test(this.message)){
          typeCount = 70;
        }

        return `Characters: ${this.message.length}/${typeCount} | SMS Counters: ${Math.ceil(this.message.length/typeCount)}`
      }
    },

    data() {
      return {
        createChatPopup: false,
        chatContacts: [],

        chatMessages: [],

        optionsFindMessages: [],

        currentChat: null,

        message: '',
        foundMessage: null,

        CHAT_STATUSES: CHAT_STATUSES,
      }
    },

    mounted() {
      this.initChat()
    },

    methods: {

      functionSearchChat(val, loading) {
        loading(true)

        if (this.chatSearchTimeoutTime !== undefined) {
          let dateNow = new Date()
          if (dateNow - this.chatSearchTimeoutTime < 500) {
            clearTimeout(this.proformSearchTimeout)
          }
        }

        this.createTimeOut(val, loading)
      },

      createTimeOut(val, loading) {
        this.chatSearchTimeoutTime = new Date()
        this.proformSearchTimeout = setTimeout(() => {
          this.getSearchChat(val, loading)
        }, 500)
      },

      getSearchChat(val = false, loading){
        if(!val) return loading(false)

        const query = new this.Query();
        let url = '?';
        let myQuery = query
            .for('posts')

        myQuery.where('MessageText', val)

        url = url + myQuery.limit(100000).page(0).url().split('?')[1]

        this.$store.dispatch('fetchChatMassage', url).then((response) => {

          this.optionsFindMessages = this.getRespData(response)

          if(loading)
            loading(false)
        })

      },

      foundChatMessage(val) {
        if (!val) return
        this.foundMessage = val
        this.optionsFindMessages = []
        this.changeChatUser(this.foundMessage.sms_contact, this.foundMessage.message_text)
      },

      scrollToElement(findMessage = false) {

        setTimeout(() => {
          let [el] = this.$refs.last;

          if(findMessage) {
            [el] = this.$refs[findMessage]
            console.log(this.$refs.last);
            console.log(this.$refs[findMessage]);
          }

          if (el) {
            el.scrollIntoView({ behavior: "smooth" });
          }
        },1)

      },

      reload() {
        this.initChat()
      },

      initChat() {
        // this.$store.dispatch('refreshApiMassages').then(() => {
        this.$store.dispatch('fetchChat').then(response => {
          this.chatContacts = this.getRespData(response)

          let contactId = parseInt(this.$route.query.contactId)
          if(contactId){
            let foundContact = this._.find(this.chatContacts, {id: contactId})
            if(foundContact){
              this.changeChatUser(foundContact)
            }
          }
        })
        // })
      },

      changeChatUser(item, findMessage = false) {
        let contactId = this.$route.query.contactId
        this.currentChat = item
        this.initChatUser(findMessage)

        if(contactId && parseInt(contactId) === parseInt(item.id)) return

        this.$router.push({ path: '', query: {'contactId': item.id}})
      },

      initChatUser(findMessage = false) {
        if(!this.currentChat.id) return

        // refresh api messages
        this.$store.dispatch('refreshApiMassageByContact', this.currentChat.id).then(() => {
          let myQuery = this.createQueryBuilderObject()

          myQuery.where('SmsContactId', this.currentChat.id)

          let url = this.generateGetParamsFromQuery(myQuery, 100000, 1)

          this.$store.dispatch('fetchChatMassage', url).then(response => {
            // console.log(response);
            this.chatMessages = this.getRespData(response)
            // console.log(22);
            this.scrollToElement(findMessage)
          })
        })

      },

      sendMessage() {
        if(this.message.length === 0) return
        // if(!this.currentChat?.contact_id) return

        let data = {
          'sms_contact_id': this.currentChat.id ? this.currentChat.id : null,
          'message_text': this.message,
          'payload_origin': 1,
          'status': 2
        }

        let typeDispatch = 'createChatMassage'

        this.$store.dispatch(typeDispatch, data).then(response => {
          switch (this.getResponseStatus(response)) {
              /**
               * Success
               */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
              this.initChatUser()
              this.message = ''

              this.$store.dispatch('sendApiMassage', {id: this.getRespData(response)?.id}).then(response => {
                console.log(21312312);
                console.log(response);
              })

              break
            }
              /**
               * Validation Error
               */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
              /**
               * Undefined Error
               */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }
        })
      },
    }

  }
</script>

<style scoped lang="scss">
@import "../../../../../../scss/colors";
@import "../../../../../../scss/mixins/mixins";

.chat{

  &__items{
    max-height: calc(100vh - 398px);
    overflow: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 3px;
      height: 2px;
      border: 1px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
      margin-right: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: $borderBrown;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: $borderBrown;
      opacity: .5;
    }

  }

  &__item{
    margin-bottom: 0;
    padding-top: 15px;
    padding-bottom: 15px;
    transition: 0.3s;
    border-radius: 10px;
    cursor: pointer;

    & + .chat__item {
      position: relative;

      .chat__user :before{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: calc(100% - 30px);
        height: 1px;
        background: $brown;
        margin-left: 15px;
        transition: 0.1s;
      }
    }

    &:hover{
      & + .chat__item {
        .chat__user :before {
          opacity: 0;
        }
      }
      .chat__user :before {
        opacity: 0;
      }

      background: rgba(143, 122, 97, 0.2);
    }

    &--active{
      background: rgba(143, 122, 97, 0.2);
      & + .chat__item {
        .chat__user :before {
          opacity: 0;
        }
      }
    }
  }

  &__user{

  }

  &__user-inner{
    display: flex;
  }

  &__user-photo{
    width: 50px;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    margin-right: 10px;
  }

  &__user-block{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding-top: 3px;
    padding-bottom: 2px;
  }

  &__user-name{
    color: $black;
    font-weight: 500;
    font-size: 16px;
    display: flex;
  }

  &__user-phone{
    color: $brown;
    font-weight: 500;
    font-size: 13px;
  }

  &__user-date{
    font-size: 13px;
    margin-left: auto;
    padding-top: 5px;
  }


  &__block{
    min-height: calc(100vh - 355px);
    max-height: calc(100vh - 355px);
    overflow: auto;
    overflow-x: hidden;
    //background: $mainBg;
    background: rgba(143, 122, 97, 0.2);
    //border: 1px solid $borderBrown;
    border-radius: 10px;
    padding: 15px;

    &::-webkit-scrollbar {
      width: 3px;
      height: 2px;
      border: 1px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
      margin-right: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: $borderBrown;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: $borderBrown;
      opacity: .5;
    }
  }
}


.messages{

  &__item-left{
    display: flex;
    width: 100%;
    align-items: flex-end;
    margin-bottom: 30px;
  }

  &__logo{
    width: 50px;
    min-width: 50px;
    border-radius: 10px;
    display: flex;
    overflow: hidden;
    margin-right: 15px;
  }

  &__item-left-txt{
    padding: 15px;
    background: white;
    border-radius: 20px;
    border-bottom-left-radius: 0;
    font-size: 13px;
    margin-right: 15px;
  }

  &__item-left-date{
    font-size: 10px;
  }

  &__item-right{
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }

  &__item-right-date{
    font-size: 10px;
    margin-right: 15px;
  }

  &__item-right-txt{
    padding: 15px;
    background: white;
    border-radius: 20px;
    border-bottom-right-radius: 0;
    font-size: 13px;
    margin-right: 15px;
  }

}

.counts{
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 13px;
  margin-bottom: -20px;
}

@include for-680 {
  .order-create__footer {
    margin-left: 0;
  }

  .detail-page__right {
    padding-bottom: 35px;
  }
}

</style>

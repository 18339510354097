<template>
	<div class="order-create__section">

		<div class="order-create__title-horizontal"
				 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
			<div class="admin-edit" @click="editTranslate([
          'common_pickupInfo',
        ])"></div>
			<TitleHorizontal
					:value="$t('common_pickupInfo.localization_value.value')"
					:rightBtn="true"
					:rightBtnType="'edit'"
					:rightBtnText="$t('common_edit.localization_value.value')"
					@rightBtnClick="$emit('changeStep', 1)"
			/>

		</div>

		<div class="order-create__row custom-row" v-if="AUTHORIZED = getUserContacts">
			<div class="order-create__col custom-col"
					 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
				<div class="admin-edit" @click="editTranslate([
          'common_address',
          'common_city',
          'common_region',
          'common_country',
          'common_zipCode',
        ])"></div>
				<div class="order-create__table block-table">
					<div class="block-table__name">
						{{$t('ex_sender.localization_value.value')}}
					</div>
					<div class="block-table__row">
						<div class="block-table__col block-table__col--20">
							<div class="block-table__label">
								{{$t('common_address.localization_value.value')}}
							</div>
							<div class="block-table__content">
								<template v-if="ex.express.sendType === ex.express.sendTypes.warehouse &&
									ex.express.warehouse && ex.express.warehouse !== ''">
									{{ex.express.warehouse.sender_address}}
								</template>
                <template v-else-if="Object.keys(ex.express.user).length > 0">
                  {{ getUserContactsBySelectedUser.address }}
                </template>
                <template v-else>
                  {{ AUTHORIZED.address }}
                </template>
							</div>
						</div>
						<div class="block-table__col block-table__col--20">
							<div class="block-table__label">
								{{$t('common_city.localization_value.value')}}
							</div>
							<div class="block-table__content">
								<template v-if="ex.express.sendType === ex.express.sendTypes.warehouse &&
									ex.express.warehouse && ex.express.warehouse !== ''">
									{{ex.express.warehouse.sender_city}}
								</template>
                <template v-else-if="Object.keys(ex.express.user).length > 0">
                  {{ getUserContactsBySelectedUser.city }}
                </template>
                <template v-else>
                  {{ AUTHORIZED.city }}
                </template>
							</div>
						</div>
<!--						<div class="block-table__col block-table__col&#45;&#45;20">-->
<!--							<div class="block-table__label">-->
<!--								{{$t('common_region.localization_value.value')}}-->
<!--							</div>-->
<!--							<div class="block-table__content">-->
<!--								<template v-if="ex.express.sendType === ex.express.sendTypes.warehouse &&-->
<!--									ex.express.warehouse && ex.express.warehouse !== ''">-->
<!--									- -->
<!--								</template>-->
<!--								<template v-else-if="Object.keys(ex.express.user).length > 0">-->
<!--                  {{ getExpressUserRegion }}-->
<!--								</template>-->
<!--								<template v-else>-->
<!--									{{AUTHORIZED.user.user_personal_contact.region.name}}-->
<!--								</template>-->
<!--							</div>-->
<!--						</div>-->
						<div class="block-table__col block-table__col--20">
							<div class="block-table__label">
								{{$t('common_country.localization_value.value')}}
							</div>
							<div class="block-table__content">
								<template v-if="ex.express.sendType === ex.express.sendTypes.warehouse &&
									ex.express.warehouse && ex.express.warehouse !== ''">
                  {{ ex.express.warehouse.country.name }}
								</template>
                <template v-else-if="Object.keys(ex.express.user).length > 0">
                  {{ getUserContactsBySelectedUser.country.name }}
                </template>
                <template v-else>
                  {{ AUTHORIZED.country.name }}
                </template>
							</div>
						</div>
						<div class="block-table__col block-table__col--20">
							<div class="block-table__label">
								{{$t('common_zipCode.localization_value.value')}}
							</div>
							<div class="block-table__content">
								<template v-if="ex.express.sendType === ex.express.sendTypes.warehouse &&
									ex.express.warehouse && ex.express.warehouse !== ''">
									{{ex.express.warehouse.sender_postcode}}
								</template>
                <template v-else-if="Object.keys(ex.express.user).length > 0">
                  {{ getUserContactsBySelectedUser.zip }}
                </template>
                <template v-else>
                  {{ AUTHORIZED.zip }}
                </template>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="order-create__row custom-row"
         v-if="ex.express.type === NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE.id">
			<div class="order-create__col custom-col"
					 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
				<div class="order-create__table block-table">
					<div class="block-table__name">
						{{$t('poshta__ServiceType.localization_value.value')}}
					</div>
					<div class="block-table__row">
						<div class="block-table__col block-table__col--20" v-if="ex.express.serviceType === ex.express.serviceTypes.doors">
							<div class="block-table__label">
								{{$t('poshta__PersonalAddress.localization_value.value')}}
							</div>
						</div>
            <div class="block-table__col block-table__col--66" v-else>
              <div class="block-table__label">
                {{$t('poshta__NovaPoshtaAddress.localization_value.value')}}
              </div>
              <div class="block-table__content">
                {{ex.express.novaPoshtaAddress ? ex.express.novaPoshtaAddress.warehouse_name : '—'}}
              </div>
            </div>
					</div>
				</div>
			</div>
		</div>

		<div class="order-create__title-horizontal"
				 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
			<div class="admin-edit" @click="editTranslate([
          'ex_products',
        ])"></div>
			<TitleHorizontal
					:value="$t('ex_products.localization_value.value')"
					:rightBtn="true"
					:rightBtnType="'edit'"
					:rightBtnText="$t('common_edit.localization_value.value')"
					@rightBtnClick="$emit('changeStep', 3)"
			/>
		</div>

		<div class="order-create__section-label section-label"
				 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
			<div class="admin-edit" @click="editTranslate([
          'ex_products',
        ])"></div>
			{{$t('ex_products.localization_value.value')}}
		</div>

		<div class="custom-row">
			<div class="custom-col custom-col--33 custom-col--md-50 custom-col--sm-100"
					 v-for="(item, index) in ex.express.products"
					 :key="index"
			>
				<div class="confirm-product-card">
					<div class="confirm-product-card__items">
						<div class="confirm-product-card__item" v-if="item.product.proformData.proformCategory !== ''">
							<div class="confirm-product-card__item-name"
									 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
								<div class="admin-edit" @click="editTranslate([
                          'proform_category',
                        ])"></div>
								{{$t('proform_category.localization_value.value')}}
							</div>
							<div class="confirm-product-card__item-val">
								{{item.product.proformData.proformCategory.translationStorage.en.name}}
							</div>
						</div>

						<template v-if="localProformTypes = item.product.proformData.proformTypes">
							<template  v-for="(itemType, index) in $store.getters.getProformProductInitialize.proformTypes">
								<div class="confirm-product-card__item" :key="index" v-if="isUkrainian">
									<div class="confirm-product-card__item-name">
										{{getProformTypeLabel(localProformTypes, itemType)}}
									</div>
									<div class="confirm-product-card__item-val">
										{{localProformTypes[itemType.id].typeValue !== '' ? localProformTypes[itemType.id].typeValue.value.translationStorage[proformUserConfig.lang].name : ''}}
									</div>
								</div>
								<div class="confirm-product-card__item" :key="index + '2'">
									<div class="confirm-product-card__item-name">
										{{localProformTypes[itemType.id].typeName[itemType.translationStorage.en.name + '_En'].name }}
									</div>
									<div class="confirm-product-card__item-val">
										{{localProformTypes[itemType.id].typeValue !== '' ? localProformTypes[itemType.id].typeValue.value.translationStorage.en.name : ''}}
									</div>
								</div>
							</template>
						</template>

						<div class="confirm-product-card__item"
								 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
							<div class="admin-edit" @click="editTranslate([
								'express_Price',
							])"></div>
								<div class="confirm-product-card__item-name">
									{{$t('express_Price.localization_value.value')}}
								</div>
								<div class="confirm-product-card__item-val">
									{{item.product.price}}
								</div>
							</div>

						<div class="confirm-product-card__item"
								 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
							<div class="admin-edit" @click="editTranslate([
								'express_Quantity',
							])"></div>
							<div class="confirm-product-card__item-name">
								{{$t('express_Quantity.localization_value.value')}}
							</div>
							<div class="confirm-product-card__item-val">
								{{item.product.itemsQuantity}}
							</div>
						</div>

						<div class="confirm-product-card__item"
								 v-if="ex.express.visibleHSCode && item.product.HSCode !== ''"
								 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
							<div class="admin-edit" @click="editTranslate([
								'common_HSCode',
							])"></div>
								<div class="confirm-product-card__item-name">
									{{$t('common_HSCode.localization_value.value')}}
								</div>
								<div class="confirm-product-card__item-val">
									{{item.product.HSCode}}
								</div>
						</div>
					</div>

					<div class="confirm-product-card__bottom" v-if="item.product.handMade"
							 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
						<div class="admin-edit" @click="editTranslate([
                          'product_Handmade',
                        ])"></div>
						<StatusIcoBtn :type="'handmade'" class="mr-2"/>
						{{$t('product_Handmade.localization_value.value')}}
					</div>
				</div>
			</div>
		</div>
		<div class="block-table__total site-line-total mt-0 mb-4">
			<div
				 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
			<div class="admin-edit" @click="editTranslate([
							'common_TotallyPerOrder',
						])"></div>
			{{$t('common_TotallyPerOrder.localization_value.value')}}
			</div>

			<span>${{calculatePrice}}</span>
		</div>

		<template v-if="ex.express.type === FEDEX_DELIVERY_SERVICE.id">
			<div class="order-create__section-label section-label"
					 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
				<div class="admin-edit" @click="editTranslate([
						'express_DocumentsINeed',
					])"></div>
				{{$t('express_DocumentsINeed.localization_value.value')}}
			</div>

			<div class="custom-row">
				<div class="custom-col custom-col--33 d-flex align-items-center "
						 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
					<div class="admin-edit" @click="editTranslate([
							'express_ToxicSubstance',
							'express_NeededForSuch',
						])"></div>
					<DefaultCheckbox
									v-model="ex.express.toxicSubstanceControlActCert"
									:label="$t('express_ToxicSubstance.localization_value.value')"
									@click="ex.express.toxicSubstanceControlActCert = !ex.express.toxicSubstanceControlActCert"
									:disabled="true"
					/>
				</div>
				<div class="custom-col custom-col--33 d-flex align-items-center "
						 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
					<div class="admin-edit" @click="editTranslate([
							'express_DropBallTest',
							'express_DropBallTest',
						])"></div>
					<DefaultCheckbox
									v-model="ex.express.dropBallTestCert"
									:label="$t('express_DropBallTest.localization_value.value')"
									@click="ex.express.dropBallTestCert = !ex.express.dropBallTestCert"
									:disabled="true"
					/>
				</div>
			</div>
		</template>

    <div class="custom-row" v-if="ex.express.promoCodes.length && ex.express.promoCodes[0].data.code !== ''">
      <div class="order-create__col custom-col mb-0">
        <div class="block-table__name"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate([
                'common_promoCodes',
              ])"></div>
          {{$t('common_promoCodes.localization_value.value')}}
        </div>
      </div>
     <div class="custom-col custom-col--33 d-flex align-items-center "
          v-for="(item, index) in ex.express.promoCodes"
          :key="index"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        {{item.data.code}}
      </div>
    </div>

		<div class="order-create__title-horizontal"
				 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
			<div class="admin-edit" @click="editTranslate([
							'ex_tabsProductsDetail',
						])"></div>
			<TitleHorizontal
					:value="$t('ex_tabsProductsDetail.localization_value.value')"
					:rightBtn="true"
					:rightBtnType="'edit'"
					:rightBtnText="$t('common_edit.localization_value.value')"
					@rightBtnClick="$emit('changeStep', 2)"
			/>
		</div>

		<div class="order-create__row custom-row">
			<div class="order-create__col custom-col">
				<div class="order-create__table block-table">
					<div class="block-table__name"
							 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
						<div class="admin-edit" @click="editTranslate([
							'ex_personalInformation',
						])"></div>
						{{$t('ex_personalInformation.localization_value.value')}}
					</div>
					<div class="block-table__row">
						<div class="block-table__col block-table__col--20">
							<div class="block-table__label"
									 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
								<div class="admin-edit" @click="editTranslate([
							'express_ContactName',
						])"></div>
								{{$t('express_ContactName.localization_value.value')}}
							</div>
							<div class="block-table__content">
								{{ex.express.personal.information.personalName}}
							</div>
						</div>
<!--						<div class="block-table__col block-table__col&#45;&#45;20 mb-0"></div>-->
						<div class="block-table__col block-table__col--20">
							<div class="block-table__label"
									 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
								<div class="admin-edit" @click="editTranslate([
							'common_phone',
						])"></div>
								{{$t('common_phone.localization_value.value')}}
							</div>
							<div class="block-table__content">
								{{ex.express.personal.information.personalPhone}}
							</div>
						</div>
						<div class="block-table__col block-table__col--20">
							<div class="block-table__label"
									 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
								<div class="admin-edit" @click="editTranslate([
							'express_Email',
						])"></div>
								{{$t('express_Email.localization_value.value')}}
							</div>
							<div class="block-table__content">
								{{ex.express.personal.information.personalEmail}}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>


		<div class="order-create__row custom-row">
			<div class="order-create__col custom-col">
				<div class="order-create__table block-table">
					<div class="block-table__name"
							 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
						<div class="admin-edit" @click="editTranslate([
							'ex_deliveryAddress',
						])"></div>
						{{$t('ex_deliveryAddress.localization_value.value')}}
					</div>
					<div class="block-table__row">
						<div class="block-table__col block-table__col--20">
							<div class="block-table__label"
									 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
								<div class="admin-edit" @click="editTranslate([
							'common_address',
						])"></div>
								{{$t('common_address.localization_value.value')}}
							</div>
							<div class="block-table__content">
								{{ex.express.delivery.delivery.address}}
							</div>
						</div>
						<div class="block-table__col block-table__col--20"
                 v-if="ex.express.delivery.delivery.address2 !== ''"
            >
							<div class="block-table__label"
									 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
								<div class="admin-edit" @click="editTranslate([
							'common_addressSecond',
						])"></div>
								{{$t('common_addressSecond.localization_value.value')}}
							</div>
							<div class="block-table__content">
								{{ex.express.delivery.delivery.address2}}
							</div>
						</div>
						<div class="block-table__col block-table__col--20">
							<div class="block-table__label"
									 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
								<div class="admin-edit" @click="editTranslate([
							'common_city',
						])"></div>
								{{$t('common_city.localization_value.value')}}
							</div>
							<div class="block-table__content">
								{{ex.express.delivery.delivery.city}}
							</div>
						</div>
						<div class="block-table__col block-table__col--20">
							<div class="block-table__label"
									 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
								<div class="admin-edit" @click="editTranslate([
							'common_region',
						])"></div>
								{{$t('common_region.localization_value.value')}}
							</div>
							<div class="block-table__content">
								{{ex.express.delivery.delivery.region}}
							</div>
						</div>
						<div class="block-table__col block-table__col--20">
							<div class="block-table__label"
									 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
								<div class="admin-edit" @click="editTranslate([
							'common_country',
						])"></div>
								{{$t('common_country.localization_value.value')}}
							</div>
							<div class="block-table__content">
								{{ex.express.delivery.delivery.country.label}}
							</div>
						</div>
						<div class="block-table__col block-table__col--20"
								 v-bind:class="{'mt-3': ex.express.delivery.delivery.address2 !== ''}"
						>
							<div class="block-table__label"
									 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
								<div class="admin-edit" @click="editTranslate([
							'common_zipCode',
						])"></div>
								{{$t('common_zipCode.localization_value.value')}}
							</div>
							<div class="block-table__content">
								{{ex.express.delivery.delivery.zip}}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>




		<div class="order-create__row custom-row">
			<div class="order-create__col custom-col">
				<div class="order-create__table block-table">
					<div class="block-table__name"
							 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
						<div class="admin-edit" @click="editTranslate([
							'express_DeliveryDate',
						])"></div>
						{{$t('express_DeliveryDate.localization_value.value')}}
					</div>
					<div class="block-table__row">
						<div class="block-table__col block-table__col--20">
							<div class="block-table__label"
									 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
								<div class="admin-edit" @click="editTranslate([
							'common_date',
						])"></div>
								{{$t('common_date.localization_value.value')}}
							</div>
							<div class="block-table__content">
								{{ ex.express.deliveryDate | moment("DD MMM, YYYY") }}
							</div>
						</div>

						<div class="block-table__col block-table__col--20">
							<div class="block-table__label"
									 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
								<div class="admin-edit" @click="editTranslate([
                          'express_TrackingNumber',
                        ])"></div>
								{{$t('express_TrackingNumber.localization_value.value')}}
							</div>
							<div class="block-table__content">
								<ValueHelper
												:value="ex.express.systemTrackingNumber"
								/>
							</div>
						</div>

						<div class="block-table__col block-table__col--20">
							<div class="block-table__label"
									 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
								<div class="admin-edit" @click="editTranslate([
                          'express_TrackingNumber',
                        ])"></div>
								{{$t('express_TrackingNumberNP.localization_value.value')}}
							</div>
							<div class="block-table__content">
								<ValueHelper
												:value="ex.express.trackingNumber"
								/>
							</div>
						</div>

						<div class="block-table__col block-table__col--20">
							<div class="block-table__label"
									 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
								<div class="admin-edit" @click="editTranslate([
                          'common_vatNumber',
                        ])"></div>
								{{$t('common_vatNumber.localization_value.value')}}
							</div>
							<div class="block-table__content">
								<ValueHelper
												:value="ex.express.vatNumber"
								/>
							</div>
						</div>

						<div class="block-table__col block-table__col--20">
							<div class="block-table__label"
									 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
								<div class="admin-edit" @click="editTranslate([
                          'express_orderPrice',
                        ])"></div>
								{{$t('express_orderPrice.localization_value.value')}}
							</div>
							<div class="block-table__content">
								<ValueHelper
												:value="ex.express.orderPrice"
								/>
							</div>
						</div>

            <div class="block-table__col block-table__col--20 mt-3">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate([
                          'express_FreightCost',
                        ])"></div>
                {{$t('express_FreightCost.localization_value.value')}}
              </div>
              <div class="block-table__content">
                <ValueHelper
                    :value="ex.express.freightCost"
                />
              </div>
            </div>

            <div class="block-table__col block-table__col--20 mt-3" v-if="ex.express.recipientTax.length">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                Mexican Tax ID / National ID
              </div>
              <div class="block-table__content">
                <ValueHelper
                    :value="ex.express.recipientTax"
                />
              </div>
            </div>

						<template v-if="ex.express.type === TNT_DELIVERY_SERVICE.id && ex.express.sendType === ex.express.sendTypes.sender">

							<!--<div class="block-table__col block-table__col&#45;&#45;20 mb-0"></div>-->

							<div class="block-table__col block-table__col--20">
								<div class="block-table__label" v-if="!ex.express.lunchBreak"
										 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
									<div class="admin-edit" @click="editTranslate([
							'express_TimeGap',
						])"></div>
									{{$t('express_TimeGap.localization_value.value')}}
								</div>
								<div class="block-table__label" v-if="ex.express.lunchBreak"
										 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
									<div class="admin-edit" @click="editTranslate([
							'express_BeforeLunch',
						])"></div>
									{{$t('express_BeforeLunch.localization_value.value')}}
								</div>
								<div class="block-table__content">
									{{ ex.express.timeCourierFrom | moment("hh:mm") }} —
									{{ ex.express.timeCourierTo | moment("hh:mm") }}
								</div>
							</div>

							<div class="block-table__col block-table__col--20 mt-3"
									 v-if="ex.express.lunchBreak"
							>
								<div class="block-table__label"
										 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
									<div class="admin-edit" @click="editTranslate([
										'express_AfterLunch',
									])"></div>
									{{$t('express_AfterLunch.localization_value.value')}}
								</div>
								<div class="block-table__content">
									{{ ex.express.timeNotAvailableFrom | moment("hh:mm") }} —
									{{ ex.express.timeNotAvailableTo | moment("hh:mm") }}
								</div>
							</div>
						</template>
					</div>
				</div>
			</div>
		</div>

		<div class="order-create__title-horizontal"
				 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
			<div class="admin-edit" @click="editTranslate([
										'ex_packaging',
									])"></div>
			<TitleHorizontal
					:value="$t('ex_packaging.localization_value.value')"
					:rightBtn="true"
					:rightBtnType="'edit'"
					:rightBtnText="$t('common_edit.localization_value.value')"
					@rightBtnClick="$emit('changeStep', 4)"
			/>
		</div>
		<div class="order-create__row custom-row">
			<div class="order-create__col custom-col">
				<div class="order-create__table block-table">
					<div class="block-table__row">
						<div class="block-table__col block-table__col--40">
							<div class="block-table__label"
									 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
								<div class="admin-edit" @click="editTranslate([
										'packaging_NamePackage',
									])"></div>
								{{$t('packaging_NamePackage.localization_value.value')}}
							</div>
							<div class="block-table__content">
								{{ex.express.packaging.packaging.name.package_name}}
							</div>
						</div>

						<div class="block-table__col block-table__col--fit">
							<div class="block-table__label"
									 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
								<div class="admin-edit" @click="editTranslate([
										'packaging_Weight',
									])"></div>
								{{$t('packaging_Weight.localization_value.value')}}
							</div>
							<div class="block-table__content">
								{{ex.express.packaging.packaging.weight}}kg
							</div>
						</div>
						<div class="block-table__col block-table__col--fit">
							<div class="block-table__label"
									 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
								<div class="admin-edit" @click="editTranslate([
										'packaging_Height',
									])"></div>
								{{$t('packaging_Height.localization_value.value')}}
							</div>
							<div class="block-table__content">
								{{ex.express.packaging.packaging.height}}cm
							</div>
						</div>
						<div class="block-table__col block-table__col--fit">
							<div class="block-table__label"
									 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
								<div class="admin-edit" @click="editTranslate([
										'packaging_Width',
									])"></div>
								{{$t('packaging_Width.localization_value.value')}}
							</div>
							<div class="block-table__content">
								{{ex.express.packaging.packaging.width}}cm
							</div>
						</div>
						<div class="block-table__col block-table__col--fit">
							<div class="block-table__label"
									 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
								<div class="admin-edit" @click="editTranslate([
										'packaging_Length',
									])"></div>
								{{$t('packaging_Length.localization_value.value')}}
							</div>
							<div class="block-table__content">
								{{ex.express.packaging.packaging['length']}}cm
							</div>
						</div>
						<div class="block-table__col block-table__col--fit">
							<div class="block-table__label"
									 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
								<div class="admin-edit" @click="editTranslate([
										'express_Volume',
									])"></div>
								{{$t('express_Volume.localization_value.value')}}
							</div>
							<div class="block-table__content">
								{{ex.express.packaging.packaging.volumeWeight}}kg
							</div>
						</div>

            <div class="block-table__col block-table__col--fit"
                 v-if="ex.getExpressType() === FEDEX_DELIVERY_SERVICE.id && isBalticByUserData(getUserContactDataFromUser(ex.express.user).country_group)">
              <div class="block-table__label">
                {{$t('express_fedexPacking.localization_value.value')}}
              </div>
              <div class="block-table__content">
                {{$t(`${ex.express.fedexPackingType.translation}.localization_value.value`)}}
              </div>
            </div>
					</div>



					<div class="block-table__row"
							 v-if="ex.express.packaging.packaging.insurance"
					>
						<div class="block-table__col block-table__col--40">
							<div class="block-table__content mt-3"
									 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
								<div class="admin-edit" @click="editTranslate([
										'express_INeedInsurance',
									])"></div>
								<DefaultCheckbox
									:label="$t('express_INeedInsurance.localization_value.value') + ' — $' + ex.express.packaging.packaging.insurancePrice"
									:value="true"
									:disabled="true"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
	import TitleHorizontal from "../../../../../coreComponents/TitleHorizontal/TitleHorizontal";
	import DefaultCheckbox from "../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
	import {mixinDetictingMobile} from "../../../../../../mixins/mobileFunctions";
	import StatusIcoBtn from "../../../../../UI/status/StatusIcoBtn/StatusIcoBtn";
  import {
    FEDEX_DELIVERY_SERVICE,
    NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE,
    TNT_DELIVERY_SERVICE
  } from "../../../../../../staticData/staticVariables";
	import {expressMixins} from "../../../../../../mixins/expressMixins/expressMixin";
	import ValueHelper from "../../../../../coreComponents/ValueHelper/ValueHelper";
	import {proformMixins} from "../../../../../../mixins/proformMixins/proformMixins";
  import {expressUserContactsMixin} from "@/mixins/expressMixins/expressUserContactsMixin";

	export default {
		name: "Confirmation",

		components: {
			ValueHelper,
			TitleHorizontal,
			DefaultCheckbox,
			StatusIcoBtn,
		},

		mixins: [mixinDetictingMobile, expressMixins, expressUserContactsMixin, proformMixins],

		props: {
			ex: Object,
		},

		data() {
			return {
				TNT_DELIVERY_SERVICE: TNT_DELIVERY_SERVICE,
				FEDEX_DELIVERY_SERVICE: FEDEX_DELIVERY_SERVICE,
        NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE: NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE,
			}
		},

		methods: {
			editTest(){
				console.log('function editTest');
			}
		}
	}
</script>

<style scoped>

</style>

<template>
  <div class="detail-page reverse">
    <div class="detail-page__left">
      <CardLeftBlock
          class="clear-992"
          :backgroundImage="'product-admin'"
      >
        <template slot="body">
          <div class="card-detail-left__date">
            {{ new Date() | moment("DD MMM, YYYY") }}
          </div>
          <div class="site-link brown text-decoration-underline mt-4">
            <div v-if="isAdmin && CU.data.historyOfChanged"
                 class="mb-3"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['common_CheckHistory'])"></div>
              <div class="site-link text-decoration-underline btn-style brown"
                   @click="historyOfChangesFunc('consolidationUnions', CU.data.historyOfChanged)">
                {{$t('common_CheckHistory.localization_value.value')}}
              </div>
            </div>
          </div>
        </template>
      </CardLeftBlock>
    </div>

    <div class="detail-page__right">
      <CardRightBlock>
        <template slot="header">
          <div class="order-create__head">
            <div class="order-create__head-line">
              <div class="order-create__header-col"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate([
                  'consolidationUnion_backTo',
                  ])"></div>
                <router-link :to="$store.getters.GET_PATHS.reportsConsolidation" class="detail-page__head-back">
                  <LinkBack
                      :value="$t('consolidationUnion_backTo.localization_value.value')"
                  />
                </router-link>
              </div>
            </div>
            <div class="order-create__head-title"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate([
                  'consolidationUnion_concolidation',
                  ])"></div>
              {{$t('consolidationUnion_concolidation.localization_value.value')}}
            </div>
            <div class="order-create__head-txt mb-2">

            </div>
          </div>
        </template>

        <template slot="body">
          <div class="order-create__section">
            <FieldsBlock
                :CU="CU"
                :warehousesData="warehousesData"
                :carriers="carriers"
                :shippingCompanies="shippingCompanies"
            />

            <div class="order-create__row custom-row">
              <div class="custom-col custom-col--66 custom-col--md-100"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate([
                  'consolidationUnion_comment',
                  ])"></div>
                <TextareaDefault
                    :label="$t('consolidationUnion_comment.localization_value.value')"
                    v-model="CU.data.comment"
                />
              </div>
            </div>

            <ScannerBlock
                :check="CU.getStatus() !== null"
                :CU="CU"
                @showWarningWrongStatePopup="showWarningWrongStatePopup"
                @saveConsolidationUnion="$emit('saveConsolidationUnion', true)"
            />

            <div class="order-create__row custom-row"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate([
                  'consolidationUnion_sendDate',
                  ])"></div>
              <div class="order-create__col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100">
                <DatePickerDefault
                    :label="$t('consolidationUnion_sendDate.localization_value.value')"
                    :placeholder="$t('common_dateYYYY_MM_DD.localization_value.value')"
                    v-bind:class="{'ui-no-valid': CU.validation.sendAt}"
                    :errorTxt="$t(`${CU.validationTranslate.sendAt}.localization_value.value`)"
                    :error="CU.validation.sendAt"
                    :disabled="true"
                    v-model="CU.data.sendAt"
                >
                  <template slot="body">
                    <date-picker
                        v-model="CU.data.sendAt"
                        ref="datePicker"
                        valueType="format"
                        :type="'datetime'"
                        :disabled="true"
                        :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                        :format="'YYYY-MM-DD HH:mm'"
                        :placeholder="$t('common_dateYYYY_MM_DD.localization_value.value')"
                    ></date-picker>
                  </template>
                </DatePickerDefault>
              </div>
            </div>

          </div>
        </template>
        <template slot="footer">
          <div class="order-create__footer">
            <div class="order-create__footer-link"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate([
                  'common_cancel',
                  ])"></div>
              <router-link :to="$store.getters.GET_PATHS.reportsConsolidation"
                           class="site-link site-link--alt"
              >
                {{$t('common_cancel.localization_value.value')}}
              </router-link>

            </div>

<!--            <div class="order-create__footer-btn ml-auto"-->
<!--                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--              <div class="admin-edit" @click="editTranslate([-->
<!--                  'common_save',-->
<!--                  ])"></div>-->
<!--              <MainButton-->
<!--                  class="order-create__footer-btn-i"-->
<!--                  :value="$t('common_save.localization_value.value')"-->
<!--                  @click.native="$emit('saveConsolidationUnion', true)"-->
<!--              />-->
<!--            </div>-->
          </div>
        </template>
      </CardRightBlock>
    </div>

    <WrongStateWarningPopup
        v-if="isWarningWrongStatePopup"
        @close="isWarningWrongStatePopup = false"
    />
  </div>
</template>

<script>
  // import MainButton from "../../../../../UI/buttons/MainButton/MainButton";
  import CardLeftBlock from "../../../../../coreComponents/CardLeftBlock/CardLeftBlock.vue";
  import CardRightBlock from "../../../../../coreComponents/CardRightBlock/CardRightBlock.vue";
  import LinkBack from "../../../../../UI/buttons/LinkBack/LinkBack";
  import TextareaDefault from "../../../../../UI/textarea/TextareaDefault/TextareaDefault";
  import {mixinDetictingMobile} from "../../../../../../mixins/mobileFunctions";
  import FieldsBlock from "../../chunks/FieldsBlock";
  // import ScannerBlock from "../../chunks/ScannerBlock";
  import DatePickerDefault from "../../../../../UI/inputs/DatePickerDefault/DatePickerDefault";
  import DatePicker from "vue2-datepicker";
  import ScannerBlock from "../../chunks/ScannerBlock";
  import WrongStateWarningPopup from "../../../popups/WrongStateWarningPopup/WrongStateWarningPopup";

  export default {
    name: "ConsolidationUnionEditAdmin",
    components: {
      WrongStateWarningPopup,
      ScannerBlock,
      DatePickerDefault,
      DatePicker,
      // ScannerBlock,
      FieldsBlock,
      // MainButton,
      CardLeftBlock,
      CardRightBlock,
      LinkBack,
      TextareaDefault,
    },

    mixins: [mixinDetictingMobile],

    props: {
      CU: Object,
      warehousesData: Array,
      carriers: Array,
      shippingCompanies: Array,
    },

    data(){
      return{
        options: [{}],
        valueQuantity: 100,
        comment: 'Please be careful with the vase inside! ',
        input01: '',
        input02: '',
        input03: '',

        optionsMulti: [
          '#49357: $100.4',
          '#49357: $10.4',
          '#49357: $59.2'
        ],

        isWarningWrongStatePopup: false,
      }
    },

    methods: {
      showWarningWrongStatePopup() {
        this.isWarningWrongStatePopup = true
      },
    }
  }
</script>

<style scoped>
  .checkbox-scoped{
    margin-right: -5px;
  }

  td:nth-child(2){
    font-size: 12px;
    line-height: 14px;
  }
</style>

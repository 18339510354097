<template>
  <div class="fragment" v-if="FBA.data.User">
    <OrdersFBAEditAdmin
            v-if="loadUserAuthorizedData && isAdmin && orderLoaded && !reloading"
            :FBA="FBA"
            @save="save"
            @recalculateFees="recalculateFees"
    />
    <OrdersFBAEditUser
            v-if="loadUserAuthorizedData && !isAdmin"
            :FBA="FBA"
            @save="save"
    />
  </div>
</template>

<script>
  import OrdersFBAEditAdmin from "./OrdersFBAEditAdmin/OrdersFBAEditAdmin";
  import OrdersFBAEditUser from "./OrdersFBAEditUser/OrdersFBAEditUser";
  import {FBAMixin} from "../../../../../mixins/FBAMixins/FBAMixin";
  import {FBA} from "../../models/OrderFBA";
  import {FBA_FILE_TYPE} from "../../../../../staticData/staticVariables";
  import {userAvatarMixin} from "../../../../../mixins/usersMixins/userAvatarMixin";

  export default {
    name: "OrdersFBAEdit",
    components: {
      OrdersFBAEditAdmin,
      OrdersFBAEditUser,
    },

    mixins: [FBAMixin, userAvatarMixin],


    watch: {
      loadUserAuthorizedData: function () {
        this.getUserAuthorizedData()
        this.initOrder()
      },

      checkProductCount() {
        if(!this.reloading) {
          this.shippingFeesCalculate()
        }
      },

      checkProductSetsCount() {
        if(!this.reloading) {
          this.shippingSetFeeCalculate()
        }
      },
    },

    computed: {
      checkProductCount() {
        return this.FBA.data.productsForCellArray.length
      },
      checkProductSetsCount() {
        return this.FBA.data.sets.length
      },
    },

    data() {
      return {
        FBA: new FBA(),
        orderLoaded: false,
        reloading: false,
        FBA_FILE_TYPE: FBA_FILE_TYPE,
      }
    },

    mounted() {
      let orderId = this.$route.params.id

      /**
       * Set OrderId
       */
      this.FBA.setOrderId(orderId)

      if (this.loadUserAuthorizedData) {
        this.initOrder()
      }

    },

    methods: {
      reloadEditPage(openLabel) {
        this.reloading = true
        this.FBA = new FBA()

        let orderId = this.$route.params.id
        // let orderType = this.$route.params.type

        /**
         * Set OrderId
         */
        this.FBA.setOrderId(orderId)
        // this.FBM.setTypeFBM(orderType)

        this.initOrder().then(() => {
          this.reloading = false
          if(openLabel){
            let routeData = this.$router.resolve({ path: this.$store.getters.GET_PATHS.getPdfFile + '/' + this.FBA.data.Order.data.fileLabel.uuid})

            window.open(routeData.href, '_blank');
          }
        })
      },

      initOrder() {
        return this.$store.dispatch('getOrderFBAEdit', this.FBA.data.Order.getId()).then(response => {
          let order = this.getRespData(response)
          this.orderLoaded = false

          /**
           * Set FBA
           */
          this.setFiles(order)
          this.FBA.setItem(order, this.isAdmin)

          if (this.isAdmin) {
            this.setUserAvatar(this.FBA.data.User)
          }

          this.orderLoaded = true
        }).catch(error => this.createErrorLog(error))
      },

      setFiles(order) {
        if (order['shipping_label'].length > 0) {
          this.getFileFromServerFc(order['shipping_label'], FBA_FILE_TYPE.SHIPPING_LABEL)
        }
        if (order['amazon_fnsku'].length > 0) {
          this.getFileFromServerFc(order['amazon_fnsku'], FBA_FILE_TYPE.AMAZON_FNSKU)
        }
      },


      getUserAuthorizedData() {

      },

      recalculateFees() {
        this.shippingFeesCalculate()
        this.shippingSetFeeCalculate()
      },

      shippingFeesCalculate() {
        if(this.FBA.data.Order.data.orderOBJ?.admin_fee) return

        let orderProcessFee = parseFloat(this.FBA.data.order_process_fee.cost)
        let feeSum = 0

        this.FBA.data.productsForCellArray.map(productsForCell => {
          let productCount = productsForCell.data.productCount
          feeSum = feeSum + (productCount * orderProcessFee)
        })
        this.FBA.setOrderFee(parseFloat(feeSum).toFixed(2))
      },

      shippingSetFeeCalculate() {
        let orderProcessFee = parseFloat(this.FBA.data.order_process_fee.cost)
        let commissionForMoreOneInSet = parseFloat(this.FBA.data.commission_for_more_one_in_set)
        let productCount = 0
        let setsCount = 0

        this.FBA.data.sets.map(setItem => {
          setItem.data.productsSet.map(setProduct => {
            productCount = productCount + setProduct.data.productCount
          })
          productCount = productCount - 1
          setsCount += setItem.data.countSet
        })

        let productSetCount = this.FBA.data.sets.length
        let setFeeSum = ((orderProcessFee * productSetCount) + (commissionForMoreOneInSet * productCount)) * setsCount
        console.log(setFeeSum);
        // this.FBA.setSetCost(parseFloat(setFeeSum).toFixed(2))
      },

    }

  }
</script>

<style scoped>

</style>

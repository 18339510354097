import { render, staticRenderFns } from "./ChatContacts.vue?vue&type=template&id=5c5a15f9&scoped=true"
import script from "./ChatContacts.vue?vue&type=script&lang=js"
export * from "./ChatContacts.vue?vue&type=script&lang=js"
import style0 from "./ChatContacts.vue?vue&type=style&index=0&id=5c5a15f9&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c5a15f9",
  null
  
)

export default component.exports
<template>
  <div class="custom-row chat__items">
    <div class="custom-col chat__item"
         v-for="(item, index) in chatContacts"
         :key="index"
         v-bind:class="{'chat__item--active' : _.has(currentHeartysanChat, 'id') && currentHeartysanChat.id === item.id}"
         @click="$emit('changeHeartysanChat', item)"
    >
      <div class="chat__user-new" v-if="item.count_new_messages > 0">New {{ item.count_new_messages }}</div>
      <div class="chat__user"
      >
        <div class="chat__user-inner">
          <!--                      <div class="chat__user-photo">-->
          <!--                        <img style="background: #ce9e64" src="/img/profile-group/profile-photo-default.svg" alt="img">-->
          <!--                      </div>-->
          <div class="chat__user-status" v-if="getDeal(item)">
            <TooltipTextHelper>
              <template slot="text">
                <StatusIcoBtn
                    :type="getDeal(item).type"
                />
              </template>
              <template slot="paragraph">
                <div class="case-cart__content--description">{{getDeal(item).text}}</div>
              </template>
            </TooltipTextHelper>
          </div>

          <div class="chat__user-block">
            <div class="chat__user-name">
              {{ item.product.name }}
            </div>
            <div class="chat__user-phone">
              <template v-if="!isUserHeartyManufacturer">
                {{ item.product.user.user_personal_contact.user_full_name }}
              </template>
              <template v-else>
                {{ item.seller.user_full_name }}
              </template>
            </div>
          </div>
          <div class="chat__user-date">
            {{ item.created_at | moment("DD MMM, YYYY") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TooltipTextHelper from "@/components/coreComponents/TooltipTextHelper/TooltipTextHelper";
import StatusIcoBtn from "@/components/UI/status/StatusIcoBtn/StatusIcoBtn";

export default {
  name: "ChatContacts",
  components: {StatusIcoBtn, TooltipTextHelper},
  props: {
    chatContacts: Array,
    currentHeartysanChat: [Object,Array],
  },

  computed: {

  },

  methods: {

    getDeal(item) {
      if(item.deal.length === 0) {
        return false
      }
      let data = {
        type: '',
        text: '',
      }
      let dealItem = this._.first(item.deal)
      if(this.isUserHeartyManufacturer) {
        if(!this.isAcceptedByManufacturer(dealItem)) {
          data.type = 'in-transit'
          data.text = this.$t('heartysan_statusDealCreated.localization_value.value')
        }
        if(this.isAcceptedByManufacturer(dealItem) && !this.isAcceptedBySeller(dealItem)) {
          data.type = 'in-progress'
          data.text = this.$t('heartysan_statusDealWaitingAccept.localization_value.value')
        }
        if(this.isAcceptedByManufacturer(dealItem) && this.isAcceptedBySeller(dealItem) && !this.isCanceledByManufacturer(dealItem) && !this.isCanceledBySeller(dealItem)) {
          data.type = 'active'
          data.text = this.$t('heartysan_statusDealAccepted.localization_value.value')
        }
        if(this.isCanceledByManufacturer(dealItem) && !this.isCanceledBySeller(dealItem)) {
          data.type = 'dots'
          data.text = this.$t('heartysan_statusDealWaitingCancel.localization_value.value')
        }
        if(!this.isCanceledByManufacturer(dealItem) && this.isCanceledBySeller(dealItem)) {
          data.type = 'declined'
          data.text = this.$t('heartysan_statusDealSellerCancel.localization_value.value')
        }
        if(this.isCanceledByManufacturer(dealItem) && this.isCanceledBySeller(dealItem)) {
          data.type = 'rejected'
          data.text = this.$t('heartysan_statusDealCanceled.localization_value.value')
        }
      }

      if(!this.isUserHeartyManufacturer) {
        if(!this.isAcceptedByManufacturer(dealItem) && !this.isAcceptedBySeller(dealItem)) {
          data.type = 'in-progress'
          data.text = this.$t('heartysan_statusDealWaitingAccept.localization_value.value')
        }
        if(this.isAcceptedByManufacturer(dealItem) && !this.isAcceptedBySeller(dealItem)) {
          data.type = 'in-transit'
          data.text = this.$t('heartysan_statusDealManufacturerAccepted.localization_value.value')
        }
        if(this.isAcceptedByManufacturer(dealItem) && this.isAcceptedBySeller(dealItem) && !this.isCanceledByManufacturer(dealItem) && !this.isCanceledBySeller(dealItem)) {
          data.type = 'active'
          data.text = this.$t('heartysan_statusDealAccepted.localization_value.value')
        }
        if(this.isCanceledByManufacturer(dealItem) && !this.isCanceledBySeller(dealItem)) {
          data.type = 'declined'
          data.text = this.$t('heartysan_statusDealManufacturerCancel.localization_value.value')
        }
        if(!this.isCanceledByManufacturer(dealItem) && this.isCanceledBySeller(dealItem)) {
          data.type = 'dots'
          data.text = this.$t('heartysan_statusDealWaitingCancel.localization_value.value')
        }
        if(this.isCanceledByManufacturer(dealItem) && this.isCanceledBySeller(dealItem)) {
          data.type = 'rejected'
          data.text = this.$t('heartysan_statusDealCanceled.localization_value.value')
        }
      }

      return data
    },

    isAcceptedByManufacturer(deal) {
      return deal.accepted_by_manufacturer
    },

    isAcceptedBySeller(deal) {
      return deal.accepted_by_seller
    },

    isCanceledByManufacturer(deal) {
      return deal.canceled_by_manufacturer
    },

    isCanceledBySeller(deal) {
      return deal.canceled_by_seller
    },
  }

}
</script>

<style scoped lang="scss">
@import "../../../../../../../scss/colors";
@import "../../../../../../../scss/mixins/mixins";

.chat{

  &__items{
    max-height: calc(100vh - 398px);
    overflow: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 3px;
      height: 2px;
      border: 1px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
      margin-right: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: $borderBrown;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: $borderBrown;
      opacity: .5;
    }

  }

  &__item{
    margin-bottom: 0;
    padding-top: 20px;
    padding-bottom: 20px;
    transition: 0.3s;
    border-radius: 10px;
    cursor: pointer;
    position: relative;

    & + .chat__item {
      position: relative;

      .chat__user :before{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: calc(100% - 30px);
        height: 1px;
        background: $brown;
        margin-left: 15px;
        transition: 0.1s;
      }
    }

    &:hover{
      & + .chat__item {
        .chat__user :before {
          opacity: 0;
        }
      }
      .chat__user :before {
        opacity: 0;
      }

      background: rgba(143, 122, 97, 0.2);
    }

    &--active{
      background: rgba(143, 122, 97, 0.2);
      & + .chat__item {
        .chat__user :before {
          opacity: 0;
        }
      }
    }
  }

  &__user{

  }

  &__user-new{
    display: inline-block;
    background: #cd9d69;
    padding: 5px;
    font-size: 11px;
    border-radius: 5px;
    line-height: 9px;
    color: white;
    position: absolute;
    right: 0;
    top: 0;
  }

  &__user-inner{
    display: flex;
  }

  &__user-photo{
    width: 50px;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    margin-right: 10px;
  }

  &__user-block{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding-top: 3px;
    padding-bottom: 2px;
  }

  &__user-name{
    color: $black;
    font-weight: 500;
    font-size: 16px;
    display: flex;
  }

  &__user-phone{
    color: $brown;
    font-weight: 500;
    font-size: 13px;
  }

  &__user-date{
    font-size: 13px;
    margin-left: auto;
    padding-top: 5px;
    white-space: nowrap;
  }

  &__user-status{
    position: absolute;
    bottom: 5px;
    right: 15px;
  }


  &__block{
    min-height: calc(100vh - 355px);
    max-height: calc(100vh - 355px);
    overflow: auto;
    overflow-x: hidden;
    //background: $mainBg;
    background: rgba(143, 122, 97, 0.2);
    //border: 1px solid $borderBrown;
    border-radius: 10px;
    padding: 15px;

    &::-webkit-scrollbar {
      width: 3px;
      height: 2px;
      border: 1px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
      margin-right: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: $borderBrown;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: $borderBrown;
      opacity: .5;
    }
  }
}
</style>
import {checkValid} from "../../../globalModels/functions/ModelsValidate";
// let  = require('lodash');

export function Chat() {

  this.checkValid = checkValid

  this.data = {

    phone: '',
    name: '',

}

  this.validation = {
    phone: false,
    name: false,
  }

  this.validationTranslate = {
    phone: '',
    name: '',
  }

  this.validationTxt = {
    phone: false,
    name: false,
  }

}


/*****************   SETTERS   ******************/

/***
 * Pseudo Required Fields start
 */

Chat.prototype.setPhone = function (val) {
  this.data.phone = val
}

Chat.prototype.setName = function (val) {
  this.data.name = val
}


/*** **/




/*****************   GETTERS   ******************/

/***
 * Pseudo Required Fields start
 */

Chat.prototype.getPhone = function () {
  return this.data.phone
}

Chat.prototype.getName = function () {
  return this.data.name
}

/*** **/

/*******************   FUNCTIONS   *************/



/******************  VALIDATIONS  *****************/


Chat.prototype.firstValidation = function () {

  let validationItems = {
    phone: this.getPhone(),
    name: this.getName(),
  }

  let validationOptions = {
    phone: {type: ['empty']},
    name: {type: ['empty']},
  }

  return (this.checkValid(validationItems, validationOptions))

}



/******************  PREPARE DATA  *****************/


Chat.prototype.prepareSave = function() {

  return {
    "phone_number": this.getPhone(),
    "contact_name": this.getName(),
  }

}
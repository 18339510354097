<template>
  <div class="fragment">
    <div class="section-label" v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['disposal_Cost',])"></div>
      {{ $t('disposal_Cost.localization_value.value') }}
    </div>
    <div class="order-create__row custom-row">
      <div class="order-create__col custom-col custom-col--33 custom-col--md-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}"
      >
        <div class="admin-edit" @click="editTranslate(['disposal_Cost',])"></div>
        <InputSum
            :label="$t('disposal_Cost.localization_value.value')"
            v-bind:class="{'ui-no-valid': DO.validation.price}"
            :error="DO.validation.price"
            :errorTxt="$t(`${DO.validationTranslate.price}.localization_value.value`)"
            v-model="DO.data.price"
        />
      </div>
    </div>
  </div>
</template>

<script>
import InputSum from "@/components/UI/inputs/InputSum/InputSum";
export default {
  name: "Comment",
  components: {InputSum },
  props: {
    DO: Object,
  },
}
</script>

<style scoped>

</style>

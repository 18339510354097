<template>
  <AccordBlock>
    <template slot="header">
      <div
              v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['userModule_Shippers',])"></div>
        {{$t('userModule_Shippers.localization_value.value')}}
      </div>
    </template>

    <template slot="body">

      <div class="custom-row">

        <div class="custom-col custom-col--33 custom-col--md-100 flex-column">
          <div class="section-label mb-low">
            <img src="/img/users-group/users-dhl-label.png" alt="img" style="margin-bottom: -5px;">
          </div>

          <div class="mb-15"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['userModule_ChangeThePriceExpress',])"></div>
            <SwitchCheckbox
                :label="$t('userModule_ChangeThePriceExpress.localization_value.value')"
                v-model="userDelivery.DHL.DHLOrderPrice"
                :horizontal="true"
            />
          </div>
<!--          <div class="mb-15"-->
<!--               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--            <div class="admin-edit" @click="editTranslate(['userModule_AllowDHL',])"></div>-->
<!--            <SwitchCheckbox-->
<!--                    :label="$t('userModule_AllowDHL.localization_value.value')"-->
<!--                v-model="userDelivery.DHL.DHLTrans"-->
<!--                :horizontal="true"-->
<!--            />-->
<!--          </div>-->
          <div class="mb-15"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['userModule_DHLNoTransactions',])"></div>
            <SwitchCheckbox
                    :label="$t('userModule_DHLNoTransactions.localization_value.value')"
                v-model="userDelivery.DHL.DHLNoTrans"
                :horizontal="true"
            />
          </div>
        </div>
<!--        <div class="custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;md-100 flex-column">-->
<!--          <div class="section-label mb-low">-->
<!--            <img src="/img/users-group/users-tnt-label.png" alt="img">-->
<!--          </div>-->

<!--          <div class="mb-15"-->
<!--               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--            <div class="admin-edit" @click="editTranslate(['userModule_AllowTNT',])"></div>-->
<!--            <SwitchCheckbox-->
<!--                    :label="$t('userModule_AllowTNT.localization_value.value')"-->
<!--                v-model="userDelivery.TNT.TNT"-->
<!--                :horizontal="true"-->
<!--            />-->
<!--          </div>-->
<!--          <div class="mb-15"-->
<!--               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--            <div class="admin-edit" @click="editTranslate(['userModule_TNTAallCountries',])"></div>-->
<!--            <SwitchCheckbox-->
<!--                    :label="$t('userModule_TNTAallCountries.localization_value.value')"-->
<!--                v-model="userDelivery.TNT.TNTAllCountries"-->
<!--                :horizontal="true"-->
<!--            />-->
<!--          </div>-->
<!--          <div class="mb-15"-->
<!--               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--            <div class="admin-edit" @click="editTranslate(['userModule_TNTNoTransactions',])"></div>-->
<!--            <SwitchCheckbox-->
<!--                    :label="$t('userModule_TNTNoTransactions.localization_value.value')"-->
<!--                v-model="userDelivery.TNT.TNTNoTrans"-->
<!--                :horizontal="true"-->
<!--            />-->
<!--          </div>-->
<!--        </div>-->
        <div class="custom-col custom-col--33 custom-col--md-100 flex-column">
          <div class="section-label mb-low">
            All Shippers
          </div>

          <div class="mb-15"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['userModule_AllowShippers',])"></div>
            <SwitchCheckbox
                :label="$t('userModule_AllowShippers.localization_value.value')"
                v-model="userDelivery.expressToNegativeBalance"
                :horizontal="true"
            />
          </div>
        </div>
        <div class="custom-col custom-col--33 custom-col--md-100 mb-0"></div>
        <div class="custom-col custom-col--33 custom-col--md-100 flex-column">
          <div class="section-label mb-low">
            <img src="/img/users-group/users-fedex-label.png" alt="img">
          </div>

<!--          <div class="mb-15">-->
<!--            <SwitchCheckbox-->
<!--                :label="'FedEx'"-->
<!--                v-model="userDelivery.FEDEX.FEDEXTrans"-->
<!--                :horizontal="true"-->
<!--            />-->
<!--          </div>-->
          <div class="mb-15"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['userModule_FedExNoTransactions',])"></div>
            <SwitchCheckbox
                    :label="$t('userModule_FedExNoTransactions.localization_value.value')"
                v-model="userDelivery.FEDEX.FEDEXNoTrans"
                :horizontal="true"
            />
          </div>
          <div class="mb-15"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['userModule_FEDEXPolandWarehouse',])"></div>
            <SwitchCheckbox
                    :label="$t('userModule_FEDEXPolandWarehouse.localization_value.value')"
                v-model="userDelivery.FEDEX.FEDEXPolandWarehouse"
                :horizontal="true"
            />
          </div>
        </div>
        <div class="custom-col custom-col--33 custom-col--md-100 flex-column">
          <div class="section-label mb-low">
            <img src="/img/company-icons-group/logo-poshta-global.svg" alt="img">
          </div>

          <div class="mb-15">
            <SwitchCheckbox
                :label="$t('express_poshtaSub.localization_value.value')"
                v-model="userDelivery.NPG.NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE"
                :horizontal="true"
            />
          </div>
        </div>

        <!--<div class="custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;md-100 flex-column">-->
          <!--<div class="section-label mb-low">-->
            <!--<img src="/img/users-group/users-landmark-label.png" alt="img" style="margin-bottom: -5px;">-->
          <!--</div>-->


          <!--<div class="mb-15">-->
            <!--<SwitchCheckbox-->
                <!--:label="'Landmark'"-->
                <!--v-model="userDelivery.LANDMARK.LANDMARK"-->
                <!--:horizontal="true"-->
            <!--/>-->
          <!--</div>-->
          <!--<div class="mb-15">-->
            <!--<SelectSum-->
                <!--:options="options"-->
                <!--:label="'Default  Shipping Method, price per item'"-->
                <!--v-model="userDelivery.LANDMARK.LANDMARKShippingMethod"-->
            <!--/>-->
          <!--</div>-->
        <!--</div>-->
        <!--<div class="custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;md-100 mb-0"></div>-->
      </div>
    </template>
  </AccordBlock>
</template>

<script>
  import AccordBlock from "../../../../../coreComponents/AccordBlock/AccordBlock";
  import SwitchCheckbox from "../../../../../UI/checkboxes/SwitchCheckbox/SwitchCheckbox";
  // import SelectSum from "../../../../../../UI/selectiones/SelectSum/SelectSum";

  export default {
    name: "Shippers",
    components: {
      AccordBlock,
      SwitchCheckbox,
      // SelectSum,
    },

    props: {
      userDelivery: Object,
    },

    data() {
      return {
        atm: false,
        sum01: 0,
        sum02: 0,
        sum03: 0,
        sum04: 0,
        radio: '',
        radio1: '',
        radio2: '',
        radio3: '',
        radio4: '',
        radio5: '',
        radio6: '',
        radio7: '',
        radio8: '',
        radio9: '',
        radio10: '',

        tooltipAddProductActive:true,

        options: [{}],
      }
    }
  }
</script>

<style scoped>

</style>

import {ProformProduct} from "../../../globalModels/ProformProduct";
import {PersonalInformation} from "../../../globalModels/PersonalInformation";
import {Packaging} from "../../../globalModels/Packaging";
import {Delivery} from "../../../globalModels/Delivery";
import {checkValid} from "../../../globalModels/functions/ModelsValidate";
import {Files} from "../../../globalModels/Files";
const _ = require('lodash');

export function Express() {

  this.checkValid = checkValid

  this.express = {
    expressObj: null,
    historyOfChanged: '',
    type: '',
    expressId: '',
    expressStatus: '',
    expressStatusChanged: '',
    expressInvoiceStatus: '',
    issetPaymentTransaction: false,

    orderCountryType: '',
    orderCountryTypesArray: [],

    isCopy: false,

    variantLabelPrice: null,

    sendType: 'warehouse',
    sendTypes: {
      warehouse: 'warehouse',
      sender: 'sender'
    },

    canUserSendFedexFromAddress: true,
    canUserSendDHLFromAddress: true,

    serviceType: 'department',
    serviceTypes: {
      doors: 'doors',
      department: 'department'
    },
    novaPoshtaAddress: null,

    /**
     * Transaction type (order external | customer payment)
     */
    typeTransaction: '',
    typeTransactionId: -1,
    paymentTransactionId: -1,
    orderExternalId: -1,

    /**
     * Order Price from transaction
     */
    orderPrice: '',
    gotOrderPriceFlag: false,

    /**
     * Only for dhl & country from eu
     */
    freightCost: '',
    visibleFreightCost: false,

    regions: [],

    warehouse: '',
    trackingNumber: '',
    expressTrack: '',

    systemTrackingNumber: '',

    senderContactName: '',
    senderPhone: '',

    fedexPackingType: '',

    products: [new ProformProduct()],
    proformProductsDeletedIds: [],

    visibleHSCode: false,

    vatNumber: '',
    visibleVatNumber: true,
    isVatNumberByCountry: false,
    vatNumberFile: new Files({
      maxCountFiles: 1,
      maxSizeFiles: 3
    }),

    dropBallTestCert: false,
    toxicSubstanceControlActCert: false,

    personal: new PersonalInformation(),

    delivery: new Delivery(),

    deliveryDate: '',
    files: [],
    filesMaxSizeError: false,
    downloadFiles: {},
    downloadPhotos: [],
    maxCountFiles: 1,

    timeCourierTo: '',
    timeCourierFrom: '',
    lunchBreak: false,
    timeNotAvailableTo: '',
    timeNotAvailableFrom: '',

    packaging: new Packaging(),

    currencies: '',

    userId: '',
    user: {},

    shippingCost: '',
    fee: '',

    transactionNum: '',

    payedInvoiceNetAmount: '',
    payedInvoiceFeeAmount: '',
    changedAdminFields: false,

    proformDocument: null,

    orderNotification: null,

    /**
     * Payment System (paypal | authorize)
     */
    transactionPaymentSystem: '',

    easyOrderType: false,

    externalImportOrderCountry: null,


    needPayFromFastReport: false,

    promoCodes: [new PromoCode()],

    recipientTax: '',
    recipientNeedTax: false,
  }

  this.validation = {
    warehouse: false,
    trackingNumber: false,
    senderContactName: false,
    senderPhone: false,
    vatNumber: false,
    deliveryDate: false,
    timeCourierTo: false,
    timeCourierFrom: false,
    timeNotAvailableTo: false,
    timeNotAvailableFrom: false,
    user: false,
    orderPrice: false,
    freightCost: false,
    files: false,
    products: false,
    novaPoshtaAddress: false,
    recipientTax: false,
  }

  this.validationTranslate = {
    warehouse: '',
    trackingNumber: '',
    senderContactName: '',
    senderPhone: '',
    vatNumber: '',
    deliveryDate: '',
    timeCourierTo: '',
    timeCourierFrom: '',
    timeNotAvailableTo: '',
    timeNotAvailableFrom: '',
    user: '',
    orderPrice: '',
    freightCost: '',
    files: '',
    products: '',
    novaPoshtaAddress: '',
    recipientTax: '',
  }

  this.validationTxt = {
    warehouse: false,
    trackingNumber: false,
    senderContactName: false,
    senderPhone: false,
    vatNumber: false,
    deliveryDate: false,
    timeCourierTo: false,
    timeCourierFrom: false,
    timeNotAvailableTo: false,
    timeNotAvailableFrom: false,
    user: false,
    orderPrice: false,
    freightCost: false,
    files: false,
    products: false,
    novaPoshtaAddress: false,
    recipientTax: false,
  }

}


/*****************   SETTERS   ******************/

import "./ExpressSetters"


/*****************   GETTERS   ******************/

import "./ExpressGetters"


/*******************   FUNCTIONS   *************/

Express.prototype.addProduct = function (expressItemInit = false) {
  this.express.products.push(new ProformProduct)

  if(expressItemInit){
    this.copyProformTypesToNewProduct(
      this.express.products[this.express.products.length - 1]
    )
  }

  return this.express.products.length - 1
}

Express.prototype.removeProduct = function (index) {
  this.express.proformProductsDeletedIds.push(this.express.products[index].product.proformEntityId)
  this.express.products.splice(index, 1)
}

Express.prototype.copyProformTypesToNewProduct = function (newProduct) {
  Object.keys(this.express.products[0].product.proformData.proformTypes).map(item => {
    newProduct.product.proformData.proformTypes[item] = {
      id: item,
      typeName: this.express.products[0].product.proformData.proformTypes[item].typeName,
      typeValue: '',
      typeOptions: [{}],
    }
  })
}

Express.prototype.removeVatNumberFile = function (id) {
  let i = -1
  _.findIndex(this.express.vatNumberFile.getDownloadFiles(), function (item, index) {
    if (item[id] !== undefined) i = index
  });

  this.express.vatNumberFile.removeDownloadFile(i)
}

Express.prototype.setExpressVatNumberFile = function(val) {
  this.express.vatNumberFile.pushDownloadFiles(val)
}

Express.prototype.addPromoCode = function () {
  this.express.promoCodes.push(new PromoCode())
  return this.express.promoCodes.length - 1
}

Express.prototype.removePromoCode = function (index) {
  this.express.promoCodes.splice(index, 1)
  return this.express.promoCodes.length - 1
}

/******************  VALIDATIONS  *****************/

import "./ExpressValidations"

/*********************************  PREPARE DATA  *********************************/

import "./ExpressPrepareData"
import {PromoCode} from "@/components/globalModels/PromoCode";

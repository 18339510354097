var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fragment"},[(_vm.$store.getters.getShippingCompany.length > 0 && _vm.$store.getters.getShippingCompanyLoading !== true)?[_c('div',{staticClass:"custom-row accounts-table"},_vm._l((_vm.$store.getters.getShippingCompany),function(item,index){return _c('div',{key:index,staticClass:"custom-col custom-col--20 custom-col--xs-100 custom-col--md-50 custom-col--xl-33"},[_c('div',{staticClass:"case-cart"},[_c('div',{staticClass:"case-cart__inner"},[_c('div',{staticClass:"case-cart__columns"},[_c('div',{staticClass:"case-cart__content-block"},[_c('div',{staticClass:"case-cart__head"},[_c('div',{staticClass:"case-cart__label-status"},[_c('div',{staticClass:"case-cart__label-status-i",class:{
                         'active': item.enable,
                         'admin-edit-item': _vm.isAdmin || _vm.checkImpersonate()
                         }},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                      'common_Active',
                      'common_Unactive',
                    ])}}}),(item.enable)?[_vm._v(" "+_vm._s(_vm.$t('common_Active.localization_value.value')))]:[_vm._v(_vm._s(_vm.$t('common_Unactive.localization_value.value')))]],2)]),_c('div',{staticClass:"case-cart__title pt-3 d-flex"},[_c('div',{staticClass:"case-cart__company-img"},[(item.svg_logo)?_c('div',{staticClass:"company-svg-ico",domProps:{"innerHTML":_vm._s(item.svg_logo)}}):_vm._e()]),_c('span',{staticClass:"color-black pr-2"},[_vm._v(" "+_vm._s(item.name)+" ")])])])])]),_c('div',{staticClass:"case-cart__bottom"},[_c('div',{staticClass:"case-cart__btn ml-auto",class:{'admin-edit-item': _vm.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate(['common_delete'])}}}),_c('CaseCartButton',{staticClass:"secondary-brown",attrs:{"value":_vm.$t('common_delete.localization_value.value'),"ico":true,"typeIco":'delete-brown'},nativeOn:{"click":function($event){return _vm.removeItem(item.id, index)}}})],1),_c('div',{staticClass:"case-cart__btn accounts-table__case-cart-button ml-auto",class:{'admin-edit-item': _vm.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate(['common_edit'])}}}),_c('CaseCartButton',{attrs:{"value":_vm.$t('common_edit.localization_value.value'),"ico":true,"typeIco":'edit'},nativeOn:{"click":function($event){return _vm.editShippingCompanyPopup(item.id)}}})],1)])])])])}),0),_c('div',{staticClass:"table-bottom-btn"},[_c('div',{staticClass:"table-bottom-btn__inner"},[(_vm.$store.getters.getShippingCompanyCommonList.next_page_url !== null && _vm.$store.getters.getShippingCompany.length > 0)?_c('ShowMore',{class:{'disabled-btn' : _vm.$store.getters.getNextShippingCompanyPage},attrs:{"count":_vm.$store.getters.getShippingCompanyCommonList.total - _vm.$store.getters.getShippingCompanyForPage < _vm.$store.getters.getShippingCompanyForPage ?
                _vm.$store.getters.getShippingCompanyCommonList.total - _vm.$store.getters.getShippingCompanyForPage:
                _vm.$store.getters.getShippingCompanyForPage},nativeOn:{"click":function($event){return _vm.$emit('showMore')}}}):_vm._e()],1)])]:_vm._e(),(_vm.$store.getters.getShippingCompanyLoading === false && _vm.$store.getters.getShippingCompany.length === 0)?[_c('NoResult',{attrs:{"countFilterParams":_vm.countFilterParams,"title":_vm.$t('common_noDataYet.localization_value.value'),"titleWithFilter":_vm.$t('common_noResult.localization_value.value'),"text":_vm.$t('common_noDataYetTxt.localization_value.value'),"textWithFilter":_vm.$t('common_noResultSeems.localization_value.value')}})]:_vm._e(),(_vm.isModalShippingCompanyPopup)?_c('ShippingCompanyPopup',{attrs:{"openType":'edit',"deliverySystemId":_vm.deliverySystemId},on:{"reload":function($event){return _vm.$emit('reload')},"close":function($event){return _vm.changeShippingCompanyPopup(false)}}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
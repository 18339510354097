import {checkValid} from "../../../globalModels/functions/ModelsValidate";
import {Dimensions} from "../../../globalModels/Dimensions";
import {Files} from "../../../globalModels/Files";
import {CONSOLIDATION_UNION_LABEL_FILE_TYPES} from "../../../../staticData/staticVariables";


export function ConsolidationUnionLabel() {

    this.checkValid = checkValid

    this.data = {
        id: '',
        consolidationUnionId: '',
        contactPerson: '',
        warehouse: '',
        createdAt: null,
        trackingNumber: '',
        Dimensions: new Dimensions(),

        status: '',
        plannedSendDate: null,
        totalPrice: null,
        addInsurance: false,
        insuranceAmount: null,

        invoice: new Files({
            maxCountFiles: 1,
            maxSizeFiles: 3
        }),

        toxicSubstanceCert: new Files({
            maxCountFiles: 1,
            maxSizeFiles: 3
        }),

        dropBallCert: new Files({
            maxCountFiles: 1,
            maxSizeFiles: 3
        }),

        certificateOfOrigin: new Files({
            maxCountFiles: 1,
            maxSizeFiles: 3
        }),

    }

    this.validation = {
        id: false,
        warehouse: false,
        trackingNumber: false,
        status: false,
        plannedSendDate: false,
        totalPrice: false,
        insuranceAmount: false,
    }

    this.validationTranslate = {
        id: '',
        warehouse: '',
        trackingNumber: '',
        status: '',
        plannedSendDate: '',
        totalPrice: '',
        insuranceAmount: '',
    }

    this.validationTxt = {
        id: false,
        warehouse: false,
        trackingNumber: false,
        status: false,
        plannedSendDate: false,
        totalPrice: false,
        insuranceAmount: false,
    }

    /**
     * Getters
     */
    this.getId = () => {
        return this.data.id
    }
    this.getConsolidationUnionId = () => {
        return this.data.consolidationUnionId
    }
    this.getContactPerson = () => {
        return this.data.contactPerson
    }
    this.getWarehouse = () => {
        return this.data.warehouse
    }
    this.getCreatedAt = () => {
        return this.data.createdAt
    }
    this.getPlannedSendDate = () => {
        return this.data.plannedSendDate
    }
    this.getTrackingNumber = () => {
        return this.data.trackingNumber
    }
    this.getStatus = () => {
        return this.data.status
    }
    this.getTotalPrice = () => {
        return this.data.totalPrice
    }
    this.getAddInsurance = () => {
        return this.data.addInsurance
    }
    this.getInsuranceAmount = () => {
        return this.data.insuranceAmount
    }

    /**
     * Setters
     */
    this.setId = (val) => {
        this.data.id = val
    }
    this.setConsolidationUnionId = (val) => {
        this.data.consolidationUnionId = val
    }
    this.setContactPerson = (val) => {
        this.data.contactPerson = val
    }
    this.setWarehouse = (val) => {
        this.data.warehouse = val
    }
    this.setCreatedAt = (val) => {
        this.data.createdAt = val
    }
    this.setPlannedSendDate = (val) => {
        this.data.plannedSendDate = val
    }
    this.setTrackingNumber = (val) => {
        this.data.trackingNumber = val
    }
    this.setStatus = (val) => {
        this.data.status = val
    }
    this.setTotalPrice = (val) => {
        this.data.totalPrice = val
    }
    this.setIAddInsurance = (val) => {
        this.data.addInsurance = val
    }
    this.setInsuranceAmount = (val) => {
        this.data.insuranceAmount = val
    }
}

/**
 * Global Setters
 */
ConsolidationUnionLabel.prototype.setConsolidationUnionLabelItem = function (item, warehouses = false, that) {
    this.setId(item?.id)
    this.setConsolidationUnionId(item.consolidation_union_id)
    this.setContactPerson(item.sender_contact_name)
    if (item.insurance_amount !== null) {
        this.setIAddInsurance(true)
    }
    this.setInsuranceAmount(item.insurance_amount)
    this.setTotalPrice(item.declare_sum)

    this.data.Dimensions.setDimensionsLength(item?.proform_packaging?.length)
    this.data.Dimensions.setDimensionsWidth(item?.proform_packaging?.width)
    this.data.Dimensions.setDimensionsHeight(item?.proform_packaging?.height)
    this.data.Dimensions.setDimensionsWeight(item?.proform_packaging?.weight)

    if (warehouses) {
        this.setWarehouse(warehouses.find(whItem => {
            return whItem.id === item.warehouse.id
        }))
    }

    this.setPlannedSendDate(item?.delivery_date ? that.$moment(item?.delivery_date).format('YYYY-MM-DD') : null)

}

/**
 * Functions
 */


/**
 * Prepare Data
 */

ConsolidationUnionLabel.prototype.prepareFilesData = function (id) {
    let readerArray = []

    this.data.invoice.getFiles().forEach(item => {
        let reader = new FormData();
        reader.append("file", item.file);
        reader.append("consolidation_union_label_id", id)
        reader.append("file-type", CONSOLIDATION_UNION_LABEL_FILE_TYPES.TYPE_FILE_INVOICE)
        readerArray.push(reader)
    })
    this.data.toxicSubstanceCert.getFiles().forEach(item => {
        let reader = new FormData();
        reader.append("file", item.file);
        reader.append("consolidation_union_label_id", id)
        reader.append("file-type", CONSOLIDATION_UNION_LABEL_FILE_TYPES.TYPE_FILE_TSCA)
        readerArray.push(reader)
    })
    this.data.dropBallCert.getFiles().forEach(item => {
        let reader = new FormData();
        reader.append("file", item.file);
        reader.append("consolidation_union_label_id", id)
        reader.append("file-type", CONSOLIDATION_UNION_LABEL_FILE_TYPES.TYPE_FILE_DBTS)
        readerArray.push(reader)
    })
    this.data.certificateOfOrigin.getFiles().forEach(item => {
        let reader = new FormData();
        reader.append("file", item.file);
        reader.append("consolidation_union_label_id", id)
        reader.append("file-type", CONSOLIDATION_UNION_LABEL_FILE_TYPES.TYPE_FILE_COOF)
        readerArray.push(reader)
    })

    console.log(11111111111111);
    console.log(readerArray);

    return readerArray
}

ConsolidationUnionLabel.prototype.prepareSave = function () {

    let formattedDimensions = {
        'weight': this.data.Dimensions.getDimensionsWeight(),
        'length': this.data.Dimensions.getDimensionsLength(),
        'width': this.data.Dimensions.getDimensionsWidth(),
        'height': this.data.Dimensions.getDimensionsHeight(),
    }

    let preparedData = {
        'consolidation_union_id': this.getConsolidationUnionId(),
        'warehouse_id': this.getWarehouse()?.id,
        'sender_contact_name': this.getContactPerson(),
        'dimensions': formattedDimensions,
        'declare_sum': this.getTotalPrice(),
    }

    if (this.getPlannedSendDate() !== null) {
        preparedData['delivery_date'] = this.getPlannedSendDate()
    }

    if (this.getAddInsurance()) {
        preparedData['insurance_amount'] = this.getInsuranceAmount() || 0
    }

    console.log(preparedData);

    return preparedData
}

/**
 * Validations
 */

ConsolidationUnionLabel.prototype.firstValidationAdmin = function () {

    let validationItems = {
        warehouse: this.getWarehouse(),
    }

    let validationOptions = {
        warehouse: {type: ['empty']},
    }

    let dimensionsValidation = this.data.Dimensions.dimensionsValidate()
    return (this.checkValid(validationItems, validationOptions) && dimensionsValidation)
}

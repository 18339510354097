<template>
  <div class="fragment">
    <div class="order-create__section-label section-label"
         v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['consolidationUnion_invoice'])"></div>
      {{$t('consolidationUnion_invoice.localization_value.value')}}
    </div>
    <div class="custom-row">
      <div class="custom-col custom-col--16 custom-col--md-50 custom-col--sm-100"
           v-for="(item, index) in CUL.data.invoice.data.downloadFiles"
           :key="index">
        <div class="site-document d-flex justify-content-center">
              <span class="site-document__remove" @click="removeDocument(item,'invoice')">
                <CloseIcon/>
              </span>
          <div class="site-document__img">
            <img v-if="item.mime_type === 'application/pdf'"
                 @click="openPdf(item['base64'])"
                 style="width: 30px; height: 40px;"
                 src="/img/common/pdf_file_icon.svg"
                 alt="ico"
            >
          </div>
        </div>
      </div>

      <div class="custom-col" v-if="CUL.data.invoice.data.maxCountFiles > CUL.data.invoice.data.downloadFiles.length">
        <DropZone class="drop-zone-bg"
                  :parentFiles="CUL.data.invoice.data.files"
                  :multiple="true"
                  :maxCount="CUL.data.invoice.data.maxCountFiles - CUL.data.invoice.data.downloadFiles.length"
                  :maxSize="CUL.data.invoice.data.maxSizeFiles"
                  :accept="'application/pdf'"
                  @changeImg="(files, maxSizeError) => {changeFiles(files, maxSizeError, 'invoice')}"
        />
      </div>
    </div>

    <div class="order-create__section-label section-label"
         v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['consolidationUnion_ToxicSubstanceControlActCertification'])"></div>
      {{$t('consolidationUnion_ToxicSubstanceControlActCertification.localization_value.value')}}
    </div>
    <div class="custom-row">
      <div class="custom-col custom-col--16 custom-col--md-50 custom-col--sm-100"
           v-for="(item, index) in CUL.data.toxicSubstanceCert.data.downloadFiles"
           :key="index">
        <div class="site-document d-flex justify-content-center">
              <span class="site-document__remove" @click="removeDocument(item, 'toxicSubstanceCert')">
                <CloseIcon/>
              </span>
          <div class="site-document__img">
            <img v-if="item.mime_type === 'application/pdf'"
                 @click="openPdf(item['base64'])"
                 style="width: 30px; height: 40px;"
                 src="/img/common/pdf_file_icon.svg"
                 alt="ico"
            >
          </div>
        </div>
      </div>

      <div class="custom-col" v-if="CUL.data.toxicSubstanceCert.data.maxCountFiles > CUL.data.toxicSubstanceCert.data.downloadFiles.length">
        <DropZone class="drop-zone-bg"
                  :parentFiles="CUL.data.toxicSubstanceCert.data.files"
                  :multiple="true"
                  :maxCount="CUL.data.toxicSubstanceCert.data.maxCountFiles - CUL.data.toxicSubstanceCert.data.downloadFiles.length"
                  :maxSize="CUL.data.toxicSubstanceCert.data.maxSizeFiles"
                  :accept="'application/pdf'"
                  @changeImg="(files, maxSizeError) => {changeFiles(files, maxSizeError, 'toxicSubstanceCert')}"
        />
      </div>
    </div>

    <div class="order-create__section-label section-label"
         v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['consolidationUnion_DropBallTestCertification'])"></div>
      {{$t('consolidationUnion_DropBallTestCertification.localization_value.value')}}
    </div>
    <div class="custom-row">
      <div class="custom-col custom-col--16 custom-col--md-50 custom-col--sm-100"
           v-for="(item, index) in CUL.data.dropBallCert.data.downloadFiles"
           :key="index">
        <div class="site-document d-flex justify-content-center">
              <span class="site-document__remove" @click="removeDocument(item, 'dropBallCert')">
                <CloseIcon/>
              </span>
          <div class="site-document__img">
            <img v-if="item.mime_type === 'application/pdf'"
                 @click="openPdf(item['base64'])"
                 style="width: 30px; height: 40px;"
                 src="/img/common/pdf_file_icon.svg"
                 alt="ico"
            >
          </div>
        </div>
      </div>

      <div class="custom-col" v-if="CUL.data.dropBallCert.data.maxCountFiles > CUL.data.dropBallCert.data.downloadFiles.length">
        <DropZone class="drop-zone-bg"
                  :parentFiles="CUL.data.dropBallCert.data.files"
                  :multiple="true"
                  :maxCount="CUL.data.dropBallCert.data.maxCountFiles - CUL.data.dropBallCert.data.downloadFiles.length"
                  :maxSize="CUL.data.dropBallCert.data.maxSizeFiles"
                  :accept="'application/pdf'"
                  @changeImg="(files, maxSizeError) => {changeFiles(files, maxSizeError, 'dropBallCert')}"
        />
      </div>
    </div>

    <div class="order-create__section-label section-label"
         v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['consolidationUnion_certOfOrigin'])"></div>
      {{$t('consolidationUnion_certOfOrigin.localization_value.value')}}
    </div>
    <div class="custom-row">
      <div class="custom-col custom-col--16 custom-col--md-50 custom-col--sm-100"
           v-for="(item, index) in CUL.data.certificateOfOrigin.data.downloadFiles"
           :key="index">
        <div class="site-document d-flex justify-content-center">
              <span class="site-document__remove" @click="removeDocument(item, 'certificateOfOrigin')">
                <CloseIcon/>
              </span>
          <div class="site-document__img">
            <img v-if="item.mime_type === 'application/pdf'"
                 @click="openPdf(item['base64'])"
                 style="width: 30px; height: 40px;"
                 src="/img/common/pdf_file_icon.svg"
                 alt="ico"
            >
          </div>
        </div>
      </div>

      <div class="custom-col" v-if="CUL.data.certificateOfOrigin.data.maxCountFiles > CUL.data.certificateOfOrigin.data.downloadFiles.length">
        <DropZone class="drop-zone-bg"
                  :parentFiles="CUL.data.certificateOfOrigin.data.files"
                  :multiple="true"
                  :maxCount="CUL.data.certificateOfOrigin.data.maxCountFiles - CUL.data.certificateOfOrigin.data.downloadFiles.length"
                  :maxSize="CUL.data.certificateOfOrigin.data.maxSizeFiles"
                  :accept="'application/pdf'"
                  @changeImg="(files, maxSizeError) => {changeFiles(files, maxSizeError,'certificateOfOrigin')}"
        />
      </div>
    </div>

  </div>

</template>

<script>
import CloseIcon from '../../../../../../public/img/modal-group/close-icon.svg?inline'
import DropZone from "../../../../coreComponents/DropZone/DropZone";

export default {
  name: "LabelDocumentsBlock",
  components: {
    CloseIcon,
    DropZone
  },
  props: {
    CUL: Object,
  },

  data() {
    return {

    }
  },

  created() {
    if (this.CUL.getInsuranceAmount()) {
      this.addInsurance = true
    }
  },

  methods: {
    acceptNumber(event, type) {
      switch (type) {
        case 'weight':
          this.CUL.data.Dimensions.data.weight = event.target.value.replace(',', '.').replace(/[^0-9.]/g, '')
          break
        case 'length':
          this.CUL.data.Dimensions.data.dimensionLength = event.target.value.replace(/\D/g, '')
          break
        case 'width':
          this.CUL.data.Dimensions.data.width = event.target.value.replace(/\D/g, '')
          break
        case 'height':
          this.CUL.data.Dimensions.data.height = event.target.value.replace(/\D/g, '')
          break
        default:
          break
      }
    },

    removeDocument(item, filesLabel) {
      let targetFile = item.id

      //delete big image
      if (item.type === 'small_image') {
        targetFile = item.id + 1
      }

      this.$store.dispatch('removeUserDocument', targetFile).then(() => {
        let files = this.CUL.data[filesLabel]
        let updatedFiles = files.data.downloadFiles.filter(filteredItem => {
          return item.id !== filteredItem.id
        })
        console.log(1111333, files, updatedFiles);
        files.setDownloadFiles(updatedFiles || [])
        this.images = item.id

      }).catch(error => this.createErrorLog(error))
    },

    openPdf(base64) {
      this.globalOpenPdfBase64(base64)
    },

    changeFiles(files, maxSizeError, filesObjectName) {
      this.CUL.data[filesObjectName].setFiles(files)

      this.CUL.data[filesObjectName].setFilesMaxSizeError(maxSizeError)
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <modal
      @close="$emit('close')"
      class="custom-popup"
  >
    <template slot="header">
      {{infoPopupItem.delivery_service.name}}: {{infoPopupItem.id}} {{ infoPopupItem.created_at | moment("DD MMM, YYYY") }}
    </template>
    <template slot="body">
      <div class="custom-popup__content"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
          'apcDelcon_id',
          'apcDelcon_order',
          'common_manage',
          'common_close',
          ])"></div>
        <table v-if="infoPopupItem.orders_fbm && infoPopupItem.orders_fbm.length > 0" class="site-table">
          <thead>
          <tr>
            <th>№</th>
            <th>{{$t('apcDelcon_order.localization_value.value')}}</th>
            <th class="text-right">{{$t('common_manage.localization_value.value')}}</th>
          </tr>
          </thead>
          <tbody>
          <tr
              v-for="(item, index) in infoPopupItem.orders_fbm"
              :key="index"
          >
            <td>
              {{index + 1}}
            </td>
            <td>
              {{$t('apcDelcon_order.localization_value.value')}} №{{item.id}}: {{item.full_name}}
            </td>
            <td class="pr-2" align="right">
              <div class="table-right table-manage-list table-manage-list--small">
                <ManagerButton @reloadManagerBtn="$store.commit('setGlobalUpdateManagerButton', Math.random()) " :key="$store.getters.getGlobalUpdateManagerButton"
                               class="right"
                >
                  <template slot="item">
                    <LinkButton
                        :value="$t('common_edit.localization_value.value')"
                        :type="'edit'"
                        :target="'_blank'"
                        :link="$store.getters.GET_PATHS.ordersFBM + '/' + getOrderFBMTypeById(item.order_type_id).name + '/' + item.id"
                    />
                  </template>
                </ManagerButton>
              </div>
            </td>
          </tr>
          </tbody>
        </table>

      </div>
    </template>
    <template slot="footer">
      <div class="d-flex flex-wrap mt-2 scoped-btns">

            <span class="site-link site-link--alt mr-3 mb-2 mt-2"
                  @click="$emit('close')"
            >
              {{$t('common_close.localization_value.value')}}
            </span>

      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from '../../../../commonModals/Modal.vue'
  import ManagerButton from "../../../../UI/buttons/ManagerButton/ManagerButton";
  import LinkButton from "../../../../UI/buttons/LinkButton/LinkButton";
  import {FBMMixinsHelper} from "../../../../../mixins/FBMMixins/FBMMixins";


  export default {
    name: "InfoPopup",
    components: {
      Modal,
      ManagerButton,
      LinkButton,
    },

    mixins: [
      FBMMixinsHelper
    ],

    props: {
      infoPopupItem: Object,
    },

    data(){
      return{

      }
    },
  }

</script>

<style lang="scss" scoped>
  .scoped-width{
    min-width: 118px;
    width: fit-content;
  }


  .table-manage-list {
    width: 175px;

    .manager-btn {
      max-width: 175px;
    }
  }

  .site-table tbody td{
    padding: 12px 15px;
  }

  .site-table {
    @media (max-width: 1120px) {
      min-width: auto;
      margin: 0;
    }
  }

</style>

<template>
  <div class="order-create__section">

    <UserLabelTypeBlock
        :FBA="FBA"
    />

    <div class="order-create__section-label section-label"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['fbm_ProductWarehouse',])"></div>
      {{ $t('fbm_ProductWarehouse.localization_value.value') }}
    </div>

    <div class="custom-row">
      <div class="custom-col">
        <div class="d-flex">
          <RadioDefault
              class="white-space-line width-initial mr-3"
              v-for="(item, index) in warehouses"
              :key="index"
              :label="item.name"
              :name="'warehouse'"
              :value="FBA.data.Order.data.warehouse && item.id === FBA.data.Order.data.warehouse.id"
              @input="changeWarehouse(item)"
          />
        </div>
      </div>
    </div>

    <div class="order-create__section-label section-label"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['fba_addProductToWarehouse'])"></div>
      {{ $t('fba_addProductToWarehouse.localization_value.value') }}
      <!--      Add Product from Warehouse-->
    </div>

    <ProductsBlock
        v-if="initialOptionsProducts.length > 0"
        :FBA="FBA"
        :products="FBA.data.productsForCellArray"
        :user="user"
        :byStorageId="FBA.data.Order.data.warehouse.id"
        :initialOptionsProducts="initialOptionsProducts"
        :productLoading="productLoading"
    />

    <div class="order-create__section-label section-label"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['fba_addSetProductToWarehouse'])"></div>
      {{ $t('fba_addSetProductToWarehouse.localization_value.value') }}
      <!--      Add Product from Warehouse-->
    </div>

    <SetBlock
        v-if="initialOptionsProducts.length > 0"
        :initialOptionsProducts="initialOptionsProducts"
        :productLoading="productLoading"
        :FBA="FBA"
        :sets="FBA.data.sets"
        :user="user"
    />

  </div>
</template>

<script>
import {mixinDetictingMobile} from "../../../../../../mixins/mobileFunctions";
import {FBAMixin} from "../../../../../../mixins/FBAMixins/FBAMixin";
import SetBlock from "../chunks/SetBlock/SetBlock";
import ProductsBlock from "../chunks/ProductsBlock/ProductsBlock";
import RadioDefault from "@/components/UI/radiobuttons/RadioDefault/RadioDefault";
import UserLabelTypeBlock
  from "@/components/modules/OrdersFBAModule/components/components/chunks/UserLabelTypeBlock/UserLabelTypeBlock";


export default {
  name: "StepA",
  components: {
    UserLabelTypeBlock,
    RadioDefault,
    ProductsBlock,
    SetBlock,
  },

  mixins: [
    mixinDetictingMobile,
    FBAMixin,
  ],

  props: {
    FBA: Object,
    user: Object,
  },

  watch: {
    userId() {
      if(Number(this.userId) > 0) {
        this.getWarehouseStorage()
      }
    }
  },

  computed: {
    userId() {
      return this.user.userId
    }
  },

  data() {
    return {
      productLoading: false,
      initialOptionsProducts: [],
      warehouses: [],
    }
  },

  mounted() {
    if(Number(this.userId) > 0) {
      this.getWarehouseStorage()
    }
  },

  methods: {
    getWarehouseStorage() {

      this.$store.dispatch('fetchWarehouseStorage').then((response) => {
        this.warehouses = this.getRespPaginateData(response)

        if (this.warehouses.length === 0) return

        // if not selected warehouse
        if (!this.FBA.data.Order.data.warehouse) {
          this.FBA.data.Order.setWarehouse(this._.first(this.warehouses))
        }

        // if selected warehouse not found in received warehouses
        if (!this._.find(this.warehouses, {id: this.FBA.data.Order.data.warehouse.id})) {
          this.FBA.data.Order.setWarehouse(this._.first(this.warehouses))
        }

        // if(withoutProducts) return

        this.getProductsByUser()
      })
    },


    changeWarehouse(val) {
      this.FBA.data.Order.setWarehouse(val)
      this.getProductsByUser().then(() => {
        this.FBA.data.productsForCellArray.map((item, index) => {
          let product = this._.find(this.initialOptionsProducts, {id: item.data.Product.product.productObject?.id})
          if (product) {
            this.FBA.data.productsForCellArray[index].data.Product.setProductObject(product)
          }
        })

        this.FBA.data.sets.map(set => {
          set.data.productsSet.map((item, index) => {
            let product = this._.find(this.initialOptionsProducts, {id: item.data.Product.product.productObject?.id})
            if (product) {
              set.data.productsSet[index].data.Product.setProductObject(product)
            }
          })
        })
      })
    },

    async getProductsByUser() {
      this.productLoading = true
      if (!this.user.userId) return

      const query = new this.Query();
      let url = '?';
      let myQuery = query
          .for('posts')

      if (this.isAdmin) {
        myQuery.where('ProductUserId', parseInt(this.user.userId))
      }

      myQuery.where('byStorageId', this.FBA.data.Order.data.warehouse?.id)

      myQuery.sort(`ProductName`)

      myQuery.where('ProductInDraft', '0')

      url = url + myQuery.limit(100000).page(parseInt(0)).url().split('?')[1]

      let typeFetch = 'fetchProducts'

      return this.$store.dispatch(typeFetch, url).then((response) => {
        this.initialOptionsProducts = this.getRespPaginateData(response)
        this.productLoading = false
      })
    },

  },
}
</script>

<style scoped>

</style>

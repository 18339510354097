
import WhiteInput from '../../../../UI/inputs/WhiteInput/WhiteInput'
import FullButton from '../../../../UI/buttons/FullButtons/FullButton'
import SimpleAlert from '../../../../UI/alerts/SimpleAlert/SimpleAlert'
import Fb from '../../../../../../public/img/login-group/fb.svg?inline'

import VFacebookLogin from 'vue-facebook-login-component'

import {validation} from '../../../../../services/validation'
import BottomLine from "../../../../coreComponents/BottomLine/BottomLine.vue";
import {
  ADMIN_ROUTE_STORAGE,
} from "../../../../../staticData/staticVariables";
import {PERMISSIONS} from "@/staticData/permissionsStatic";
var _ = require('lodash');


export default {
  name: "Login",

  components: {
    WhiteInput,
    Fb,
    BottomLine,
    FullButton,
    SimpleAlert,
    VFacebookLogin,
  },

  data() {
    return {
      email: '',
      password: '',
      openAlert: false,
      openErrorAlert: false,
      openBlockedAlert: false,
      errorForm: false,
      serverError: false,

      FB: {},
      model: {},
      scope: {},
      fbId: '',

      validation: {
        email: false,
        password: false
      },

      validationTranslate: {
        email: '',
        password: ''
      },

      validationTxt: {
        email: false,
        password: false
      },
    }
  },

  mounted() {
    if(location.hash.length > 0){
      this.$store.dispatch('verifyUser', location.hash.substring(1)).then((response) => {

        if(_.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE){
          this.openAlert = true;
        } else if(response.response.status === this.$store.getters.GET_ERRORS.BAD_REQUEST_CODE) {
          this.openErrorAlert = true;
        }

      });
    }



  },

  methods: {

    closeAlert() {
      this.openAlert = false;
      this.openErrorAlert = false;
      this.openBlockedAlert = false;
    },

    // loginFb() {
    //   this.$store.dispatch('getFbLink').then((response) => {
    //     console.log(response.data.facebookLink);
    //   })
    // },

    auth() {
      let validationItems = {
        email: this.email,
        password: this.password,
      };

      let validationOptions = {
        email: {
          type: [
            'email',
            'empty',
          ]
        },
        password: {
          type: [
            // 'password',
            'empty',
          ]
        },
      };

      let validate = validation(validationItems, validationOptions)
      this.validation = validate.validation
      this.validationTranslate = validate.validationError

      if(validate.isValidate){
        this.serverError = false;

        this.$store.dispatch('auth', validationItems).then((response) => {

          // if (!this._.has(response, 'data')) return this.openNotify('error', 'common_notificationUndefinedError')

          switch (this.getResponseStatus(response)) {
              /**
               * Success
               */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {

              this.$store.dispatch('fetchCurrentUserRights').then(() => {

                if(localStorage.getItem('parentUserId') !== null) localStorage.removeItem('parentUserId')

                if(Object.hasOwnProperty.call(this.$store.getters.getCurrentUserRights.additionalPermissions, PERMISSIONS.VIEW_ADMIN_ROUTE_PREFIX)){

                  // Add to localstorage variable for checking admin routes
                  localStorage.setItem(ADMIN_ROUTE_STORAGE, 'Y')
                  location.href = localStorage.getItem('urlRedirectedFromUnauthorizedPage') ?
                      localStorage.getItem('urlRedirectedFromUnauthorizedPage') :
                      this.$store.getters.GET_PATHS.dashboardAdmin
                  localStorage.removeItem('urlRedirectedFromUnauthorizedPage')
                  // End add to localstorage variable for checking admin routes

                } else {
                  localStorage.removeItem(ADMIN_ROUTE_STORAGE)

                  localStorage.getItem('urlRedirectedFromUnauthorizedPage') ?
                    this.$router.push(localStorage.getItem('urlRedirectedFromUnauthorizedPage')) :
                    this.$router.push(this.$store.getters.GET_PATHS.dashboard)
                  localStorage.removeItem('urlRedirectedFromUnauthorizedPage')

                  // if(Object.hasOwnProperty.call(this.$store.getters.GET_PATHS, 'isAdminRoutes')) {
                  //   location.href = localStorage.getItem('urlRedirectedFromUnauthorizedPage') ?
                  //       localStorage.getItem('urlRedirectedFromUnauthorizedPage') :
                  //       this.$store.getters.GET_PATHS.dashboardUser
                  // } else {
                  //   localStorage.getItem('urlRedirectedFromUnauthorizedPage') ?
                  //       this.$router.push(localStorage.getItem('urlRedirectedFromUnauthorizedPage')) :
                  //       this.$router.push(this.$store.getters.GET_PATHS.dashboard)
                  //   localStorage.removeItem('urlRedirectedFromUnauthorizedPage')
                  // }
                }
              }).catch(error => this.createErrorLog(error))

              break
            }
              /**
               * Validation Error
               */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;

              this.serverError = true;
              errors.email ?      ( this.validation.email = true,     this.validationTxt.email = errors.email[0] )          : false;
              errors.password ?   ( this.validation.password = true,  this.validationTxt.password = errors.password[0] )    : false;
              // this.notifyErrorHelper(errors)
              break
            }
              /**
               * Blocked account
               */
            case this.$store.getters.GET_ERRORS.BLOCKED_CODE: {
              this.openBlockedAlert = true
              break
            }
              /**
               * Undefined Error
               */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }

        })
      }
    },


    handleSdkInit({ FB, scope }) {
      this.FB = FB;
      this.scope = scope;
    },

    loginFb() {
      this.getUserData()
    },

    getUserData() {
      this.FB.api(
        '/me',
        { fields: 'id, name, picture, email' },
        (user) => {
          this.email = user.email
          this.fbId = user.id

          let data = {
            facebook_id: this.fbId
          }

          this.scope.logout()

          this.$store.dispatch('authThroughtFb', data).then((response) => {
            if(_.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE){

              this.$store.dispatch('fetchCurrentUserRights').then(() => {

                if(Object.hasOwnProperty.call(this.$store.getters.getCurrentUserRights.additionalPermissions, PERMISSIONS.VIEW_ADMIN_ROUTE_PREFIX)){

                  // Add to localstorage variable for checking admin routes
                  localStorage.setItem(ADMIN_ROUTE_STORAGE, 'Y')
                  location.href = this.$store.getters.GET_PATHS.dashboardAdmin
                  // End add to localstorage variable for checking admin routes

                } else {
                  localStorage.removeItem(ADMIN_ROUTE_STORAGE)
                  if(Object.hasOwnProperty.call(this.$store.getters.GET_PATHS, 'isAdminRoutes')) {
                    location.href = this.$store.getters.GET_PATHS.dashboardUser
                  } else {
                    this.$router.push(this.$store.getters.GET_PATHS.dashboard)
                  }
                }
              }).catch(error => this.createErrorLog(error))



            } else if(response.response.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {

              let errors = response.response.data.errors;

              this.serverError = true;
              errors.email ?      ( this.validation.email = true,     this.validationTxt.email = errors.email[0] )          : false;
              errors.facebook_id ?      ( this.validation.email = true,     this.validationTxt.email = errors.facebook_id[0] )          : false;
            }

          })

        }
      )
    },

  },


}

import {checkValid} from "../../../globalModels/functions/ModelsValidate";
import {Files} from "../../../globalModels/Files";


export function ShippingCompany() {

  this.checkValid = checkValid

  this.data = {
    id: '',
    status: '',
    deliveryTypeId: 1,
    name: '',
    code: '',
    description: '',
    active: false,
    showInListing: false,

    Files: new Files({
      maxCountFiles: 1,
      maxSizeFiles: 3
    }),
  }

  this.validation = {
    name: false,
    code: false,
    description: false,
  }

  this.validationTranslate = {
    name: '',
    code: '',
    description: '',
  }

  this.validationTxt = {
    name: false,
    code: false,
    description: false,
  }

  /**
   * Getters
   */
  this.getId = () => {
    return this.data.id
  }
  this.getStatus = () => {
    return this.data.status
  }
  this.getDeliveryTypeId = () => {
    return this.data.deliveryTypeId
  }
  this.getName = () => {
    return this.data.name
  }
  this.getCode = () => {
    return this.data.code
  }
  this.getDescription = () => {
    return this.data.description
  }
  this.getActive = () => {
    return this.data.active
  }
  this.getShowInListing = () => {
    return this.data.showInListing
  }

  /**
   * Setters
   */
  this.setId = (val) => {
    this.data.id = val
  }
  this.setStatus = (val) => {
    this.data.status = val
  }
  this.setDeliveryTypeId = (val) => {
    this.data.deliveryTypeId = val
  }
  this.setDate = (val) => {
    this.data.date = val
  }
  this.setName = (val) => {
    this.data.name = val
  }
  this.setCode = (val) => {
    this.data.code = val
  }
  this.setDescription = (val) => {
    this.data.description = val
  }
  this.setActive = (val) => {
    this.data.active = val
  }
  this.setShowInListing = (val) => {
    this.data.showInListing = val
  }

}


/**
 * Global Setters
 */

ShippingCompany.prototype.setItem = function (val) {

  this.setName(val.name)
  this.setCode(val.code)
  this.setDescription(val.description)
  this.setDeliveryTypeId(val['delivery_type_id'])
  this.setActive(val.enable)
  this.setShowInListing(val['use_for_listings'])

  if (val['logo_base64'])
    this.setShippingCompanyDownloadFiles(val['logo_base64'])
}


ShippingCompany.prototype.setShippingCompanyDownloadFiles = function (val) {
  this.data.Files.pushDownloadFiles(val)
}

ShippingCompany.prototype.removeShippingCompanyFile = function () {
  this.data.Files.removeDownloadFile(1)
}


/**
 * Functions
 */


/**
 * Validations
 */

ShippingCompany.prototype.firstValidation = function () {

  let data = this.data

  let validationItems = {
    name: data.name,
    code: data.code,
    description: data.description,
  }

  let validationOptions = {
    name: {type: ['empty']},
    code: {type: ['empty']},
    // description: {type: ['empty']},
  }


  return this.checkValid(validationItems, validationOptions)
}


/**
 * Prepare Data
 */

ShippingCompany.prototype.prepareSave = function () {

  let data = {
    "delivery_type_id": this.getDeliveryTypeId(),
    "code": this.getCode(),
    "name": this.getName(),
    "description": this.getDescription(),
    "enable": this.getActive(),
    "use_for_listings": this.getShowInListing()
  }

  if(this.data.Files.getFiles().length > 0 ){
    data['logo_base64'] = `data:${this.data.Files.getFiles()[0].item[0].type};base64,` + this.data.Files.getFiles()[0].base64String
    data['svg_logo'] = this.data.Files.getFiles()[0].binaryData
  }

  return data

}

<template>
  <div v-if="_.has(currentChat, 'id')">
<!--    {{num}}-->
<!--{{num > currentChat.deal.length}}-->
<!--    <div v-if="num">-->

<!--{{currentChat.deal[0].accepted_by_manufacturer}}-->
<!--    </div>-->
    <template v-if="_.has(currentChat, 'deal') && currentChat.deal.length > 0">

      <div class="d-flex align-items-center justify-content-end">
        <div class="fsz12 mr-3">(Price: ${{ _.first(currentChat.deal).deal_price }})</div>

        <template v-if="isUserHeartyManufacturer">
          <MainButton
              v-if="!isAcceptedByManufacturer"
              :value="$t('heartysan_dealAccept.localization_value.value')"
              class=" buy-label__btn-i wfc wfc"
              @click.native="showDeal(_.first(currentChat.deal).id, 'accept')"
          />
          <MainButton
              v-if="isAcceptedByManufacturer && !isAcceptedBySeller"
              :value="$t('heartysan_dealDeaccept.localization_value.value')"
              class=" buy-label__btn-i wfc"
              @click.native="showDeal(_.first(currentChat.deal).id,'deaccept')"
          />

          <template
              v-if="isAcceptedByManufacturer && isAcceptedBySeller">
<!--            <MainButton-->
<!--                v-if="!isCanceledBySeller && !isCanceledByManufacturer"-->
<!--                :value="$t('heartysan_dealShow.localization_value.value')"-->
<!--                class=" buy-label__btn-i wfc"-->
<!--                @click.native="showDeal(_.first(currentChat.deal).id, 'canCancel')"-->
<!--            />-->

            <MainButton
                v-if="(!isCanceledBySeller && !isCanceledByManufacturer) || (!isCanceledByManufacturer && isCanceledBySeller)"
                :value="$t('heartysan_dealShow.localization_value.value')"
                class=" buy-label__btn-i wfc"
                @click.native="showDeal(_.first(currentChat.deal).id, 'canCancel')"
            />
<!--            <MainButton-->
<!--                v-else-->
<!--                :value="$t('heartysan_dealShow.localization_value.value')"-->
<!--                class=" buy-label__btn-i wfc"-->
<!--                @click.native="showDeal(_.first(currentChat.deal).id, 'show')"-->
<!--            />-->
          </template>
          <MainButton
              v-if="isCanceledByManufacturer && !isCanceledBySeller"
              :value="$t('heartysan_dealShow.localization_value.value')"
              class=" buy-label__btn-i wfc"
              @click.native="showDeal(_.first(currentChat.deal).id, 'show')"
          />

          <MainButton
              v-if="isCanceledBySeller && isCanceledByManufacturer"
              :value="$t('heartysan_dealShow.localization_value.value')"
              class=" buy-label__btn-i wfc"
              @click.native="showDeal(_.first(currentChat.deal).id, 'reCreate')"
          />
        </template>
        <template v-if="!isUserHeartyManufacturer">
          <MainButton
              v-if="!isAcceptedByManufacturer"
              :value="$t('heartysan_dealUpdate.localization_value.value')"
              class=" buy-label__btn-i wfc"
              @click.native="updateDeal(_.first(currentChat.deal).id)"
          />
          <MainButton
              v-if="isAcceptedByManufacturer && !isAcceptedBySeller"
              :value="$t('heartysan_dealAccept.localization_value.value')"
              class=" buy-label__btn-i wfc"
              @click.native="showDeal(_.first(currentChat.deal).id, 'accept')"
          />


          <template v-if="isAcceptedByManufacturer && isAcceptedBySeller">
            <MainButton
                v-if="(!isCanceledBySeller && !isCanceledByManufacturer) || (isCanceledByManufacturer && !isCanceledBySeller)"
                :value="$t('heartysan_dealShow.localization_value.value')"
                class=" buy-label__btn-i wfc"
                @click.native="showDeal(_.first(currentChat.deal).id, 'canCancel')"
            />
          </template>

          <MainButton
              v-if="!isCanceledByManufacturer && isCanceledBySeller"
              :value="$t('heartysan_dealShow.localization_value.value')"
              class=" buy-label__btn-i wfc"
              @click.native="showDeal(_.first(currentChat.deal).id, 'show')"
          />

          <MainButton
              v-if="isCanceledBySeller && isCanceledByManufacturer"
              :value="$t('heartysan_dealShow.localization_value.value')"
              class=" buy-label__btn-i wfc"
              @click.native="showDeal(_.first(currentChat.deal).id, 'reCreate')"
          />

        </template>
      </div>


    </template>
    <MainButton
        v-else-if="!isUserHeartyManufacturer"
        :value="$t('heartysan_dealCreate.localization_value.value')"
        class=" buy-label__btn-i wfc ml-auto"
        @click.native="createDeal"
    />


    <CreateDealPopup
        v-if="createDealPopup"
        :currentChat="currentChat"
        :type="type"
        :id="id"
        @close="createDealPopup = false; id = -1"
        @reload="reload"
    />

    <ShowDealPopup
        v-if="showDealPopup"
        :isUserHeartyManufacturer="isUserHeartyManufacturer"
        :currentChat="currentChat"
        :deal="_.first(currentChat.deal)"
        :type="type"
        :id="id"
        :isCanceledBySeller="isCanceledBySeller"
        :isCanceledByManufacturer="isCanceledByManufacturer"
        @close="showDealPopup = false; id = -1"
        @reload="reload"
    />

  </div>
</template>

<script>
import MainButton from "@/components/UI/buttons/MainButton/MainButton";
import CreateDealPopup from "@/components/modules/HeartysanChatModule/popups/CreateDealPopup/CreateDealPopup";
import ShowDealPopup from "@/components/modules/HeartysanChatModule/popups/ShowDealPopup/ShowDealPopup";

export default {
  name: "DealBlock",

  components: {ShowDealPopup, CreateDealPopup, MainButton},

  props: {
    currentChat: Object,
  },

  computed: {

    isAcceptedByManufacturer() {
      return this._.first(this.currentChat.deal).accepted_by_manufacturer
    },

    isAcceptedBySeller() {
      return this._.first(this.currentChat.deal).accepted_by_seller
    },

    isCanceledByManufacturer() {
      return this._.first(this.currentChat.deal).canceled_by_manufacturer
    },

    isCanceledBySeller() {
      return this._.first(this.currentChat.deal).canceled_by_seller
    },
  },

  data() {
    return {
      createDealPopup: false,
      showDealPopup: false,
      type: 'create',
      id: -1,
    }
  },

  mounted() {

  },

  methods: {
    createDeal() {
      this.createDealPopup = true
      this.type = 'create'
    },

    updateDeal(id) {
      this.id = id
      this.type = 'edit'
      this.createDealPopup = true
    },

    showDeal(id, type) {
      this.id = id
      this.type = type
      this.showDealPopup = true
    },

    reload() {
      console.log(45);
      this.$emit('reload')
    }
  }

}
</script>

<style scoped>

</style>
<template>
  <div class="detail-page chat">
    <div class="detail-page__left"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['chat_createHeartysanChat', 'heartysan_findHeartysanChat', 'chat_findMessage'])"></div>
      <CardLeftBlock
          :backgroundImage="'no-bg'"
      >
        <template slot="body">
          <div>
            <div class="custom-row">

              <div class="custom-col"
                   v-if="isUserHeartyManufacturer"
              >
                <DefaultSelect
                    :options="optionsManufacturerProducts"
                    :label="$t('heartysan_findHeartysanChat.localization_value.value')"
                    :optionsLabel="'name'"
                    @change="(item) => changeFilterChat(item.id, false)"
                />
              </div>

              <div class="custom-col"
                   v-if="!isUserHeartyManufacturer"
              >
                <DefaultSelect
                    :options="optionsManufacturers"
                    :label="$t('heartysan_findHeartysanManufacturer.localization_value.value')"
                    :optionsLabel="'user_full_name'"
                    @change="(item) => changeFilterChat(false, item.id)"
                />
              </div>

            </div>
            <div class="mt-3">
              <ChatContacts
                  :chatContacts="chatContacts"
                  :currentHeartysanChat="currentHeartysanChat"
                  @changeHeartysanChat="changeHeartysanChat"
              />
            </div>
          </div>
        </template>
      </CardLeftBlock>
    </div>

    <div class="detail-page__right">
      <CardRightBlock>
        <template slot="header">
          <div class="order-create__head">
            <div class="order-create__head-line align-center" style="height: 42px;">
              <div class="custom-col--50"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['chat_backTo',])"></div>
                <router-link :to="$store.getters.GET_PATHS.dashboard" class="order-create__head-back">
                  <LinkBack
                      :value="$t('chat_backTo.localization_value.value')"
                  />
                </router-link>
              </div>

              <div class="order-create__header-col custom-col--50">
                <DealBlock
                    :currentChat="currentHeartysanChat"
                    :key="key"
                    @reload="reloadCurrentChat"
                />
              </div>
            </div>
            <div class="order-create__head-title"
                 v-if="_.has(currentHeartysanChat, 'id')"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['chat_Messages'])"></div>
              {{ $t('chat_Messages.localization_value.value') }}
              {{currentHeartysanChat.product.name}} ({{currentHeartysanChat.product.user.user_personal_contact.user_full_name}})
              <br>
              {{ $t('heartysan_price.localization_value.value') }}: ${{currentHeartysanChat.product.price}}
            </div>
          </div>
        </template>
        <template slot="body">

          <div class="order-create__section chat__block"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate([
                'heartysan_createDealMessage',
                'heartysan_createDealUpdateMessage',
                'heartysan_manufacturerAcceptDeal',
                'heartysan_sellerAcceptDeal',
                'heartysan_deacceptManufacturer',
                'heartysan_cancelManufacturer',
                'heartysan_cancelConfirm',
                'heartysan_cancelSeller',
                'heartysan_recreatedDealSeller',
            ])"></div>
            <div class="custom-row">
              <div class="custom-col">
                <div class="messages"
                     v-if="_.has(currentHeartysanChat, 'id')"
                >
                  <div class="m-auto text-center" v-if="chatMessages && chatMessages.messages && chatMessages.messages.length === 0">Нет сообщений</div>
                  <template v-else>
                    <div v-for="(message, index) in chatMessages.messages"
                         :key="index"
                         :ref="chatMessages.messages.length - 1 === index ? 'last' : undefined"
                    >
                      <template v-if="firstLoadChat">
                        <div class="messages__item-new-message"
                             :ref="'newMessage'"
                             v-if="!message.viewed && (message.author.id !== getCurrentUser.id && (index === 0 || chatMessages.messages[index - 1].viewed) ||
                          (index === 0 || chatMessages.messages[index - 1].viewed && chatMessages.messages[index - 1].author.id !== getCurrentUser.id))">
                          New messages
                        </div>
                      </template>

                      <div class="messages__item-system"
                           v-if="message.system"
                      >
                        <div class="messages__item-system-txt">
                          {{message.message}}
                          <template
                              v-if="_.first(currentHeartysanChat.deal).accepted_by_manufacturer && !_.first(currentHeartysanChat.deal).accepted_by_seller">
                            {{$t('heartysan_acceptConfirm.localization_value.value')}}
                          </template>
                        </div>
                      </div>

                      <template v-if="message.author.id !== getCurrentUser.id && !message.system">
                        <div class="messages__item-left">
                          <div class="messages__logo">
                            <img
                                v-if="message.author.avatarBase64 && _.has(message.author.avatarBase64, 'fileBase64')"
                                :src="`data:${message.author.avatarBase64.mime_type};base64,` + message.author.avatarBase64.fileBase64"
                                alt="alt"
                            >
                            <img v-else style="background: #ce9e64" src="/img/profile-group/profile-photo-default.svg" alt="img">
                          </div>
                          <div class="messages__item-left-txt">
                            {{message.message}}
                          </div>
                          <div class="messages__item-left-date">
                            {{ message.created_at | moment("DD MMM, YYYY") }} ({{ message.created_at | moment("hh:mm") }})
                          </div>
                        </div>
                        <div class="fsz12 mt--9 mb-3">
                          {{message.author.user_personal_contact.user_full_name}}
                        </div>
                      </template>

                      <template v-if="message.author.id === getCurrentUser.id && !message.system">
                        <div class="messages__item-right" :ref="message.message">
                          <div class="messages__item-right-date">
                            {{ message.created_at | moment("DD MMM, YYYY") }} ({{ message.created_at | moment("hh:mm") }})
                          </div>
                          <div class="messages__item-right-txt">
                            {{message.message}}
                          </div>
                          <div class="messages__logo">
                            <img
                                v-if="message.author.avatarBase64 && _.has(message.author.avatarBase64, 'fileBase64')"
                                :src="`data:${message.author.avatarBase64.mime_type};base64,` + message.author.avatarBase64.fileBase64"
                                alt="alt"
                            >
                            <img v-else style="background: #ce9e64" src="/img/profile-group/profile-photo-default.svg" alt="img">
                          </div>
                        </div>
                        <div class="fsz12 mt--9 text-right pr-3 mb-3">
                          {{message.author.user_personal_contact.user_full_name}}
                        </div>
                      </template>




                    </div>

                  </template>



                </div>
              </div>
            </div>
          </div>


        </template>
        <template slot="footer">
          <div class="order-create__footer mt-3" v-if="_.has(currentHeartysanChat, 'id')">

            <div
                class="w-100 mr-3 position-relative message-input-block"
                v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['chat_Message'])"></div>
              <DefaultInput
                  :label="$t('chat_Message.localization_value.value')"
                  v-model="message"
                  @onEnter="sendMessage"
              />
<!--              <div class="counts">-->
<!--                {{getCounters}}-->
<!--              </div>-->
            </div>

            <div class="order-create__footer-btn ml-auto"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit"
                   @click="editTranslate(['common_send'])"></div>
              <MainButton
                  class="order-create__footer-btn-i white-space-nowrap__from-680"
                  :value="$t('common_send.localization_value.value')"
                  @click.native="sendMessage"
              />
            </div>

          </div>
        </template>
      </CardRightBlock>

    </div>

  </div>
</template>

<script>
  import MainButton from "@/components/UI/buttons/MainButton/MainButton";
  import CardLeftBlock from "@/components/coreComponents/CardLeftBlock/CardLeftBlock";
  import CardRightBlock from "@/components/coreComponents/CardRightBlock/CardRightBlock";
  import LinkBack from "@/components/UI/buttons/LinkBack/LinkBack";
  import DefaultSelect from "@/components/UI/selectiones/DefaultSelect/DefaultSelect";
  import DefaultInput from "@/components/UI/inputs/DefaultInput/DefaultInput";
  import {queryFilterMixin} from "@/mixins/routeFilterMixins/queryFilterMixin";
  import {CHAT_STATUSES} from "@/staticData/staticVariables";
  import DealBlock
    from "@/components/modules/HeartysanChatModule/components/HeartysanChatPage/HeartysanChatSection/DealBlock/DealBlock";
  import ChatContacts
    from "@/components/modules/HeartysanChatModule/components/HeartysanChatPage/HeartysanChatSection/ChatContacts/ChatContacts";
  // import SearchSelect from "@/components/UI/selectiones/SearchSelect/SearchSelect";

  export default {
    name: "HeartysanChatSection",
    components: {
      ChatContacts,
      DealBlock,
      // SearchSelect,
      DefaultInput,
      DefaultSelect,
      LinkBack,
      CardRightBlock,
      CardLeftBlock,
      MainButton
    },

    mixins: [queryFilterMixin],

    computed: {

      getChatEvents() {
        return this.$store.getters.getHeartysanChatEvents
      },

      getSocketChatId() {
        return this.$route.query.socketChat
      },
    },

    watch: {
      loadUserRoleAndAuthorizedData() {
        this.initHeartysanProducts()
      },

      getChatEvents(newVal) {
        console.log('event')
        console.log(newVal)
        let eventChat = newVal?.data?.chat?.id
        let chatId = this.$route.query.chatId
        if(parseInt(chatId) === eventChat) {
          let foundChat = this._.find(this.chatContacts, {id: parseInt(chatId)})
          if(foundChat) {
            this.changeHeartysanChatProcess(foundChat)
          }
        } else {

          let myQuery = this.createQueryBuilderObject({withUserNewMark: true})
          if(this.productId) {
            myQuery.where('hearty_product_id', this.productId)
          }
          if(this.manufacturerId) {
            myQuery.where('manufacturer_user_id', this.manufacturerId)
          }
          let url = this.generateGetParamsFromQuery(myQuery, 1000000, 0)
          this.$store.dispatch('fetchHeartysanChat', url).then(response => {
            this.chatContacts = this.getRespPaginateData(response)
          })
        }

        this.firstLoadChat = false
      },

      getSocketChatId(id) {
        if(parseInt(id) === 1){
          let chatId = this.$route.query.chatId
          let foundChat = this._.find(this.chatContacts, {id: parseInt(chatId)})
          this.changeHeartysanChat(foundChat)
        }
        let query = Object.assign({}, this.$route.query);
        delete query.socketChat;
        this.$router.replace({ query });
      },
    },

    data() {
      return {
        createHeartysanChatPopup: false,
        chatContacts: [],

        chatMessages: [],

        optionsFindMessages: [],

        optionsManufacturerProducts: [],

        optionsManufacturers: [],

        currentHeartysanChat: null,

        message: '',
        foundMessage: null,

        firstLoadChat: true,

        CHAT_STATUSES: CHAT_STATUSES,

        productId: false,
        manufacturerId: false,

        key: -1,
      }
    },

    mounted() {
      this.initHeartysanChat()

      this.initHeartysanProducts()
    },

    methods: {

      initHeartysanProducts() {
        if(this.loadUserRoleAndAuthorizedData && this.isUserHeartyManufacturer) {
          let myQuery = this.createQueryBuilderObject({withUserNewMark: true})
          myQuery.where('user_id', this.getCurrentUser?.id)
          let url = this.generateGetParamsFromQuery(myQuery, 1000000, 0)

          this.$store.dispatch('fetchHeartysanProducts', url).then(response => {
            this.optionsManufacturerProducts = this.getRespPaginateData(response).filter(item => item.chats.length > 0)
          })
        }
      },

      initHeartysanChat() {
        let myQuery = this.createQueryBuilderObject({withUserNewMark: true})
        if(this.productId) {
          myQuery.where('hearty_product_id', this.productId)
        }
        if(this.manufacturerId) {
          myQuery.where('manufacturer_user_id', this.manufacturerId)
        }
        let url = this.generateGetParamsFromQuery(myQuery, 1000000, 0)

        this.$store.dispatch('fetchHeartysanChat', url).then(response => {
          this.chatContacts = this.getRespPaginateData(response)

          this.chatContacts.sort(function compare(a, b) {
            let dateA = new Date(a?.last_message[0]?.created_at);
            let dateB = new Date(b?.last_message[0]?.created_at);
            return dateB - dateA;
          });

          if(this.optionsManufacturers.length === 0 && !this.isUserHeartyManufacturer) {
            this.initManufacturers(this.getRespPaginateData(response))
          }

          let chatId = this.$route.query.chatId
          if(chatId) {
            let foundChat = this._.find(this.chatContacts, {id: parseInt(chatId)})
            if(foundChat) {
              this.changeHeartysanChat(foundChat)
            }
          } else if(this.chatContacts.length > 0) {
            this.changeHeartysanChat(this.chatContacts[0])
          }
        }).catch(error => this.createErrorLog(error))
      },

      initHeartysanChatMessages() {
        if(!this.currentHeartysanChat.id) return

        this.$store.dispatch('getHeartysanChat', this.currentHeartysanChat.id).then(response => {
          this.chatMessages = this.getRespData(response)?.chat
          this.currentHeartysanChat.deal = this.chatMessages.deal
          this.key = Math.random()

          let viewed = this._.filter(this.chatMessages.messages, {viewed: false})

          if(viewed.length > 0) {
            this.readMessage(viewed)
          }

          this.scrollToElement()
        }).catch(error => this.createErrorLog(error))
      },

      initManufacturers(chats) {
        chats.map(chat => {
          if(!this._.find(this.optionsManufacturers, {id: chat.manufacturer.id})){
            this.optionsManufacturers.push(chat.manufacturer)
          }
        })
      },

      changeFilterChat(productId = false, manufacturerId = false) {
        this.productId = productId
        this.manufacturerId = manufacturerId
        this.initHeartysanChat()
        this.firstLoadChat = true
      },

      changeHeartysanChat(item) {
        this.firstLoadChat = true
        this.changeHeartysanChatProcess(item)
        item.count_new_messages = 0
      },

      changeHeartysanChatProcess(item) {
        let chatId = this.$route.query.chatId
        this.currentHeartysanChat = item
        this.initHeartysanChatMessages()

        if(chatId && parseInt(chatId) === parseInt(item.id)) return

        this.$router.push({ path: '', query: {'chatId': item.id}})
      },

      scrollToElement() {

        setTimeout(() => {
          let [el] = this.$refs.last;

          if(this.$refs.newMessage) {
            [el] = this.$refs.newMessage
          }

          if (el) {
            el.scrollIntoView({ behavior: "smooth" });
          }
        },1)

      },

      reload() {
        this.initHeartysanChat()
      },

      reloadCurrentChat() {
        this.initHeartysanChatMessages()
      },

      readMessage(viewed) {
        let data = []
        viewed.map(item => {
          data.push({id: item.id})
        })

        let authorizedData = this.$store.getters.GET_COMMON_AUTHORIZED
        authorizedData.count_hearty_chat_new_messages = authorizedData.count_hearty_chat_new_messages - viewed.length
        this.$store.commit('SET_COMMON_AUTHORIZED', {COMMON_AUTHORIZED: authorizedData})

        this.$store.dispatch('readMessageHeartysanChat', {messages: data}).then(response => {
          console.log(response);

        })
      },

      sendMessage() {
        if(this.message.length === 0) return

        this.message = this.message.replace('mysql_escape_string','')
        this.message = this.message.replace('mysql_real_escape_string','')

        let data = {
          'message': this.message,
        }

        this.message = ''

        let typeDispatch = 'createMessageHeartysanChat'

        this.$store.dispatch(typeDispatch, {data: data, id: this.currentHeartysanChat.id}).then(response => {
          switch (this.getResponseStatus(response)) {
              /**
               * Success
               */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
              this.initHeartysanChatMessages()
              break
            }
              /**
               * Validation Error
               */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
              /**
               * Undefined Error
               */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }
        })
      },
    }

  }
</script>

<style scoped lang="scss">
@import "../../../../../../scss/colors";
@import "../../../../../../scss/mixins/mixins";

@include for-680 {
  .message-input-block{

    //margin-left: 15px;

  }

  .order-create__footer{
    flex-direction: column;
    padding: 0 15px;
  }

  .order-create__footer-btn{
    margin-top: 20px;
  }
}




.chat{

  &__items{
    max-height: calc(100vh - 275px);
    overflow: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 3px;
      height: 2px;
      border: 1px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
      margin-right: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: $borderBrown;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: $borderBrown;
      opacity: .5;
    }

  }

  &__item{
    margin-bottom: 0;
    padding-top: 20px;
    padding-bottom: 20px;
    transition: 0.3s;
    border-radius: 10px;
    cursor: pointer;
    position: relative;

    & + .chat__item {
      position: relative;

      .chat__user :before{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: calc(100% - 30px);
        height: 1px;
        background: $brown;
        margin-left: 15px;
        transition: 0.1s;
      }
    }

    &:hover{
      & + .chat__item {
        .chat__user :before {
          opacity: 0;
        }
      }
      .chat__user :before {
        opacity: 0;
      }

      background: rgba(143, 122, 97, 0.2);
    }

    &--active{
      background: rgba(143, 122, 97, 0.2);
      & + .chat__item {
        .chat__user :before {
          opacity: 0;
        }
      }
    }
  }

  &__user{

  }

  &__user-new{
    display: inline-block;
    background: #cd9d69;
    padding: 5px;
    font-size: 11px;
    border-radius: 5px;
    line-height: 9px;
    color: white;
    position: absolute;
    right: 0;
    top: 0;
  }

  &__user-inner{
    display: flex;
  }

  &__user-photo{
    width: 50px;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    margin-right: 10px;
  }

  &__user-block{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding-top: 3px;
    padding-bottom: 2px;
  }

  &__user-name{
    color: $black;
    font-weight: 500;
    font-size: 16px;
    display: flex;
  }

  &__user-phone{
    color: $brown;
    font-weight: 500;
    font-size: 13px;
  }

  &__user-date{
    font-size: 13px;
    margin-left: auto;
    padding-top: 5px;
    white-space: nowrap;
  }


  &__block{
    min-height: calc(100vh - 413px);
    max-height: calc(100vh - 413px);
    overflow: auto;
    overflow-x: hidden;
    //background: $mainBg;
    background: rgba(143, 122, 97, 0.2);
    //border: 1px solid $borderBrown;
    border-radius: 10px;
    padding: 15px;

    @include for-680 {
      min-height: calc(100vh - 213px);
      max-height: calc(100vh - 213px);
    }

    &::-webkit-scrollbar {
      width: 3px;
      height: 2px;
      border: 1px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
      margin-right: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: $borderBrown;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: $borderBrown;
      opacity: .5;
    }
  }
}


.messages{

  &__item-system{
    padding: 15px 0;
    font-size: 14px;
    display: flex;
    justify-content: center;
    position: relative;

    &:before{
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      background: black;
      position: absolute;
      top: 27px;
      left: 0;
    }
  }

  &__item-system-txt{
    position: relative;
    z-index: 2;
    padding: 5px 15px;
    background: white;
    border-radius: 20px;
  }

  &__item-left{
    display: flex;
    width: 100%;
    align-items: flex-end;
    margin-bottom: 15px;
  }

  &__logo{
    width: 50px;
    min-width: 50px;
    height: 50px;
    border-radius: 30px;
    display: flex;
    overflow: hidden;
    margin-right: 15px;
    display: flex;
    justify-content: center;
    align-items: center;

    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__item-left-txt{
    padding: 15px;
    background: white;
    border-radius: 20px;
    border-bottom-left-radius: 0;
    font-size: 13px;
    margin-right: 15px;
  }

  &__item-left-date{
    font-size: 10px;
  }

  &__item-right{
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-bottom: 15px;
  }

  &__item-new-message{
    text-align: center;
    margin-bottom: 10px;
    margin-top: 10px;
    padding-bottom: 5px;
    border-bottom: 1px solid;
  }

  &__item-right-date{
    font-size: 10px;
    margin-right: 15px;
  }

  &__item-right-txt{
    padding: 15px;
    background: white;
    border-radius: 20px;
    border-bottom-right-radius: 0;
    font-size: 13px;
    margin-right: 15px;
  }

}

.counts{
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 13px;
  margin-bottom: -20px;
}

</style>

<template>
  <div class="fragment">
    <template v-if="$store.getters.getConsolidationUnion.length > 0 && !$store.getters.getConsolidationUnionLoading">
      <div class="site-table-wrap small-table"
           v-if="activeTab !== 7"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
          'consolidationUnion_id',
          'common_user',
          'consolidationUnion_shippingCompany',
          'consolidationUnion_trackingNumber',
          'consolidationUnion_City',
          'consolidationUnion_comment',
          'consolidationUnion_Quantity',
          'consolidationUnion_amountCharged',
          'consolidationUnion_sendDate',
          'consolidationUnion_consoUser',
          'common_manage',
          'common_edit',
          'common_delete',
          'consolidationUnion_CreateLabel',
          'poshta_Label',
          'consolidationUnion_editLabelParams',
          'consolidationUnion_invoice',
          'consolidationUnion_ToxicSubstance',
          'consolidationUnion_DropBall',
          'consolidationUnion_certOfOrigin',
          'consolidationUnion_SendTrackingNum',
          ])"></div>
        <table class="site-table" v-if="!isMobileFunc()">
          <thead>
          <tr>
            <th></th>
            <th>{{$t('consolidationUnion_id.localization_value.value')}}</th>
            <th>{{$t('common_user.localization_value.value')}}</th>
            <th>{{$t('consolidationUnion_shippingCompany.localization_value.value')}}</th>
            <th v-if="navTabs.sent.active">{{$t('consolidationUnion_formIE599.localization_value.value')}}</th>
            <th>{{$t('consolidationUnion_trackingNumber.localization_value.value')}}</th>
            <th>{{$t('consolidationUnion_City.localization_value.value')}}</th>
            <th>{{$t('consolidationUnion_comment.localization_value.value')}}</th>
            <th v-if="navTabs.sent.active">{{$t('consolidationUnion_Quantity.localization_value.value')}}</th>
            <th v-if="navTabs.sent.active">{{$t('consolidationUnion_amountCharged.localization_value.value')}}</th>
            <th v-if="navTabs.sent.active">{{$t('consolidationUnion_sendDate.localization_value.value')}}</th>
            <th v-if="navTabs.sent.active">{{$t('consolidationUnion_consoUser.localization_value.value')}}</th>
<!--            <th width="100%"></th>-->
            <th>{{$t('common_manage.localization_value.value')}}</th>
          </tr>
          </thead>
          <tbody>

          <tr
              v-for="(item, index) in $store.getters.getConsolidationUnion"
              :key="index"
          >
            <td>
              <DefaultCheckbox
                  v-if="item.status !== 'received'"
                  class="empty-label"
                  :selectedNow="selectedNow"
                  :dataValue="item.id"
              />
              <StatusIcoBtn v-else :type="'delivered'" class="mr-2" />
            </td>
            <td>
              <span class="table-link btn-style"
                @click="changeInfoPopup(item)"
              >
               {{item.delivery_service.name}}: {{item.tracking_number}}
              </span>
              <MainButton
                  v-if="navTabs.sent.active"
                  class="mt-2"
                  style="height: 35px; font-size: 14px;"
                  :value="$t('consolidationUnion_sendTrackingNum2.localization_value.value')"
                  :disabledBtn="$store.getters.getConsolidationUnionBtn"
                  @click.native="sendTrack(item.id)"
              />
            </td>
            <td>
              <LinkButton
                  v-if="item.admin"
                  :value="item.admin.full_name"
                  :link="$store.getters.GET_PATHS.mainSettingsUsers + `?userId=${item.admin.id}`"
              />
            </td>
            <td>
              {{item.delivery_service.name}}
            </td>
            <td v-if="navTabs.sent.active" class="white-space-line">
              <div class="table-row">
                <div class="d-flex align-center"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">

                  <span class="admin-edit" @click="editTranslate(['consolidationUnion_comment', 'consolidationUnion_addComment'])"></span>

                  <template v-if="item.form_number !== null && item.form_number !== ''">
                    <v-popover class="site-tooltip" offset="5" placement="top" trigger="hover">

                      <div class="table-link btn-style ellipsis">{{ item.form_number }}</div>

                      <template slot="popover">
                        <p>{{ item.form_number }}</p>
                      </template>

                    </v-popover>
                    <div class="table-ico">
                      <v-popover class="site-tooltip" offset="5" placement="top-left" @show="hideEditField = true" @hide="hideEditField = false">

                        <span class="site-link"><LinkButton :type="'edit'"/></span>

                        <template slot="popover">
                          <div class="section-label mb-2">{{$t('consolidationUnion_formIE599.localization_value.value')}}
                          </div>
                          <InputEditField
                              :value="item.form_number"
                              :propId="item.id"
                              :hideEditField="hideEditField"
                              :disabledBtn="$store.getters.getConsolidationUnionBtn"
                              @onChange="changeFormNumberConsolidationUnion"
                          />
                          <a v-close-popover ref="closePopover" class="tooltip__close"></a>
                        </template>
                      </v-popover>
                    </div>
                  </template>
                  <template v-else>
                    <div class="">
                      <v-popover class="site-tooltip" offset="5" placement="top-left" @show="hideEditField = true" @hide="hideEditField = false">

                        <span class="table-link btn-style">{{$t('consolidationUnion_addFormIE599.localization_value.value')}}</span>

                        <template slot="popover">
                          <div class="section-label mb-2">{{$t('consolidationUnion_formIE599.localization_value.value')}}</div>
                          <InputEditField
                              :value="''"
                              :propId="item.id"
                              :hideEditField="hideEditField"
                              :disabledBtn="$store.getters.getConsolidationUnionBtn"
                              @onChange="changeFormNumberConsolidationUnion"
                          />

                          <a v-close-popover ref="closePopover" class="tooltip__close"></a>
                        </template>
                      </v-popover>
                    </div>
                  </template>
                </div>
              </div>
            </td>
            <td>
              {{item.tracking_number}}
            </td>
            <td>
              {{item.warehouse.currentTranslate.name}}
            </td>
            <td class="white-space-line">
              <div class="table-row">
                <div class="d-flex align-center"
                    v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <span class="admin-edit"
                        @click="editTranslate(['consolidationUnion_comment', 'consolidationUnion_addComment'])"></span>
                  <template v-if="item.comment !== null && item.comment !== ''">
                    <!--<span>{{item.tracking_number}}</span>-->
                    <v-popover
                        class="site-tooltip"
                        offset="5"
                        placement="top"
                        trigger="hover"
                    >
                      <div class="table-link btn-style ellipsis">{{ item.comment }}</div>
                      <template slot="popover">
                        <p>{{ item.comment }}</p>
                      </template>
                    </v-popover>
                    <div class="table-ico">
                      <!--v-if="item.tracking_sent === 0"-->
                      <v-popover
                          class="site-tooltip"
                          offset="5"
                          placement="top-left"
                          @show="hideEditField = true"
                          @hide="hideEditField = false"
                      >
                        <span class="site-link">
                            <LinkButton
                                :type="'edit'"
                            />
                        </span>
                        <template slot="popover">
                          <div class="section-label mb-2">{{$t('consolidationUnion_comment.localization_value.value')}}
                          </div>
                          <InputEditField
                              :value="item.comment"
                              :propId="item.id"
                              :hideEditField="hideEditField"
                              :disabledBtn="$store.getters.getConsolidationUnionBtn"
                              @onChange="changeCommentConsolidationUnion"
                          />
                          <a v-close-popover ref="closePopover" class="tooltip__close"></a>
                        </template>
                      </v-popover>
                    </div>
                  </template>
                  <template v-else>
                    <div class="">
                      <v-popover
                          class="site-tooltip"
                          offset="5"
                          placement="top-left"
                          @show="hideEditField = true"
                          @hide="hideEditField = false"
                      >
                        <span class="table-link btn-style">{{$t('consolidationUnion_addComment.localization_value.value')}}</span>

                        <template slot="popover">
                          <div class="section-label mb-2">{{$t('consolidationUnion_comment.localization_value.value')}}
                          </div>
                          <InputEditField
                              :value="''"
                              :propId="item.id"
                              :hideEditField="hideEditField"
                              :disabledBtn="$store.getters.getConsolidationUnionBtn"
                              @onChange="changeCommentConsolidationUnion"
                          />

                          <a v-close-popover ref="closePopover" class="tooltip__close"></a>
                        </template>
                      </v-popover>
                    </div>
                  </template>
                </div>
              </div>

              <div class="table-row" v-if="navTabs.sent.active">
                <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <span class="admin-edit"
                        @click="editTranslate(['consolidationUnion_consoTag', 'consolidationUnion_addConsoTag'])"></span>
                  <template v-if="item.tag !== null">
                    <span class="btn-style">
                      <span class="color-red alarm-style"
                      >
                        {{ item.tag.name }}
                      </span>
                    </span>
                    <div class="table-ico">
                      <!--v-if="item.tracking_sent === 0"-->
                      <v-popover
                          class="site-tooltip"
                          offset="5"
                          placement="top-left"
                          @show="hideEditField = true"
                          @hide="hideEditField = false"
                      >
                        <span class="site-link">
                            <LinkButton
                                :type="'edit'"
                            />
                        </span>
                        <template slot="popover">
                          <div class="section-label mb-2">{{$t('consolidationUnion_consoTag.localization_value.value')}}
                          </div>
                          <div class="edit-field-input">
                            <div class="edit-field-input__inner">
                              <DefaultSelect
                                  class="conso-user-select"
                                  :options="consoTags"
                                  :optionsLabel="'name'"
                                  :selected="item.tag"
                                  @change="(val) => {selectedConsoTag = val}"
                              />
                              <button class="edit-field-input__btn"
                                      v-bind:class="{'disable-edit-field-btn': disabledConsoUnionBtn}"
                                      @click="changeTagConsolidationUnion(item.id)"></button>
                            </div>
                          </div>
                          <a v-close-popover ref="closePopover" class="tooltip__close"></a>
                        </template>
                      </v-popover>
                    </div>
                  </template>
                  <template v-else>
                    <div class="">
                      <v-popover
                          class="site-tooltip"
                          offset="5"
                          placement="top-left"
                          @show="hideEditField = true"
                          @hide="hideEditField = false"
                      >
                        <span class="table-link btn-style">{{$t('consolidationUnion_addConsoTag.localization_value.value')}}</span>

                        <template slot="popover">
                          <div class="section-label mb-2">{{$t('consolidationUnion_consoTag.localization_value.value')}}
                          </div>
                          <div class="edit-field-input">
                            <div class="edit-field-input__inner">
                              <DefaultSelect
                                  class="conso-user-select"
                                  :options="consoTags"
                                  :optionsLabel="'name'"
                                  @change="(val) => {selectedConsoTag = val}"
                              />
                              <button class="edit-field-input__btn"
                                      v-bind:class="{'disable-edit-field-btn': disabledConsoUnionBtn}"
                                      @click="changeTagConsolidationUnion(item.id)"></button>
                            </div>
                          </div>

                          <a v-close-popover ref="closePopover" class="tooltip__close"></a>
                        </template>
                      </v-popover>
                    </div>
                  </template>
                </div>
              </div>
            </td>
            <td v-if="navTabs.sent.active">
              {{item.consolidation_count}}
            </td>
            <td v-if="navTabs.sent.active">
              <ValueHelper
                  :value="formatAmountCharged(item.amount_charged)"
                  :replaceValue="'—'"
              />
            </td>
            <td v-if="navTabs.sent.active">
              {{ item.send_at | moment("DD MMM, YYYY") }}
            </td>
            <td v-if="navTabs.sent.active" class="white-space-line">
              <div class="table-row">
                <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <span class="admin-edit"
                        @click="editTranslate(['consolidationUnion_consoUser', 'consolidationUnion_addConsoUser'])"></span>
                  <template v-if="item.consolidation_responsible_person !== null && item.consolidation_responsible_person.name">
                    <span class="table-link btn-style">{{ item.consolidation_responsible_person.name }}</span>
                    <div class="table-ico">
                      <!--v-if="item.tracking_sent === 0"-->
                      <v-popover
                          class="site-tooltip"
                          offset="5"
                          placement="top-left"
                          @show="hideEditField = true"
                          @hide="hideEditField = false"
                      >
                        <span class="site-link">
                            <LinkButton
                                :type="'edit'"
                            />
                        </span>
                        <template slot="popover">
                          <div class="section-label mb-2">{{$t('consolidationUnion_consoUser.localization_value.value')}}
                          </div>
                          <div class="edit-field-input">
                            <div class="edit-field-input__inner">
                              <DefaultSelect
                                  class="conso-user-select"
                                  :options="adminUsers"
                                  :optionsLabel="'name'"
                                  :otherValue="'adminItem'"
                                  @change="(val) => {selectedConsoUser = val}"
                              />
<!--                                  :selected="item.consolidation_responsible_person"-->
                              <button class="edit-field-input__btn"
                                      v-bind:class="{'disable-edit-field-btn': disabledConsoUnionBtn}"
                                      @click="changeConsoUserConsolidationUnion(item.id)"></button>
                            </div>
                          </div>
                          <a v-close-popover ref="closePopover" class="tooltip__close"></a>
                        </template>
                      </v-popover>
                    </div>
                  </template>
                  <template v-else>
                    <div class="">
                      <v-popover
                          class="site-tooltip"
                          offset="5"
                          placement="top-left"
                          @show="hideEditField = true"
                          @hide="hideEditField = false"
                      >
                        <span class="table-link btn-style">{{$t('consolidationUnion_addConsoUser.localization_value.value')}}</span>

                        <template slot="popover">
                          <div class="section-label mb-2">{{$t('consolidationUnion_consoUser.localization_value.value')}}
                          </div>
                          <div class="edit-field-input">
                            <div class="edit-field-input__inner">
                              <DefaultSelect
                                  class="conso-user-select"
                                  :options="adminUsers"
                                  :optionsLabel="'name'"
                                  :otherValue="'adminItem'"
                                  @change="(val) => {selectedConsoUser = val}"
                              />
                              <button class="edit-field-input__btn"
                                      v-bind:class="{'disable-edit-field-btn': disabledConsoUnionBtn}"
                                      @click="changeConsoUserConsolidationUnion(item.id)"></button>
                            </div>
                          </div>

                          <a v-close-popover ref="closePopover" class="tooltip__close"></a>
                        </template>
                      </v-popover>
                    </div>
                  </template>
                </div>
              </div>
            </td>
<!--            <td width="100%">-->

<!--            </td>-->
            <td align="right">
              <div class="table-row">
                <div class="table-right table-manage-list table-manage-list--small"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <span class="admin-edit"
                        @click="editTranslate(['common_Proforma'])"></span>

                  <ManagerButton @reloadManagerBtn="$store.commit('setGlobalUpdateManagerButton', Math.random()) " :key="$store.getters.getGlobalUpdateManagerButton"
                      class="right"
                  >
                    <template v-if="!_.has(item, 'possible_state') || item['possible_state'].length === 0">
                      <template slot="item">
                        <LinkButton
                            :key="index"
                            :value="$t('common_Proforma.localization_value.value')"
                            :type="'proforma'"
                            @click.native="$emit('getConsolidationUnionProform', item.id)"
                        />
                      </template>
                    </template>
                    <template v-else-if="_.has(item, 'possible_state')">
                      <template v-for="(state, index) in item['possible_state']">
                        <template slot="item">
                          <LinkButton
                              :key="index"
                              :value="$t('common_Proforma.localization_value.value') + ' ' + state"
                              :type="'proforma'"
                              @click.native="$emit('getConsolidationUnionProformByState', item.id, state)"
                          />
                        </template>
                      </template>
                    </template>


                    <template slot="item">
                      <LinkButton
                          :value="$t('common_edit.localization_value.value')"
                          :type="'edit'"
                          :link="$store.getters.GET_PATHS.reportsConsolidationEdit + '/' + item.id"
                      />
                    </template>
                    <template slot="item">
                      <LinkButton
                          v-if="item.status !== 'received'"
                          :value="$t('common_delete.localization_value.value')"
                          :type="'delete'"
                          @click.native="$emit('removeItem', item.id)"
                      />
                    </template>
                    <template v-if="navTabs.sent.active && item.status === 'departed'" slot="item">
                      <LinkButton
                          :value="$t('consolidationUnion_acceptInMN.localization_value.value')"
                          :type="'mark'"
                          @click.native="$emit('acceptInMinnesota', item.id)"
                      />
                    </template>
                    <template v-if="!navTabs.sent.active && item.consolidation_union_label === null" slot="item">
                      <LinkButton
                          :value="$t('consolidationUnion_CreateLabel.localization_value.value')"
                          :type="'mark'"
                          :link="$store.getters.GET_PATHS.reportsConsolidationCreateLabel + '/' + item.id"
                      />
                    </template>
                    <template v-if="!navTabs.sent.active && item.consolidation_union_label !== null" slot="item">
                      <LinkButton
                          :value="$t('consolidationUnion_editLabelParams.localization_value.value')"
                          :type="'mark'"
                          :link="$store.getters.GET_PATHS.reportsConsolidationEditLabel + '/' + item.consolidation_union_label.id"
                      />
                    </template>

                    <template v-if="!navTabs.sent.active && item.consolidation_union_label !== null
                     && item.consolidation_union_label.label_file === null" slot="item">
                      <LinkButton
                          :value="$t('consolidationUnion_CreateLabel.localization_value.value')"
                          :type="'dog'"
                          @click.native="createLabelConsolidationUnion(item)"
                      />
                    </template>
                    <template v-if="!navTabs.sent.active && item.consolidation_union_label !== null
                    && item.consolidation_union_label.label_file !== null" slot="item">
                      <LinkButton
                          :value="$t('poshta_Label.localization_value.value')"
                          :type="'dog'"
                          @click.native="openFileByUUID(item.consolidation_union_label.label_file)"
                      />
                    </template>
                    <template v-if="!navTabs.sent.active && item.consolidation_union_label !== null
                    && item.consolidation_union_label.proform_document_file !== null" slot="item">
                      <LinkButton
                          :value="$t('consolidationUnion_invoice.localization_value.value')"
                          :type="'doc'"
                          @click.native="openFileByUUID(item.consolidation_union_label.proform_document_file)"
                      />
                    </template>
                    <template v-if="!navTabs.sent.active && item.consolidation_union_label !== null
                    && item.consolidation_union_label.fedex_tsca_document_file !== null" slot="item">
                      <LinkButton
                          :value="$t('consolidationUnion_ToxicSubstance.localization_value.value')"
                          :type="'doc'"
                          @click.native="openFileByUUID(item.consolidation_union_label.fedex_tsca_document_file)"
                      />
                    </template>
                    <template v-if="!navTabs.sent.active && item.consolidation_union_label !== null
                    && item.consolidation_union_label.fedex_dbts_document_file !== null" slot="item">
                      <LinkButton
                          :value="$t('consolidationUnion_DropBall.localization_value.value')"
                          :type="'doc'"
                          @click.native="openFileByUUID(item.consolidation_union_label.fedex_dbts_document_file)"
                      />
                    </template>
                    <template v-if="!navTabs.sent.active && item.consolidation_union_label !== null
                    && item.consolidation_union_label.fedex_coof_document_file !== null" slot="item">
                      <LinkButton
                          :value="$t('consolidationUnion_certOfOrigin.localization_value.value')"
                          :type="'doc'"
                          @click.native="openFileByUUID(item.consolidation_union_label.fedex_coof_document_file)"
                      />
                    </template>
                  </ManagerButton>
                </div>
              </div>
            </td>
          </tr>

          </tbody>
        </table>

        <div class="table-card" v-if="isMobileFunc()">
          <div class="table-card__list">
            <div class="table-card__item"
                 v-for="(item, index) in $store.getters.getConsolidationUnion"
                 :key="index"
            >
              <ConsolidationUnionTableMobile
                  :item="item"
                  :navTabs="navTabs"
                  :selectedNow="selectedNow"
                  :consoUsers="consoUsers"
                  :adminUsers="adminUsers"
                  :consoTags="consoTags"
                  :filterGetParams="filterGetParams"
                  @changeCommentConsolidationUnion="changeCommentConsolidationUnion"
                  @changeFormNumberConsolidationUnion="changeFormNumberConsolidationUnion"
                  @changeConsoUserConsolidationUnion="changeConsoUserConsolidationUnion"
                  @changeTagConsolidationUnion="changeTagConsolidationUnion"
                  @createLabelConsolidationUnion="createLabelConsolidationUnion"
                  @openFileByUUID="openFileByUUID"
                  @removeItem="$emit('removeItem', item.id)"
                  @acceptInMinnesota="(id) => { $emit('acceptInMinnesota', id) }"
                  @getConsolidationUnionProform="(id) => { $emit('getConsolidationUnionProform', id) }"
                  @sendTrack="sendTrack(item.id)"
              />
            </div>

          </div>
        </div>

        <div class="table-bottom-btn">
          <div class="table-bottom-btn__inner">
            <ShowMore
                v-if="$store.getters.getConsolidationUnionCommonList.next_page_url !== null && $store.getters.getConsolidationUnion.length > 0"
                @click.native="$emit('showMore')"
                v-bind:class="{'disabled-btn' : $store.getters.getNextConsolidationUnionPage}"
                :count="$store.getters.getConsolidationUnionCommonList.total - $store.getters.getConsolidationUnionForPage * $store.getters.getConsolidationUnionCommonList.current_page < $store.getters.getConsolidationUnionForPage ?
                  $store.getters.getConsolidationUnionCommonList.total - $store.getters.getConsolidationUnionForPage * $store.getters.getConsolidationUnionCommonList.current_page :
                  $store.getters.getConsolidationUnionForPage"
            />
            <ExportBtn
                v-if="navTabs.sent.active"
                @downloadFiles="type => $emit('downloadFiles', type)"
                class="table-bottom-btn__right"/>
          </div>
        </div>
      </div>
    </template>

    <template v-if="$store.getters.getConsolidationUnionLoading === false && $store.getters.getConsolidationUnion.length === 0">
      <NoResult
          :countFilterParams="countFilterParams"
          :title="$t('common_noDataYet.localization_value.value')"
          :titleWithFilter="$t('common_noResult.localization_value.value')"
          :text="$t('common_noDataYetTxt.localization_value.value')"
          :textWithFilter="$t('common_noResultSeems.localization_value.value')"
          @resetFilter="$emit('resetFilter')"
      />
    </template>



    <!--<ProductsToWarehouse @close="closePopup" v-if="isModalProductsToWarehouse"/>-->
    <InfoPopup
      v-if="isModalInfoPopup"
      :infoPopupItem="infoPopupItem"
      @close="isModalInfoPopup = false"
    ></InfoPopup>

    <PayOrderPopup
        v-if="payOrderPopup"
        :orderPrice="orderPrice"
        :disableBtn="$store.getters.getConsolidationUnionBtn"
        @close="changePayOrderPopup(false)"
        @createLabel="createLabel(orderId, true)"
    />

  </div>
</template>

<script>
  // import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  // import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
  import ShowMore from "../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
  import LinkButton from "../../../../../../UI/buttons/LinkButton/LinkButton";
  import ManagerButton from "../../../../../../UI/buttons/ManagerButton/ManagerButton";
  import ExportBtn from '../../../../../../coreComponents/Buttons/Export/Export'
  import {mixinDetictingMobile} from '../../../../../../../mixins/mobileFunctions'
  import DefaultCheckbox from "../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import InfoPopup from "../../../../popups/InfoPopup/InfoPopup";
  import InputEditField from "../../../../../../UI/inputs/InputEditField/InputEditField";
  import ConsolidationUnionTableMobile from "./ConsolidationUnionTableMobile/ConsolidationUnionTableMobile";
  import NoResult from "../../../../../../coreComponents/NoResult/NoResult";
  import DefaultSelect from "../../../../../../UI/selectiones/DefaultSelect/DefaultSelect";
  import StatusIcoBtn from "../../../../../../UI/status/StatusIcoBtn/StatusIcoBtn";
  import ValueHelper from "../../../../../../coreComponents/ValueHelper/ValueHelper";
  import {consolidationUnionLabelTableMixin} from "../../../../../../../mixins/consolidationUnionMixins/consolidationUnionLabelMixin";
  import PayOrderPopup from "../../../../../../coreComponents/Popups/PayOrderPopup/PayOrderPopup";
  import MainButton from "@/components/UI/buttons/MainButton/MainButton";


  export default {
    name: "ConsolidationUnionTable",

    components: {
      MainButton,
      PayOrderPopup,
      ValueHelper,
      StatusIcoBtn,
      DefaultSelect,
      // ValueHelper,
      NoResult,
      ConsolidationUnionTableMobile,
      InputEditField,
      // TooltipBtn,
      // MainButton,
      // DefaultInput,
      ShowMore,
      ExportBtn,
      LinkButton,
      ManagerButton,
      // ProductsToWarehouse,
      DefaultCheckbox,
      InfoPopup,
    },

    mixins: [mixinDetictingMobile, consolidationUnionLabelTableMixin],

    props: {
      selectedNow: Boolean,
      filterGetParams: Object,
      countFilterParams: Number,
      activeTab: Number,
      navTabs: Object,
      consoUsers: Array,
      consoTags: Array,
      adminUsers: Array,
    },


    data() {
      return {
        orderId: '',
        showFilter: false,
        isMobile: false,

        showContent: false,
        hideEditField: false,

        disabledConsoUnionBtn: false,
        selectedConsoUser: null,

        selectedConsoTag: null,

        show1: false,
        show2: false,

        isModalInfoPopup: false,
        infoPopupItemId: 0,
        payOrderPopup: false,
      }
    },

    methods: {

      sendTrack(id) {
        this.$store.dispatch('getReportSendTrackingNumber', id).then(response => {
          switch (this.getResponseStatus(response)) {
              /**
               * Success
               */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
              this.openNotify('success', 'common_notificationRequestSended')
              break
            }
              /**
               * Validation Error
               */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
              /**
               * Undefined Error
               */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }
        })
      },

      closePopup(){
        // this.isModalProductsToWarehouse = false
      },

      formatAmountCharged(amount) {
        if (Number(amount) === amount && amount % 1 !== 0) {
          return parseFloat(amount).toFixed(2)
        }
        else {
          return amount
        }
      },

      changeInfoPopup(item){
        this.isModalInfoPopup = true
        this.infoPopupItem = item
      },

      changePayOrderPopup(val) {
        this.payOrderPopup = val
      },

      changeFormNumberConsolidationUnion({value, id}) {
        this.$store.dispatch('updateFormNumberConsolidationUnion', {
          id: id,
          data: {form_number: value}
        }).then((response) => {
          switch (this.getResponseStatus(response)) {
              /**
               * Success
               */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
              this.openNotify('success', 'common_notificationRecordChanged')
              let consolidationUnions = this.$store.getters.getConsolidationUnion
              this._.find(consolidationUnions, {id: id}).form_number = value
              break
            }
              /**
               * Validation Error
               */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
              /**
               * Undefined Error
               */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }
        })
      },

      changeCommentConsolidationUnion({value, id}) {
        this.$store.dispatch('updateCommentConsolidationUnion', {
          id: id,
          data: {comment: value}
        }).then((response) => {
          switch (this.getResponseStatus(response)) {
              /**
               * Success
               */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
              this.openNotify('success', 'common_notificationRecordChanged')
              let consolidationUnions = this.$store.getters.getConsolidationUnion
              this._.find(consolidationUnions, {id: id}).comment = value
              break
            }
              /**
               * Validation Error
               */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
              /**
               * Undefined Error
               */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }
        })
      },

      changeTagConsolidationUnion(id, localConsoTag = false) {
        this.selectedConsoTag = localConsoTag ? localConsoTag : this.selectedConsoTag

        if (!this.selectedConsoTag) {
          this.$store.dispatch('deleteTagConsolidationUnion', id)
          let consolidationUnions = this.$store.getters.getConsolidationUnion
          this._.find(consolidationUnions, {id: id}).tag = null
          // to prevent any odd behaviour when opening 2nd and further inputs
          this.selectedConsoTag = null
          return
        }

        this.$store.dispatch('updateTagConsolidationUnion', {
          id: id,
          data: {tag_id: this.selectedConsoTag?.id}
        }).then((response) => {
          switch (this.getResponseStatus(response)) {
              /**
               * Success
               */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
              this.openNotify('success', 'common_notificationRecordChanged')
              let consolidationUnions = this.$store.getters.getConsolidationUnion
              this._.find(consolidationUnions, {id: id}).tag = this.selectedConsoTag
              // to prevent any odd behaviour when opening 2nd and further inputs
              this.selectedConsoTag = null
              break
            }
              /**
               * Validation Error
               */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
              /**
               * Undefined Error
               */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }
        })

      },

      changeConsoUserConsolidationUnion(id, localConsoUser = false) {
        this.selectedConsoUser = localConsoUser ? localConsoUser : this.selectedConsoUser

        if (!this.selectedConsoUser) return

        this.$store.dispatch('updateConsoUserConsolidationUnion', {
          id: id,
          data: {consolidation_responsible_person_id: this.selectedConsoUser?.id}
        }).then((response) => {
          switch (this.getResponseStatus(response)) {
              /**
               * Success
               */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
              this.openNotify('success', 'common_notificationRecordChanged')
              let consolidationUnions = this.$store.getters.getConsolidationUnion
              this._.find(consolidationUnions, {id: id}).consolidation_responsible_person = {
                id: this.selectedConsoUser?.id,
                name: this.selectedConsoUser?.user_personal_contact?.user_full_name,
              }
              // to prevent any odd behaviour when opening 2nd and further inputs
              this.selectedConsoUser = null
              break
            }
              /**
               * Validation Error
               */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
              /**
               * Undefined Error
               */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }
        })
      },

      createLabelConsolidationUnion(item) {
        this.orderId = item.consolidation_union_label?.id
        if (this.orderId) {
          this.getDeliveryPriceFromTable(item.consolidation_union_label.id)
        }
      },

      createLabel(id, fromTable = false) {
        let data = {
          'consolidation_union_label_id': id
        }
        this.$store.dispatch('createConsolidationUnionLabelAPI', data).then(response => {
          if (this._.has(response, 'data') && response.data.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {

            this.openNotify('success', 'common_notificationRecordCreated')

            if(fromTable) {
              this.$emit('reload')
              if(this.payOrderPopup) this.payOrderPopup = false
              return
            }

            this.$router.push(this.$store.getters.GET_PATHS.reportsConsolidation)

          } else if (response.response.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {
            let errors = response.response.data.errors;

            this.openNotify('error', {txtServer: errors[0]})
          } else {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        })
      },

      openFileByUUID(file) {
        let routeData = this.$router.resolve({
          path: this.$store.getters.GET_PATHS.getPdfFile + '/' + file?.uuid
        })

        window.open(routeData.href, '_blank');
      },
    }

  }

</script>

<style scoped lang="scss">
  .comment-row{
    overflow: hidden;
    text-overflow: ellipsis;
    width: 356px;
    position: relative;
    display: inline-block;
    white-space: nowrap;
  }

  .table-manage-list {
    width: 240px;


    .manager-btn {
      max-width: 370px;
      width: 100%;
    }
  }


  @media (max-width: 1900px){
    .comment-row{
      width: 250px;
    }
  }

  @media (max-width: 1400px){
    .comment-row{
      width: 200px;
    }
  }

  .site-table thead th:first-child,
  .site-table tbody td:first-child{
    padding-right: 0;
  }

  .site-table thead th:nth-child(2),
  .site-table tbody td:nth-child(2){
    padding-left: 0;
  }

  .site-table thead th:last-child,
  .site-table tbody td:last-child{
    padding-right: 5px;
    padding-top: 0;
    padding-bottom: 0;
  }

  .small-table th,
  .small-table td{
    white-space: nowrap;
  }

  .conso-user-select {
    min-width: 150px;
  }

  .site-table .ellipsis {
    max-width: 180px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    display: inline-block;
  }


  .alarm-style {
    padding: 2px 4px;
    border: 1px solid #eb5757;
    border-radius: 4px;
    text-transform: uppercase;
    background-color: #eb5757;
    color: white;
    cursor: pointer;
    font-size: 12px;
  }

</style>
